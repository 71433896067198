/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */

import { Table, ScrollArea } from '@mantine/core'
import _ from 'lodash'
import React from 'react'
import {
  useTable,
  Column,
  TableOptions,
  usePagination,
  useFlexLayout,
  useRowSelect,
  useResizeColumns,
  Hooks,
  TableInstance,
} from 'react-table'
import styled from 'styled-components'
import CustomPagination from '../Pagination'
import ProgressLoader from '../ProgressLoader'
import { notEmpty } from '../../utils/ts-utils'
import IndeterminateCheckbox from './components/CheckBox'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CustomColum = Column | { cellProps?: {} }
export interface ITable extends TableOptions<any> {
  fixedHeader?: Boolean
  getInstanceCallback: (instance: TableInstance) => void
  checkboxSelection?: Boolean
  postionGiven?: Boolean
}
interface Iprops {
  colDef: any[]
  rowData: any[]
  gridApi: (instance: TableInstance) => void
  loading: boolean
  height?: any
  totalRecords: any
  checkboxSelection?: Boolean
  recordsPerPageOptions?: number[]
  recordsPerPage: any
  page: any
  onPageChange: any
  onRecordsPerPageChange: any
  total: any
  pageFrom: any
  pageTo: any
  positionGiven?: boolean
}

const StyledComponent = styled.div`
  .table {
    display: inline-block;

    text-overflow: ellipsis;
    white-space: nowrap;
    border-spacing: 0;

    .tr {
     
      z-index: 1,
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      display: inline-block;

      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      text-align: left;
      text-transform: capitalize;
      vertical-align: middle;
      margin: 0;
      :last-child {
        border-right: 0;
      }
      .td :last-child {
        overflow: scroll;
      }
      .tablehead {
        background-color: white;
        position: fixed;
        top: 1;
        z-index: 1;
        width: calc(100% - 17px);
        height: 31px;
      }
      th {
        top: 0;
        position: sticky;
        background: #e5d2f1;
        color: black;
     }
      .resizer {
        display: flex;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        touch-action: none;
      }
    }
  }
`

function StyledTable(props: ITable) {
  const { checkboxSelection, postionGiven, ...rest } = props

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 150,
      width: 200,
      maxWidth: 1000,
    }),
    []
  )
  const useInstance = (instance: any): void => {
    if (instance && instance.getInstanceCallback) {
      instance.getInstanceCallback(instance)
    }
  }
  const useCheckBox = (hooks: Hooks<any>) => {
    hooks.allColumns.push((columns) => [
      // Let's make a column for selection
      {
        id: 'selection',
        disableResizing: true,
        minWidth: 35,
        width: 35,
        maxWidth: 35,
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }: any) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }: any) => (
          <div>
            <IndeterminateCheckbox
              indeterminate
              {...row.getToggleRowSelectedProps()}
            />
          </div>
        ),
      },
      ...columns,
    ])
    hooks.useInstanceBeforeDimensions.push(({ headerGroups }: any) => {
      // fix the parent group of the selection button to not be resizable
      const selectionGroupHeader = headerGroups[0].headers[0]

      selectionGroupHeader.canResize = false
    })
  }

  const hooks = [
    usePagination,
    useFlexLayout,
    useResizeColumns,
    checkboxSelection && useRowSelect,
    checkboxSelection && useCheckBox,

    (hooks: Hooks) => hooks.useInstance.push(useInstance),
  ].filter(notEmpty)
  const instance = useTable(
    {
      ...rest,
      defaultColumn,
    },
    ...hooks
  )
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    // selectedFlatRows,
    // state: { pageIndex, pageSize, selectedRowIds },

    // page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,
  } = instance

  return (
    <div>
      <Table {...getTableProps()} className="table">
        <thead className="tableHead">
          {headerGroups.map((headerGroup, idx) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="tr"
              style={{
                position: postionGiven ? 'sticky' : 'static',
                backgroundColor: '#f4f4f4',
                top: 0,
                fontSize: '20px',
              }}
            >
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()} className="th">
                  {column.render('Header')}
                  {column.canResize && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {rows.length !== 0 && (
          <tbody>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className="tr">
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps({})} className="td">
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        )}
      </Table>
    </div>
  )
}
export default function CustomTable({
  colDef,
  rowData,
  onPageChange,
  onRecordsPerPageChange,
  totalRecords,
  recordsPerPage,
  recordsPerPageOptions,
  loading,
  total,
  gridApi,
  height,
  page,
  pageFrom,
  pageTo,
  positionGiven,
  ...rest
}: Iprops) {
  // Use the state and functions returned from useTable to build your UI
  const col = React.useMemo(() => colDef, [colDef])
  const data = React.useMemo(() => rowData, [rowData])

  // Render the UI for your table
  return (
    <StyledComponent>
      <ScrollArea style={{ height: height ? `${height}px` : '100px' }}>
        {loading && <ProgressLoader />}
        <StyledTable
          columns={col}
          data={data}
          postionGiven={positionGiven}
          {...rest}
          getInstanceCallback={gridApi}
        />
        {data.length === 0 && (
          <div
            style={{
              display: 'flex',
              height,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading
              ? 'Please wait records are loading...'
              : 'No records found'}
          </div>
        )}
      </ScrollArea>

      <div style={{ marginTop: '10px' }}>
        <CustomPagination
          total={total}
          onChange={onPageChange}
          rowPerPageOptions={
            recordsPerPageOptions?.map((val) => `${val}`) || ['10', '20', '30']
          }
          onRowPerPageChange={(value: string | null) => {
            onRecordsPerPageChange(parseInt(value || '', 10))
          }}
          rowPerPageDefault="10"
          rowPerPage={recordsPerPage}
          pageFrom={pageFrom}
          pageTo={pageTo}
          totalRecords={totalRecords}
          page={page}
        />
      </div>
      {/* <div>
        {JSON.stringify(
          {
            selectedRowIds: selectedRowIds,
            'selectedFlatRows[].original': selectedFlatRows.map(
              (d) => d.original
            ),
          },
          null,
          2
        )}
      </div> */}
    </StyledComponent>
  )
}
