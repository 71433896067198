import { Notification, Dialog } from '@mantine/core'
import { useEffect } from 'react'
import { closeSnackbar } from '../../redux/snackbar'
import { useAppDispatch } from '../../redux/store'

interface INotiProps {
  color: string
  title: string
  icon: any
  message: string
  autoHideDuriation: any
}
function CustomNotification({
  color,
  title,
  icon,
  message,
  autoHideDuriation,
}: INotiProps) {
  const dispatch = useAppDispatch()
  // Auto hide Notification bar
  useEffect(() => {
    const autoHide = setTimeout(() => {
      dispatch(closeSnackbar())
    }, autoHideDuriation || 4000)

    return () => {
      window.clearTimeout(autoHide)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoHideDuriation])

  return (
    <Notification
      icon={icon}
      color={color}
      title={title}
      onClose={() => {
        dispatch(closeSnackbar())
      }}
    >
      {message}
    </Notification>
  )
}

interface Iprops {
  open: boolean
  message?: string
  color: string
  icon: React.ReactNode
  title: string
  autoHideDuriation: number
}
function SnackBar(porps: Iprops) {
  const { open, message, icon, color, title, autoHideDuriation } = porps

  const dispatch = useAppDispatch()

  return (
    <Dialog
      opened={open}
      onClose={() => {
        dispatch(closeSnackbar())
      }}
      position={{ top: 20, left: 20 }}
      sx={{ padding: '0px !important' }}
      zIndex={10000}
    >
      {' '}
      <CustomNotification
        color={color}
        title={title}
        icon={icon}
        message={message || ''}
        autoHideDuriation={autoHideDuriation}
      />
    </Dialog>
  )
}
export default SnackBar
