import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as _ from 'lodash'
import { IconAlertCircle, TablerIcon } from '@tabler/icons'

export interface ISnackBarProps {
  open?: boolean
  messege?: string
  icon?: TablerIcon
  title: string
  color: string
  autHideDuriation?: number
}

const initialState: ISnackBarProps = {
  open: false,
  messege: '',
  color: '',
  title: '',
  autHideDuriation: 4000,
  icon: IconAlertCircle,
}

export const snackBarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar: (state, action: PayloadAction<ISnackBarProps>) => {
      state.open = true
      state.messege = action.payload.messege
      state.icon = _.get(action.payload, 'icon', IconAlertCircle)
      state.title = action.payload.title
      state.color = action.payload.color
      state.autHideDuriation = _.get(action, 'payload.autHideDuriation', 4000)
    },
    closeSnackbar: (state) => {
      state.open = false
      state.autHideDuriation = 4000
    },
  },
})

// Action creators are generated for each case reducer function
export const { openSnackbar, closeSnackbar } = snackBarSlice.actions

export default snackBarSlice.reducer
