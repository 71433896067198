import { forwardRef, useEffect, useState, KeyboardEvent } from 'react'
import {
  Grid,
  TextInput,
  Button,
  Checkbox,
  Loader,
  SelectItemProps,
  Group,
  Text,
  createStyles,
  Autocomplete,
  MantineColor,
} from '@mantine/core'
import { get } from 'lodash'
import { AxiosError, AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { AlertColor, AlertIcons, API_ENDPOINTS } from '../../../constants'
import { API_SEVICES } from '../../../config/services'
import { useAppDispatch } from '../../../redux/store'
import { openSnackbar } from '../../../redux/snackbar'
import SpinningLoader from '../../../components/SpinningLoader'

interface IProps {
  fetchData: () => void
  onClose: () => void
  mode: any
  isEditMode: any
  isViewMode: any
}
interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}
interface ItemProps extends SelectItemProps {
  _id: any
  name: string
}
const useStyles = createStyles((theme) => ({
  formikError: {
    color: '#CC0000',
    fontSize: '13px',
  },
  outline: {
    border: 'none',
    outline: 'none !important',
  },
}))

function CityModelForm(props: IProps) {
  const dispatch = useAppDispatch()
  const { onClose, mode, isEditMode, isViewMode, fetchData } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [state, setState] = useState([])
  const { classes } = useStyles()
  const navigate = useNavigate()

  useEffect(() => {
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.GET_STATE
      }?match=${JSON.stringify({
        status: 'true',
      })}`,
      (res: AxiosResponse) => {
        setState(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'State Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )
  }, [dispatch, navigate])
  const stateData = state.map((item: any) => ({
    ...item,
    value: item.label,
  }))
  const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ description, value, image, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text>{value}</Text>
          </div>
        </Group>
      </div>
    )
  )
  AutoCompleteItem.displayName = 'AutoCompleteItem'
  // eslint-disable-next-line react/display-name

  const formik = useFormik({
    initialValues: {
      labe: '',
      state: '',
      status: true,
    },
    onSubmit: (values) => {
      setLoading1(true)
      !isEditMode || isViewMode
        ? API_SEVICES.PostObjRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.CITY_DETAILS}`,
            (res: AxiosResponse) => {
              setLoading1(false)
              formik.resetForm()
              dispatch(
                openSnackbar({
                  messege: 'City Added Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              if (
                get(err, 'response.status', '') === 401 ||
                get(err, 'response.status', '') === 403
              ) {
                localStorage.clear()
                navigate('/login', { replace: true })
              }
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'City Details Cant be Added'
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              console.error(err)
            },
            values
          )
        : API_SEVICES.PatchObjRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.CITY_DETAILS}?id=${id}`,
            (res: AxiosResponse) => {
              setLoading1(false)
              formik.resetForm()
              dispatch(
                openSnackbar({
                  messege: 'City Updated Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              if (
                get(err, 'response.status', '') === 401 ||
                get(err, 'response.status', '') === 403
              ) {
                localStorage.clear()
                navigate('/login', { replace: true })
              }
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'City Details Cant be Updated'
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              console.error(err)
            },
            values
          )
    },
    validationSchema: Yup.object({
      labe: Yup.string().required('City Name is Required'),
      state: Yup.string().required('State is Required'),
    }),
  })
  useEffect(() => {
    if (id) {
      setLoading(true)
      const match = {
        _id: id,
      }

      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_ENDPOINTS.CITY_DETAILS
        }?match=${JSON.stringify(match)}`,
        (res: AxiosResponse) => {
          setLoading(false)
          formik.setFieldValue('labe', get(res, 'data.data[0].labe', ''))
          formik.setFieldValue('state', get(res, 'data.data[0].state', ''))
          formik.setFieldValue('status', get(res, 'data.data[0].status', ''))
        },
        (err: AxiosError) => {
          setLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div
      className={classes.outline}
      role="button"
      tabIndex={0}
      onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
          formik.handleSubmit()
        }
      }}
    >
      <Grid sx={{ minHeight: '200px' }}>
        {loading ? (
          <SpinningLoader />
        ) : (
          <>
            {isEditMode ? (
              <Text fz="sm" style={{ fontSize: '15px', margin: '8px' }}>
                City Name : {formik.values.labe}
              </Text>
            ) : (
              ''
            )}
            <Grid.Col xs={12}>
              <Autocomplete
                label="Select State"
                data={stateData}
                placeholder="services"
                withAsterisk
                maxDropdownHeight={200}
                itemComponent={AutoCompleteItem}
                limit={stateData.length}
                defaultValue={formik.values.state}
                onItemSubmit={(item: any) => {
                  formik.setFieldValue('state', item.label)
                }}
                onBlur={formik.handleBlur}
              />

              {formik.errors.state && formik.touched.state && (
                <div className={classes.formikError}>{formik.errors.state}</div>
              )}
            </Grid.Col>
            <Grid.Col xs={12}>
              <TextInput
                placeholder="Enter City"
                name="labe"
                id="labe"
                label="City"
                withAsterisk
                onChange={formik.handleChange}
                value={formik.values.labe}
                onBlur={formik.handleBlur}
              />
              {formik.errors.labe && formik.touched.labe && (
                <div className={classes.formikError}>{formik.errors.labe}</div>
              )}
            </Grid.Col>

            <Grid.Col xs={5}>
              <Checkbox
                checked={formik.values.status}
                required
                name="status"
                label={formik.values.status === true ? 'Active' : 'Inactive'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid.Col>
            <Grid.Col xs={2} style={{ marginLeft: '135px' }}>
              <Button
                variant="gradient"
                gradient={{ from: 'orange', to: 'red' }}
                onClick={onClose}
                disabled={loading1}
              >
                Cancel
              </Button>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Button
                variant="gradient"
                gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                onClick={() => {
                  formik.handleSubmit()
                }}
                className={
                  !(formik.dirty && formik.isValid) ? 'disabled-btn' : ''
                }
                disabled={!(formik.dirty && formik.isValid)}
                rightIcon={loading1 ? <Loader size={14} color="white" /> : ''}
                loading={loading1}
                loaderPosition="right"
                style={{ marginLeft: '5px' }}
              >
                {isEditMode ? 'Update' : 'Save'}
              </Button>
            </Grid.Col>
          </>
        )}
      </Grid>
    </div>
  )
}
export default CityModelForm
