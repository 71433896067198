/* eslint-disable react/jsx-no-useless-fragment */
import { useState } from 'react'
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  UnstyledButton,
  createStyles,
} from '@mantine/core'
import { TablerIcon, IconChevronLeft, IconChevronRight } from '@tabler/icons'
import { filter } from 'lodash'
import { Link, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../redux/store'
import { BRANDID, ROLEPROVIDED, SATHYAID } from '../../constants'

const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: 'block',
    width: '80%',
    padding: `${theme.spacing.xs}px ${theme.spacing.xs}px`,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,

    textDecoration: 'none',

    paddingLeft: 30,
    marginLeft: 30,

    borderLeft: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      width: '80%',
      borderRadius: '0px 10px 10px 0px',
    },

    '&:selected': {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      width: '80%',
    },
  },
  mainLinkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({
        variant: 'light',
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor })
        .color,
      width: '80%',
      borderRadius: '0px 10px 10px 0px',
    },
  },

  link: {
    fontWeight: 500,
    width: '90%',
    display: 'block',
    textDecoration: 'none',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    paddingLeft: 6,
    marginLeft: 10,
    fontSize: theme.fontSizes.md,
    color: 'black',

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  chevron: {
    transition: 'transform 200ms ease',
  },
}))

export interface LinksGroupProps {
  icon: TablerIcon
  label: string
  initiallyOpened?: boolean
  links?: { label: string; link: string; brand?: string }[]
  parentLink?: string
  brandData?: string
}

export function LinksGroup({
  icon: Icon,
  label,
  initiallyOpened,
  links,
  parentLink,
  brandData,
}: LinksGroupProps) {
  const { classes, theme, cx } = useStyles()
  const hasLinks = Array.isArray(links)
  const [opened, setOpened] = useState(initiallyOpened || false)
  const location = useLocation()
  const role = useAppSelector((stat) => stat.role)
  const roleProvided: string = role.role || ROLEPROVIDED
  const brandID = role.brandId || BRANDID || null
  const ChevronIcon = theme.dir === 'ltr' ? IconChevronRight : IconChevronLeft

  const items = (hasLinks ? links : []).map((link) => (
    <UnstyledButton
      onClick={() => link.link}
      key={link.label}
      className={cx(classes.control, {
        [classes.mainLinkActive]: link.link === location.pathname,
      })}
    >
      <Link
        to={link.link || '#'}
        style={{ textDecoration: 'none', color: 'green' }}
      >
        <Group position="apart" spacing={0}>
          {' '}
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
            <Box ml="md"> {link.label}</Box>
          </Box>{' '}
        </Group>
      </Link>
    </UnstyledButton>
  ))
  const filteredArray = filter(links, (o) => o.brand !== SATHYAID)

  const items1 = (hasLinks ? filteredArray : []).map((link) => (
    <UnstyledButton
      onClick={() => link.link}
      key={link.label}
      className={cx(classes.control, {
        [classes.mainLinkActive]: link.link === location.pathname,
      })}
    >
      <Link
        to={link.link || '#'}
        style={{ textDecoration: 'none', color: 'green' }}
      >
        <Group position="apart" spacing={0}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
            <Box ml="md"> {link.label}</Box>
          </Box>{' '}
        </Group>
      </Link>
    </UnstyledButton>
  ))

  return (
    <>
      {brandID?.[0] !== SATHYAID || label !== 'Admin' ? (
        <UnstyledButton
          onClick={() => setOpened((o) => !o)}
          className={classes.link}
        >
          <Link
            to={parentLink || '#'}
            style={{ textDecoration: 'none', color: 'green' }}
          >
            <Group position="apart" spacing={0}>
              {' '}
              <ThemeIcon variant="light" size={36}>
                <Icon size={20} />
              </ThemeIcon>
              <Box
                sx={{ display: 'flex', alignItems: 'center', color: 'black' }}
              >
                <Box ml="lg">{label}</Box>
              </Box>
              {hasLinks && (
                <ChevronIcon
                  className={classes.chevron}
                  size={14}
                  stroke={1.5}
                  style={{
                    transform: opened
                      ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)`
                      : 'none',
                  }}
                />
              )}
            </Group>
          </Link>
        </UnstyledButton>
      ) : (
        ''
      )}
      {hasLinks ? (
        <Collapse in={brandID?.[0] === SATHYAID ? true : opened}>
          {brandID?.[0] === SATHYAID ? items1 : items}
        </Collapse>
      ) : null}
    </>
  )
}
