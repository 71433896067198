/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/aria-unsupported-elements */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */
import {
  Box,
  Grid,
  Text,
  Group,
  SelectItemProps,
  createStyles,
  Button,
  useMantineTheme,
  Popover,
  Card,
  Skeleton,
  MantineColor,
  Dialog,
  FileInput,
  Select,
  Modal,
  Loader,
} from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { DateRangePicker, DatePicker } from '@mantine/dates'
import axios, { AxiosError, AxiosResponse } from 'axios'
import _, { get, map } from 'lodash'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import {
  IconFileUpload,
  IconFilter,
  IconTableExport,
  IconUpload,
  IconTrash,
} from '@tabler/icons'
import moment from 'moment'
import { useAppSelector } from '../../hooks'
import { API_SEVICES } from '../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
  ROLEPROVIDED,
  BRANDID,
  SATHYAID,
  STORECODE,
} from '../../constants'
import { openSnackbar } from '../../redux/snackbar'
import { useAppDispatch } from '../../redux/store'
import { getLocalStorageItem } from '../../utils/localStorage'
import CustomTable, { CustomColum } from '../../components/NewcustomTable'
import DiscoveryCard from '../../components/discoveryCard'
import BarChat from '../../components/Barchat'
import CustomDetailCard from './CustomDetailCard'
import DetailCard from './DetailCard'
import GMBDelete from './GMBDelete'

interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  description: string
  cityName: string
}

interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}

const useStyles = createStyles((theme) => ({
  outside: {
    opacity: 0,
  },

  weekend: {
    color: `${theme.colors.blue[6]} !important`,
  },

  selected: {
    color: `${theme.white} !important`,
  },
  tableBodyStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

/* eslint @typescript-eslint/no-var-requires: "off" */
const FileDownload = require('js-file-download')

export default function Discovery() {
  const filterRef = useRef<HTMLInputElement>(null)
  const paperRef = useRef<HTMLInputElement>(null)
  const theme = useMantineTheme()
  const [uploadDrawer, setUploadDrawer] = useState(false)
  const [tableHeight, setTableHeight] = useState(10)
  const [loading, setLoading] = useState(false)
  const [recordData, setRecordData] = useState([])
  const [state, setState] = useState([])
  const [stateData, setStateData] = useState([])
  const [city, setCity] = useState([])
  const [page, setPage] = useState(1)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState(false)
  const [pageSize, setPageSize] = useState(PAGESIZES[0])
  const [storeVal, setStoreVal] = useState<any>(null)
  const [brandVal, setBrandVal] = useState<any>(null)
  const [countFrom, setCountForm] = useState<any>()
  const [countTo, setCountTo] = useState<any>()
  const [brandData, setBrandData] = useState([])
  const [totalRowSize, setTotalRowSize]: any = useState(0)
  const [selectedDate, setSelectedDate] = useState<any>(null)
  const [stateValue, setStateValue] = useState(null)
  const [cityValue, setCityValue] = useState(null)
  const [fromDate, setFromDate] = useState<any>(null)
  const [todate, setToDate] = useState<any>(new Date())
  const [rangedate, setRangeDate] = useState<any>([])
  const [discoverySearch, setDiscoverSearch] = useState<any>([])
  const [directSearch, setDirectSearch] = useState<any>([])
  const [totalSearch, setTotalSearch] = useState<any>([])
  const [fileData, setFileData] = useState<any>(null)
  const [uploadBrand, setUploadBrand] = useState<any>(null)
  const [download, setDownload] = useState(false)
  const [UploadLoading, setUploadLoading] = useState(false)
  const [availableDate, setAvailableDate] = useState<any>(null)
  const [exportLoading, setExportLoading] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [stateLoading, setStateLoading] = useState(false)
  const [cityLoading, setCityLoading] = useState(false)
  const [opened, setOpened] = useState(false)
  const { classes, cx } = useStyles()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const role = useAppSelector((stat) => stat.role)
  const brandID = role.brandId || BRANDID || null
  const roleProvided = role.role || ROLEPROVIDED
  const storeCode = role.storeCode || STORECODE || null
  const Token = getLocalStorageItem('token')

  useEffect(() => {
    setLoading(true)
    const controller = new AbortController()
    let rangeMatch
    if (brandVal === null) {
      if (roleProvided === 'admin') {
        rangeMatch = JSON.stringify({ brandId: brandID })
      } else if (roleProvided === 'CRM manager') {
        rangeMatch = JSON.stringify({ brandId: { $in: brandID } })
      } else if (roleProvided === 'brand manager') {
        rangeMatch = JSON.stringify({ brandId: brandID })
      } else if (roleProvided === 'super admin') {
        rangeMatch = JSON.stringify({})
      } else if (roleProvided === 'user') {
        rangeMatch = JSON.stringify({ brandId: brandID, storeId: storeCode })
      } else if (roleProvided === 'admin manager') {
        rangeMatch = JSON.stringify({ brandId: brandID, storeId: storeCode })
      }
    } else if (brandVal !== null) {
      rangeMatch = JSON.stringify({ brandId: brandVal })
    }

    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_AVAILABLE_DATES}?match=${rangeMatch}`,
        {
          signal: controller.signal,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-type': 'Application/json',
            Authorization: `Bearer ${Token}`,
          },
        }
      )
      .then((response: AxiosResponse) => {
        if (Object.keys(response.data.data).length === 0) {
          setFromDate(null)
          setToDate(null)
          setRangeDate([new Date(), new Date()])
        } else {
          setFromDate(moment(response.data.data.startDate).format('YYYY-MM-DD'))
          setToDate(moment(response.data.data.endDate).format('YYYY-MM-DD'))
        }

        let match
        if (stateValue === null && brandVal === null && storeVal === null) {
          if (roleProvided === 'super admin') {
            match = {}
          } else if (roleProvided === 'admin' && brandID) {
            match = { brandId: brandID }
          } else if (roleProvided === 'CRM manager' && brandID) {
            match = { brandId: { $in: brandID } }
          } else if (roleProvided === 'brand manager' && brandID) {
            match = { brandId: brandID }
          } else if (roleProvided === 'user' && brandID) {
            match = { brandId: brandID, storeId: storeCode }
          } else if (roleProvided === 'admin manager' && brandID) {
            match = { brandId: brandID, storeId: storeCode }
          }
        } else if (roleProvided === 'admin' && brandID) {
          match = {
            brandId: brandID,
            state: stateValue,
            city: cityValue,
            storeId: storeVal !== null ? [storeVal] : null,
          }
        } else if (roleProvided === 'admin manager' && brandID) {
          match = {
            brandId: brandID,
            state: stateValue,
            city: cityValue,
            storeId: storeVal !== null ? [storeVal] : null,
          }
        } else if (roleProvided === 'brand manager' && brandID) {
          match = {
            brandId: brandID,
            state: stateValue,
            city: cityValue,
            storeId: storeVal !== null ? [storeVal] : null,
          }
        } else {
          match = {
            brandId: brandVal,
            state: stateValue,
            city: cityValue,
            storeId: storeVal !== null ? [storeVal] : null,
          }
        }

        const clean = (obj: any) => {
          for (const propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
              delete obj[propName]
            }
          }
          return obj
        }
        const mat = clean(match)
        const match1 = JSON.stringify(mat)

        setLoading(true)
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_ACTION_TABLE}?pageNo=${page}&size=${pageSize}`,
            {
              from:
                rangedate.length === 0
                  ? moment(response?.data.data.endDate).format('YYYY-MM-DD')
                  : moment(rangedate[0]).format('YYYY-MM-DD'),
              to:
                rangedate.length === 0
                  ? moment(response?.data.data.endDate)
                      .add(1, 'day')
                      .format('YYYY-MM-DD')
                  : moment(rangedate[1]).add(1, 'day').format('YYYY-MM-DD'),
              match: match1,
            },
            {
              signal: controller.signal,
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-type': 'Application/json',
                Authorization: `Bearer ${Token}`,
              },
            }
          )
          .then((res: AxiosResponse) => {
            setLoading(false)
            setTotalRowSize(res?.data?.data?.total)
            setRecordData(res?.data?.data?.data)
          })
          .catch((error) => {
            setLoading(false)
            if (
              get(error, 'response.status', '') === 401 ||
              get(error, 'response.status', '') === 403
            ) {
              localStorage.clear()
              navigate('/login', { replace: true })
            }
            dispatch(
              openSnackbar({
                messege: 'Store Not Found',
                icon: AlertIcons.error,
                title: 'error',
                color: AlertColor.error,
              })
            )
            console.error(error)
          })
      })
      .catch((error) => {
        // handle error
        setLoading(false)
        if (
          get(error, 'response.status', '') === 401 ||
          get(error, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        console.error(error)
      })
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
  }, [search, page, pageSize])

  useEffect(() => {
    let dateMatch
    const controller = new AbortController()
    if (brandVal === null) {
      if (roleProvided === 'admin') {
        dateMatch = JSON.stringify({ brandId: brandID })
      } else if (roleProvided === 'CRM manager') {
        dateMatch = JSON.stringify({ brandId: { $in: brandID } })
      } else if (roleProvided === 'brand manager') {
        dateMatch = JSON.stringify({ brandId: brandID })
      } else if (roleProvided === 'super admin') {
        dateMatch = JSON.stringify({})
      } else if (roleProvided === 'user') {
        dateMatch = JSON.stringify({ brandId: brandID, storeId: storeCode })
      } else if (roleProvided === 'admin manager') {
        dateMatch = JSON.stringify({ brandId: brandID, storeId: storeCode })
      }
    } else if (brandVal !== null) {
      dateMatch = JSON.stringify({ brandId: brandVal })
    }

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_AVAILABLE_DATES}?match=${dateMatch}`,
      (res: AxiosResponse) => {
        if (Object.keys(res.data.data).length === 0) {
          setFromDate(null)
          setToDate(null)
          setRangeDate([new Date(), new Date()])
        } else {
          setFromDate(moment(res.data.data.startDate).format('YYYY-MM-DD'))
          setToDate(moment(res.data.data.endDate).format('YYYY-MM-DD'))
          setRangeDate([
            dayjs(res.data.data.endDate).toDate(),
            dayjs(res.data.data.endDate).toDate(),
          ])
        }
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Available Date Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
  }, [brandVal])

  useEffect(() => {
    const dateMatch = JSON.stringify({ brandId: uploadBrand })

    if (uploadBrand !== null) {
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_AVAILABLE_DATES}?match=${dateMatch}`,
        (res: AxiosResponse) => {
          if (Object.keys(res.data.data).length === 0) {
            setAvailableDate(null)
          } else {
            setAvailableDate(moment(res.data.data.endDate).format('YYYY-MM-DD'))
          }
        },
        (err: AxiosError) => {
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Available Date Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          console.error(err)
        }
      )
    }
  }, [uploadBrand])

  useEffect(() => {
    const controller = new AbortController()
    let match
    if (brandID.length > 0) {
      match = JSON.stringify({ _id: brandID, status: true })
    } else {
      match = JSON.stringify({ status: true })
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${match}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                ' Brand Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.GET_CITY_DETAILS
      }?match=${JSON.stringify({
        state: stateValue,
        brandId: brandVal || brandID,
        status: 'true',
      })}`,
      (res: AxiosResponse) => {
        setCityLoading(false)
        const statesArray: any = map(
          res.data.data,
          (values: any) => values
        )?.map((option: any) => option.city)

        function removeDuplicates(arr: any) {
          return arr.filter(
            (item: any, index: any) => arr.indexOf(item) === index
          )
        }

        setCity(removeDuplicates(statesArray))
      },
      (err: AxiosError) => {
        setCityLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                ' City Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.GET_STATE_DETAILS
      }?match=${JSON.stringify({
        status: 'true',
        brandId: brandVal || brandID,
      })}`,
      (res: AxiosResponse) => {
        setStateLoading(false)
        const statesArray: any = map(
          res.data.data,
          (values: any) => values
        )?.map((option: any) => option.state)

        function removeDuplicates(arr: any) {
          return arr.filter(
            (item: any, index: any) => arr.indexOf(item) === index
          )
        }

        setStateData(removeDuplicates(statesArray))
      },
      (err: AxiosError) => {
        setStateLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'State Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    let storeMatch

    if (brandVal !== null) {
      storeMatch = JSON.stringify({ brandId: brandVal, status: true })
    } else if (brandID && roleProvided === 'admin manager') {
      storeMatch = JSON.stringify({
        brandId: brandID?.[0],
        status: true,
        storeId: storeCode,
      })
    } else if (brandID && brandVal === null) {
      storeMatch = JSON.stringify({ brandId: brandID?.[0], status: true })
    } else {
      storeMatch = JSON.stringify({ status: true })
    }

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_STORE}?match=${storeMatch}`,
      (res: AxiosResponse) => {
        setState(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Store Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandVal, stateValue, download])

  const storeDetails = state.map((item: any) => ({
    ...item,
    value: item.storeId,
    label: item.storeName,
    description: item.locality,
    cityName: item.city,
  }))
  const brandDetails = brandData.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.name,
  }))
  const stateDetail = stateData?.map((item: any) => ({
    ...item,
    value: item,
    label: item,
  }))
  const cityDetail = city?.map((item: any) => ({
    ...item,
    value: item,
    label: item,
  }))

  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, name, description, cityName, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            {description || cityName ? (
              <Text size="xs" opacity={0.65}>
                {description}-{cityName}
              </Text>
            ) : null}
          </div>
        </Group>
      </div>
    )
  )
  function fetchData() {
    setSearch(true)
    setPage(1)
    setPageNo(1)
    setTotalSearch([])
    setDirectSearch([])
    setDiscoverSearch([])
    setRecordData([])
    setTotalRowSize(0)
    setRangeDate([])
    setFromDate(null)
    setToDate(new Date())
  }
  SelectItem.displayName = 'SelectItem'
  useEffect(() => {
    let rowDataSize

    if (totalRowSize >= pageSize) {
      rowDataSize = totalRowSize / pageSize

      if (rowDataSize - Math.floor(rowDataSize) !== 0) {
        setPageNo(Math.ceil(rowDataSize))
      } else {
        setPageNo(rowDataSize)
      }
    } else {
      rowDataSize = Math.ceil(totalRowSize / pageSize)
      setPageNo(rowDataSize)
    }
  }, [pageSize, totalRowSize, recordData, download])

  const onDownload = () => {
    setExportLoading(true)
    let match
    if (stateValue === null && brandVal === null && storeVal === null) {
      if (roleProvided === 'super admin') {
        match = {}
      } else if (roleProvided === 'admin' && brandID) {
        match = { brandId: brandID }
      } else if (roleProvided === 'CRM manager' && brandID) {
        match = { brandId: brandID }
      } else if (roleProvided === 'brand manager' && brandID) {
        match = { brandId: brandID }
      } else if (roleProvided === 'user' && brandID) {
        match = { brandId: brandID, storeId: storeCode }
      } else if (roleProvided === 'admin manager' && brandID) {
        match = { brandId: brandID, storeId: storeCode }
      }
    } else if (roleProvided === 'admin' && brandID) {
      match = {
        brandId: brandID,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'brand manager' && brandID) {
      match = {
        brandId: brandID,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'admin manager' && brandID) {
      match = {
        brandId: brandID,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else {
      match = {
        brandId: brandVal,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    }

    const clean = (obj: any) => {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
      return obj
    }
    const mat = clean(match)

    const match1 = JSON.stringify(mat)
    const fromMatch =
      rangedate.length === 0
        ? moment(todate).format('YYYY-MM-DD')
        : moment(rangedate[0]).format('YYYY-MM-DD')
    const toMatch =
      rangedate.length === 0
        ? moment(todate).add(1, 'day').format('YYYY-MM-DD')
        : moment(rangedate[1]).add(1, 'day').format('YYYY-MM-DD')
    const path = `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.EXPORT_GMB_INSIGHTS}`
    axios({
      url: path,
      headers: {
        Authorization: `Bearer ${Token}`,
      },

      method: 'post',
      responseType: 'blob', // important
      data: {
        from: fromMatch,
        to: toMatch,
        match: match1,
      },
    })
      .then((res) => {
        setExportLoading(false)
        FileDownload(res.data, `Close_Bi( ${fromMatch}_${toMatch}).xlsx`)
      })
      .catch((error) => {
        setExportLoading(false)
        if (
          get(error, 'response.status', '') === 401 ||
          get(error, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              error,
              'response.data.message',
              'Failed To Download File'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error('download file error', error)
      })
  }

  useEffect(() => {
    setSearchLoading(true)
    let match1
    const controller = new AbortController()

    if (stateValue === null && brandVal === null && storeVal === null) {
      if (roleProvided === 'super admin') {
        match1 = {}
      } else if (roleProvided === 'admin' && brandID) {
        match1 = { brandId: brandID }
      } else if (roleProvided === 'CRM manager' && brandID) {
        match1 = { brandId: brandID }
      } else if (roleProvided === 'brand manager' && brandID) {
        match1 = { brandId: brandID }
      } else if (roleProvided === 'user' && brandID) {
        match1 = { brandId: brandID, storeId: storeCode }
      } else if (roleProvided === 'admin manager' && brandID) {
        match1 = { brandId: brandID, storeId: storeCode }
      }
    } else if (roleProvided === 'admin' && brandID) {
      match1 = {
        brandId: brandID,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'admin manager' && brandID) {
      match1 = {
        brandId: brandID,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'brand manager' && brandID) {
      match1 = {
        brandId: brandID,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'CRM manager' && brandID) {
      match1 = {
        brandId: brandVal,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else {
      match1 = {
        brandId: brandVal,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    }

    const clean = (obj: any) => {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
      return obj
    }

    const mat = clean(match1)

    const match = JSON.stringify(mat)

    API_SEVICES.PostObjRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_ANALYTICS_GRAPH}`,
      (res: AxiosResponse) => {
        setSearchLoading(false)

        setDirectSearch(_.get(res, 'data.data', [])[0])
      },
      (err: AxiosError) => {
        setSearchLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
      },
      {
        date: todate,
        match: match,
        type: 'calls',
      },
      controller.signal
    )

    API_SEVICES.PostObjRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_ANALYTICS_GRAPH}`,
      (res: AxiosResponse) => {
        setSearchLoading(false)

        setDiscoverSearch(_.get(res, 'data.data', [])[0])
      },
      (err: AxiosError) => {
        setSearchLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        console.error(err)
      },
      {
        date: todate,
        match: match,
        type: 'googleMapsMobile',
      },
      controller.signal
    )
    API_SEVICES.PostObjRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_ANALYTICS_GRAPH}`,
      (res: AxiosResponse) => {
        setSearchLoading(false)

        setTotalSearch(_.get(res, 'data.data', [])[0])
      },
      (err: AxiosError) => {
        setSearchLoading(false)
        console.error(err)
      },
      {
        date: todate,
        match: match,
        type: 'googleSearchMobile',
      },
      controller.signal
    )
    setSearch(false)
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
  }, [search, download, todate])

  const colDef: CustomColum[] = useMemo(
    () => [
      {
        accessor: 'storeCode',
        Header: () => (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            Store Code
          </div>
        ),
        textAlignment: 'center',
        width: 10,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.storeCode}
          </p>
        ),
      },
      {
        accessor: 'businessName',
        Header: 'Business Name',
        textAlignment: 'center',
        minWidth: 300,
        Cell: ({ ...value }: any) => (
          <p>
            {value.row.original.businessName}
            <div
              style={{
                display: 'flex',
                fontSize: '13px',
                color: 'grey',
                borderSpacing: 0,
              }}
            >
              {value.row.original.address}
            </div>
          </p>
        ),
      },
      {
        accessor: 'date',
        textAlignment: 'center',
        minWidth: 40,
        Header: () => (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            Date
          </div>
        ),
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.date.substring(0, 10)}
          </p>
        ),
      },

      {
        accessor: 'googleSearchMobile',
        Header: () => (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            Google Search Mobile
          </div>
        ),
        minWidth: 40,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.googleSearchMobile}
          </p>
        ),
      },

      {
        accessor: 'googleSearchDesktop',
        textAlignment: 'center',
        minWidth: 100,
        Header: () => (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            Google Search Desktop
          </div>
        ),
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.googleSearchDesktop}
          </p>
        ),
      },
      {
        accessor: 'googleMapsMobile',
        textAlignment: 'center',
        Header: () => (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            Google Maps Mobile
          </div>
        ),
        minWidth: 100,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.googleMapsMobile}
          </p>
        ),
      },
      {
        accessor: 'googleMapsDesktop',
        textAlignment: 'center',
        Header: () => (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            Google Maps Desktop
          </div>
        ),
        minWidth: 100,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.googleMapsDesktop}
          </p>
        ),
      },
      {
        accessor: 'calls',
        textAlignment: 'center',
        Header: () => (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            Calls
          </div>
        ),
        minWidth: 100,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>{value.row.original.calls}</p>
        ),
      },
      {
        accessor: 'directions',
        textAlignment: 'center',
        Header: () => (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            Directions
          </div>
        ),
        minWidth: 100,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.directions}
          </p>
        ),
      },

      {
        accessor: 'websiteclicks',
        textAlignment: 'center',
        Header: () => (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            Web Site Clicks
          </div>
        ),
        minWidth: 100,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.websiteclicks}
          </p>
        ),
      },
    ],
    []
  )

  useEffect(() => {
    if (filterRef.current != null) {
      const element = paperRef.current?.getClientRects()
      setTableHeight(window.innerHeight - filterRef.current.clientHeight - 110)
    }
    if (totalRowSize > pageSize) {
      const from = (page - 1) * pageSize
      const to = from + pageSize
      setCountForm(from + 1)
      setCountTo(to)
    } else {
      setCountForm(1)
      setCountTo(totalRowSize)
    }
  }, [page, pageSize, recordData, theme, totalRowSize])
  useEffect(() => {
    let rowDataSize
    if (totalRowSize >= pageSize) {
      rowDataSize = totalRowSize / pageSize
      if (rowDataSize - Math.floor(rowDataSize) !== 0) {
        setPageNo(Math.ceil(rowDataSize))
      } else {
        setPageNo(rowDataSize)
      }
    } else {
      rowDataSize = Math.ceil(totalRowSize / pageSize)
      setPageNo(rowDataSize)
    }
  }, [pageSize, totalRowSize, recordData])

  const lables = [
    '7 Day',
    `${moment().format('MMMM')}`,
    `${moment().subtract(1, 'months').format('MMMM')}`,
    '3 month',
    '6 month',
  ]

  const handleChange = (file: any) => {
    const fileName = moment(file.name, 'YYYY-MM-DD').format()
    const dateChoosed = moment(selectedDate, 'YYYY-MM-DD').format()
    if (moment(fileName).isSame(dateChoosed)) {
      setFileData(file)
    } else {
      dispatch(
        openSnackbar({
          messege: 'Invalid File Name',
          icon: AlertIcons.error,
          title: 'error',
          color: AlertColor.error,
        })
      )
    }
  }

  function handleSubmit(event: any) {
    event.preventDefault()
    setUploadLoading(true)
    const formData = new FormData()
    formData.append('insightFile', fileData)
    formData.append('date', selectedDate)
    formData.append('brand', uploadBrand)

    API_SEVICES.PostRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.UPLOAD_GMB_INSIGHTS}`,
      (res: AxiosResponse) => {
        setUploadDrawer(false)
        setSearch(true)
        setTotalSearch([])
        setDirectSearch([])
        setDiscoverSearch([])
        setRecordData([])
        setTotalRowSize(0)
        setDownload(true)
        setUploadBrand(null)
        setUploadLoading(false)

        dispatch(
          openSnackbar({
            messege: 'GMB Insights Data Upload Sucessfully',
            icon: AlertIcons.success,
            title: 'Success',
            color: AlertColor.success,
          })
        )
      },
      (err: AxiosError) => {
        setUploadLoading(false)
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'GMB Insights Data Cant beUpload '
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      },
      formData
    )
  }

  return (
    <div>
      <Grid>
        <Grid.Col xs={12} ref={filterRef}>
          {brandID?.[0] !== SATHYAID &&
          roleProvided !== 'user' &&
          roleProvided !== 'brand manager' &&
          roleProvided !== 'CRM manager' &&
          roleProvided !== 'admin manager' &&
          roleProvided !== 'admin' ? (
            <Grid>
              <Grid.Col xs={12} sm={4} md={4} lg={6} xl={6}>
                <Text
                  weight={100}
                  style={{
                    // marginTop: ' 10px',
                    fontSize: '20px',
                    fontWeight: 500,
                  }}
                >
                  Discovery
                </Text>
              </Grid.Col>
              <Grid.Col xs={12} sm={2} md={2} lg={1.5} xl={1.5}>
                <Popover
                  width={350}
                  position="bottom"
                  withArrow
                  shadow="xl"
                  styles={() => ({
                    dropdown: {
                      backgroundColor: '#f0f6f7',
                    },
                  })}
                >
                  <Popover.Target>
                    <Button
                      sx={{
                        width: '100%',
                        backgroundColor: 'rgb(160, 213, 239)',
                        '&[data-disabled]': { opacity: 0.4 },
                        '&:hover': {
                          backgroundColor: 'rgb(160, 213, 239)',
                          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        },
                      }}
                      disabled={loading}
                    >
                      <IconFilter color="black" />
                      <span style={{ marginLeft: '6px', color: 'black' }}>
                        Filter
                      </span>
                    </Button>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      {' '}
                      {roleProvided === 'super admin' ||
                      roleProvided === 'CRM manager' ? (
                        <Select
                          label="Select Brand"
                          placeholder="Select Brand"
                          itemComponent={SelectItem}
                          data={brandDetails}
                          limit={brandDetails.length}
                          maxDropdownHeight={200}
                          disabled={brandDetails.length === 0}
                          value={brandVal}
                          nothingFound="No Data Found"
                          clearable
                          onChange={(value: any) => {
                            setStateLoading(true)
                            setFromDate(null)

                            setRangeDate([])
                            setBrandVal(value)
                            setStateValue(null)
                            setStoreVal(null)
                            setCityValue(null)
                          }}
                          styles={() => ({
                            item: {
                              // applies styles to selected item
                              '&[data-selected]': {
                                '&, &:hover': {
                                  backgroundColor:
                                    theme.colorScheme === 'dark'
                                      ? theme.colors.teal[9]
                                      : theme.colors.teal[1],
                                  color:
                                    theme.colorScheme === 'dark'
                                      ? theme.white
                                      : theme.colors.teal[9],
                                },
                              }, // applies styles to hovered item (with mouse or keyboard)

                              '&[data-hovered]': {},
                            },
                          })}
                        />
                      ) : null}
                    </Grid.Col>
                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <DateRangePicker
                        label="Select Date Range"
                        placeholder="Pick dates range"
                        minDate={moment(fromDate).toDate()}
                        allowSingleDateInRange={true}
                        maxDate={moment(todate).toDate()}
                        disabled={fromDate === null}
                        value={
                          rangedate.length === 0
                            ? [moment(todate).toDate(), moment(todate).toDate()]
                            : rangedate
                        }
                        onChange={(i: any) => {
                          setPage(1)
                          if (i[0] && i[1]) {
                            setRangeDate(i)
                            setPage(1)
                            setPageNo(1)
                          }
                        }}
                        dayClassName={(date, modifiers) =>
                          cx({
                            [classes.outside]: modifiers.outside,
                          })
                        }
                      />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Text fz="sm" style={{ fontWeight: 500 }}>
                        Select State{' '}
                        {stateLoading ? (
                          <span style={{ margin: 5 }}>
                            <Loader size={15} />
                          </span>
                        ) : (
                          ''
                        )}
                      </Text>
                      <Select
                        placeholder="Select state"
                        clearable
                        searchable
                        itemComponent={SelectItem}
                        data={stateDetail}
                        limit={stateDetail.length}
                        disabled={stateDetail.length === 0 || fromDate === null}
                        maxDropdownHeight={200}
                        nothingFound="No Data Found"
                        value={stateValue}
                        onChange={(value: any) => {
                          setCityLoading(true)
                          setStateValue(value)
                          setStoreVal(null)
                          setCityValue(null)
                        }}
                        styles={() => ({
                          item: {
                            // applies styles to selected item
                            '&[data-selected]': {
                              '&, &:hover': {
                                backgroundColor:
                                  theme.colorScheme === 'dark'
                                    ? theme.colors.teal[9]
                                    : theme.colors.teal[1],
                                color:
                                  theme.colorScheme === 'dark'
                                    ? theme.white
                                    : theme.colors.teal[9],
                              },
                            }, // applies styles to hovered item (with mouse or keyboard)

                            '&[data-hovered]': {},
                          },
                        })}
                      />
                    </Grid.Col>

                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Text fz="sm" style={{ fontWeight: 500 }}>
                        Select City{' '}
                        {cityLoading ? (
                          <span style={{ margin: 5 }}>
                            <Loader size={15} />
                          </span>
                        ) : (
                          ''
                        )}
                      </Text>
                      <Select
                        // label="Select City"
                        placeholder="Select city"
                        clearable
                        searchable
                        itemComponent={SelectItem}
                        data={cityDetail}
                        limit={cityDetail.length}
                        disabled={cityDetail.length === 0}
                        maxDropdownHeight={200}
                        nothingFound="No Data Found"
                        value={cityValue}
                        onChange={(value: any) => {
                          setCityValue(value)
                        }}
                        styles={() => ({
                          item: {
                            // applies styles to selected item
                            '&[data-selected]': {
                              '&, &:hover': {
                                backgroundColor:
                                  theme.colorScheme === 'dark'
                                    ? theme.colors.teal[9]
                                    : theme.colors.teal[1],
                                color:
                                  theme.colorScheme === 'dark'
                                    ? theme.white
                                    : theme.colors.teal[9],
                              },
                            }, // applies styles to hovered item (with mouse or keyboard)

                            '&[data-hovered]': {},
                          },
                        })}
                      />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      {roleProvided === 'super admin' ||
                      (brandID !== null && roleProvided === 'admin') ||
                      (brandID !== null && roleProvided === 'CRM manager') ||
                      roleProvided === 'brand manager' ? (
                        <Select
                          label="Select Store"
                          placeholder="Select store"
                          clearable
                          searchable
                          disabled={
                            fromDate === null || storeDetails.length === 0 // notify
                          }
                          itemComponent={SelectItem}
                          data={storeDetails}
                          limit={storeDetails.length}
                          maxDropdownHeight={300}
                          nothingFound="No Data Found"
                          value={storeVal}
                          onChange={(value: any) => {
                            setStoreVal(value)
                            setStateValue(null)
                            setCityValue(null)
                          }}
                          styles={() => ({
                            item: {
                              // applies styles to selected item
                              '&[data-selected]': {
                                '&, &:hover': {
                                  backgroundColor:
                                    theme.colorScheme === 'dark'
                                      ? theme.colors.teal[9]
                                      : theme.colors.teal[1],
                                  color:
                                    theme.colorScheme === 'dark'
                                      ? theme.white
                                      : theme.colors.teal[9],
                                },
                              },

                              // applies styles to hovered item (with mouse or keyboard)
                              '&[data-hovered]': {},
                            },
                          })}
                        />
                      ) : null}
                    </Grid.Col>
                    <Grid.Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{ display: 'flex', justifyContent: 'right' }}
                    >
                      <Button
                        sx={{
                          marginTop: '5px',
                          backgroundColor: 'rgb(160, 213, 239)',
                          color: '#000',
                          '&[data-disabled]': { opacity: 0.4 },
                          '&:hover': {
                            backgroundColor: 'rgb(160, 213, 239)',
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                          },
                        }}
                        onClick={() => {
                          setSearch(true)
                          setPage(1)
                          setPageNo(1)
                          setTotalSearch([])
                          setDirectSearch([])
                          setDiscoverSearch([])
                          setRecordData([])
                          setTotalRowSize(0)
                        }}
                        loading={searchLoading}
                        // disabled={fromDate === null}
                      >
                        <Text color="black">Apply</Text>
                      </Button>
                    </Grid.Col>
                  </Popover.Dropdown>
                </Popover>
              </Grid.Col>

              <Grid.Col xs={12} sm={2} md={2} lg={1.5} xl={1.5}>
                {' '}
                <Button
                  sx={{
                    // marginTop: '15px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'rgb(160, 213, 239)',
                    '&[data-disabled]': { opacity: 0.4 },
                    '&:hover': {
                      backgroundColor: 'rgb(160, 213, 239)',
                      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    },
                  }}
                  onClick={() => {
                    onDownload()
                  }}
                  gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                  disabled={recordData.length === 0}
                  loading={exportLoading}
                >
                  {!exportLoading ? <IconTableExport color="black" /> : ''}
                  <Text color="black" style={{ marginLeft: '10px' }}>
                    Export
                  </Text>
                </Button>
              </Grid.Col>

              <Grid.Col xs={12} sm={2} md={2} lg={1.5} xl={1.5}>
                <Button
                  sx={{
                    // marginTop: '15px',
                    width: '100%',
                    backgroundColor: 'rgb(160, 213, 239)',
                    '&[data-disabled]': { opacity: 0.4 },
                    '&:hover': {
                      backgroundColor: 'rgb(160, 213, 239)',
                      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    },
                  }}
                  onClick={() => {
                    setUploadDrawer(true)
                  }}
                  gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                >
                  <IconFileUpload color="black" />
                  <Text color="black" style={{ marginLeft: '10px' }}>
                    Upload
                  </Text>
                </Button>
              </Grid.Col>
              <Grid.Col xs={12} sm={2} md={2} lg={1.5} xl={1.5}>
                <Button
                  sx={{
                    // marginTop: '15px',
                    width: '100%',
                    backgroundColor: 'rgb(160, 213, 239)',
                    '&[data-disabled]': { opacity: 0.4 },
                    '&:hover': {
                      backgroundColor: 'rgb(160, 213, 239)',
                      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    },
                  }}
                  onClick={() => {
                    setOpened(true)
                  }}
                  gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                >
                  <IconTrash color="black" />
                  <Text color="black" style={{ marginLeft: '10px' }}>
                    Delete
                  </Text>
                </Button>
              </Grid.Col>
            </Grid>
          ) : (
            <Grid>
              <Grid.Col xl={9} lg={9} md={8} xs={12} sm={8}>
                <Text
                  weight={100}
                  style={{
                    marginTop: ' 10px',
                    fontSize: '20px',
                    fontWeight: 500,
                  }}
                >
                  Discovery
                </Text>
              </Grid.Col>
              <Grid.Col xl={1.5} lg={1.5} md={2} xs={12} sm={2}>
                <Popover
                  width={350}
                  position="bottom"
                  withArrow
                  shadow="xl"
                  styles={() => ({
                    dropdown: {
                      backgroundColor: '#f0f6f7',
                    },
                  })}
                >
                  <Popover.Target>
                    <Button
                      sx={{
                        width: '100%',
                        backgroundColor: 'rgb(160, 213, 239)',
                        '&[data-disabled]': { opacity: 0.4 },
                        '&:hover': {
                          backgroundColor: 'rgb(160, 213, 239)',
                          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        },
                      }}
                    >
                      <IconFilter color="black" />
                      <span style={{ marginLeft: '6px', color: 'black' }}>
                        Filter
                      </span>
                    </Button>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      {' '}
                      {roleProvided === 'CRM manager' ? (
                        <Select
                          label="Select Brand"
                          placeholder="Select Brand"
                          itemComponent={SelectItem}
                          data={brandDetails}
                          limit={brandDetails.length}
                          disabled={brandDetails.length === 0}
                          maxDropdownHeight={200}
                          value={brandVal}
                          nothingFound="No Data Found"
                          clearable
                          onChange={(value: any) => {
                            setStateLoading(true)
                            setPage(1)
                            setPageNo(1)
                            setFromDate(null)
                            setRangeDate([])
                            setBrandVal(value)
                            setStateValue(null)
                            setStoreVal(null)
                            setCityValue(null)
                          }}
                          styles={() => ({
                            item: {
                              // applies styles to selected item
                              '&[data-selected]': {
                                '&, &:hover': {
                                  backgroundColor:
                                    theme.colorScheme === 'dark'
                                      ? theme.colors.teal[9]
                                      : theme.colors.teal[1],
                                  color:
                                    theme.colorScheme === 'dark'
                                      ? theme.white
                                      : theme.colors.teal[9],
                                },
                              }, // applies styles to hovered item (with mouse or keyboard)

                              '&[data-hovered]': {},
                            },
                          })}
                        />
                      ) : null}
                    </Grid.Col>
                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <DateRangePicker
                        label="Select Date Range"
                        placeholder="Pick dates range"
                        minDate={moment(fromDate).toDate()}
                        allowSingleDateInRange={true}
                        maxDate={moment(todate).toDate()}
                        disabled={fromDate === null}
                        value={
                          rangedate.length === 0
                            ? [moment(todate).toDate(), moment(todate).toDate()]
                            : rangedate
                        }
                        onChange={(i: any) => {
                          setPage(1)
                          if (i[0] && i[1]) {
                            setRangeDate(i)
                            setPage(1)
                            setPageNo(1)
                          }
                        }}
                        dayClassName={(date, modifiers) =>
                          cx({
                            [classes.outside]: modifiers.outside,
                          })
                        }
                      />
                    </Grid.Col>
                    {roleProvided !== 'user' &&
                    roleProvided !== 'admin manager' ? (
                      <>
                        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Text fz="sm" style={{ fontWeight: 500 }}>
                            Select State{' '}
                            {stateLoading ? (
                              <span style={{ margin: 5 }}>
                                <Loader size={15} />
                              </span>
                            ) : (
                              ''
                            )}
                          </Text>
                          <Select
                            placeholder="Select state"
                            clearable
                            searchable
                            itemComponent={SelectItem}
                            data={stateDetail}
                            limit={stateDetail.length}
                            disabled={
                              stateDetail.length === 0 || fromDate === null
                            }
                            maxDropdownHeight={200}
                            nothingFound="No Data Found"
                            value={stateValue}
                            onChange={(value: any) => {
                              setCityLoading(true)
                              setPage(1)
                              setPageNo(1)
                              setStateValue(value)
                              setStoreVal(null)
                              setCityValue(null)
                            }}
                            styles={() => ({
                              item: {
                                // applies styles to selected item
                                '&[data-selected]': {
                                  '&, &:hover': {
                                    backgroundColor:
                                      theme.colorScheme === 'dark'
                                        ? theme.colors.teal[9]
                                        : theme.colors.teal[1],
                                    color:
                                      theme.colorScheme === 'dark'
                                        ? theme.white
                                        : theme.colors.teal[9],
                                  },
                                }, // applies styles to hovered item (with mouse or keyboard)

                                '&[data-hovered]': {},
                              },
                            })}
                          />
                        </Grid.Col>

                        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Text fz="sm" style={{ fontWeight: 500 }}>
                            Select City{' '}
                            {cityLoading ? (
                              <span style={{ margin: 5 }}>
                                <Loader size={15} />
                              </span>
                            ) : (
                              ''
                            )}
                          </Text>
                          <Select
                            // label="Select City"
                            placeholder="Select city"
                            clearable
                            searchable
                            itemComponent={SelectItem}
                            data={cityDetail}
                            limit={cityDetail.length}
                            disabled={cityDetail.length === 0}
                            maxDropdownHeight={200}
                            nothingFound="No Data Found"
                            value={cityValue}
                            onChange={(value: any) => {
                              setPage(1)
                              setPageNo(1)
                              setCityValue(value)
                            }}
                            styles={() => ({
                              item: {
                                // applies styles to selected item
                                '&[data-selected]': {
                                  '&, &:hover': {
                                    backgroundColor:
                                      theme.colorScheme === 'dark'
                                        ? theme.colors.teal[9]
                                        : theme.colors.teal[1],
                                    color:
                                      theme.colorScheme === 'dark'
                                        ? theme.white
                                        : theme.colors.teal[9],
                                  },
                                }, // applies styles to hovered item (with mouse or keyboard)

                                '&[data-hovered]': {},
                              },
                            })}
                          />
                        </Grid.Col>
                      </>
                    ) : (
                      ''
                    )}
                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      {roleProvided === 'super admin' ||
                      (brandID !== null && roleProvided === 'admin') ||
                      (brandID !== null && roleProvided === 'CRM manager') ||
                      (brandID !== null && roleProvided === 'brand manager') ||
                      (brandID !== null && roleProvided === 'admin manager') ? (
                        <Select
                          label="Select Store"
                          placeholder="Select store"
                          clearable
                          searchable
                          disabled={
                            fromDate === null || storeDetails.length === 0
                          }
                          itemComponent={SelectItem}
                          data={storeDetails}
                          limit={storeDetails.length}
                          maxDropdownHeight={300}
                          nothingFound="No Data Found"
                          value={storeVal}
                          onChange={(value: any) => {
                            setPage(1)
                            setPageNo(1)
                            setStoreVal(value)
                            setStateValue(null)
                            setCityValue(null)
                          }}
                          styles={() => ({
                            item: {
                              // applies styles to selected item
                              '&[data-selected]': {
                                '&, &:hover': {
                                  backgroundColor:
                                    theme.colorScheme === 'dark'
                                      ? theme.colors.teal[9]
                                      : theme.colors.teal[1],
                                  color:
                                    theme.colorScheme === 'dark'
                                      ? theme.white
                                      : theme.colors.teal[9],
                                },
                              },

                              // applies styles to hovered item (with mouse or keyboard)
                              '&[data-hovered]': {},
                            },
                          })}
                        />
                      ) : null}
                    </Grid.Col>
                    <Grid.Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{ display: 'flex', justifyContent: 'right' }}
                    >
                      <Button
                        sx={{
                          marginTop: '5px',
                          backgroundColor: 'rgb(160, 213, 239)',
                          color: '#000',
                          '&[data-disabled]': { opacity: 0.4 },
                          '&:hover': {
                            backgroundColor: 'rgb(160, 213, 239)',
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                          },
                        }}
                        onClick={() => {
                          setSearch(true)
                          setTotalSearch([])
                          setDirectSearch([])
                          setDiscoverSearch([])
                          setRecordData([])
                          setTotalRowSize(0)
                        }}
                        loading={searchLoading}
                        disabled={fromDate === null}
                      >
                        <Text color="black">Apply</Text>
                      </Button>
                    </Grid.Col>
                  </Popover.Dropdown>
                </Popover>
              </Grid.Col>

              <Grid.Col xl={1.5} lg={1.5} md={2} xs={12} sm={2}>
                {' '}
                <Button
                  sx={{
                    width: '100%',
                    display: 'flex',

                    justifyContent: 'center',
                    backgroundColor: 'rgb(160, 213, 239)',
                    '&[data-disabled]': { opacity: 0.4 },
                    '&:hover': {
                      backgroundColor: 'rgb(160, 213, 239)',
                      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    },
                  }}
                  onClick={() => {
                    onDownload()
                  }}
                  gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                  disabled={recordData.length === 0}
                  loading={exportLoading}
                >
                  {!exportLoading ? <IconTableExport color="black" /> : ''}
                  <Text color="black">Export</Text>
                </Button>
              </Grid.Col>
            </Grid>
          )}
        </Grid.Col>
        <Grid.Col xs={12}>
          <DiscoveryCard
            direct={directSearch || [0]}
            discovery={discoverySearch || [0]}
            total={totalSearch || [0]}
            endDate={
              rangedate.length > 0
                ? moment(rangedate[1]).format('YYYY-MM-DD')
                : moment(todate).format('YYYY-MM-DD')
            }
          />
        </Grid.Col>
        <Grid.Col xs={12} md={9} lg={9} xl={9} sm={12}>
          <Text style={{ fontSize: '17px', fontWeight: 500 }}>
            Google Search Mobile
          </Text>
        </Grid.Col>

        <Grid.Col xs={12} md={8.5} lg={9.5} xl={9.5} sm={12}>
          <Card shadow="sm" p="lg" radius="md" withBorder>
            <Box sx={{ height: '202px' }}>
              {totalSearch?.length === undefined || null ? (
                <BarChat
                  label="
                  Google Search Mobile
                  "
                  title="
                  Google Search Mobile
                  "
                  data={{
                    labels: lables,
                    datasets: [
                      {
                        data: _.values(totalSearch)?.slice(1),
                        barPercentage: 0.5,
                        backgroundColor: [
                          '#BDB7B7',
                          '#EDD069',
                          '#EDBEBF',
                          '#92F194',
                          '#A0D5EF',
                        ],
                      },
                    ],
                  }}
                />
              ) : (
                <Box>
                  <Skeleton height={20} radius="xl" />
                  <Skeleton height={20} mt={8} radius="xl" />
                  <Skeleton height={150} mt={8} />
                </Box>
              )}
            </Box>
          </Card>
        </Grid.Col>
        <Grid.Col xs={12} sm={12} lg={2.5} md={3.5} xl={2.5}>
          <Box>
            {totalSearch?.length === undefined || null ? (
              (
                <CustomDetailCard
                  data={totalSearch || [0, 0, 0, 0, 0, 0]}
                  endDate={
                    rangedate.length > 0
                      ? moment(rangedate[1]).format('YYYY-MM-DD')
                      : moment(todate).format('YYYY-MM-DD')
                  }
                  labels={lables}
                />
              ) || [0]
            ) : (
              <Card
                shadow="sm"
                p="lg"
                radius="md"
                withBorder
                style={{ height: '240px' }}
              >
                <Skeleton height={20} radius="xl" />
                <Skeleton height={20} mt={8} radius="xl" />
                <Skeleton height={150} mt={8} />
              </Card>
            )}
          </Box>
        </Grid.Col>
        <Grid sx={{ marginTop: '2px', flexGrow: 1 }}>
          <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Text
              style={{ fontSize: '17px', marginLeft: '8px', fontWeight: 500 }}
            >
              Google Maps Mobile
            </Text>

            <Card
              style={{ marginTop: '10px', height: '350px' }}
              shadow="sm"
              p="lg"
              radius="md"
              withBorder
            >
              <Box sx={{ width: '98%' }}>
                <Box style={{ height: '160px' }}>
                  {discoverySearch?.length === undefined || null ? (
                    <BarChat
                      title="Google Maps Mobile"
                      label="Google Maps Mobile"
                      data={{
                        labels: lables,
                        datasets: [
                          {
                            data: _.values(discoverySearch)?.slice(1),
                            barPercentage: 0.3,
                            backgroundColor: [
                              '#BDB7B7',
                              '#EDD069',
                              '#EDBEBF',
                              '#92F194',
                              '#A0D5EF',
                            ],
                          },
                        ],
                      }}
                    />
                  ) : (
                    <Box>
                      <Skeleton height={20} radius="xl" />
                      <Skeleton height={20} mt={8} radius="xl" />
                      <Skeleton height={120} mt={8} />
                    </Box>
                  )}
                </Box>

                <Box sx={{ flexGrow: 1, marginTop: '5px' }}>
                  {discoverySearch?.length === undefined || null ? (
                    <DetailCard
                      lables={lables}
                      data={discoverySearch || [0, 0, 0, 0, 0, 0]}
                      endDate={moment(rangedate[1]).format('YYYY-MM-DD')}
                    />
                  ) : (
                    <Skeleton sx={{ height: 120 }} />
                  )}
                </Box>
              </Box>
            </Card>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Text
              sx={{ marginBottom: '8px', fontSize: '17px', fontWeight: 500 }}
            >
              Calls
            </Text>

            <Card
              style={{ marginTop: '10px', height: '350px' }}
              shadow="sm"
              p="lg"
              radius="md"
              withBorder
            >
              <Box sx={{ width: '98%' }}>
                <Box style={{ height: '160px' }}>
                  {directSearch?.length === undefined || null ? (
                    <BarChat
                      title="Calls"
                      data={{
                        labels: lables,
                        datasets: [
                          {
                            data: _.values(directSearch)?.slice(1),
                            barPercentage: 0.3,
                            backgroundColor: [
                              '#BDB7B7',
                              '#EDD069',
                              '#EDBEBF',
                              '#92F194',
                              '#A0D5EF',
                            ],
                          },
                        ],
                      }}
                    />
                  ) : (
                    <Box>
                      <Skeleton height={20} radius="xl" />
                      <Skeleton height={20} mt={8} radius="xl" />
                      <Skeleton height={120} mt={8} />
                    </Box>
                  )}
                </Box>

                <Box sx={{ flexGrow: 1, marginTop: '5px' }}>
                  {directSearch?.length === undefined || null ? (
                    <DetailCard
                      lables={lables}
                      data={directSearch || [0, 0, 0, 0, 0, 0]}
                      endDate={moment(rangedate[1]).format('YYYY-MM-DD')}
                    />
                  ) : (
                    <Skeleton sx={{ height: 120 }} />
                  )}
                </Box>
              </Box>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card
            shadow="sm"
            p="lg"
            radius="md"
            withBorder
            style={{ marginTop: '10px' }}
          >
            <Text style={{ fontSize: '18px' }}>Discovery</Text>
            <Text style={{ fontSize: '14px', marginTop: '7px' }}>
              Total Records Found For This Search : {totalRowSize}
            </Text>
            <Grid.Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ width: '100%' }}
            >
              <CustomTable
                pageFrom={countFrom}
                pageTo={countTo}
                total={pageNo}
                gridApi={(instance) => {}}
                totalRecords={!loading ? totalRowSize : 1}
                checkboxSelection={false}
                recordsPerPage={pageSize}
                page={page}
                height={tableHeight}
                onPageChange={(p: any) => setPage(p)}
                recordsPerPageOptions={PAGESIZES}
                onRecordsPerPageChange={setPageSize}
                loading={!!loading}
                rowData={!loading ? recordData : []}
                colDef={colDef}
                positionGiven={true}
              />
            </Grid.Col>
          </Card>
        </Grid.Col>
      </Grid>
      <Dialog
        transition="rotate-left"
        transitionDuration={300}
        transitionTimingFunction="ease"
        opened={uploadDrawer}
        withCloseButton
        position={{ top: 20, right: 20 }}
        onClose={(): void => {
          setUploadDrawer(false)
          setUploadBrand(null)
        }}
      >
        <Select
          label="Select Brand"
          placeholder="Select Brand"
          itemComponent={SelectItem}
          data={brandDetails}
          maxDropdownHeight={400}
          value={uploadBrand}
          nothingFound="No Data Found"
          clearable
          onChange={(value: any) => {
            setUploadBrand(value)
            setSelectedDate(null)
          }}
          styles={() => ({
            item: {
              // applies styles to selected item
              '&[data-selected]': {
                '&, &:hover': {
                  backgroundColor:
                    theme.colorScheme === 'dark'
                      ? theme.colors.teal[9]
                      : theme.colors.teal[1],
                  color:
                    theme.colorScheme === 'dark'
                      ? theme.white
                      : theme.colors.teal[9],
                },
              }, // applies styles to hovered item (with mouse or keyboard)

              '&[data-hovered]': {},
            },
          })}
        />
        <DatePicker
          placeholder="Select Date to Upload GMB"
          label="Select Date"
          minDate={
            availableDate === null
              ? dayjs('2022-01-01').toDate()
              : dayjs(availableDate).toDate()
          }
          value={selectedDate}
          maxDate={dayjs(new Date()).toDate()}
          disabled={uploadBrand === null}
          dayStyle={(date) =>
            moment(date).format('YYYY-MM-DD') === availableDate
              ? { backgroundColor: theme.colors.red[9], color: theme.white }
              : {}
          }
          onChange={(dates) => {
            setSelectedDate(moment(dates).format('YYYY-MM-DD'))
          }}
          dayClassName={(date, modifiers) =>
            cx({
              [classes.outside]: modifiers.outside,
            })
          }
        />

        <FileInput
          label="Select file "
          description="Your File"
          // placeholder="Your File"
          icon={<IconUpload size={14} />}
          accept=".xls,.xlsx,.csv"
          onChange={handleChange}
          disabled={selectedDate === null}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: '5px',
          }}
        >
          <a
            href={`${process.env.PUBLIC_URL}/samplereport.csv`}
            download="2023-02-21.csv"
            style={{
              color: 'green',
              textDecorationColor: 'green',
            }}
          >
            <Text
              style={{
                color: 'green',
                textDecoration: 'none',
                fontSize: '14px',
              }}
            >
              Download sample CSV
            </Text>
          </a>
        </div>
        <Text style={{ fontSize: '13px', margin: '10px' }}>
          Note: Please Upload YYYY-MM-DD File Format(.csv,.xlsx,.xls)
        </Text>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          <Button
            gradient={{ from: 'teal', to: 'lime', deg: 105 }}
            onClick={(e: any) => handleSubmit(e)}
            loading={UploadLoading}
            disabled={fileData === null}
          >
            Upload GMB
          </Button>
        </div>
      </Dialog>

      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false)
        }}
        sx={{ marginTop: '70px', zIndex: 10 }}
        overlayOpacity={0.55}
        size="md"
      >
        <GMBDelete
          onClose={() => {
            setOpened(false)
          }}
          fetchData={() => fetchData()}
        />
      </Modal>
    </div>
  )
}
