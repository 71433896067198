import { Card, Text, Grid } from '@mantine/core'
import { map, values } from 'lodash'
import moment from 'moment'

interface Iprops {
  data: any
  endDate: any
  labels: any
}

export default function CustomDetailCard({ data, endDate, labels }: Iprops) {
  const keylist = values(data).slice(1)
  const k = 'k'
  return (
    <div>
      <Card shadow="sm" p="lg" radius="md" withBorder sx={{ height: 'auto' }}>
        <Text
          style={{ fontSize: '16px', fontWeight: 500, textAlign: 'center' }}
        >
          Google Search Mobile
        </Text>
        <Text
          style={{ fontSize: '14px', marginTop: '5px', textAlign: 'center' }}
        >
          {endDate &&
            moment(new Date(endDate))
              .subtract(180, 'days')
              .format('DD MMM YYYY')}{' '}
          <span> - </span>
          {moment(endDate).format('DD MMM YYYY')}
        </Text>
        <hr
          style={{ borderBottom: '1px dashed #999', textDecoration: 'none' }}
        />
        {/* <table style={{ width: '100%' }}>
          <tr> */}
        <Grid>
          <Grid.Col
            xs={6}
            sm={6}
            md={6}
            xl={6}
            lg={6}
            style={{ maxWidth: '50%' }}
          >
            {map(labels, (getLabel) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  paddingTop: '2px',
                  fontSize: '13px',
                  color: '#000',
                }}
              >
                {getLabel}
              </div>
            ))}
          </Grid.Col>
          <Grid.Col
            xs={6}
            sm={6}
            md={6}
            xl={6}
            lg={6}
            style={{ maxWidth: '50%' }}
          >
            {map(keylist, (getValuesData) => (
              <div
                style={{
                  paddingTop: '2px',
                  paddingLeft: '10px',
                  fontSize: '13px',
                  color: '#000',
                }}
              >
                {getValuesData < 1000
                  ? getValuesData
                  : (getValuesData / 1000).toFixed(2) + k}
              </div>
            ))}
          </Grid.Col>
        </Grid>
        {/* </tr>
        </table> */}
      </Card>
    </div>
  )
}
