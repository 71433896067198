import { useEffect, useState } from 'react'
import { Text, createStyles, Grid, Paper } from '@mantine/core'
import { AxiosError, AxiosResponse } from 'axios'
import { get } from 'lodash'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { API_SEVICES } from '../../../config/services'
import { API_ENDPOINTS } from '../../../constants'
import SpinningLoader from '../../../components/SpinningLoader'

// Dropzone Styles
const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
    fontStyle: 'Helvetica',
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
}))
interface IProps {
  onClose: () => void
}

export default function ProductViewModel(props: IProps) {
  const { onClose } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const [productData, setProductData] = useState([])
  const id = searchParams.get('id')
  const { classes } = useStyles()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  // eslint-disable-next-line react/display-name

  useEffect(() => {
    if (id) {
      setLoading(true)
      const match = {
        _id: id,
      }
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_ENDPOINTS.GET_PRODUCT
        }?match=${JSON.stringify(match)}`,
        (res: AxiosResponse) => {
          setLoading(false)

          setProductData(res.data.data[0])
        },
        (err: AxiosError) => {
          setLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
        }
      )
    }
  }, [id, navigate])

  return (
    <div className={classes.wrapper}>
      <Grid gutter={5}>
        <Grid.Col xs={6} sm={6} xl={6} lg={6} md={6}>
          <Paper
            shadow="lg"
            p="md"
            style={{ width: '740px', minHeight: '300px' }}
          >
            {/* <p>paper hai</p>
            <SpinningLoader /> */}
            <Grid>
              {loading ? (
                <SpinningLoader />
              ) : (
                <>
                  <Grid.Col xs={6} sm={6} xl={6} lg={6} md={6}>
                    <Text fw={500}>Product Id</Text>
                    <Text fz="sm">
                      {' '}
                      {get(productData, 'productId')
                        ? get(productData, 'productId')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Product Name
                    </Text>
                    <Text fz="sm">
                      {get(productData, 'productName')
                        ? get(productData, 'productName')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Title
                    </Text>
                    <Text fz="sm">
                      {get(productData, 'title')
                        ? get(productData, 'title')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Brand Id
                    </Text>
                    <Text fz="sm">
                      {get(productData, 'brandId.name')
                        ? get(productData, 'brandId.name')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Store
                    </Text>

                    <Text fz="sm">
                      {get(productData, 'store[0].storeName')
                        ? get(productData, 'store', []).map((i: any) => (
                            <p key={i._id}>{i.storeName}</p>
                          ))
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Category
                    </Text>

                    <Text fz="sm">
                      {get(productData, 'category')
                        ? get(productData, 'category')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      SubCategory
                    </Text>

                    <Text fz="sm">
                      {' '}
                      {get(productData, 'subCategory')
                        ? get(productData, 'subCategory')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Description
                    </Text>

                    <Text fz="sm">
                      {' '}
                      {get(productData, 'description')
                        ? get(productData, 'description')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      ShortDescription
                    </Text>

                    <Text fz="sm">
                      {' '}
                      {get(productData, 'shortDescription')
                        ? get(productData, 'shortDescription')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      MetaTitle
                    </Text>
                    <Text fz="sm">
                      {get(productData, 'metaTitle')
                        ? get(productData, 'metaTitle')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      MetaDescription
                    </Text>
                    <Text fz="sm">
                      {get(productData, 'metaDescription')
                        ? get(productData, 'metaDescription')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      MetaKeywords
                    </Text>
                    <Text fz="sm">
                      {get(productData, 'metaKeywords')
                        ? get(productData, 'metaKeywords')
                        : '-'}
                    </Text>

                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Vendor
                    </Text>
                    <Text fz="sm">
                      {get(productData, 'vendor')
                        ? get(productData, 'vendor')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      videos
                    </Text>
                    <Text fz="sm">
                      {get(productData, 'videos[0]')
                        ? get(productData, 'videos[0]')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Offer
                    </Text>
                    {get(productData, 'offer') && (
                      <Grid>
                        <Grid.Col xs={6}>
                          {Object?.keys(
                            JSON?.parse(get(productData, 'offer', '{}'))
                          ).map((data: any) => (
                            <Text fz="sm" key={data}>
                              {data}
                            </Text>
                          ))}
                        </Grid.Col>
                        <Grid.Col xs={6}>
                          {Object?.values(
                            JSON?.parse(get(productData, 'offer', '{}'))
                          ).map((data: any) => (
                            <Text fz="sm" key={data}>
                              {data}
                            </Text>
                          ))}
                        </Grid.Col>
                      </Grid>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={6} sm={6} xl={6} lg={6} md={6}>
                    <Text fw={500}> Price</Text>
                    <Text fz="sm">
                      {' '}
                      {get(productData, 'price')
                        ? get(productData, 'price')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Rating
                    </Text>
                    <Text fz="sm">
                      {' '}
                      {get(productData, 'rating')
                        ? get(productData, 'rating')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Specification
                    </Text>

                    {get(productData, 'specification') && (
                      <Grid>
                        <Grid.Col span={12}>
                          {Object.values(
                            JSON?.parse(get(productData, 'specification', ''))
                          ).map((data: any) => (
                            <Text fz="sm" key={data}>
                              {data}
                            </Text>
                          ))}
                        </Grid.Col>
                      </Grid>
                    )}
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Features
                    </Text>

                    {get(productData, 'features') && (
                      <Grid>
                        <Grid.Col span={12}>
                          {Object.values(
                            JSON?.parse(get(productData, 'features', ''))
                          ).map((data: any) => (
                            <Text fz="sm" key={data}>
                              {data}
                            </Text>
                          ))}
                        </Grid.Col>
                      </Grid>
                    )}
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      status
                    </Text>
                    <Text fz="sm">
                      {' '}
                      {get(productData, 'status') ? 'Active' : 'In Active'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Featured
                    </Text>
                    <Text fz="sm">
                      {get(productData, 'featured') ? 'Active' : 'Inactive'}
                    </Text>
                    {/* <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Alias Name
                    </Text>
                    <Grid.Col span={12}>
                      {get(productData, 'aliasName', [{}]).map((data: any) => (
                        <Text fz="sm" key={data}>
                          {data.name}
                        </Text>
                      ))}
                    </Grid.Col> */}
                    {/*  <Text fz="sm">{get(productData, 'aliasName')}</Text> */}
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      MetaData
                    </Text>
                    {get(productData, 'metaData') && (
                      <Grid>
                        <Grid.Col span={6}>
                          <>
                            {Object.keys(
                              JSON?.parse(get(productData, 'metaData', '{}'))
                            ).map((val) => (
                              <Text fz="sm" key={val}>
                                {val}
                              </Text>
                            ))}
                          </>
                        </Grid.Col>
                        <Grid.Col span={6}>
                          {Object.values(
                            JSON?.parse(get(productData, 'metaData', '{}'))
                          ).map((data: any) => (
                            <Text fz="sm" key={data}>
                              {data}
                            </Text>
                          ))}
                        </Grid.Col>
                      </Grid>
                    )}

                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Product Image
                    </Text>
                    {get(productData, 'productImage.file.Location') ? (
                      <div>
                        <img
                          src={get(productData, 'productImage.file.Location')}
                          height={150}
                          width={260}
                          alt="logoFile"
                        />
                      </div>
                    ) : (
                      '-'
                    )}
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Product Gallery
                    </Text>
                    {get(productData, 'productGallery') ? (
                      <div>
                        {get(productData, 'productGallery', []).map(
                          (image: any) => (
                            <div style={{ marginTop: '10px' }} key={image}>
                              <img
                                src={image.file.Location}
                                height={150}
                                width={260}
                                alt="logoFile"
                              />
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      '-'
                    )}
                  </Grid.Col>
                </>
              )}
            </Grid>
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  )
}
