import { useEffect, useState, forwardRef } from 'react'
import {
  Grid,
  TextInput,
  Button,
  Loader,
  PasswordInput,
  Select,
  SelectItemProps,
  Group,
  createStyles,
  Text,
  MultiSelect,
} from '@mantine/core'
import { get } from 'lodash'
import { AxiosError, AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSearchParams } from 'react-router-dom'
import { AlertColor, AlertIcons, API_ENDPOINTS } from '../../../constants'
import { API_SEVICES } from '../../../config/services'
import { useAppDispatch } from '../../../redux/store'
import { openSnackbar } from '../../../redux/snackbar'
import SpinningLoader from '../../../components/SpinningLoader'
import { useAppSelector } from '../../../hooks'
import StoreView from '../../Store/StoreView'

interface IProps {
  fetchData: () => void
  onClose: () => void
  mode: any
  isEditMode: any
  isViewMode: any
}
interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  city?: string
  storeName?: string
  logo?: string
  storeId?: string
  locality?: string
}

const useStyles = createStyles((theme) => ({
  formikError: {
    color: '#CC0000',
    fontSize: '13px',
  },
  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },
  outline: {
    border: 'none',
    outline: 'none !important',
  },
}))
function UserModelForm(props: IProps) {
  const dispatch = useAppDispatch()
  const { classes, theme } = useStyles()
  const { onClose, mode, isEditMode, isViewMode, fetchData } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [brandData, setBrandData] = useState([])
  const [storeData, setStoreData] = useState([])
  const [brandVal, setBrandVal] = useState<any>([])
  const [storeVal, setStoreVal] = useState<any>([])
  const [storeIds, setStoreIds] = useState<any>([])
  const [brandId, setBrandId] = useState<any>([])
  const [storeLoading, setStoreLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      role: '',
      brand: [],
      store: [] || null || '',
    },
    onSubmit: (values) => {
      setLoading1(true)

      if (values.role === 'super admin') {
        values.brand = [] || null
        values.store = [] || null || ''
      }
      if (values.role === 'admin') {
        values.store = [] || null || ''
      }
      if (values.role === 'admin manager') {
        values.store = storeIds
      }
      if (values.role === 'user') {
        const data: any = [storeVal]
        values.store = data
      }

      if (brandId.length > 0) {
        values.brand = brandId
      } else if (values.role === 'super admin') {
        values.brand = []
      } else {
        const data: any = [brandVal]

        values.brand = data
      }

      !isEditMode || isViewMode
        ? API_SEVICES.PostObjRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.POST_USER}`,
            (res: AxiosResponse) => {
              setLoading1(false)
              dispatch(
                openSnackbar({
                  messege: 'User Added Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'User is not Created'
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              console.error(err)
            },
            values
          )
        : API_SEVICES.PatchObjRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.EDIT_USER}?id=${id}`,
            (res: AxiosResponse) => {
              setLoading1(false)
              dispatch(
                openSnackbar({
                  messege: 'Users Updated Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'User Details are Not Updated'
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              console.error(err)
            },
            values
          )
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required('First Name is Required')
        .matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          'First Name can only contain  letters.'
        ),
      lastName: Yup.string().matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Last Name can only contain  letters.'
      ),
      role: Yup.string().required('Role is Required'),
      email: Yup.string()
        .email('Enter a valid email')
        .required('Email is required'),

      password: Yup.string()
        .required('Please Enter your password')
        .min(8, 'Password is too short - should be 8 chars minimum.'),

      confirmPassword: Yup.string()
        .required()
        .oneOf([Yup.ref('password'), null], 'Passwords does not match'),
    }),
  })
  useEffect(() => {
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.GET_BRAND
      }?match=${JSON.stringify({
        status: 'true',
      })}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Brand Details are not fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )

    if (brandVal?.length > 0) {
      setStoreLoading(true)
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_ENDPOINTS.GET_STORE
        }?match=${JSON.stringify({
          status: 'true',
          brandId: brandVal,
        })}`,
        (res: AxiosResponse) => {
          setStoreLoading(false)
          setStoreData(res.data.data)
        },
        (err: AxiosError) => {
          setStoreLoading(false)
          console.error(err)
        }
      )
    }
  }, [dispatch, formik.values.brand, brandVal])
  const brand = brandData.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.name,
  }))
  const store = storeData.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.storeName,
  }))

  useEffect(() => {
    if (id) {
      setLoading(true)
      const match = {
        _id: id,
      }
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_ENDPOINTS.GET_USER
        }?match=${JSON.stringify(match)}`,
        (res: AxiosResponse) => {
          setLoading(false)

          formik.setFieldValue('email', get(res, 'data.data[0].email', ''))
          formik.setFieldValue(
            'firstName',
            get(res, 'data.data[0].firstName', '')
          )
          formik.setFieldValue(
            'lastName',
            get(res, 'data.data[0].lastName', '')
          )
          formik.setFieldValue(
            'password',
            get(res, 'data.data[0].password', '')
          )
          formik.setFieldValue(
            'confirmPassword',
            get(res, 'data.data[0].password', '')
          )
          formik.setFieldValue('role', get(res, 'data.data[0].role', ''))

          if (res.data.data[0].role === 'admin manager') {
            formik.setFieldValue('store', res.data.data[0].store)
          }
          if (res.data.data[0].role === 'CRM manager') {
            formik.setFieldValue('brand', res.data.data[0].brand)
          }

          setBrandVal(res.data.data[0].brand?.[0]?._id)
          setStoreVal(res.data.data[0].store?.[0]?._id)
        },
        (err: AxiosError) => {
          setLoading(false)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const BrandSelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ value, label, name, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
          </div>
        </Group>
      </div>
    )
  )
  BrandSelectItem.displayName = 'SelectItem'
  const StoreSelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, storeId, locality, city, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <Grid>
            <Grid.Col span={12}>
              <Text size="sm">
                {label} - {storeId}
              </Text>
              {city && locality ? (
                <Text
                  size="xs"
                  opacity={0.65}
                  sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                  }}
                >
                  {locality}-{city}
                </Text>
              ) : (
                ''
              )}
            </Grid.Col>
          </Grid>
        </Group>
      </div>
    )
  )
  StoreSelectItem.displayName = 'SelectItem'
  // const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  //   (
  //     {
  //       value,
  //       label,
  //       name,
  //       description,
  //       cityName,
  //       storeId,
  //       ...others
  //     }: ItemProps,
  //     ref
  //   ) => (
  //     <div ref={ref} {...others}>
  //       <Group noWrap>
  //         <div>
  //           <Grid sx={{ marginBottom: '3px' }}>
  //             <Text size="sm" sx={{ marginRight: '5px' }}>
  //               {label}
  //             </Text>{' '}
  //             {storeId ? (
  //               <Text
  //                 size="xs"
  //                 opacity={0.65}
  //                 sx={{
  //                   display: 'flex',
  //                   justifyContent: 'center',
  //                   alignItems: 'center',
  //                 }}
  //               >
  //                 - {storeId}
  //               </Text>
  //             ) : (
  //               ''
  //             )}
  //           </Grid>
  //           {description || cityName ? (
  //             <Text size="xs" opacity={0.65}>
  //               {description}-{cityName}
  //             </Text>
  //           ) : null}
  //         </div>
  //       </Group>
  //     </div>
  //   )
  // )

  useEffect(() => {
    setBrandId(formik?.values?.brand?.map((i: any) => i?._id))
  }, [formik?.values?.brand, id])
  useEffect(() => {
    setStoreIds(formik?.values?.store?.map((i: any) => i?._id))
  }, [formik?.values?.store, id])

  return (
    <div
      className={classes.outline}
      role="button"
      tabIndex={0}
      onKeyDown={(e: any) => {
        if (e.key === 'Enter') {
          formik.handleSubmit()
        }
      }}
    >
      <Grid sx={{ minHeight: '300px' }}>
        {loading ? (
          <SpinningLoader />
        ) : (
          <>
            <Grid.Col xs={12}>
              <TextInput
                placeholder="e.g : First Name"
                name="firstName"
                label="First Name"
                withAsterisk
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />
              {formik.errors.firstName && formik.touched.firstName && (
                <div className={classes.formikError}>
                  {formik.errors.firstName}
                </div>
              )}
            </Grid.Col>
            <Grid.Col xs={12}>
              <TextInput
                placeholder="e.g : Last Name"
                name="lastName"
                label="Last Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
              {formik.errors.lastName && formik.touched.lastName && (
                <div className={classes.formikError}>
                  {formik.errors.lastName}
                </div>
              )}
            </Grid.Col>
            <Grid.Col xs={12}>
              <TextInput
                placeholder="e.g : xyz@gmail.com"
                name="email"
                label="Email"
                withAsterisk
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.errors.email && formik.touched.email && (
                <div className={classes.formikError}>{formik.errors.email}</div>
              )}
            </Grid.Col>

            <Grid.Col xs={12}>
              {!isEditMode ? (
                <PasswordInput
                  label="New Password"
                  placeholder="Your password"
                  required
                  id="password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  mt="md"
                />
              ) : (
                <PasswordInput
                  label="New Password"
                  placeholder="Your New password"
                  id="password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  mt="md"
                />
              )}
              {formik.errors.password && formik.touched.password && (
                <div className={classes.formikError}>
                  {formik.errors.password}
                </div>
              )}
            </Grid.Col>
            <Grid.Col xs={12}>
              {!isEditMode ? (
                <PasswordInput
                  label="Confirm Password"
                  placeholder="Your confirmPassword"
                  required
                  id="confirmPassword"
                  name="confirmPassword"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                  mt="md"
                />
              ) : (
                <PasswordInput
                  label="ConfirmPassword"
                  placeholder="Your confirmPassword"
                  id="confirmPassword"
                  name="confirmPassword"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  mt="md"
                />
              )}
              {formik.errors.confirmPassword &&
                formik.touched.confirmPassword && (
                  <div className={classes.formikError}>
                    {formik.errors.confirmPassword}
                  </div>
                )}
            </Grid.Col>
            <Grid.Col xs={12}>
              <Select
                label="Role"
                placeholder="admin/super admin/user/manager/CRM Manager/admin manager"
                data={[
                  'admin',
                  'super admin',
                  'admin manager',
                  'user',
                  'brand manager',
                  'CRM manager',
                ]}
                maxDropdownHeight={400}
                searchable
                value={formik.values.role}
                nothingFound="No Role Found"
                withAsterisk
                onChange={(value) => formik.setFieldValue('role', value)}
                styles={() => ({
                  item: {
                    // applies styles to selected item
                    '&[data-selected]': {
                      '&, &:hover': {
                        backgroundColor:
                          theme.colorScheme === 'dark'
                            ? theme.colors.teal[9]
                            : theme.colors.teal[1],
                        color:
                          theme.colorScheme === 'dark'
                            ? theme.white
                            : theme.colors.teal[9],
                      },
                    },

                    // applies styles to hovered item (with mouse or keyboard)
                    '&[data-hovered]': {},
                  },
                })}
              />
              {formik.errors.role && formik.touched.role && (
                <div className={classes.formikError}>{formik.errors.role}</div>
              )}
            </Grid.Col>
            {formik.values.role === 'user' ||
            formik.values.role === 'admin' ||
            formik.values.role === 'brand manager' ||
            formik.values.role === 'admin manager' ? (
              <Grid.Col xs={12}>
                <Select
                  label="Select Brand"
                  placeholder="e.g Select Brand"
                  itemComponent={BrandSelectItem}
                  data={brand}
                  limit={brand.length}
                  withAsterisk
                  maxDropdownHeight={200}
                  value={brandVal}
                  nothingFound="Nobody here"
                  onChange={(value: any) => {
                    setBrandVal(value)
                  }}
                  styles={() => ({
                    item: {
                      // applies styles to selected item
                      '&[data-selected]': {
                        '&, &:hover': {
                          backgroundColor:
                            theme.colorScheme === 'dark'
                              ? theme.colors.teal[9]
                              : theme.colors.teal[1],
                          color:
                            theme.colorScheme === 'dark'
                              ? theme.white
                              : theme.colors.teal[9],
                        },
                      },

                      // applies styles to hovered item (with mouse or keyboard)
                      '&[data-hovered]': {},
                    },
                  })}
                />
              </Grid.Col>
            ) : (
              ''
            )}
            {formik.values.role === 'user' ||
            formik.values.role === 'manager' ? (
              <Grid.Col xs={12}>
                <Select
                  label={
                    !storeLoading ? (
                      'Select Store'
                    ) : (
                      <div>
                        <span>
                          <Loader color="green" size="xs" />
                        </span>
                        <span>Select Store</span>
                      </div>
                    )
                  }
                  placeholder="e.g Select Store"
                  value={storeVal}
                  itemComponent={StoreSelectItem}
                  data={store}
                  disabled={storeLoading}
                  limit={store.length}
                  maxDropdownHeight={200}
                  nothingFound="No Store Found"
                  onChange={(value: any) => {
                    setStoreVal(value)
                  }}
                  styles={() => ({
                    item: {
                      // applies styles to selected item
                      '&[data-selected]': {
                        '&, &:hover': {
                          backgroundColor:
                            theme.colorScheme === 'dark'
                              ? theme.colors.teal[9]
                              : theme.colors.teal[1],
                          color:
                            theme.colorScheme === 'dark'
                              ? theme.white
                              : theme.colors.teal[9],
                        },
                      },

                      // applies styles to hovered item (with mouse or keyboard)
                      '&[data-hovered]': {},
                    },
                  })}
                />
              </Grid.Col>
            ) : (
              ''
            )}
            {formik.values.role === 'CRM manager' ? (
              <Grid.Col md={12} sm={12} xs={12} xl={12} lg={12}>
                <MultiSelect
                  data={brand}
                  label="Select Brand"
                  placeholder="e.g Select Brand"
                  defaultValue={brandId}
                  itemComponent={BrandSelectItem}
                  value={brandId}
                  onChange={(value) => {
                    setBrandId(value)
                  }}
                />
              </Grid.Col>
            ) : (
              ''
            )}
            {formik.values.role === 'admin manager' ? (
              <Grid.Col md={12} sm={12} xs={12} xl={12} lg={12}>
                <MultiSelect
                  data={store}
                  disabled={storeLoading}
                  // label="Select store"
                  label={
                    !storeLoading ? (
                      'Select Store'
                    ) : (
                      <div>
                        <span>Select Store</span>
                        <span style={{ marginLeft: '5px' }}>
                          <Loader color="green" size="xs" />
                        </span>
                      </div>
                    )
                  }
                  placeholder="e.g Select store"
                  defaultValue={storeIds}
                  value={storeIds}
                  itemComponent={StoreSelectItem}
                  maxDropdownHeight={260}
                  onChange={(value) => {
                    setStoreIds(value)
                  }}
                />
              </Grid.Col>
            ) : (
              ''
            )}
            <Grid.Col xs={2}>
              <Button
                variant="gradient"
                gradient={{ from: 'orange', to: 'red' }}
                onClick={() => {
                  onClose()
                }}
                disabled={loading1}
              >
                Cancel
              </Button>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Button
                variant="gradient"
                gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                onClick={() => {
                  formik.handleSubmit()
                }}
                className={
                  !(formik.dirty && formik.isValid) ? 'disabled-btn' : ''
                }
                disabled={!(formik.dirty && formik.isValid)}
                rightIcon={loading1 ? <Loader size={14} color="white" /> : ''}
              >
                {isEditMode ? 'Update' : 'Save'}
              </Button>
            </Grid.Col>
          </>
        )}
      </Grid>
    </div>
  )
}
export default UserModelForm
