/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-key */
import { useEffect, useState } from 'react'
import { Text, createStyles, Grid, Paper } from '@mantine/core'
import { AxiosError, AxiosResponse } from 'axios'
import { get } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { API_SEVICES } from '../../../config/services'
import { API_ENDPOINTS } from '../../../constants'
import SpinningLoader from '../../../components/SpinningLoader'
// Dropzone Styles
const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
    fontStyle: 'Helvetica',
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
}))
interface IProps {
  onClose: () => void
  data: any
}

export default function BrandViewModel(props: IProps) {
  const { onClose, data } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const [brandData, setBrandData] = useState<any>(data)
  const id = searchParams.get('id')
  const { classes } = useStyles()
  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   if (id) {
  //     setLoading(true)
  //     const match = {
  //       _id: id,
  //     }
  //     API_SEVICES.GetRequest(
  //       `${process.env.REACT_APP_BACKEND_API_PROXY}${
  //         API_ENDPOINTS.GET_ARTICLES
  //       }?match=${JSON.stringify(match)}`,
  //       (res: AxiosResponse) => {
  //         setLoading(false)

  //         setBrandData(res.data.data.data[0])
  //       },
  //       (err: AxiosError) => {
  //         setLoading(false)
  //         console.error('err', err)
  //       }
  //     )
  //   }
  // }, [id])
  // const Link = get(brandData, 'interLink', '')

  return (
    <div className={classes.wrapper}>
      <Grid gutter={5}>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper
            // shadow="xs"
            p="md"
            style={{ width: '580px', minHeight: '300px' }}
          >
            <Grid>
              {loading ? (
                <SpinningLoader />
              ) : (
                <Grid.Col xs={12} sm={12} xl={12} lg={12} md={12}>
                  <Text fw={500}> Brand Name</Text>
                  <Text fz="sm">
                    {get(brandData, 'brandId.name')
                      ? get(brandData, 'brandId.name')
                      : '-'}
                  </Text>
                  <Text fw={500} style={{ marginTop: '10px' }}>
                    {' '}
                    Store Name
                  </Text>

                  <Text fz="sm">
                    {' '}
                    {get(brandData, 'storeName')
                      ? get(brandData, 'storeName')
                      : '-'}
                  </Text>
                  <Text fw={500} style={{ marginTop: '10px' }}>
                    {' '}
                    Title
                  </Text>

                  <Text fz="sm">
                    {' '}
                    {get(brandData, 'title') ? get(brandData, 'title') : '-'}
                  </Text>
                  <Text fw={500} style={{ marginTop: '10px' }}>
                    {' '}
                    Content
                  </Text>
                  <Text fz="sm">
                    {get(brandData, 'content') ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: get(brandData, 'content'),
                        }}
                      />
                    ) : (
                      '-'
                    )}
                  </Text>
                  <Text fw={500} style={{ marginTop: '10px' }}>
                    {' '}
                    volume
                  </Text>
                  <Text fz="sm">
                    {get(brandData, 'volume') ? get(brandData, 'volume') : '-'}
                  </Text>
                  {/* <Text fw={500} style={{ marginTop: '10px' }}>
                    {' '}
                    Link
                  </Text> */}
                  {/* <a
                    href={get(brandData, 'interLink')}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {get(brandData, 'interLink')}
                  </a> */}
                  <Text fw={500} style={{ marginTop: '10px' }}>
                    {' '}
                    Meta Title
                  </Text>

                  <Text fz="sm">
                    {' '}
                    {get(brandData, 'metaTitle')
                      ? get(brandData, 'metaTitle')
                      : '-'}
                  </Text>
                  <Text fw={500} style={{ marginTop: '10px' }}>
                    {' '}
                    Meta Description
                  </Text>

                  <Text fz="sm">
                    {' '}
                    {get(brandData, 'metaDescription')
                      ? get(brandData, 'metaDescription')
                      : '-'}
                  </Text>
                  {/* <Text fw={500} style={{ marginTop: '10px' }}>
                    {' '}
                    Description
                  </Text>

                  <Text fz="sm">
                    {get(brandData, 'description') ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: get(brandData, 'description'),
                        }}
                      />
                    ) : (
                      '-'
                    )}
                  </Text> */}

                  <Text fw={500} style={{ marginTop: '10px' }}>
                    {' '}
                    status
                  </Text>

                  <Text fz="sm">
                    {' '}
                    {get(brandData, 'status') ? 'Active' : 'Inactive'}
                  </Text>
                </Grid.Col>
              )}
            </Grid>
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  )
}
