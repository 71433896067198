/* eslint-disable max-len */
import {
  Box,
  Paper,
  Title,
  Grid,
  Button,
  Modal,
  Text,
  Menu,
} from '@mantine/core'
import {
  IconCirclePlus,
  IconEyeCheck,
  IconEdit,
  IconTrash,
  IconDotsVertical,
  IconCircleCheck,
  IconCircleX,
} from '@tabler/icons'
import { AxiosError, AxiosResponse } from 'axios'
import { get } from 'lodash'
import { DataTableColumn } from 'mantine-datatable'
import { useEffect, useMemo, useState, useRef } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { API_SEVICES } from '../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
  ROLEPROVIDED,
  BRANDID,
} from '../../constants'
import { openSnackbar } from '../../redux/snackbar'
import SubCategoryModelForm from './SubCategoryModelForm'
import { useAppSelector } from '../../redux/store'
import DeleteModal from '../../components/DeleteModal'
import CustomTable from '../../components/NewcustomTable'
import SubCategoryViewModal from './SubCategoryViewModel'
import theme from '../../config/Theme'
import { useAppDispatch } from '../../hooks'

export default function SubCategory() {
  const [searchParams, setSearchParams] = useSearchParams()
  const mode = searchParams.get('mode')
  const [loading, setLoading] = useState(true)
  const [modelState, setModel] = useState(false)
  const [open, setOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [pageNo, setPageNo] = useState(1)
  const [countFrom, setCountForm] = useState<any>()
  const [countTo, setCountTo] = useState<any>()
  const [pageSize, setPageSize] = useState(PAGESIZES[0])
  const [recordData, setRecordData] = useState([])
  const [totalRowSize, setTotalRowSize]: any = useState(10)
  const [tableHeight, setTableHeight] = useState(500)
  const dispatch = useAppDispatch()
  const filterRef = useRef<HTMLInputElement>(null)
  const paperRef = useRef<HTMLInputElement>(null)
  const deleteCity = API_ENDPOINTS.CATEGORY_DETAILS
  const deletedData = API_ENDPOINTS.CATEGORY_DETAILS
  const deleteName = 'subCategory'
  const navigate = useNavigate()
  const role = useAppSelector((stat) => stat.role)

  const roleProvided = role.role || ROLEPROVIDED
  const brandID = role.brandId || BRANDID || null

  const handleClose = () => {
    setModel(false)
    setDeleteOpen(false)
    setSearchParams({})
  }
  const fetchData = () => {
    setLoading(true)
    let match
    if (roleProvided === 'admin' && brandID === null) {
      match = JSON.stringify({ _id: null })
    } else if (roleProvided === 'CRM manager' && brandID === null) {
      match = JSON.stringify({ _id: null })
    } else {
      match = JSON.stringify({ isSubCategory: 'true' })
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.CATEGORY_DETAILS}?pageNo=${page}&size=${pageSize}&match=${match}`,
      (res: AxiosResponse) => {
        setLoading(false)
        setTotalRowSize(res.data.subCategoryCount)
        setRecordData(res.data.data)
      },
      (err: AxiosError) => {
        setLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        openSnackbar({
          messege: get(
            err,
            'response.data.message',
            'SubCategory Details Cant be fetched'
          ),
          icon: AlertIcons.error,
          title: 'error',
          color: AlertColor.error,
        })
      }
    )
  }
  useEffect(() => {
    setLoading(true)
    let match
    if (roleProvided === 'admin' && brandID === null) {
      match = JSON.stringify({ _id: null })
    } else if (roleProvided === 'CRM manager' && brandID === null) {
      match = JSON.stringify({ _id: null })
    } else {
      match = JSON.stringify({ isSubCategory: 'true' })
    }
    const controller = new AbortController()
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.CATEGORY_DETAILS}?pageNo=${page}&size=${pageSize}&match=${match}`,
      (res: AxiosResponse) => {
        setLoading(false)
        setTotalRowSize(res.data.subCategoryCount)
        setRecordData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'SubCategory Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          setLoading(false)
        }
      },
      controller.signal
    )
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize])
  const colDef: DataTableColumn<any>[] = useMemo(
    () => [
      {
        Header: 'Actions',
        accessor: 'actions',
        fixed: 'left',
        title: <Text mr="xs">Actions</Text>,
        textAlignment: 'center',
        Cell: ({ ...row }) => (
          <Menu shadow="md" width={130} position="right-start">
            <Menu.Target>
              <Button style={{ background: 'none' }}>
                {' '}
                <IconDotsVertical size={16} color="black" />
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                color="green"
                icon={<IconEyeCheck size={14} />}
                onClick={() => {
                  setOpen(true)
                  setSearchParams({
                    mode: 'view',
                    id: row?.row?.original._id,
                  })
                }}
              >
                View
              </Menu.Item>
              <Menu.Item
                color="blue"
                onClick={() => {
                  setModel(true)
                  setSearchParams({
                    mode: 'edit',
                    id: row?.row?.original._id,
                  })
                }}
                icon={<IconEdit size={14} />}
                disabled={
                  roleProvided === 'user' || roleProvided === 'brand manager'
                }
              >
                Edit
              </Menu.Item>
              <Menu.Item
                color="red"
                onClick={() => {
                  setDeleteOpen(true)
                  setSearchParams({
                    mode: 'delete',
                    id: row?.row?.original._id,
                  })
                }}
                icon={<IconTrash size={14} />}
                disabled={
                  roleProvided === 'user' || roleProvided === 'brand manager'
                }
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ),
      },
      {
        accessor: 'parentCategory',
        Header: 'Category Name',
        textAlignment: 'center',
        minWidth: 270,
      },
      {
        accessor: 'label',
        Header: 'SubCategory Name',
        textAlignment: 'center',
        minWidth: 300,
      },

      {
        accessor: 'status',
        Header: 'Status',
        textAlignment: 'center',

        Cell: ({ ...status }) => {
          if (status.row.original.status === true) {
            return <IconCircleCheck color="green" size={30} />
          }
          return <IconCircleX color="#ff2825" size={30} />
        },
      },
      {
        accessor: 'isSubCategory',
        Header: 'SubCategory',
        textAlignment: 'center',
        minWidth: 200,
        Cell: (subCategory: any) => {
          if (subCategory.row.original.isSubCategory === true) {
            return 'Yes'
          }
          return 'No'
        },
      },
    ],
    [roleProvided, setSearchParams]
  )
  useEffect(() => {
    if (filterRef.current != null) {
      const element = paperRef.current?.getClientRects()
      setTableHeight(window.innerHeight - filterRef.current.clientHeight - 110)
    }

    if (totalRowSize > pageSize) {
      const from = (page - 1) * pageSize
      const to = from + pageSize
      setCountForm(from + 1)
      setCountTo(to)
    } else {
      setCountForm(1)
      setCountTo(totalRowSize)
    }
  }, [page, pageSize, recordData, totalRowSize])
  return (
    <>
      <Box>
        <Paper shadow="xs" p="md">
          <Grid>
            <Grid.Col
              xs={12}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
              ref={filterRef}
            >
              <Title order={3} weight={100}>
                Sub Category
              </Title>

              <Grid>
                {roleProvided === 'super admin' ||
                (brandID !== null && roleProvided === 'admin') ||
                (brandID !== null && roleProvided === 'CRM manager') ? (
                  <Grid.Col span={4}>
                    <Button
                      variant="gradient"
                      gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                      leftIcon={<IconCirclePlus size={18} />}
                      onClick={() => {
                        setModel(true)
                        setSearchParams({ mode: 'add' })
                      }}
                    >
                      Add SubCategory
                    </Button>
                  </Grid.Col>
                ) : null}
              </Grid>
            </Grid.Col>

            <Grid.Col xs={12}>
              <CustomTable
                pageFrom={countFrom}
                pageTo={countTo}
                total={pageNo}
                gridApi={(instance) => {}}
                totalRecords={!loading ? totalRowSize : 1}
                checkboxSelection={false}
                recordsPerPage={pageSize}
                page={page}
                height={tableHeight}
                onPageChange={(p: any) => setPage(p)}
                recordsPerPageOptions={PAGESIZES}
                onRecordsPerPageChange={setPageSize}
                loading={!!loading}
                rowData={!loading ? recordData : []}
                colDef={colDef}
              />
            </Grid.Col>
          </Grid>
        </Paper>
      </Box>

      <Modal
        style={{
          fontSize: '18px',
          textTransform: 'capitalize',
          fontWeight: 500,
        }}
        opened={modelState}
        centered
        onClose={() => {
          setModel(false)
          setSearchParams({})
        }}
        title={`${mode} SubCategory`}
        size="lg"
      >
        <SubCategoryModelForm
          fetchData={() => fetchData()}
          onClose={() => {
            setSearchParams({})
            handleClose()
          }}
          mode={searchParams.get('mode')}
          isEditMode={searchParams.get('mode')?.toLowerCase() === 'edit'}
          isViewMode={searchParams.get('mode')?.toLowerCase() === 'view'}
        />
      </Modal>

      <Modal
        opened={deleteOpen}
        centered
        onClose={() => {
          setDeleteOpen(false)
        }}
        title="Delete SubCategory"
        size="lg"
      >
        <DeleteModal
          deleteName={deleteName}
          fetchData={fetchData}
          onClose={handleClose}
          deleteApi={deleteCity}
          deletedGetApi={deletedData}
        />
      </Modal>
      <Modal
        opened={open}
        centered
        onClose={() => {
          setOpen(false)
          setSearchParams({})
        }}
        title="View SubCategory"
        size="lg"
      >
        <SubCategoryViewModal onClose={handleClose} />
      </Modal>
    </>
  )
}
