import { useEffect, useState } from 'react'
import {
  Grid,
  TextInput,
  Button,
  FileInput,
  Checkbox,
  Loader,
  ActionIcon,
  Textarea,
  Text,
  createStyles,
  Modal,
} from '@mantine/core'
import { IconTrash, IconUpload, IconX } from '@tabler/icons'
import { get } from 'lodash'
import Compressor from 'compressorjs'
import { AxiosError, AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSearchParams } from 'react-router-dom'
import { AlertColor, AlertIcons, API_ENDPOINTS } from '../../../constants'
import { API_SEVICES } from '../../../config/services'
import { useAppDispatch } from '../../../redux/store'
import { openSnackbar } from '../../../redux/snackbar'
import SpinningLoader from '../../../components/SpinningLoader'

interface IProps {
  fetchData: () => void
  onClose: () => void
  mode: any
  isEditMode: any
  isViewMode: any
}

const useStyles = createStyles((theme) => ({
  formikError: {
    color: '#CC0000',
    fontSize: '13px',
  },
  outline: {
    border: 'none',
    outline: 'none !important',
  },
}))
function BrandModelForm(props: IProps) {
  const [selectedImage, setSelectedImage] = useState<any | null>(null)
  const [storeGalleryImage, setStoreGalleryImage] = useState<any | null>(null)
  const [images, setImages] = useState<any | null>(null)
  const dispatch = useAppDispatch()
  const { onClose, mode, isEditMode, isViewMode, fetchData } = props
  const [open, setOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const { classes, theme } = useStyles()

  const formik = useFormik({
    initialValues: {
      name: '',
      title: '',
      brandId: '',
      status: true,
      longDescription: '',
      metaTitle: '',
      metaDescription: '',
      metaKeywords: '',
      shortDescription: '',
      logo: [],
    },
    onSubmit: (values) => {
      setLoading1(true)
      !isEditMode || isViewMode
        ? API_SEVICES.PostRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.POST_BRAND}`,
            (res: AxiosResponse) => {
              setLoading1(false)
              dispatch(
                openSnackbar({
                  messege: 'Brand Added Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'Brand Details Cant be Added'
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              console.error(err)
            },
            values
          )
        : API_SEVICES.PatchRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.EDIT_BRAND}?id=${id}`,
            (res: AxiosResponse) => {
              setLoading(false)
              dispatch(
                openSnackbar({
                  messege: 'Brand Updated Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading(false)
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'Brand Details Cant be Edited'
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              console.error(err)
            },
            values
          )
    },
    validationSchema: Yup.object({
      brandId: Yup.string()
        .required('Brand Id is Required')
        .uppercase()
        .max(255),
      name: Yup.string().required('Name is Required').min(2).max(255),
      title: Yup.string().required('Title is Required').min(2).max(255),
      longDescription: Yup.string()
        .required('LongDescription is Required')
        .min(2)
        .max(500),
      metaTitle: Yup.string().min(2).required('MetaTitle is Required').max(255),
      metaKeywords: Yup.string()
        .required('MetaKeywords is Required')
        .min(2)
        .max(256),
      metaDescription: Yup.string()
        .required('MetaDescription is Required')
        .min(2)
        .max(255),
      shortDescription: Yup.string()
        .required('shortDescription is Required')
        .min(2)
        .max(255),
    }),
  })
  useEffect(() => {
    if (id) {
      setLoading(true)
      const match = {
        _id: id,
      }
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_ENDPOINTS.GET_BRAND
        }?match=${JSON.stringify(match)}`,
        (res: AxiosResponse) => {
          setLoading(false)

          setStoreGalleryImage(res.data.data[0].brandGallery)
          formik.setFieldValue('name', get(res, 'data.data[0].name', ''))
          formik.setFieldValue('brandId', get(res, 'data.data[0].brandId', ''))
          formik.setFieldValue(
            'longDescription',
            get(res, 'data.data[0].longDescription', '')
          )
          formik.setFieldValue(
            'metaDescription',
            get(res, 'data.data[0].metaDescription', '')
          )
          formik.setFieldValue(
            'metaKeywords',
            get(res, 'data.data[0].metaKeywords', '')
          )
          formik.setFieldValue(
            'metaTitle',
            get(res, 'data.data[0].metaTitle', '')
          )
          formik.setFieldValue(
            'shortDescription',
            get(res, 'data.data[0].shortDescription', '')
          )
          formik.setFieldValue('title', get(res, 'data.data[0].title', ''))
          formik.setFieldValue('status', get(res, 'data.data[0].status', ''))
          formik.setFieldValue('logo', get(res, 'data.data[0].logo', ''))
          if (res.data.data[0].logo.length === 0) {
            setImages(null)
          } else {
            setImages(res.data.data[0].logo)
          }
        },
        (err: AxiosError) => {
          setLoading(false)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const imageChange = async (e: any) => {
    if (e) {
      const fileMb = e.size / 1024 ** 2
      if (fileMb >= 2) {
        dispatch(
          openSnackbar({
            messege: 'Please select a file less than 2MB.',
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
      } else {
        setSelectedImage(e)
        formik.setFieldValue('logo', e)
      }
    }
  }

  const removeImage = () => {
    setSelectedImage(null)
    setImages(null)
    formik.setFieldValue('logo', [])
  }

  const removeImageBanner = (idx: any) => {
    const deleteImage = JSON.stringify({ imageId: idx })
    setLoading(true)
    API_SEVICES.DeleteRequestObj(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.BRAND_DELETE_GALLERY}?id=${id}`,
      (res: AxiosResponse) => {
        setLoading(false)
        dispatch(
          openSnackbar({
            messege: 'Brand image Deleted Sucessfully',
            icon: AlertIcons.success,
            title: 'Success',
            color: AlertColor.success,
          })
        )
        setOpen(false)
        onClose()
      },
      (err: AxiosError) => {
        setLoading1(false)
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Brand Images Cant be Deleted'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      },
      deleteImage
    )
  }
  const fileUpload = (event: any) => {
    if (event) {
      const file = [...event]
      const arr: any = []

      for (let i = 0; i < event.length; i++) {
        if (
          event[i].type === 'image/png' ||
          event[i].type === 'image/jpeg' ||
          event[i].type === 'image/webp' ||
          event[i].type === 'image/jpg'
        ) {
          const files: any = file[i]
          const image = new Compressor(files, {
            quality: 0.8,
            success: (compressedResult) => compressedResult,
          })

          const image1 = Object.values(image)
          arr.push(image1[0])
          setSelectedFile(arr)
        } else {
          setSelectedFile(null)
          dispatch(
            openSnackbar({
              messege: 'Image File Format is not supported',
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
      }
    }
  }
  const handleRemoveGal = (e: any) => {
    const s = selectedFile.filter((item: any, index: any) => index !== e)
    setSelectedFile(s)
  }
  function handleSubmit(event: any) {
    event.preventDefault()
    const formData = new FormData()
    if (selectedFile === null) {
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append('brandGallery', selectedFile[i])
      }
    } else if (selectedFile !== null) {
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append('brandGallery', selectedFile[i])
      }
    }

    setLoading1(true)
    API_SEVICES.PostRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.BRAND_GALLERY}?id=${id}`,
      (res: AxiosResponse) => {
        setLoading1(false)
        dispatch(
          openSnackbar({
            messege: 'Banner image upload Sucessfully',
            icon: AlertIcons.success,
            title: 'Success',
            color: AlertColor.success,
          })
        )
        setOpen(false)
        onClose()
      },
      (err: AxiosError) => {
        setLoading1(false)
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Brand Details Cant be Added'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      },
      formData
    )
  }

  return (
    <div>
      <div
        className={classes.outline}
        role="button"
        tabIndex={0}
        onKeyDown={(e: any) => {
          if (e.key === 'Enter') {
            formik.handleSubmit()
          }
        }}
      >
        <Grid sx={{ minHeight: '300px' }}>
          {loading ? (
            <SpinningLoader />
          ) : (
            <>
              {isEditMode ? (
                <Text fz="sm" style={{ fontSize: '15px', marginLeft: '10px' }}>
                  Brand Name : {formik.values.name}
                </Text>
              ) : null}
              <Grid.Col xs={12}>
                <TextInput
                  placeholder="e.g : BRAND001"
                  name="brandId"
                  label="Brand Id"
                  withAsterisk
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.brandId}
                />
                {formik.errors.brandId && formik.touched.brandId && (
                  <div className={classes.formikError}>
                    {formik.errors.brandId}
                  </div>
                )}
              </Grid.Col>
              <Grid.Col xs={12}>
                <TextInput
                  placeholder="e.g : Brand Name"
                  name="name"
                  label="Name"
                  withAsterisk
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.errors.name && formik.touched.name && (
                  <div className={classes.formikError}>
                    {formik.errors.name}
                  </div>
                )}
              </Grid.Col>
              <Grid.Col xs={12}>
                <TextInput
                  placeholder="e.g : TVS Electronics"
                  name="title"
                  label="Title"
                  withAsterisk
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
                {formik.errors.title && formik.touched.title && (
                  <div className={classes.formikError}>
                    {formik.errors.title}
                  </div>
                )}
              </Grid.Col>

              <Grid.Col xs={12}>
                <TextInput
                  placeholder="e.g : brand meta Title"
                  name="metaTitle"
                  id="metaTitle"
                  label="Meta Title - maximum (255 characters Only)"
                  withAsterisk
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.metaTitle}
                />
                {formik.errors.metaTitle && formik.touched.metaTitle && (
                  <div className={classes.formikError}>
                    {formik.errors.metaTitle}
                  </div>
                )}
              </Grid.Col>
              <Grid.Col xs={12}>
                <Textarea
                  placeholder="e.g : Electronic store, printers and scanners price, printer price..."
                  name="metaKeywords"
                  label="Meta Keywords - maximum (256 characters Only)"
                  autosize
                  withAsterisk
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.metaKeywords}
                />
                {formik.errors.metaKeywords && formik.touched.metaKeywords && (
                  <div className={classes.formikError}>
                    {formik.errors.metaKeywords}
                  </div>
                )}
              </Grid.Col>
              <Grid.Col xs={12}>
                <Textarea
                  placeholder="e.g : Find closest TVS Electronic Store. Get contact information..."
                  name="metaDescription"
                  id="metaDescription"
                  label="Meta Description - maximum (255 characters Only)"
                  autosize
                  withAsterisk
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.metaDescription}
                />
                {formik.errors.metaDescription &&
                  formik.touched.metaDescription && (
                    <div className={classes.formikError}>
                      {formik.errors.metaDescription}
                    </div>
                  )}
              </Grid.Col>

              <Grid.Col xs={12}>
                <Textarea
                  placeholder="e.g : Find closest TVS Electronic Store. Get contact information..."
                  name="longDescription"
                  label="Long Description - maximum (500 characters Only)"
                  autosize
                  withAsterisk
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.longDescription}
                />
                {formik.errors.longDescription &&
                  formik.touched.longDescription && (
                    <div className={classes.formikError}>
                      {formik.errors.longDescription}
                    </div>
                  )}
              </Grid.Col>
              <Grid.Col xs={12}>
                <Textarea
                  placeholder="e.g Find closest TVS Electronic Store. Get contact information..."
                  name="shortDescription"
                  id="shortDescription"
                  onBlur={formik.handleBlur}
                  label="Short Description - maximum (255 characters Only)"
                  withAsterisk
                  onChange={formik.handleChange}
                  value={formik.values.shortDescription}
                  autosize
                />
                {formik.errors.shortDescription &&
                  formik.touched.shortDescription && (
                    <div className={classes.formikError}>
                      {formik.errors.shortDescription}
                    </div>
                  )}
              </Grid.Col>
              <Grid.Col xs={12}>
                <FileInput
                  label="Brand Images"
                  description="Brand Images"
                  // placeholder="Brand Images"
                  icon={<IconUpload size={14} />}
                  name="logo"
                  accept="image/png,image/jpeg,image/webp"
                  onChange={imageChange}
                />
              </Grid.Col>
              {selectedImage ? (
                <>
                  <Grid.Col xs={6}>
                    {selectedImage && (
                      <div>
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          height={180}
                          width={300}
                          alt="logoFile"
                          style={{ marginTop: '10px' }}
                        />
                      </div>
                    )}
                  </Grid.Col>

                  <Grid.Col xs={6}>
                    <Button
                      onClick={() => removeImage()}
                      style={{ background: 'none', marginLeft: '-110px' }}
                    >
                      <ActionIcon
                        sx={{
                          color: 'black',
                          backgroundImage:
                            'linear-gradient(45deg, #fd7e14 0%, #fa5252 100%)',
                        }}
                      >
                        <IconX size={15} />
                      </ActionIcon>
                    </Button>
                  </Grid.Col>
                </>
              ) : (
                <Grid.Col xs={12}>
                  {images && (
                    <div>
                      <img
                        src={images?.Location}
                        height={150}
                        width={300}
                        alt="logoFile"
                      />
                    </div>
                  )}
                </Grid.Col>
              )}
              {isEditMode ? (
                <>
                  <Grid.Col xs={12}>
                    <Text
                      fz="sm"
                      style={{
                        // margin: '10px',
                        fontSize: '14px',
                      }}
                    >
                      Brand Gallery Images
                    </Text>
                  </Grid.Col>

                  {storeGalleryImage?.map((val: any) => (
                    <>
                      <Grid.Col xs={5} key={val}>
                        <img
                          src={val?.file?.Location}
                          height={180}
                          width={310}
                          alt="logoFile"
                          style={{
                            margin: 10,
                          }}
                        />
                      </Grid.Col>
                      <Grid.Col xs={1}>
                        <Button
                          onClick={() => removeImageBanner(val._id)}
                          style={{
                            background: 'none',
                            marginLeft: '-30px',
                          }}
                        >
                          <ActionIcon
                            sx={{
                              color: 'black',
                              backgroundImage:
                                'linear-gradient(45deg, #fd7e14 0%, #fa5252 100%)',
                            }}
                          >
                            <IconX size={15} />
                          </ActionIcon>
                        </Button>
                      </Grid.Col>
                    </>
                  ))}

                  <Grid.Col xs={12}>
                    <Button onClick={() => setOpen(true)}>Add Image</Button>
                  </Grid.Col>
                </>
              ) : (
                ''
              )}
              <Grid.Col xs={12} style={{ marginTop: '5px' }}>
                <Checkbox
                  checked={formik.values.status}
                  required
                  name="status"
                  label={formik.values.status === true ? 'Active' : 'Inactive'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid.Col>
              <Grid.Col xs={2} style={{ marginLeft: '90px' }}>
                <Button
                  variant="gradient"
                  gradient={{ from: 'orange', to: 'red' }}
                  onClick={() => {
                    onClose()
                  }}
                  disabled={loading1}
                >
                  Cancel
                </Button>
              </Grid.Col>
              <Grid.Col xs={2}>
                <Button
                  variant="gradient"
                  gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                  className={
                    !(formik.dirty && formik.isValid) ? 'disabled-btn' : ''
                  }
                  disabled={!(formik.dirty && formik.isValid)}
                  rightIcon={loading1 ? <Loader size={14} color="white" /> : ''}
                >
                  {isEditMode ? 'Update' : 'Save'}
                </Button>
              </Grid.Col>
            </>
          )}
        </Grid>
        <Modal
          opened={open}
          centered
          onClose={() => {
            setOpen(false)
            setSearchParams({})
          }}
          title="Brand Image Gallery (Upto 8 images can be Uploaded)"
          size="lg"
        >
          <Grid>
            <Grid.Col xs={12}>
              <FileInput
                multiple
                label="File Upload (Only image/png,image/jpeg,image/webp file format is supported)"
                description="image/png,image/jpeg,image/webp,image.jpg"
                // placeholder="image/png,image/jpeg,image/webp,image.jpg"
                icon={<IconUpload size={14} />}
                name="storeImage"
                disabled={loading1}
                onChange={fileUpload}
                accept="image/png,image/jpeg,image/webp"
              />
            </Grid.Col>
            {selectedFile?.length !== 0 ? (
              <div>
                {selectedFile?.map((image: any, index: any) => (
                  <Grid key={image}>
                    <Grid.Col xs={6}>
                      <img
                        src={URL.createObjectURL(image)}
                        height={200}
                        width={350}
                        alt="logoFile"
                        style={{ margin: 10 }}
                      />
                    </Grid.Col>
                    <Grid.Col xs={6}>
                      <Button
                        onClick={() => handleRemoveGal(index)}
                        style={{ background: 'none', marginLeft: '60px' }}
                      >
                        <ActionIcon
                          sx={{
                            color: 'black',
                            backgroundImage:
                              'linear-gradient(45deg, #fd7e14 0%, #fa5252 100%)',
                          }}
                        >
                          <IconX size={15} />
                        </ActionIcon>
                      </Button>
                    </Grid.Col>
                  </Grid>
                ))}
              </div>
            ) : (
              ''
            )}
            <Grid.Col xs={12}>
              <Button
                variant="gradient"
                gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                onClick={(e: any) => handleSubmit(e)}
                loading={loading1 || false}
                loaderPosition="right"
                styles={() => ({
                  root: {
                    border: 0,
                    height: 42,
                    paddingLeft: 20,
                    paddingRight: 20,

                    '&:hover': {
                      backgroundColor: theme.fn.darken('#00acee', 0.09),
                    },
                  },
                })}
              >
                Submit
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>
      </div>
    </div>
  )
}
export default BrandModelForm
