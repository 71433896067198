/* eslint-disable max-len */
import {
  Box,
  Paper,
  Title,
  Grid,
  Button,
  SelectItemProps,
  MantineColor,
  Popover,
} from '@mantine/core'
import * as XLSX from 'xlsx'
import {
  IconCircleCheck,
  IconCircleX,
  IconFilter,
  IconUpload,
} from '@tabler/icons'
import axios, { AxiosError, AxiosResponse } from 'axios'
import _, { get } from 'lodash'
import { DataTableColumn, DataTable } from 'mantine-datatable'
import { useEffect, useMemo, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { DateRangePicker } from '@mantine/dates'
import dayjs from 'dayjs'
import { API_SEVICES } from '../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
} from '../../constants'
import { openSnackbar } from '../../redux/snackbar'
import ProgressLoader from '../../components/ProgressLoader'
import { useAppDispatch } from '../../redux/store'
import { getLocalStorageItem } from '../../utils/localStorage'

interface ItemProps extends SelectItemProps {
  _id: any
  name: string
}
interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}
export default function URLIndex() {
  const [loading, setLoading] = useState(false)
  const [recordData, setRecordData] = useState([])

  const [page, setPage] = useState(1)
  const [pageNo, setPageNo] = useState(1)
  const [exportLoading, setExportLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState<any>(null)

  const [pageSize, setPageSize] = useState(PAGESIZES[0])

  const [records, setRecords] = useState<any>(null)
  const filterRef = useRef<HTMLInputElement>(null)
  const [rangeDate, setRangeDate] = useState<[Date | null, Date | null]>([
    new Date(),
    new Date(),
  ])
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const Token = getLocalStorageItem('token')

  useEffect(() => {
    setLoading(true)

    const controller = new AbortController()
    if (selectedDate === null) {
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_URL_INDEXING}`,
        (res: AxiosResponse) => {
          setLoading(false)
          if (res.data.data.data.length !== 0) {
            setRecordData(res.data.data.data)
            setRecords(res.data.data.data.slice(0, 10))
          } else {
            setRecordData([])
            setRecords(null)
            dispatch(
              openSnackbar({
                messege: 'No URL Found',
                icon: AlertIcons.error,
                title: 'error',
                color: AlertColor.error,
              })
            )
          }
        },
        (err: AxiosError) => {
          setLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
          if (err.message !== 'canceled') {
            dispatch(
              openSnackbar({
                messege: get(
                  err,
                  'response.data.message',
                  'State Details Cant be fetched'
                ),
                icon: AlertIcons.error,
                title: 'error',
                color: AlertColor.error,
              })
            )
          }
          console.error(err)
        },
        controller.signal
      )
    } else {
      const match = JSON.stringify({
        fromdate: moment(rangeDate[0]).format('YYYY-MM-DD'),
        todate: moment(rangeDate[1]).add(1, 'days').format('YYYY-MM-DD'),
      })
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_URL_INDEXING}?match=${match}`,
        (res: AxiosResponse) => {
          setLoading(false)

          if (res.data.data.data.length !== 0) {
            setRecordData(res.data.data.data)
            setRecords(res.data.data.data.slice(0, 10))
          } else {
            setRecordData([])
            setRecords([])
            dispatch(
              openSnackbar({
                messege: 'No URL Found',
                icon: AlertIcons.error,
                title: 'error',
                color: AlertColor.error,
              })
            )
          }
        },
        (err: AxiosError) => {
          setLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
          if (err.message !== 'canceled') {
            dispatch(
              openSnackbar({
                messege: get(
                  err,
                  'response.data.message',
                  'State Details Cant be fetched'
                ),
                icon: AlertIcons.error,
                title: 'error',
                color: AlertColor.error,
              })
            )
          }
          console.error(err)
        },
        controller.signal
      )
    }

    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])
  useEffect(() => {
    const from = (page - 1) * pageSize
    const to = from + pageSize
    setRecords(recordData.slice(from, to))
  }, [page, pageSize, recordData])

  const colDef: DataTableColumn<any>[] = useMemo(
    () => [
      {
        accessor: 'notifyTime',
        title: 'Date',
        textAlignment: 'center',
        minWidth: 70,
        width: 100,
        titleSx: () => ({
          '&&': { backgroundColor: '#f4f4f4' },
        }),

        render: ({ notifyTime }) => (
          <p>{moment(notifyTime).format('DD-MM-YYYY')}</p>
        ),
      },
      {
        accessor: 'urls',
        Header: 'URL',
        textAlignment: 'center',
        minWidth: 300,
        width: 800,
        titleSx: () => ({
          '&&': { backgroundColor: '#f4f4f4' },
        }),
        render: ({ urls }) => (
          <a href={`${urls}`} target="_blank" rel="noreferrer">
            {urls}
          </a>
        ),
      },
      {
        accessor: 'type',
        title: 'Status',
        textAlignment: 'center',
        minWidth: 270,
        width: 200,
        titleSx: () => ({
          '&&': { backgroundColor: '#f4f4f4' },
        }),
        render: ({ type }: any) => {
          if (type === 'URL_UPDATED') {
            return <IconCircleCheck color="green" size={30} />
          }
          return <IconCircleX color="#ff2825" size={30} />
        },
      },
    ],
    []
  )

  const onDownload = () => {
    setExportLoading(true)
    const match = JSON.stringify({
      fromdate: moment(rangeDate[0]).format('YYYY-MM-DD'),
      todate: moment(rangeDate[1]).add(1, 'days').format('YYYY-MM-DD'),
    })
    const path =
      selectedDate === null
        ? `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.EXPORT_URL_INDEXING}`
        : `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.EXPORT_URL_INDEXING}?match=${match}`
    axios({
      url: path,
      headers: {
        Authorization: `Bearer ${Token}`,
      },

      method: 'get',
      responseType: 'json', // important
    })
      .then((res) => {
        setExportLoading(false)
        const worksheet = XLSX.utils.json_to_sheet(res.data.data)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

        XLSX.writeFile(workbook, 'Closebi_Selected_Keywords.xlsx')
      })
      .catch((error) => {
        setExportLoading(false)
        dispatch(
          openSnackbar({
            messege: get(
              error,
              'response.data.message',
              'Failed To Download File'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        if (
          get(error, 'response.status', '') === 401 ||
          get(error, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        console.error('download file error', error)
      })
  }

  return (
    <Box>
      <Paper shadow="xs" p="md">
        <Grid>
          <Grid.Col
            xs={6.5}
            sm={6.5}
            md={6.5}
            lg={7.5}
            xl={7.5}
            ref={filterRef}
          >
            <Title order={3} weight={100} style={{ marginTop: ' 13px' }}>
              URL Status
            </Title>
          </Grid.Col>
          <Grid.Col xs={2} sm={2} md={2} lg={2} xl={2}>
            <Popover
              width={350}
              position="bottom"
              withArrow
              shadow="xl"
              styles={() => ({
                dropdown: {
                  backgroundColor: '#f0f6f7',
                },
              })}
            >
              <Popover.Target>
                <Button
                  variant="gradient"
                  gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                  loading={loading}
                  disabled={recordData.length === 0}
                  style={{ width: '80%' }}
                >
                  {!loading ? <IconFilter /> : ''}
                  <span style={{ marginLeft: '6px' }}>Filter</span>
                </Button>
              </Popover.Target>

              <Popover.Dropdown>
                <Grid.Col xs={12}>
                  <DateRangePicker
                    label="Select Date Range"
                    placeholder="Pick dates range"
                    minDate={dayjs('2022-12-15').toDate()}
                    allowSingleDateInRange={true}
                    maxDate={dayjs(new Date()).toDate()}
                    value={rangeDate}
                    range={[
                      dayjs(new Date()).startOf('month').toDate(),
                      dayjs(new Date()).endOf('month').toDate(),
                    ]}
                    onChange={(i: any) => {
                      setLoading(true)
                      setPage(1)
                      if (i[0] && i[1]) {
                        setSelectedDate(i)
                        setRangeDate(i)
                        setPage(1)
                        setPageNo(1)
                      }
                    }}
                  />
                </Grid.Col>
              </Popover.Dropdown>
            </Popover>
          </Grid.Col>

          <Grid.Col xs={2} sm={2} md={2} lg={2} xl={2}>
            <div>
              <Button
                style={{ width: '100%' }}
                variant="gradient"
                gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                disabled={recordData.length === 0}
                loading={exportLoading}
                leftIcon={<IconUpload size={18} />}
                onClick={() => {
                  onDownload()
                }}
              >
                Export
              </Button>
            </div>
          </Grid.Col>
          <Grid.Col xs={12} sx={{ maxHeight: 600, minHeight: 400 }}>
            <div>{loading ? <ProgressLoader /> : ''}</div>
            <DataTable
              records={records}
              columns={colDef}
              totalRecords={recordData.length}
              recordsPerPage={pageSize}
              page={page}
              paginationColor="green"
              onPageChange={(p) => setPage(p)}
              recordsPerPageOptions={PAGESIZES}
              onRecordsPerPageChange={setPageSize}
            />
          </Grid.Col>
        </Grid>
      </Paper>
    </Box>
  )
}
