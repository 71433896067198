import React, { useEffect, useState } from 'react'
import { Group, Textarea, ActionIcon, Grid } from '@mantine/core'
import { IconCheck, IconPlus, IconX } from '@tabler/icons'

interface Iprops {
  handleSave: (value: any) => any
  handleDelete: (state: any) => any
  fieldKey?: string
  fieldValue?: string
  isEditMode: any
  inputField: any
}

export default function AddField({
  handleSave,
  handleDelete,
  fieldKey = '',
  fieldValue = '',
  isEditMode,
  inputField,
}: Iprops) {
  const [state, setState] = useState<any>({
    key: '',
    value: '',
  })
  const [isEdited, setEdited] = useState(false)
  const [isEdit, setEdit] = useState(false)

  const handleClose = () => {
    setState({ key: '', value: '' })
    handleDelete(state)
  }

  const onClickSave = () => {
    setState({ key: '', value: '' })
    handleSave(state)
  }
  const onClickEdit = () => {
    setEdit(true)
    setState({ key: '', value: '' })
    handleSave(state)
  }

  useEffect(() => {
    setState({
      key: fieldKey,
      value: fieldValue,
    })
  }, [fieldKey, fieldValue])

  return (
    <Grid>
      {!isEdit ? (
        <>
          <Grid.Col span={9}>
            <Grid>
              <Grid.Col span={6}>
                <Textarea
                  label="Field"
                  name="key"
                  id="key"
                  value={state.key}
                  autosize
                  onChange={(e: React.ChangeEvent<any>) => {
                    setState((prev: any) => ({ ...prev, key: e.target.value }))
                  }}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Textarea
                  size="xs"
                  name="value"
                  id="value"
                  value={state.value}
                  autosize
                  label="Value"
                  onChange={(e: React.ChangeEvent<any>) => {
                    isEditMode && state.value ? setEdited(true) : ''
                    setState((prev: any) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                  }}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col
            span={3}
            sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
          >
            <Group position="center">
              {isEditMode === true && state.value && isEdited === true && (
                <ActionIcon
                  variant="transparent"
                  disabled={!state.value}
                  onClick={
                    isEdited && isEditMode === true ? onClickEdit : () => {}
                  }
                >
                  {isEdited && isEditMode === true ? (
                    <IconCheck size={24} color="green" />
                  ) : (
                    ''
                  )}
                </ActionIcon>
              )}
              <ActionIcon
                variant="transparent"
                disabled={!state.value}
                onClick={!fieldKey ? onClickSave : () => {}}
              >
                {!fieldKey ? <IconPlus size={30} color="green" /> : ''}
              </ActionIcon>
              <ActionIcon
                variant="transparent"
                onClick={fieldKey ? handleClose : () => {}}
              >
                {fieldKey && <IconX size={24} color="red" />}
              </ActionIcon>
            </Group>
          </Grid.Col>
        </>
      ) : (
        ''
      )}
    </Grid>
  )
}
