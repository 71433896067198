import { useEffect, useState, KeyboardEvent } from 'react'
import {
  Grid,
  TextInput,
  Button,
  Checkbox,
  Loader,
  Text,
  createStyles,
} from '@mantine/core'
import { get } from 'lodash'
import { AxiosError, AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { AlertColor, AlertIcons, API_ENDPOINTS } from '../../../constants'
import { API_SEVICES } from '../../../config/services'
import { useAppDispatch } from '../../../redux/store'
import { openSnackbar } from '../../../redux/snackbar'
import SpinningLoader from '../../../components/SpinningLoader'

interface IProps {
  fetchData: () => void
  onClose: () => void
  mode: any
  isEditMode: any
  isViewMode: any
}
const useStyles = createStyles({
  formikError: {
    color: '#CC0000',
    fontSize: '13px',
  },
  outline: {
    border: 'none',
    outline: 'none !important',
  },
})
function StateModelForm(props: IProps) {
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get('id')
  const { classes } = useStyles()
  const { onClose, mode, isEditMode, isViewMode, fetchData } = props
  const formik = useFormik({
    initialValues: {
      label: '',
      status: true,
    },
    onSubmit: (values) => {
      setLoading1(true)
      !isEditMode || isViewMode
        ? API_SEVICES.PostObjRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.POST_STATE}`,
            (res: AxiosResponse) => {
              onClose
              formik.resetForm()
              setLoading1(false)
              dispatch(
                openSnackbar({
                  messege: 'State Added Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              if (
                get(err, 'response.status', '') === 401 ||
                get(err, 'response.status', '') === 403
              ) {
                localStorage.clear()
                navigate('/login', { replace: true })
              }
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'State Details Cant be Added'
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              console.error(err)
            },
            values
          )
        : API_SEVICES.PatchObjRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.PATCH_STATE}?id=${id}`,
            (res: AxiosResponse) => {
              setLoading1(false)
              onClose
              formik.resetForm()
              dispatch(
                openSnackbar({
                  messege: 'State Updated Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              if (
                get(err, 'response.status', '') === 401 ||
                get(err, 'response.status', '') === 403
              ) {
                localStorage.clear()
                navigate('/login', { replace: true })
              }
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'State Cant be  Updated'
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              console.error(err)
            },
            values
          )
    },
    validationSchema: Yup.object({
      label: Yup.string().required('State Name is Required'),
    }),
  })
  useEffect(() => {
    if (id) {
      setLoading(true)
      const match = {
        _id: id,
      }
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_ENDPOINTS.GET_STATE
        }?match=${JSON.stringify(match)}`,
        (res: AxiosResponse) => {
          setLoading(false)

          formik.setFieldValue('label', get(res, 'data.data[0].label', ''))
          formik.setFieldValue('status', get(res, 'data.data[0].status', ''))
        },
        (err: AxiosError) => {
          setLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div
      className={classes.outline}
      role="button"
      tabIndex={0}
      onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
          formik.handleSubmit()
        }
      }}
    >
      <Grid sx={{ minHeight: '200px' }}>
        {loading ? (
          <SpinningLoader />
        ) : (
          <>
            {isEditMode ? (
              <Text fz="sm" style={{ fontSize: '15px', margin: '8px' }}>
                State Name : {formik.values.label}
              </Text>
            ) : (
              ''
            )}
            <Grid.Col xs={12}>
              <TextInput
                placeholder="State Name"
                name="label"
                label="State Name"
                withAsterisk
                onChange={formik.handleChange}
                value={formik.values.label}
                onBlur={formik.handleBlur}
              />
              {formik.errors.label && formik.touched.label && (
                <div className={classes.formikError}>{formik.errors.label}</div>
              )}
            </Grid.Col>

            <Grid.Col xs={5}>
              <Checkbox
                checked={formik.values.status}
                required
                name="status"
                label={formik.values.status === true ? 'Active' : 'Inactive'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid.Col>

            <Grid.Col xs={2} style={{ marginLeft: '130px' }}>
              <Button
                variant="gradient"
                gradient={{ from: 'orange', to: 'red' }}
                onClick={onClose}
                disabled={loading1}
              >
                Cancel
              </Button>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Button
                variant="gradient"
                gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                onClick={() => {
                  formik.handleSubmit()
                }}
                className={
                  !(formik.dirty && formik.isValid) ? 'disabled-btn' : ''
                }
                disabled={!(formik.dirty && formik.isValid)}
                rightIcon={loading1 ? <Loader size={14} color="white" /> : ''}
                loading={loading1}
                loaderPosition="right"
                style={{ marginLeft: '5px' }}
              >
                {isEditMode ? 'Update' : 'Save'}
              </Button>
            </Grid.Col>
          </>
        )}
      </Grid>
    </div>
  )
}
export default StateModelForm
