import {
  Paper,
  Title,
  Grid,
  Button,
  Modal,
  Text,
  Menu,
  useMantineTheme,
} from '@mantine/core'
import {
  IconCirclePlus,
  IconEyeCheck,
  IconEdit,
  IconTrash,
  IconDotsVertical,
  IconCircleCheck,
  IconCircleX,
} from '@tabler/icons'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { get } from 'lodash'
import { DataTableColumn } from 'mantine-datatable'
import { useEffect, useMemo, useState, useRef } from 'react'
import CustomTable from '../../components/NewcustomTable'
import { API_SEVICES } from '../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
  BRANDID,
  ROLEPROVIDED,
} from '../../constants'
import { openSnackbar } from '../../redux/snackbar'
import BrandModelForm from './BrandModelForm'
import UploadFileForm from './uploadFileForm/uploadFile'
import { useAppSelector } from '../../redux/store'
import DeleteModal from '../../components/DeleteModal'
import BrandViewModel from './BrandViewModel'
import { getLocalStorageItem } from '../../utils/localStorage'
import { useAppDispatch } from '../../hooks'

export default function ViewBrandData() {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const mode = searchParams.get('mode')
  const [modelState, setModel] = useState(false)
  const [uploadSate, setUploadState] = useState(false)
  const [rowData, setRowData] = useState([])
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [recordData, setRecordData] = useState([])
  const [page, setPage] = useState(1)
  const [pageNo, setPageNo] = useState(1)
  const [pageSize, setPageSize] = useState(PAGESIZES[0])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [countFrom, setCountForm] = useState<any>()
  const [tableHeight, setTableHeight] = useState(500)
  const [totalRowSize, setTotalRowSize]: any = useState(10)
  const [countTo, setCountTo] = useState<any>()
  const filterRef = useRef<HTMLInputElement>(null)
  const paperRef = useRef<HTMLInputElement>(null)
  const deleteBrand = API_ENDPOINTS.DELETE_BRAND
  const deletedData = API_ENDPOINTS.GET_BRAND
  const deleteName = 'brand'
  const navigate = useNavigate()
  const theme = useMantineTheme()
  const dispatch = useAppDispatch()
  const role = useAppSelector((state) => state.role)
  const brandId = role.brandId || BRANDID || null

  const roleProvided = role.role || ROLEPROVIDED

  const handleClose = () => {
    setModel(false)
    setDeleteOpen(false)
    setSearchParams({})
  }

  const fetchData = () => {
    setLoading(true)
    const match = {
      _id: brandId,
    }
    let mat
    if (brandId === null && roleProvided === 'admin') {
      mat = JSON.stringify({ _id: null })
    } else if (brandId !== null && roleProvided === 'admin') {
      mat = JSON.stringify(match)
    } else if (roleProvided === 'user') {
      mat = JSON.stringify(match)
    } else if (roleProvided === 'brand manager') {
      mat = JSON.stringify(match)
    } else if (brandId !== null && roleProvided === 'CRM manager') {
      mat = JSON.stringify(match)
    } else if (brandId === null && roleProvided === 'CRM manager') {
      mat = JSON.stringify({ _id: null })
    } else {
      mat = JSON.stringify({})
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${mat}&pageNo=${page}&size=${pageSize}`,

      (res: AxiosResponse) => {
        setLoading(false)
        setTotalRowSize(res.data.TotalRecords)
        setRecordData(res.data.data)
        setRowData(res.data.data.slice(0, pageSize))
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        openSnackbar({
          messege: get(
            err,
            'response.data.message',
            'Brand Details Cant be fetched'
          ),
          icon: AlertIcons.error,
          title: 'error',
          color: AlertColor.error,
        })
        setLoading(false)
        console.error(err)
      }
    )
  }

  useEffect(() => {
    setLoading(true)
    const match = {
      _id: brandId,
    }
    let mat
    if (brandId === null && roleProvided === 'admin') {
      mat = JSON.stringify({ _id: null })
    } else if (brandId !== null && roleProvided === 'admin') {
      mat = JSON.stringify(match)
    } else if (roleProvided === 'user') {
      mat = JSON.stringify(match)
    } else if (roleProvided === 'brand manager') {
      mat = JSON.stringify(match)
    } else if (brandId !== null && roleProvided === 'CRM manager') {
      mat = JSON.stringify(match)
    } else if (brandId === null && roleProvided === 'CRM manager') {
      mat = JSON.stringify({ _id: null })
    } else {
      mat = JSON.stringify({})
    }
    const controller = new AbortController()
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${mat}&pageNo=${page}&size=${pageSize}`,

      (res: AxiosResponse) => {
        setLoading(false)
        setTotalRowSize(res.data.TotalRecords)
        setRecordData(res.data.data)
        setRowData(res.data.data.slice(0, pageSize))
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        } else if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Brand Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          setLoading(false)
        }
        console.error(err)
      },
      controller.signal
    )
    // return cleanup function to abort request
    return () => {
      setLoading(false)
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize])

  useEffect(() => {
    if (filterRef.current != null) {
      const element = paperRef.current?.getClientRects()

      setTableHeight(window.innerHeight - filterRef.current.clientHeight - 110)
    }

    if (totalRowSize > pageSize) {
      const from = (page - 1) * pageSize
      const to = from + pageSize
      setCountForm(from + 1)
      setCountTo(to)
    } else {
      setCountForm(1)
      setCountTo(totalRowSize)
    }
    let rowDataSize

    if (totalRowSize >= pageSize) {
      rowDataSize = totalRowSize / pageSize

      if (rowDataSize - Math.floor(rowDataSize) !== 0) {
        setPageNo(Math.ceil(rowDataSize))
      } else {
        setPageNo(rowDataSize)
      }
    } else {
      rowDataSize = Math.ceil(totalRowSize / pageSize)
      setPageNo(rowDataSize)
    }
  }, [page, pageSize, recordData, theme, totalRowSize])

  const colDef: DataTableColumn<any>[] = useMemo(
    () => [
      {
        Header: 'Actions',
        accessor: 'actions',
        fixed: 'left',
        title: <Text mr="xs">Actions</Text>,
        textAlignment: 'center',
        minWidth: 100,
        width: 100,

        Cell: ({ ...row }) => (
          <Menu shadow="md" width={130} position="right-start">
            <Menu.Target>
              <Button style={{ background: 'none' }}>
                {' '}
                <IconDotsVertical size={16} color="black" />
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                color="green"
                icon={<IconEyeCheck size={14} />}
                onClick={() => {
                  setOpen(true)
                  setSearchParams({
                    mode: 'view',
                    id: row?.row?.original._id,
                  })
                }}
              >
                View
              </Menu.Item>
              <Menu.Item
                color="blue"
                onClick={() => {
                  setModel(true)
                  setSearchParams({
                    mode: 'edit',
                    id: row?.row?.original._id,
                  })
                }}
                icon={<IconEdit size={14} />}
                disabled={
                  roleProvided === 'user' || roleProvided === 'brand manager'
                }
              >
                Edit
              </Menu.Item>
              <Menu.Item
                color="red"
                onClick={() => {
                  setDeleteOpen(true)
                  setSearchParams({
                    mode: 'delete',
                    id: row?.row?.original._id,
                  })
                }}
                icon={<IconTrash size={14} />}
                disabled={roleProvided !== 'super admin'}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ),
      },
      {
        Header: 'Brand Id',
        accessor: 'brandId',
        textAlignment: 'center',
        minWidth: 150,
        width: 150,
      },
      {
        Header: 'Name',
        accessor: 'name',
        title: 'Name',
        textAlignment: 'center',
        minWidth: 150,
        width: 150,
      },
      {
        Header: 'Title',
        accessor: 'title',
        title: 'Title',
        textAlignment: 'center',
        minWidth: 150,
        width: 180,
      },
      {
        Header: 'Meta Title',
        accessor: 'metaTitle',
        title: 'Meta Title',
        textAlignment: 'center',
        minWidth: 150,
        width: 180,
      },
      {
        Header: 'Meta Description',
        accessor: 'metaDescription',
        title: 'Meta Description',
        textAlignment: 'center',
        minWidth: 150,
        width: 250,
      },
      {
        Header: 'Status',
        accessor: 'status',
        textAlignment: 'center',
        minWidth: 120,
        Cell: ({ ...status }) => {
          if (status.row.original.status === true) {
            return <IconCircleCheck color="green" size={30} />
          }
          return <IconCircleX color="#ff2825" size={30} />
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSearchParams]
  )

  return (
    <>
      <Paper shadow="xs" p="md">
        <Grid>
          <Grid.Col
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            ref={filterRef}
          >
            <Title order={3} weight={100}>
              Brand
            </Title>
            {roleProvided === 'super admin' ? (
              <Grid>
                <Grid.Col span={4}>
                  <Button
                    variant="gradient"
                    gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                    leftIcon={<IconCirclePlus size={18} />}
                    onClick={() => {
                      setModel(true)
                      setSearchParams({ mode: 'add' })
                    }}
                  >
                    Add Brand
                  </Button>
                </Grid.Col>
              </Grid>
            ) : null}
          </Grid.Col>

          <Grid.Col xs={12}>
            <CustomTable
              pageFrom={countFrom}
              pageTo={countTo}
              total={pageNo}
              gridApi={(instance) => {}}
              totalRecords={!loading ? totalRowSize : 1}
              checkboxSelection={false}
              recordsPerPage={pageSize}
              page={page}
              height={tableHeight}
              onPageChange={(p: any) => setPage(p)}
              recordsPerPageOptions={PAGESIZES}
              onRecordsPerPageChange={setPageSize}
              loading={!!loading}
              rowData={!loading ? recordData : []}
              colDef={colDef}
            />
          </Grid.Col>
        </Grid>
      </Paper>

      <Modal
        style={{
          fontSize: '18px',
          textTransform: 'capitalize',
          fontWeight: 500,
        }}
        opened={modelState}
        centered
        onClose={() => {
          setSearchParams({})
          setModel(false)
        }}
        title={`${mode} brand`}
        size="xl"
      >
        <BrandModelForm
          fetchData={() => fetchData()}
          onClose={() => {
            setSearchParams({})
            handleClose()
          }}
          mode={searchParams.get('mode')}
          isEditMode={searchParams.get('mode')?.toLowerCase() === 'edit'}
          isViewMode={searchParams.get('mode')?.toLowerCase() === 'view'}
        />
      </Modal>
      <Modal
        opened={uploadSate}
        centered
        onClose={() => {
          setUploadState(false)
          setSearchParams({})
        }}
        title="Upload Brand"
        size="xl"
      >
        <UploadFileForm onClose={handleClose} />
      </Modal>
      <Modal
        opened={deleteOpen}
        centered
        onClose={() => {
          setDeleteOpen(false)
          setSearchParams({})
        }}
        title="Delete Brand"
        size="lg"
      >
        <DeleteModal
          fetchData={fetchData}
          onClose={handleClose}
          deleteApi={deleteBrand}
          deletedGetApi={deletedData}
          deleteName={deleteName}
        />
      </Modal>
      <Modal
        opened={open}
        centered
        onClose={() => {
          setOpen(false)
          setSearchParams({})
        }}
        title="View Brand"
        size="lg"
      >
        <BrandViewModel onClose={handleClose} />
      </Modal>
    </>
  )
}
