import { IconChartBar } from '@tabler/icons'
import { LinksGroupProps } from '../components/NavBarLinkGroups'

export const ROUTE_PATH = {
  home: '/adminmanager',
  login: '/login',
  brand: '/adminmanager/brand',
  type: '/adminmanager/types',
  category: '/adminmanager/category',
  users: '/adminmanager/users',
  token: '/adminmanager/token',
  store: '/adminmanager/stores',
  product: '/adminmanager/products',
  state: '/adminmanager/state',
  city: '/adminmanager/city',
  subCategory: '/adminmanager/subCategory',
  knowlarity: '/adminmanager/virtualphonenumber',
  calllog: '/adminmanager/calllog',
  keywords: '/adminmanager/keywords',
  action: '/adminmanager/action',
  calllogChart: '/adminmanager/calllogChart',
  callActions: '/adminmanager/callActions',
  discovery: '/adminmanager/discovery',
  monthlyReport: '/adminmanager/monthlyReport',
}

export const drawerOptions: LinksGroupProps[] = [
  {
    label: 'Dashboard',
    icon: IconChartBar,
    initiallyOpened: true,
    links: [
      { label: 'CallLog', link: ROUTE_PATH.calllog },
      { label: 'Discovery', link: ROUTE_PATH.discovery },
      { label: 'Action', link: ROUTE_PATH.callActions },
      { label: 'Keywords', link: ROUTE_PATH.keywords },
      { label: 'Monthly Report', link: ROUTE_PATH.monthlyReport },
    ],
  },
]
