/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */

/* eslint-disable jsx-a11y/media-has-caption */

import {
  Paper,
  Title,
  Grid,
  Text,
  Group,
  SelectItemProps,
  Select,
  Popover,
  createStyles,
  Button,
  Modal,
  useMantineTheme,
  Loader,
} from '@mantine/core'
import moment from 'moment'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { IconFilter, IconUpload, IconTrash, IconX } from '@tabler/icons'
import _, { get, map } from 'lodash'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { DatePicker } from 'rsuite'
import { useNavigate } from 'react-router-dom'
import 'rsuite/DateRangePicker/styles/index.less'
import 'rsuite/dist/rsuite.min.css'
import isAfter from 'date-fns/isAfter'
import dayjs from 'dayjs'
import { useAppSelector } from '../../hooks'
import KeywordsDelete from './keywordsDelete'
import { API_SEVICES } from '../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
  ROLEPROVIDED,
  BRANDID,
  STORECODE,
  SATHYAID,
} from '../../constants'

import { openSnackbar } from '../../redux/snackbar'
import { useAppDispatch } from '../../redux/store'
import CustomTable, { CustomColum } from '../../components/NewcustomTable'
import KeywordsUpload from './keywordsUpload'
import { getLocalStorageItem } from '../../utils/localStorage'

interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  description: string
  cityName: string
}

const useStyles = createStyles((theme) => ({
  outside: {
    opacity: 0,
  },

  weekend: {
    color: `${theme.colors.blue[6]} !important`,
  },

  selected: {
    color: `${theme.white} !important`,
  },
  tableBodyStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableHeaderStyle: {
    textAlign: 'center',
  },
  'mantine-Navbar-root': {
    zIndex: 0,
  },
}))

export default function Keywords() {
  const filterRef = useRef<HTMLInputElement>(null)
  const paperRef = useRef<HTMLInputElement>(null)
  const theme = useMantineTheme()
  const [cityValue, setCityValue] = useState<any>(null)
  const [tableHeight, setTableHeight] = useState(10)
  const [loading, setLoading] = useState(false)
  const [recordData, setRecordData] = useState([])
  const [state, setState] = useState([])
  const [page, setPage] = useState(1)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState(false)
  const [stateData, setStateData] = useState([])
  const [city, setCity] = useState([])
  const [pageSize, setPageSize] = useState(PAGESIZES[0])
  const [storeVal, setStoreVal] = useState<any>(null)
  const [brandVal, setBrandVal] = useState<any>(null)
  const [countFrom, setCountForm] = useState<any>()
  const [countTo, setCountTo] = useState<any>()
  const [brandData, setBrandData] = useState([])
  const [opened, setOpened] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [selectedDate, setSelectedDate] = useState<any>(null)
  const [totalRowSize, setTotalRowSize]: any = useState(0)
  const [stateValue, setStateValue] = useState<any>(null)
  const [openFilter, setOpenFilter] = useState(false)
  const [deleteBrand, setDeleteBrand] = useState<any>(null)
  const [fromDate, setFromDate] = useState<any>(null)
  const [toDate, setToDate] = useState<any>(new Date())
  const [stateLoading, setStateLoading] = useState(false)
  const [cityLoading, setCityLoading] = useState(false)
  const navigate = useNavigate()

  const { classes } = useStyles()

  const dispatch = useAppDispatch()

  const role = useAppSelector((stat) => stat.role)
  const brandID = role.brandId || BRANDID || null
  const roleProvided = role.role || ROLEPROVIDED
  const storeCode = role.storeCode || STORECODE || null
  const Token = getLocalStorageItem('token')

  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, name, description, cityName, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            {description || cityName ? (
              <Text size="xs" opacity={0.65}>
                {description}-{cityName}
              </Text>
            ) : null}
          </div>
        </Group>
      </div>
    )
  )

  useEffect(() => {
    let rangeMatch
    setLoading(true)
    const controller = new AbortController()
    if (brandVal === null) {
      if (roleProvided === 'admin') {
        rangeMatch = JSON.stringify({ brand: brandID })
      } else if (roleProvided === 'CRM manager') {
        rangeMatch = JSON.stringify({ brand: { $in: brandID } })
      } else if (roleProvided === 'brand manager') {
        rangeMatch = JSON.stringify({ brand: brandID })
      } else if (roleProvided === 'super admin') {
        rangeMatch = JSON.stringify({})
      } else if (roleProvided === 'user') {
        rangeMatch = JSON.stringify({ brand: brandID, storeId: storeCode })
      } else if (roleProvided === 'admin manager') {
        rangeMatch = JSON.stringify({ brand: brandID })
      }
    } else if (brandVal !== null) {
      rangeMatch = JSON.stringify({ brand: brandVal })
    }

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_SEARCH_AVAILABLE_DATES}?match=${rangeMatch}`,
        { match: rangeMatch },
        {
          signal: controller.signal,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-type': 'Application/json',
            Authorization: `Bearer ${Token}`,
          },
        }
      )
      .then((response: AxiosResponse) => {
        if (Object.keys(response.data.data).length === 0) {
          setFromDate(null)
          setToDate(null)
          setSelectedDate(new Date())
        } else {
          setFromDate(dayjs(response.data.data.startDate).toDate())
          setToDate(dayjs(response.data.data.endDate).toDate())
        }
        let match
        if (stateValue === null && brandVal === null && storeVal === null) {
          if (roleProvided === 'super admin') {
            match = {}
          } else if (roleProvided === 'admin' && brandID) {
            match = { brandId: brandID }
          } else if (roleProvided === 'CRM manager' && brandID) {
            match = { brandId: { $in: brandID } }
          } else if (roleProvided === 'brand manager' && brandID) {
            match = { brandId: brandID }
          } else if (roleProvided === 'admin manager' && brandID) {
            match = { brandId: brandID, storeId: storeCode }
          } else if (roleProvided === 'user' && brandID) {
            match = { storeId: storeCode }
          }
        } else if (roleProvided === 'admin' && brandID) {
          match = {
            brandId: brandID,
            state: stateValue,
            city: cityValue,
            storeId: storeVal !== null ? [storeVal] : null,
          }
        } else if (roleProvided === 'CRM manager' && brandID) {
          match = {
            brandId: brandVal,
            state: stateValue,
            city: cityValue,
            storeId: storeVal !== null ? [storeVal] : null,
          }
        } else if (roleProvided === 'brand manager' && brandID) {
          match = {
            brandId: brandID,
            state: stateValue,
            city: cityValue,
            storeId: storeVal !== null ? [storeVal] : null,
          }
        } else if (roleProvided === 'admin manager' && brandID) {
          match = {
            brandId: brandID,
            state: stateValue,
            city: cityValue,
            storeId: storeVal !== null ? [storeVal] : null,
          }
        } else {
          match = {
            brandId: brandVal,
            state: stateValue,
            city: cityValue,
            storeId: storeVal !== null ? [storeVal] : null,
          }
        }

        const clean = (obj: any) => {
          for (const propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
              delete obj[propName]
            }
          }
          return obj
        }
        const mat = clean(match)

        const match1 = JSON.stringify(mat)
        const fromQuery =
          selectedDate === null
            ? moment(response.data.data.endDate)
                .startOf('month')
                .format('YYYY-MM-DD')
            : moment(selectedDate).startOf('month').format('YYYY-MM-DD')

        const toQuery =
          selectedDate === null
            ? moment(response.data.data.endDate)
                .endOf('month')
                .format('YYYY-MM-DD')
            : moment(selectedDate).endOf('month').format('YYYY-MM-DD')
        setLoading(true)
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_KEYWORDS}?pageNo=${page}&size=${pageSize}`,
            {
              from: `${fromQuery}`,
              to: `${toQuery}`,
              match: `${match1}`,
            },
            {
              signal: controller.signal,
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-type': 'Application/json',
                Authorization: `Bearer ${Token}`,
              },
            }
          )
          .then((res: AxiosResponse) => {
            setLoading(false)
            setTotalRowSize(res?.data?.data?.total)
            setRecordData(res?.data?.data?.data)
          })
          .catch((error) => {
            setLoading(false)
            if (
              get(error, 'response.status', '') === 401 ||
              get(error, 'response.status', '') === 403
            ) {
              localStorage.clear()
              navigate('/login', { replace: true })
            }
            if (error.message !== 'canceled') {
              dispatch(
                openSnackbar({
                  messege: 'Store Not Found',
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
            }
            console.error(error)
          })
      })
      .catch((error) => {
        // handle error
        setLoading(false)
        if (
          get(error, 'response.status', '') === 401 ||
          get(error, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        console.error(error)
      })
    setSearch(false)
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
  }, [search, page, pageSize])

  useEffect(() => {
    let rangeMatch
    const controller = new AbortController()
    if (brandVal === null) {
      if (roleProvided === 'admin') {
        rangeMatch = JSON.stringify({ brand: brandID })
      } else if (roleProvided === 'CRM manager') {
        rangeMatch = JSON.stringify({ brand: { $in: brandID } })
      } else if (roleProvided === 'brand manager') {
        rangeMatch = JSON.stringify({ brand: brandID })
      } else if (roleProvided === 'super admin') {
        rangeMatch = JSON.stringify({})
      } else if (roleProvided === 'user') {
        rangeMatch = JSON.stringify({ brand: brandID, storeId: storeCode })
      } else if (roleProvided === 'admin manager') {
        rangeMatch = JSON.stringify({ brand: brandID })
      }
    } else if (brandVal !== null) {
      rangeMatch = JSON.stringify({ brand: brandVal })
    }
    API_SEVICES.PostRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_SEARCH_AVAILABLE_DATES}?match=${rangeMatch}`,
      (res: AxiosResponse) => {
        if (Object.keys(res.data.data).length === 0) {
          setFromDate(null)
          setToDate(null)
          setSelectedDate(new Date())
        } else {
          setFromDate(dayjs(res.data.data.startDate).toDate())
          setToDate(dayjs(res.data.data.endDate).toDate())
          setSelectedDate(moment(res.data.data.endDate).format('YYYY-MM-DD'))
        }
      },
      (err: AxiosError) => {
        console.error(err)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
      },
      {},
      controller.signal
    )
    // return cleanup function to abort request
    return () => {
      setLoading(false)
      controller.abort()
    }
  }, [brandVal])

  function fetchData() {
    setRecordData([])
    setTotalRowSize(0)
    setSelectedDate(null)
    setSearch(true)
    setPage(1)
    setPageNo(1)
  }

  useEffect(() => {
    const controller = new AbortController()
    let match
    if (brandID.length === 0) {
      match = JSON.stringify({ status: true })
    } else {
      match = JSON.stringify({ _id: brandID, status: true })
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${match}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Brand Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    let storeMatch

    if (brandVal !== null) {
      storeMatch = JSON.stringify({ brandId: brandVal, status: true })
    } else if (brandID && roleProvided === 'admin manager') {
      storeMatch = JSON.stringify({
        brandId: brandID?.[0],
        status: true,
        storeId: storeCode,
      })
    } else if (brandID && brandVal === null) {
      storeMatch = JSON.stringify({ brandId: brandID?.[0], status: true })
    } else {
      storeMatch = JSON.stringify({ status: true })
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_STORE}?match=${storeMatch}`,
      (res: AxiosResponse) => {
        setState(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Store Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.GET_CITY_DETAILS
      }?match=${JSON.stringify({
        state: stateValue,
        brandId: brandVal || brandID,
        status: 'true',
      })}`,
      (res: AxiosResponse) => {
        setCityLoading(false)
        const statesArray: any = map(
          res.data.data,
          (values: any) => values
        )?.map((option: any) => option.city)

        function removeDuplicates(arr: any) {
          return arr.filter(
            (item: any, index: any) => arr.indexOf(item) === index
          )
        }

        setCity(removeDuplicates(statesArray))
      },
      (err: AxiosError) => {
        setCityLoading(false)

        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'City Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.GET_STATE_DETAILS
      }?match=${JSON.stringify({
        status: 'true',
        brandId: brandVal || brandID,
      })}`,
      (res: AxiosResponse) => {
        setStateLoading(false)
        const statesArray: any = map(
          res.data.data,
          (values: any) => values
        )?.map((option: any) => option.state)

        function removeDuplicates(arr: any) {
          return arr.filter(
            (item: any, index: any) => arr.indexOf(item) === index
          )
        }

        setStateData(removeDuplicates(statesArray))
      },
      (err: AxiosError) => {
        setStateLoading(false)

        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'State Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandVal, stateValue])

  const brandDetails = brandData.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.name,
  }))
  const stateDetail = stateData?.map((item: any) => ({
    ...item,
    value: item,
    label: item,
  }))
  const cityDetail = city?.map((item: any) => ({
    ...item,
    value: item,
    label: item,
  }))
  const storeDetails = state.map((item: any) => ({
    ...item,
    value: item.storeId,
    label: item.storeName,
    description: item.locality,
    cityName: item.city,
  }))

  useEffect(() => {
    let rowDataSize

    if (totalRowSize >= pageSize) {
      rowDataSize = totalRowSize / pageSize

      if (rowDataSize - Math.floor(rowDataSize) !== 0) {
        setPageNo(Math.ceil(rowDataSize))
      } else {
        setPageNo(rowDataSize)
      }
    } else {
      rowDataSize = Math.ceil(totalRowSize / pageSize)
      setPageNo(rowDataSize)
    }
  }, [pageSize, totalRowSize, recordData])
  const colDef: CustomColum[] = useMemo(
    () => [
      {
        accessor: 'brand',
        textAlignment: 'center',
        Header: () => <div className={classes.tableHeaderStyle}>Brand</div>,
        minWidth: 11,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.brand.name}
          </p>
        ),
      },
      {
        accessor: 'storecode',

        textAlignment: 'center',
        Header: () => <div className={classes.tableHeaderStyle}>Store Id</div>,
        minWidth: 10,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.storecode}
          </p>
        ),
      },
      {
        accessor: 'storename',
        Header: 'Store Name',

        textAlignment: 'center',
        minWidth: 200,
        Cell: ({ ...value }: any) => (
          <p>
            {value.row.original.storename}
            <div
              style={{
                display: 'flex',
                fontSize: '13px',
                color: 'grey',
                borderSpacing: 0,
              }}
            >
              {value.row.original.location}, {value.row.original.state}.
            </div>
          </p>
        ),
      },
      {
        accessor: 'date',

        Header: () => <div className={classes.tableHeaderStyle}>date</div>,
        textAlignment: 'center',
        minWidth: 100,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.date.substring(0, 10)}
          </p>
        ),
      },

      {
        accessor: 'keyword',

        Header: () => <div className={classes.tableHeaderStyle}>keyword</div>,
        textAlignment: 'center',
        minWidth: 250,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>{value.row.original.keyword}</p>
        ),
      },

      {
        accessor: 'volume',

        Header: () => <div className={classes.tableHeaderStyle}>volume</div>,
        textAlignment: 'center',
        minWidth: 60,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>{value.row.original.volume}</p>
        ),
      },

      {
        accessor: 'category',

        Header: () => <div className={classes.tableHeaderStyle}>category</div>,
        textAlignment: 'center',
        minWidth: 100,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.category}
          </p>
        ),
      },
    ],
    [classes.tableBodyStyle, classes.tableHeaderStyle]
  )

  useEffect(() => {
    if (filterRef.current != null) {
      const element = paperRef.current?.getClientRects()

      setTableHeight(window.innerHeight - filterRef.current.clientHeight - 110)
    }
    if (totalRowSize > pageSize) {
      const from = (page - 1) * pageSize
      const to = from + pageSize
      setCountForm(from + 1)
      setCountTo(to)
    } else {
      setCountForm(1)
      setCountTo(totalRowSize)
    }
  }, [page, pageSize, recordData, totalRowSize])
  const startDate =
    fromDate === null ? null : moment(fromDate).format('MMM-YYYY')

  const endDate = fromDate === null ? null : moment(toDate).format('MMM-YYYY')

  return (
    <div>
      <Paper
        shadow="xs"
        p="md"
        style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
      >
        <Grid>
          <Grid.Col xs={12} ref={filterRef}>
            <Grid>
              <Grid.Col xs={12} sm={6} md={6} lg={7.5} xl={7.5}>
                <Title order={3} weight={100}>
                  Keywords
                </Title>
              </Grid.Col>
              {brandID?.[0] !== SATHYAID &&
              roleProvided !== 'user' &&
              roleProvided !== 'brand manager' &&
              roleProvided !== 'admin manager' &&
              roleProvided !== 'admin' ? (
                <>
                  <Grid.Col
                    xs={12}
                    sm={2}
                    md={2}
                    lg={1.5}
                    xl={1.5}
                    sx={{ zIndex: 1, display: 'flex', justifyContent: 'right' }}
                  >
                    <Popover
                      width={350}
                      // position="left-start"
                      withArrow
                      shadow="xl"
                      opened={openFilter}
                    >
                      <Popover.Target>
                        <Button
                          sx={{
                            width: '100%',
                            backgroundColor: 'rgb(160, 213, 239)',
                            '&[data-disabled]': { opacity: 0.4 },
                            '&:hover': {
                              backgroundColor: 'rgb(160, 213, 239)',
                              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                            },
                          }}
                          onClick={() => setOpenFilter((o) => !o)}
                          disabled={loading}
                        >
                          <IconFilter color="black" />
                          <span style={{ marginLeft: '10px', color: 'black' }}>
                            Filter
                          </span>
                        </Button>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <div
                          style={{
                            marginLeft: '170px',
                          }}
                        >
                          <Button
                            style={{
                              background: 'none',
                              marginLeft: '50px',
                            }}
                            onClick={() => {
                              setOpenFilter(false)
                            }}
                          >
                            <IconX
                              color="black"
                              size={20}
                              style={{ marginLeft: '50px' }}
                            />
                          </Button>
                        </div>
                        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          {' '}
                          {roleProvided === 'super admin' ||
                          (brandID !== null &&
                            roleProvided === 'CRM manager') ? (
                            <Select
                              label="Select Brand"
                              placeholder="Select Brand"
                              itemComponent={SelectItem}
                              data={brandDetails}
                              limit={brandDetails.length}
                              // disabled={brandDetails.length === 0}
                              maxDropdownHeight={200}
                              value={brandVal}
                              nothingFound="No Data Found"
                              clearable
                              onChange={(value: any) => {
                                setStateLoading(true)
                                setBrandVal(value)
                                setStoreVal(null)
                                setStateValue(null)
                                setCityValue(null)
                              }}
                              styles={() => ({
                                item: {
                                  // applies styles to selected item
                                  '&[data-selected]': {
                                    '&, &:hover': {
                                      backgroundColor:
                                        theme.colorScheme === 'dark'
                                          ? theme.colors.teal[9]
                                          : theme.colors.teal[1],
                                      color:
                                        theme.colorScheme === 'dark'
                                          ? theme.white
                                          : theme.colors.teal[9],
                                    },
                                  },

                                  // applies styles to hovered item (with mouse or keyboard)
                                  '&[data-hovered]': {},
                                },
                              })}
                            />
                          ) : null}
                        </Grid.Col>
                        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Text>Select Month</Text>
                          {startDate !== null && endDate !== null ? (
                            <Text
                              style={{ fontSize: '13px', marginTop: '5px' }}
                            >
                              AvailableDates - ({startDate} to {endDate})
                            </Text>
                          ) : (
                            ''
                          )}
                          <DatePicker
                            format="yyyy-MM"
                            ranges={[]}
                            style={{
                              width: '700px',
                            }}
                            value={
                              selectedDate !== null
                                ? dayjs(selectedDate).toDate()
                                : toDate
                            }
                            disabledDate={(date: any) => isAfter(date, toDate)}
                            disabled={fromDate === null}
                            onChange={(dates) => {
                              if (dates) {
                                setSelectedDate(moment(dates).format('YYYY-MM'))
                              }
                            }}
                          />
                        </Grid.Col>

                        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          {roleProvided === 'super admin' ||
                          (brandID !== null && roleProvided === 'admin') ||
                          (brandID !== null &&
                            roleProvided === 'CRM manager') ? (
                            <Select
                              label="Select Store"
                              placeholder="Select store"
                              clearable
                              searchable
                              disabled={
                                fromDate === null || storeDetails.length === 0
                              }
                              itemComponent={SelectItem}
                              data={storeDetails}
                              limit={storeDetails.length}
                              maxDropdownHeight={400}
                              nothingFound="No Data Found"
                              value={storeVal}
                              onChange={(value: any) => {
                                setStoreVal(value)
                                setStateValue(null)
                                setCityValue(null)
                              }}
                              styles={() => ({
                                item: {
                                  // applies styles to selected item
                                  '&[data-selected]': {
                                    '&, &:hover': {
                                      backgroundColor:
                                        theme.colorScheme === 'dark'
                                          ? theme.colors.teal[9]
                                          : theme.colors.teal[1],
                                      color:
                                        theme.colorScheme === 'dark'
                                          ? theme.white
                                          : theme.colors.teal[9],
                                    },
                                  },

                                  // applies styles to hovered item (with mouse or keyboard)
                                  '&[data-hovered]': {},
                                },
                              })}
                            />
                          ) : null}
                        </Grid.Col>
                        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Text fz="sm" style={{ fontWeight: 500 }}>
                            Select State{' '}
                            {stateLoading ? (
                              <span style={{ margin: 5 }}>
                                <Loader size={15} />
                              </span>
                            ) : (
                              ''
                            )}
                          </Text>
                          <Select
                            // label="Select State"
                            placeholder="Select state"
                            clearable
                            searchable
                            itemComponent={SelectItem}
                            data={stateDetail}
                            limit={stateDetail.length}
                            disabled={
                              (brandVal === null && brandID === null) ||
                              fromDate === null ||
                              stateDetail.length === 0
                            }
                            maxDropdownHeight={200}
                            nothingFound="No Data Found"
                            value={stateValue}
                            onChange={(value: any) => {
                              setCityLoading(true)
                              setStateValue(value)
                              setStoreVal(null)
                              setCityValue(null)
                            }}
                            styles={() => ({
                              item: {
                                // applies styles to selected item
                                '&[data-selected]': {
                                  '&, &:hover': {
                                    backgroundColor:
                                      theme.colorScheme === 'dark'
                                        ? theme.colors.teal[9]
                                        : theme.colors.teal[1],
                                    color:
                                      theme.colorScheme === 'dark'
                                        ? theme.white
                                        : theme.colors.teal[9],
                                  },
                                },

                                // applies styles to hovered item (with mouse or keyboard)
                                '&[data-hovered]': {},
                              },
                            })}
                          />
                        </Grid.Col>
                        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Text fz="sm" style={{ fontWeight: 500 }}>
                            Select City{' '}
                            {cityLoading ? (
                              <span style={{ margin: 5 }}>
                                <Loader size={15} />
                              </span>
                            ) : (
                              ''
                            )}
                          </Text>
                          <Select
                            // label="Select City"
                            placeholder="Select city"
                            clearable
                            searchable
                            itemComponent={SelectItem}
                            data={cityDetail}
                            limit={cityDetail.length}
                            disabled={
                              stateValue === null || cityDetail.length === 0
                            }
                            maxDropdownHeight={200}
                            nothingFound="No Data Found"
                            value={cityValue}
                            onChange={(value: any) => {
                              setCityValue(value)
                            }}
                            styles={() => ({
                              item: {
                                // applies styles to selected item
                                '&[data-selected]': {
                                  '&, &:hover': {
                                    backgroundColor:
                                      theme.colorScheme === 'dark'
                                        ? theme.colors.teal[9]
                                        : theme.colors.teal[1],
                                    color:
                                      theme.colorScheme === 'dark'
                                        ? theme.white
                                        : theme.colors.teal[9],
                                  },
                                },

                                // applies styles to hovered item (with mouse or keyboard)
                                '&[data-hovered]': {},
                              },
                            })}
                          />
                        </Grid.Col>
                        <Grid.Col
                          sx={{ display: 'flex', justifyContent: 'right' }}
                        >
                          <Button
                            sx={{
                              backgroundColor: 'rgb(160, 213, 239)',

                              color: '#000',
                              '&[data-disabled]': { opacity: 0.4 },
                              '&:hover': {
                                backgroundColor: 'rgb(160, 213, 239)',
                                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                              },
                            }}
                            onClick={() => {
                              setOpenFilter(false)
                              setPage(1)
                              setPageNo(1)
                              setSearch(true)
                              setRecordData([])
                              setTotalRowSize(0)
                            }}
                            disabled={fromDate === null}
                          >
                            <Text color="black">Apply</Text>
                          </Button>
                        </Grid.Col>
                      </Popover.Dropdown>
                    </Popover>
                  </Grid.Col>

                  <Grid.Col xs={12} sm={2} md={2} lg={1.5} xl={1.5}>
                    <Button
                      leftIcon={<IconUpload size={18} color="black" />}
                      sx={{
                        backgroundColor: 'rgb(160, 213, 239)',
                        width: '100%',
                        color: '#000',
                        '&[data-disabled]': { opacity: 0.4 },
                        '&:hover': {
                          backgroundColor: 'rgb(160, 213, 239)',
                          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        },
                      }}
                      onClick={() => {
                        setOpened(true)
                        setOpenFilter(false)
                      }}
                    >
                      Upload
                    </Button>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={2} md={2} lg={1.5} xl={1.5}>
                    <Button
                      leftIcon={<IconTrash size={18} color="black" />}
                      sx={{
                        backgroundColor: 'rgb(160, 213, 239)',
                        width: '100%',
                        color: '#000',
                        '&[data-disabled]': { opacity: 0.4 },
                        '&:hover': {
                          backgroundColor: 'rgb(160, 213, 239)',
                          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        },
                      }}
                      onClick={() => {
                        setOpenDelete(true)
                        setOpenFilter(false)
                      }}
                    >
                      Delete
                    </Button>
                  </Grid.Col>
                </>
              ) : (
                <>
                  <Grid.Col xs={5} sm={2} md={2} lg={1.5} xl={1.5} />
                  <Grid.Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={1.5}
                    xl={1.5}
                    sx={{ zIndex: 1 }}
                  >
                    <Popover
                      width={340}
                      // position="left-start"
                      withArrow
                      shadow="xl"
                      opened={openFilter}
                    >
                      <Popover.Target>
                        <Button
                          sx={{
                            backgroundColor: 'rgb(160, 213, 239)',
                            width: '100%',
                            color: '#000',
                            '&[data-disabled]': { opacity: 0.4 },
                            '&:hover': {
                              backgroundColor: 'rgb(160, 213, 239)',
                              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                            },
                          }}
                          onClick={() => setOpenFilter((o) => !o)}
                        >
                          <IconFilter color="black" />
                          <span style={{ marginLeft: '10px', color: 'black' }}>
                            Filter
                          </span>
                        </Button>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <div
                          style={{
                            marginLeft: '170px',
                          }}
                        >
                          <Button
                            style={{
                              background: 'none',
                              marginLeft: '50px',
                            }}
                            onClick={() => {
                              setOpenFilter(false)
                            }}
                          >
                            <IconX
                              color="black"
                              size={20}
                              style={{ marginLeft: '50px' }}
                            />
                          </Button>
                        </div>
                        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Text>Select Month</Text>
                          {startDate !== null && endDate !== null ? (
                            <Text
                              style={{ fontSize: '13px', marginTop: '5px' }}
                            >
                              AvailableDates - ({startDate} to {endDate})
                            </Text>
                          ) : (
                            ''
                          )}
                          <DatePicker
                            format="yyyy-MM"
                            ranges={[]}
                            style={{
                              width: '700px',
                            }}
                            value={
                              selectedDate !== null
                                ? dayjs(selectedDate).toDate()
                                : toDate
                            }
                            disabledDate={(date: any) => isAfter(date, toDate)}
                            disabled={fromDate === null}
                            onChange={(dates) => {
                              if (dates) {
                                setSelectedDate(moment(dates).format('YYYY-MM'))
                              }
                            }}
                          />
                        </Grid.Col>
                        {roleProvided === 'super admin' ||
                        (brandID !== null && roleProvided === 'CRM manager') ? (
                          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Select
                              label="Select Brand"
                              placeholder="Select Brand"
                              itemComponent={SelectItem}
                              data={brandDetails}
                              // disabled={brandDetails.length === 0}
                              maxDropdownHeight={400}
                              value={brandVal}
                              nothingFound="No Data Found"
                              clearable
                              onChange={(value: any) => {
                                setStateLoading(true)
                                setBrandVal(value)
                                setStoreVal(null)
                                setStateValue(null)
                                setCityValue(null)
                              }}
                              styles={() => ({
                                item: {
                                  // applies styles to selected item
                                  '&[data-selected]': {
                                    '&, &:hover': {
                                      backgroundColor:
                                        theme.colorScheme === 'dark'
                                          ? theme.colors.teal[9]
                                          : theme.colors.teal[1],
                                      color:
                                        theme.colorScheme === 'dark'
                                          ? theme.white
                                          : theme.colors.teal[9],
                                    },
                                  },

                                  // applies styles to hovered item (with mouse or keyboard)
                                  '&[data-hovered]': {},
                                },
                              })}
                            />
                          </Grid.Col>
                        ) : null}
                        {roleProvided === 'super admin' ||
                        (brandID !== null && roleProvided === 'admin') ||
                        (brandID !== null && roleProvided === 'CRM manager') ||
                        (brandID !== null &&
                          roleProvided === 'brand manager') ||
                        (brandID !== null &&
                          roleProvided === 'admin manager') ? (
                          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Select
                              label="Select Store"
                              placeholder="Select store"
                              clearable
                              searchable
                              itemComponent={SelectItem}
                              data={storeDetails}
                              disabled={storeDetails.length === 0}
                              maxDropdownHeight={200}
                              nothingFound="No Data Found"
                              value={storeVal}
                              onChange={(value: any) => {
                                setStoreVal(value)
                                setStateValue(null)
                                setCityValue(null)
                              }}
                              styles={() => ({
                                item: {
                                  // applies styles to selected item
                                  '&[data-selected]': {
                                    '&, &:hover': {
                                      backgroundColor:
                                        theme.colorScheme === 'dark'
                                          ? theme.colors.teal[9]
                                          : theme.colors.teal[1],
                                      color:
                                        theme.colorScheme === 'dark'
                                          ? theme.white
                                          : theme.colors.teal[9],
                                    },
                                  },

                                  // applies styles to hovered item (with mouse or keyboard)
                                  '&[data-hovered]': {},
                                },
                              })}
                            />
                          </Grid.Col>
                        ) : null}
                        {roleProvided === 'super admin' ||
                        (brandID !== null && roleProvided === 'admin') ||
                        (brandID !== null && roleProvided === 'CRM manager') ||
                        (brandID !== null &&
                          roleProvided === 'brand manager') ? (
                          <>
                            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Text fz="sm" style={{ fontWeight: 500 }}>
                                Select State{' '}
                                {stateLoading ? (
                                  <span style={{ margin: 5 }}>
                                    <Loader size={15} />
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Text>
                              <Select
                                // label="Select State"
                                placeholder="Select state"
                                clearable
                                searchable
                                itemComponent={SelectItem}
                                data={stateDetail}
                                disabled={
                                  (brandVal === null && brandID === null) ||
                                  stateDetail.length === 0
                                }
                                maxDropdownHeight={400}
                                nothingFound="No Data Found"
                                value={stateValue}
                                onChange={(value: any) => {
                                  setCityLoading(true)
                                  setStateValue(value)
                                  setStoreVal(null)
                                  setCityValue(null)
                                }}
                                styles={() => ({
                                  item: {
                                    // applies styles to selected item
                                    '&[data-selected]': {
                                      '&, &:hover': {
                                        backgroundColor:
                                          theme.colorScheme === 'dark'
                                            ? theme.colors.teal[9]
                                            : theme.colors.teal[1],
                                        color:
                                          theme.colorScheme === 'dark'
                                            ? theme.white
                                            : theme.colors.teal[9],
                                      },
                                    },

                                    // applies styles to hovered item (with mouse or keyboard)
                                    '&[data-hovered]': {},
                                  },
                                })}
                              />
                            </Grid.Col>
                            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Text fz="sm" style={{ fontWeight: 500 }}>
                                Select City{' '}
                                {cityLoading ? (
                                  <span style={{ margin: 5 }}>
                                    <Loader size={15} />
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Text>
                              <Select
                                // label="Select City"
                                placeholder="Select city"
                                clearable
                                searchable
                                itemComponent={SelectItem}
                                data={cityDetail}
                                disabled={
                                  stateValue === null || cityDetail.length === 0
                                }
                                maxDropdownHeight={400}
                                nothingFound="No Data Found"
                                value={cityValue}
                                onChange={(value: any) => {
                                  setCityValue(value)
                                }}
                                styles={() => ({
                                  item: {
                                    // applies styles to selected item
                                    '&[data-selected]': {
                                      '&, &:hover': {
                                        backgroundColor:
                                          theme.colorScheme === 'dark'
                                            ? theme.colors.teal[9]
                                            : theme.colors.teal[1],
                                        color:
                                          theme.colorScheme === 'dark'
                                            ? theme.white
                                            : theme.colors.teal[9],
                                      },
                                    },

                                    // applies styles to hovered item (with mouse or keyboard)
                                    '&[data-hovered]': {},
                                  },
                                })}
                              />
                            </Grid.Col>{' '}
                          </>
                        ) : null}
                        <Grid.Col
                          sx={{ display: 'flex', justifyContent: 'right' }}
                        >
                          <Button
                            sx={{
                              backgroundColor: 'rgb(160, 213, 239)',
                              color: '#000',
                              '&[data-disabled]': { opacity: 0.4 },
                              '&:hover': {
                                backgroundColor: 'rgb(160, 213, 239)',
                                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                              },
                            }}
                            onClick={() => {
                              setOpenFilter(false)
                              setPage(1)
                              setPageNo(1)
                              setSearch(true)
                              setRecordData([])
                              setTotalRowSize(0)
                            }}
                            disabled={fromDate === null}
                          >
                            <Text color="black">Apply</Text>
                          </Button>
                        </Grid.Col>
                      </Popover.Dropdown>
                    </Popover>
                  </Grid.Col>
                </>
              )}
            </Grid>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col xs={12} style={{ width: 1060, paddingTop: 20 }}>
            <CustomTable
              pageFrom={countFrom}
              pageTo={countTo}
              total={pageNo}
              gridApi={(instance) => {}}
              totalRecords={!loading ? totalRowSize : 1}
              checkboxSelection={false}
              recordsPerPage={pageSize}
              page={page}
              height={tableHeight}
              onPageChange={(p: any) => setPage(p)}
              recordsPerPageOptions={PAGESIZES}
              onRecordsPerPageChange={setPageSize}
              loading={!!loading}
              rowData={!loading ? recordData : []}
              colDef={colDef}
            />
          </Grid.Col>
        </Grid>
        <div style={{ zIndex: 8 }}>
          <Modal
            opened={opened}
            onClose={() => {
              setOpened(false)
              setSelectedDate(null)
            }}
            sx={{ marginTop: '70px', zIndex: 7 }}
            overlayOpacity={0.55}
          >
            <KeywordsUpload
              onClose={() => {
                setOpened(false)
                setSelectedDate(null)
              }}
              fetchData={() => fetchData()}
            />
          </Modal>
        </div>
        <div>
          <Modal
            opened={openDelete}
            onClose={() => {
              setOpenDelete(false)
              setSelectedDate(null)
            }}
            sx={{ marginTop: '70px', zIndex: 5 }}
            overlayOpacity={0.55}
          >
            <KeywordsDelete
              onClose={() => {
                setOpenDelete(false)

                setDeleteBrand(null)
                setSelectedDate(null)
              }}
              fetchData={() => fetchData()}
            />
          </Modal>
        </div>
      </Paper>
    </div>
  )
}
