/* eslint-disable react/no-array-index-key */
import { forwardRef, useEffect, useState } from 'react'
import {
  Grid,
  TextInput,
  Button,
  FileInput,
  Autocomplete,
  Checkbox,
  Loader,
  SelectItemProps,
  Group,
  Text,
  Modal,
  ActionIcon,
  createStyles,
  Accordion,
  Textarea,
  MantineColor,
} from '@mantine/core'
import { IconTrash, IconUpload, IconX } from '@tabler/icons'
import { filter, get, map } from 'lodash'
import { AxiosError, AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import Compressor from 'compressorjs'
import * as Yup from 'yup'
import { useSearchParams, useNavigate } from 'react-router-dom'
import {
  AlertColor,
  AlertIcons,
  API_ENDPOINTS,
  BRANDID,
} from '../../../constants'
import { API_SEVICES } from '../../../config/services'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { openSnackbar } from '../../../redux/snackbar'
import AddField from '../../../components/AddField'
import SpinningLoader from '../../../components/SpinningLoader'

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
    fontStyle: 'Helvetica',
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },
  formikError: {
    color: '#CC0000',
    fontSize: '13px',
  },
  outline: {
    border: 'none',
    outline: 'none !important',
  },
  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
}))
interface IProps {
  fetchData: () => void
  onClose: () => void
  onOpen: () => void
  mode: any
  isEditMode: any
  isViewMode: any
}

interface ItemProps extends SelectItemProps {
  _id: any
  name: string
}
interface Ifields {
  key: string
  value: string
}
interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}

function StoreModelForm(props: IProps) {
  const [selectedImage, setSelectedImage] = useState<any | null>(null)
  const [inputFields, setInputFields] = useState<Ifields[] | any[]>([])
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { classes, theme } = useStyles()
  const [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [brandData, setBrandData] = useState([])
  const [type, setType] = useState([])
  const [typeValue, setTypeValue] = useState('')
  const [category, setCategory] = useState([])
  const [categoryValue, setCategoryValue] = useState('')
  const [subCategory, setSubCategory] = useState([])
  const [city, setCity] = useState([])
  const [state, setState] = useState([])
  const [stateValue, setStateValue] = useState('')
  const [open, setOpen] = useState(false)
  const [brandValueId, setBrandValueId] = useState('')
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [images, setImages] = useState<any | null>(null)
  const [storeGalleryImage, setStoreGalleryImage] = useState<any | null>(null)
  const { onClose, mode, isEditMode, isViewMode, fetchData, onOpen } = props

  // eslint-disable-next-line no-shadow
  const role = useAppSelector((state) => state.role)

  const brandID = role.brandId || BRANDID || null

  useEffect(() => {
    let match
    if (brandID.length === 0) {
      match = JSON.stringify({
        status: 'true',
      })
    } else {
      match = JSON.stringify({
        status: 'true',
        _id: brandID,
      })
    }

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${match}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Brand Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.GET_TYPE
      }?match=${JSON.stringify({
        status: 'true',
      })}`,
      (res: AxiosResponse) => {
        setType(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Type Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.CATEGORY_DETAILS
      }?match=${JSON.stringify({
        parentCategory: typeValue,
        isSubCategory: 'false',
        status: 'true',
      })}`,
      (res: AxiosResponse) => {
        setCategory(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Category Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.CATEGORY_DETAILS
      }?match=${JSON.stringify({
        parentCategory: categoryValue,
        isSubCategory: 'true',
        status: 'true',
      })}`,
      (res: AxiosResponse) => {
        setSubCategory(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'SubCategory Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.CITY_DETAILS
      }?match=${JSON.stringify({
        state: stateValue,
        status: 'true',
      })}`,
      (res: AxiosResponse) => {
        setCity(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'City Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.STATE_DETAILS
      }?match=${JSON.stringify({
        status: 'true',
      })}`,
      (res: AxiosResponse) => {
        setState(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'State Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )
  }, [dispatch, typeValue, stateValue, categoryValue, brandID, navigate])
  const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ description, value, image, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text>{value}</Text>
          </div>
        </Group>
      </div>
    )
  )
  AutoCompleteItem.displayName = 'AutoCompleteItem'
  const brand = brandData.map((item: any) => ({
    ...item,
    value: item.name,
    label: item._id,
  }))
  const types = type.map((item: any) => ({
    ...item,
    value: item.label,
  }))
  const categoryDetail = category.map((item: any) => ({
    ...item,
    value: item.label,
  }))
  const subCategoryDetail = subCategory.map((item: any) => ({
    ...item,
    value: item.label,
  }))
  const stateDetail = state.map((item: any) => ({
    ...item,
    value: item.label,
  }))

  const cityDetail = city.map((item: any) => ({
    ...item,
    value: item.labe,
  }))

  const fileUpload = (event: any) => {
    if (event) {
      const file = [...event]
      const arr: any = []

      for (let i = 0; i < event.length; i++) {
        if (
          event[i].type === 'image/png' ||
          event[i].type === 'image/jpeg' ||
          event[i].type === 'image/webp' ||
          event[i].type === 'image/jpg'
        ) {
          const files: any = file[i]
          const image = new Compressor(files, {
            quality: 0.8,
            success: (compressedResult) => compressedResult,
          })

          const image1 = Object.values(image)
          arr.push(image1[0])

          setSelectedFile(arr)
        } else {
          setSelectedFile(null)
          dispatch(
            openSnackbar({
              messege: 'Image File Format is not supported',
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
      }
    }
  }

  // eslint-disable-next-line react/display-name
  const handleRemoveGal = (e: any) => {
    const s = selectedFile.filter((item: any, index: any) => index !== e)
    setSelectedFile(s)
  }
  function handleSubmit(event: any) {
    event.preventDefault()
    const formData = new FormData()
    if (selectedFile === null) {
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append('storeGallery', selectedFile[i])
      }
    } else if (selectedFile !== null) {
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append('storeGallery', selectedFile[i])
      }
    }

    setLoading1(true)
    API_SEVICES.PostRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.STORE_GALLERY}?id=${id}`,
      (res: AxiosResponse) => {
        setLoading1(false)

        dispatch(
          openSnackbar({
            messege: 'Store image upload Sucessfully',
            icon: AlertIcons.success,
            title: 'Success',
            color: AlertColor.success,
          })
        )
        setOpen(false)
        onClose()
      },
      (err: AxiosError) => {
        setLoading1(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Store image cant be uploaded'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      },
      formData
    )
  }
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const longitudeRegex = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/
  /* eslint-disable no-useless-escape */
  const urlRegex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
  const formik = useFormik({
    initialValues: {
      storeId: '',
      storeName: '',
      title: '',
      ownerName: '',
      phoneNumber: '',
      notificationNumber: '',
      email: '',
      longitude: '',
      latitude: '',
      locationUrl: '',
      linkedInUrl: '',
      twitterUrl: '',
      instagramUrl: '',
      youtubeUrl: '',
      locality: '',
      addressLine1: '',
      addressLine2: '',
      noOfYearsInBussiness: '',
      noOfMarriage: '',
      pinCode: '',
      city: '',
      state: '',
      offer: {
        title: '',
        description: '',
      },
      videos: [],
      status: true,
      brandId: '',
      type: '',
      category: '',
      subCategory: '',
      termsAndContions: '',
      metaTitle: '',
      metaDescription: '',
      metaKeywords: '',
      longdescription: '',
      shortDescription: '',
      featured: false,
      metaData: {},
      storeImage: [],
    },
    onSubmit: (values) => {
      const formData = new FormData()
      formData.append('storeId', values.storeId)
      formData.append('storeName', values.storeName)
      formData.append('title', values.title)
      formData.append('ownerName', values.ownerName)
      formData.append('phoneNumber', values.phoneNumber)
      formData.append('notificationNumber', values.notificationNumber)
      formData.append('email', values.email)
      formData.append('longitude', values.longitude)
      formData.append('latitude', values.latitude)
      formData.append('locationUrl', values.locationUrl)
      formData.append('linkedInUrl', values.linkedInUrl)
      formData.append('twitterUrl', values.twitterUrl)
      formData.append('instagramUrl', values.instagramUrl)
      formData.append('youtubeUrl', values.youtubeUrl)
      formData.append('locality', values.locality)
      formData.append('addressLine1', values.addressLine1)
      formData.append('addressLine2', values.addressLine2)
      formData.append('noOfYearsInBussiness', values.noOfYearsInBussiness)
      formData.append('noOfMarriage', values.noOfMarriage)
      formData.append('pinCode', values.pinCode)
      formData.append('city', values.city)
      formData.append('state', values.state)
      formData.append('videos.0', values.videos[0])
      formData.append('status', JSON.stringify(values.status))
      formData.append('offer', JSON.stringify(values.offer))
      formData.append('brandId', values.brandId)
      formData.append('type', values.type)
      formData.append('category', values.category)
      formData.append('subCategory', values.subCategory)
      formData.append('termsAndContions', values.termsAndContions)
      formData.append('metaTitle', values.metaTitle)
      formData.append('metaDescription', values.metaDescription)
      formData.append('metaKeywords', values.metaKeywords)
      formData.append('longdescription', values.longdescription)
      formData.append('shortDescription', values.shortDescription)
      if (selectedImage !== null) {
        formData.append('storeImage', selectedImage)
      }

      if (inputFields.length > 0) {
        const obj: any = {}
        for (let i = 0; i < [...inputFields].length; i++) {
          obj[inputFields[i].key] = inputFields[i].value
        }
        formData.append('metaData', JSON.stringify(obj))
      }

      setLoading1(true)
      !isEditMode || isViewMode
        ? API_SEVICES.PostRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.POST_STORE}`,
            (res: AxiosResponse) => {
              setLoading1(false)
              dispatch(
                openSnackbar({
                  messege: 'Store Added Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              if (
                get(err, 'response.status', '') === 401 ||
                get(err, 'response.status', '') === 403
              ) {
                localStorage.clear()
                navigate('/login', { replace: true })
              }
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'Store cant be Added'
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              console.error(err)
            },
            formData
          )
        : API_SEVICES.PatchRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.EDIT_STORE}?id=${id}`,
            (res: AxiosResponse) => {
              setLoading1(false)
              dispatch(
                openSnackbar({
                  messege: 'Store Updated Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              if (
                get(err, 'response.status', '') === 401 ||
                get(err, 'response.status', '') === 403
              ) {
                localStorage.clear()
                navigate('/login', { replace: true })
              }
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'Store cant be Updated'
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              console.error(err)
            },
            formData
          )
    },
    validationSchema: Yup.object({
      storeId: Yup.string().required('Store Id is Required'),
      storeName: Yup.string()
        .required('Store Name is Required')
        .min(2)
        .max(255),
      brandId: Yup.string().required('Brand Id is Required'),
      title: Yup.string().required('Title is Required').min(2).max(255),
      phoneNumber: Yup.string()
        .min(10)
        .max(13)
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone Number is Required'),
      locality: Yup.string().required('Locality is Required'),
      notificationNumber: Yup.string()
        .min(10)
        .max(13)
        .matches(phoneRegExp, 'Notification number is not valid')
        .required('Notification Number is Required'),
      email: Yup.string()
        .email('Enter a valid email')
        .required('Email is required'),
      longitude: Yup.string().matches(
        longitudeRegex,
        'Longitude number is not valid'
      ),
      latitude: Yup.string().matches(
        longitudeRegex,
        'Latitude number is not valid'
      ),
      type: Yup.string().required('Type is Required').nullable(true),
      category: Yup.string().required('Category is Required').nullable(true),
      city: Yup.string().required('City is Required').nullable(true),
      state: Yup.string().required('State is Required').nullable(true),

      instagramUrl: Yup.string().matches(
        urlRegex,
        'Enter correct Instagram url!'
      ),
      linkedinUrl: Yup.string().matches(
        urlRegex,
        'Enter correct Linked in url!'
      ),
      twitterUrl: Yup.string().matches(
        urlRegex,
        'Enter correct TwitterUrl url!'
      ),
      youtubeUrl: Yup.string().matches(urlRegex, 'Enter correct Youtube url!'),
    }),
  })

  const handleDeleteField = (idx: any) => {
    setInputFields(filter(inputFields, (o) => o.key !== inputFields[idx].key))
  }

  useEffect(() => {
    if (id) {
      setLoading(true)
      const match = {
        _id: id,
      }
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_ENDPOINTS.GET_STORE
        }?match=${JSON.stringify(match)}`,
        (res: AxiosResponse) => {
          setLoading(false)
          setBrandValueId(get(res, 'data.data[0].brandId.name'))
          formik.setFieldValue(
            'category',
            get(res, 'data.data[0].category', '')
          )
          formik.setFieldValue('city', get(res, 'data.data[0].city', ''))
          formik.setFieldValue('state', get(res, 'data.data[0].state', ''))
          formik.setFieldValue(
            'addressLine1',
            get(res, 'data.data[0].addressLine1', '')
          )
          formik.setFieldValue(
            'addressLine2',
            get(res, 'data.data[0].addressLine2', '')
          )
          formik.setFieldValue(
            'longdescription',
            get(res, 'data.data[0].longdescription', '')
          )
          formik.setFieldValue(
            'featured',
            get(res, 'data.data[0].featured', false)
          )
          formik.setFieldValue(
            'instagramUrl',
            get(res, 'data.data[0].instagramUrl', '')
          )
          formik.setFieldValue(
            'notificationNumber',
            get(res, 'data.data[0].notificationNumber', '')
          )
          formik.setFieldValue(
            'latitude',
            get(res, 'data.data[0].latitude', '')
          )
          formik.setFieldValue(
            'linkedInUrl',
            get(res, 'data.data[0].linkedInUrl', '')
          )
          formik.setFieldValue('email', get(res, 'data.data[0].email', ''))
          formik.setFieldValue(
            'locationUrl',
            get(res, 'data.data[0].locationUrl', '')
          )
          formik.setFieldValue(
            'longitude',
            get(res, 'data.data[0].longitude', '')
          )
          formik.setFieldValue(
            'metaDescription',
            get(res, 'data.data[0].metaDescription', '')
          )
          formik.setFieldValue(
            'metaKeywords',
            get(res, 'data.data[0].metaKeywords', '')
          )
          formik.setFieldValue(
            'metaTitle',
            get(res, 'data.data[0].metaTitle', '')
          )
          formik.setFieldValue(
            'noOfMarriage',
            get(res, 'data.data[0].noOfMarriage', '')
          )
          formik.setFieldValue(
            'locality',
            get(res, 'data.data[0].locality', '')
          )
          formik.setFieldValue(
            'noOfYearsInBussiness',
            get(res, 'data.data[0].noOfYearsInBussiness', '')
          )
          formik.setFieldValue(
            'ownerName',
            get(res, 'data.data[0].ownerName', '')
          )
          formik.setFieldValue(
            'phoneNumber',
            get(res, 'data.data[0].phoneNumber', '')
          )
          formik.setFieldValue('pinCode', get(res, 'data.data[0].pinCode', ''))
          formik.setFieldValue(
            'shortDescription',
            get(res, 'data.data[0].shortDescription', '')
          )
          formik.setFieldValue('status', get(res, 'data.data[0].status', false))
          formik.setFieldValue('storeId', get(res, 'data.data[0].storeId', ''))
          formik.setFieldValue(
            'storeImage',
            get(res, 'data.data[0].storeImage[0].file', '')
          )
          formik.setFieldValue(
            'storeName',
            get(res, 'data.data[0].storeName', '')
          )
          formik.setFieldValue(
            'subCategory',
            get(res, 'data.data[0].subCategory', '')
          )
          formik.setFieldValue(
            'termsAndContions',
            get(res, 'data.data[0].termsAndContions', '')
          )
          formik.setFieldValue('title', get(res, 'data.data[0].title', ''))
          formik.setFieldValue(
            'twitterUrl',
            get(res, 'data.data[0].twitterUrl', '')
          )
          formik.setFieldValue(
            'brandId',
            get(res, 'data.data[0].brandId._id', '')
          )
          formik.setFieldValue('type', get(res, 'data.data[0].type', ''))

          if (res.data.data[0].videos[0] !== 'undefined') {
            formik.setFieldValue('videos', get(res, 'data.data[0].videos', ''))
          }
          formik.setFieldValue(
            'youtubeUrl',
            get(res, 'data.data[0].youtubeUrl', '')
          )

          setImages(res.data.data[0].storeImage)

          setStoreGalleryImage(res.data.data[0].storeGallery)

          if (res.data.data[0].metaData.length === 0) {
            setInputFields([])
          } else {
            const metaData = JSON.parse(res.data.data[0].metaData)
            const metakeys = Object.keys(metaData)
            const metaArray = map(metakeys, (key) => ({
              key,
              value: metaData[key],
            }))
            setInputFields(metaArray)
          }
          formik.setFieldValue(
            'offer',
            JSON.parse(get(res, 'data.data[0].offer', ''))
          )
        },
        (err: AxiosError) => {
          setLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const removeImage = (idx: any) => {
    const deleteImage = JSON.stringify({ imageId: idx })
    setLoading(true)
    API_SEVICES.DeleteRequestObj(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.STORE_DELETE_GALLERY}?id=${id}`,
      (res: AxiosResponse) => {
        setLoading(false)
        dispatch(
          openSnackbar({
            messege: 'Product image Deleted Sucessfully',
            icon: AlertIcons.success,
            title: 'Success',
            color: AlertColor.success,
          })
        )
        setOpen(false)
        onClose()
      },
      (err: AxiosError) => {
        setLoading1(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Product image cant be  Deleted'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      },
      deleteImage
    )
  }
  const removeImageAdd = () => {
    setSelectedImage(null)
    setImages(null)
    formik.setFieldValue('storeImage', [])
  }
  const imageChange = async (e: any) => {
    if (
      e.type === 'image/png' ||
      e.type === 'image/jpeg' ||
      e.type === 'image/webp' ||
      e.type === 'image/jpg'
    ) {
      const file = e

      const image = new Compressor(file, {
        quality: 0.7,
        success: (compressedResult: any) => compressedResult,
      })
      const image1 = Object.values(image)
      setSelectedImage(image1[0])
      formik.setFieldValue('storeImage', image1[0])
    } else {
      setSelectedImage(null)
      dispatch(
        openSnackbar({
          messege: 'Image File Format is not supported',
          icon: AlertIcons.error,
          title: 'error',
          color: AlertColor.error,
        })
      )
    }
  }

  return (
    <div
      className={classes.outline}
      role="button"
      tabIndex={0}
      onKeyDown={(e: any) => {
        if (e.key === 'Enter') {
          formik.handleSubmit()
        }
      }}
    >
      <Accordion defaultValue="storeDetails" sx={{ minHeight: '200px' }}>
        {loading ? (
          <SpinningLoader />
        ) : (
          <>
            {isEditMode ? (
              <Text fz="sm" style={{ fontSize: '15px' }}>
                Store Name : {formik.values.storeName}
              </Text>
            ) : null}
            <Accordion.Item value="storeDetails">
              <Accordion.Control>StoreDetails</Accordion.Control>
              <Accordion.Panel>
                <Grid style={{ marginTop: '10px' }}>
                  <Grid.Col xs={12}>
                    <Autocomplete
                      label="Type"
                      placeholder="e.g Select Type"
                      data={types}
                      withAsterisk
                      limit={types.length}
                      maxDropdownHeight={200}
                      itemComponent={AutoCompleteItem}
                      defaultValue={formik.values.type}
                      onBlur={formik.handleBlur}
                      onItemSubmit={(item: any) => {
                        setTypeValue(item.label)
                        formik.setFieldValue('type', item.label)
                      }}
                    />

                    {formik.errors.type && formik.touched.type && (
                      <div className={classes.formikError}>
                        {formik.errors.type}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Autocomplete
                      label="Category"
                      placeholder="e.g Select Category"
                      data={categoryDetail}
                      withAsterisk
                      limit={categoryDetail.length}
                      maxDropdownHeight={200}
                      itemComponent={AutoCompleteItem}
                      defaultValue={formik.values.category}
                      onBlur={formik.handleBlur}
                      onItemSubmit={(item: any) => {
                        setCategoryValue(item.label)
                        formik.setFieldValue('category', item.label)
                      }}
                    />
                    {formik.errors.category && formik.touched.category && (
                      <div className={classes.formikError}>
                        {formik.errors.category}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Autocomplete
                      label="SubCategory"
                      placeholder="e.g Select SubCategory"
                      data={subCategoryDetail}
                      limit={subCategoryDetail.length}
                      maxDropdownHeight={200}
                      itemComponent={AutoCompleteItem}
                      defaultValue={formik.values.subCategory}
                      onBlur={formik.handleBlur}
                      onItemSubmit={(item: any) => {
                        formik.setFieldValue('subCategory', item.label)
                      }}
                    />
                    {formik.errors.subCategory &&
                      formik.touched.subCategory && (
                        <div className={classes.formikError}>
                          {formik.errors.subCategory}
                        </div>
                      )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Autocomplete
                      label="Select Brand"
                      placeholder="e.g Select Brand"
                      data={brand}
                      limit={brand.length}
                      maxDropdownHeight={200}
                      withAsterisk
                      defaultValue={brandValueId}
                      itemComponent={AutoCompleteItem}
                      onItemSubmit={(item: any) => {
                        formik.setFieldValue('brandId', item._id)
                      }}
                    />
                    {formik.errors.brandId && formik.touched.brandId && (
                      <div className={classes.formikError}>
                        {formik.errors.brandId}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g STORE001"
                      name="storeId"
                      label="Store Id"
                      withAsterisk
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.storeId}
                    />
                    {formik.errors.storeId && formik.touched.storeId && (
                      <div className={classes.formikError}>
                        {formik.errors.storeId}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g TVS Electronics Ltd-Space Tech Enterprises"
                      name="storeName"
                      label="Store Name"
                      withAsterisk
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.storeName}
                    />
                    {formik.errors.storeName && formik.touched.storeName && (
                      <div className={classes.formikError}>
                        {formik.errors.storeName}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g TVS Electronics Ltd-Space Tech Enterprises"
                      name="title"
                      label="Title"
                      withAsterisk
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.title}
                    />
                    {formik.errors.title && formik.touched.title && (
                      <div className={classes.formikError}>
                        {formik.errors.title}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g ownerName"
                      name="ownerName"
                      label="Owner Name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.ownerName}
                    />
                    {formik.errors.ownerName && formik.touched.ownerName && (
                      <div className={classes.formikError}>
                        {formik.errors.ownerName}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g 7863786739"
                      name="phoneNumber"
                      label="Phone Number (Call landing number)"
                      withAsterisk
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.phoneNumber}
                    />
                    {formik.errors.phoneNumber &&
                      formik.touched.phoneNumber && (
                        <div className={classes.formikError}>
                          {formik.errors.phoneNumber}
                        </div>
                      )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g 9876567890"
                      name="notificationNumber"
                      label="Notification Number (Alerts messages are notified via this number)"
                      withAsterisk
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.notificationNumber}
                    />
                    {formik.errors.notificationNumber &&
                      formik.touched.notificationNumber && (
                        <div className={classes.formikError}>
                          {formik.errors.notificationNumber}
                        </div>
                      )}
                  </Grid.Col>

                  <Grid.Col xs={12}>
                    <TextInput
                      label="Email"
                      placeholder="e.g you@mantine.dev"
                      id="email"
                      name="email"
                      withAsterisk
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <div className={classes.formikError}>
                        {formik.errors.email}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g 76.958885"
                      name="longitude"
                      label="Longitude"
                      withAsterisk
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.longitude}
                    />
                    {formik.errors.longitude && formik.touched.longitude && (
                      <div className={classes.formikError}>
                        {formik.errors.longitude}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g 11.017363"
                      name="latitude"
                      label="Latitude"
                      withAsterisk
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.latitude}
                    />
                    {formik.errors.latitude && formik.touched.latitude && (
                      <div className={classes.formikError}>
                        {formik.errors.latitude}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g 66, Vysial St, Prakasam, "
                      name="addressLine1"
                      label="AddressLine1"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.addressLine1}
                    />
                    {formik.errors.addressLine1 &&
                      formik.touched.addressLine1 && (
                        <div className={classes.formikError}>
                          {formik.errors.addressLine1}
                        </div>
                      )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g Town Hall"
                      name="addressLine2"
                      label="Address Line2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.addressLine2}
                    />
                    {formik.errors.addressLine2 &&
                      formik.touched.addressLine2 && (
                        <div className={classes.formikError}>
                          {formik.errors.addressLine2}
                        </div>
                      )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g Coimbatore"
                      name="locality"
                      label="Locality"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.locality}
                      withAsterisk
                    />
                    {formik.errors.locality && formik.touched.locality && (
                      <div className={classes.formikError}>
                        {formik.errors.locality}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Autocomplete
                      label="State"
                      placeholder="e.g Select State"
                      data={stateDetail}
                      limit={stateDetail.length}
                      maxDropdownHeight={200}
                      withAsterisk
                      filter={(value, item) => item.label}
                      value={formik.values.state}
                      onBlur={formik.handleBlur}
                      onItemSubmit={(item: any) => {
                        setStateValue(item.label)
                        formik.setFieldValue('state', item.label)
                      }}
                    />
                    {formik.errors.state && formik.touched.state && (
                      <div className={classes.formikError}>
                        {formik.errors.state}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Autocomplete
                      label="City"
                      placeholder="Select City"
                      data={cityDetail}
                      limit={cityDetail.length}
                      maxDropdownHeight={200}
                      withAsterisk
                      filter={(value, item) => item.labe}
                      value={formik.values.city}
                      onBlur={formik.handleBlur}
                      onItemSubmit={(item: any) => {
                        formik.setFieldValue('city', item.labe)
                      }}
                    />
                    {formik.errors.city && formik.touched.city && (
                      <div className={classes.formikError}>
                        {formik.errors.city}
                      </div>
                    )}
                  </Grid.Col>

                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g 641006"
                      name="pinCode"
                      label="PinCode"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.pinCode}
                    />
                    {formik.errors.pinCode && formik.touched.pinCode && (
                      <div className={classes.formikError}>
                        {formik.errors.pinCode}
                      </div>
                    )}
                  </Grid.Col>

                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="Title"
                      name="offer.title"
                      label="Offer Title"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.offer.title}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="Description"
                      name="offer.description"
                      label="Offer Description"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.offer.description}
                    />
                  </Grid.Col>

                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g 12"
                      name="noOfYearsInBussiness"
                      label="Number Of Years In Bussiness"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.noOfYearsInBussiness}
                    />
                    {formik.errors.noOfYearsInBussiness &&
                      formik.touched.noOfYearsInBussiness && (
                        <div className={classes.formikError}>
                          {formik.errors.noOfYearsInBussiness}
                        </div>
                      )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g 21"
                      name="noOfMarriage"
                      label="Number Of Marriages"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.noOfMarriage}
                    />
                    {formik.errors.noOfMarriage &&
                      formik.touched.noOfMarriage && (
                        <div className={classes.formikError}>
                          {formik.errors.noOfMarriage}
                        </div>
                      )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g https://www.youtube.com/watch?v=zWh3CShX_do"
                      name="videos[0]"
                      label="Video Url"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.videos[0]}
                    />
                    {formik.errors.videos && formik.touched.videos && (
                      <div className={classes.formikError}>
                        {formik.errors.videos}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g brand meta title"
                      name="metaTitle"
                      label="Meta Title"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metaTitle}
                    />
                    {formik.errors.metaTitle && formik.touched.metaTitle && (
                      <div className={classes.formikError}>
                        {formik.errors.metaTitle}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Textarea
                      placeholder="e.g Find closest TVS Electronic Store. Get contact information..."
                      name="metaDescription"
                      label="Meta Description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metaDescription}
                      autosize
                    />
                    {formik.errors.metaDescription &&
                      formik.touched.metaDescription && (
                        <div className={classes.formikError}>
                          {formik.errors.metaDescription}
                        </div>
                      )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Textarea
                      placeholder="e.g Electronic store, printers and scanners price, printer price"
                      name="metaKeywords"
                      label="Meta Keywords"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metaKeywords}
                      autosize
                    />
                    {formik.errors.metaKeywords &&
                      formik.touched.metaKeywords && (
                        <div className={classes.formikError}>
                          {formik.errors.metaKeywords}
                        </div>
                      )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Textarea
                      placeholder="e.g Find closest TVS Electronic Store. Get contact information..."
                      name="longdescription"
                      label=" Long Description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.longdescription}
                      autosize
                    />
                    {formik.errors.longdescription &&
                      formik.touched.longdescription && (
                        <div className={classes.formikError}>
                          {formik.errors.longdescription}
                        </div>
                      )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Textarea
                      placeholder="e.g Find closest TVS Electronic Store. Get contact information..."
                      name="shortDescription"
                      label="Short description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.shortDescription}
                      autosize
                    />
                    {formik.errors.shortDescription &&
                      formik.touched.shortDescription && (
                        <div className={classes.formikError}>
                          {formik.errors.shortDescription}
                        </div>
                      )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Textarea
                      placeholder="e.g https://www.latlong.net/place/coimbatore-tamil-nadu-india-2267.html"
                      name="locationUrl"
                      label="Location Url"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.locationUrl}
                      autosize
                    />
                    {formik.errors.locationUrl &&
                      formik.touched.locationUrl && (
                        <div className={classes.formikError}>
                          {formik.errors.locationUrl}
                        </div>
                      )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g https://www.linkedin.com/in/wikiwed?trk=nav_responsive_tab_profile"
                      name="linkedInUrl"
                      label="LinkedIn Url"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.linkedInUrl}
                    />
                    {formik.errors.linkedInUrl &&
                      formik.touched.linkedInUrl && (
                        <div className={classes.formikError}>
                          {formik.errors.linkedInUrl}
                        </div>
                      )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g https://twitter.com/wikiwed"
                      name="twitterUrl"
                      label="Twitter Url"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.twitterUrl}
                    />
                    {formik.errors.twitterUrl && formik.touched.twitterUrl && (
                      <div className={classes.formikError}>
                        {formik.errors.twitterUrl}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g https://help.instagram.com/372819389498306"
                      name="instagramUrl"
                      label="Instagram Url"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.instagramUrl}
                    />
                    {formik.errors.instagramUrl &&
                      formik.touched.instagramUrl && (
                        <div className={classes.formikError}>
                          {formik.errors.instagramUrl}
                        </div>
                      )}
                  </Grid.Col>

                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g https://www.youtube.com/channel/UC3yg20Z-6DNnNzSaj0ZLO8A"
                      name="youtubeUrl"
                      label="Youtube Url"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.youtubeUrl}
                    />
                    {formik.errors.youtubeUrl && formik.touched.youtubeUrl && (
                      <div className={classes.formikError}>
                        {formik.errors.youtubeUrl}
                      </div>
                    )}
                  </Grid.Col>

                  <Grid.Col xs={12}>
                    <FileInput
                      label="Store Image (Only image/png,image/jpeg,image/webp file format is supported)"
                      // caption="image/png,image/jpeg,image/webp,image.jpg"
                      description="image/png,image/jpeg,image/webp,image.jpg"
                      icon={<IconUpload size={14} />}
                      name="storeImage"
                      onChange={imageChange}
                      accept="image/png,image/jpeg,image/webP"
                    />
                  </Grid.Col>
                  {selectedImage ? (
                    <Grid>
                      <Grid.Col xs={6}>
                        {selectedImage && (
                          <div style={{ margin: 10 }}>
                            <img
                              src={URL.createObjectURL(selectedImage)}
                              height={180}
                              width={300}
                              alt="logoFile"
                            />
                          </div>
                        )}
                      </Grid.Col>
                      <Grid.Col xs={6}>
                        <Button
                          onClick={() => removeImageAdd()}
                          style={{
                            background: 'none',
                            zIndex: 1,
                            marginLeft: '40px',
                          }}
                        >
                          <ActionIcon
                            sx={{
                              color: 'black',
                              backgroundImage:
                                'linear-gradient(45deg, #fd7e14 0%, #fa5252 100%)',
                            }}
                          >
                            <IconX size={15} />
                          </ActionIcon>
                        </Button>
                      </Grid.Col>
                    </Grid>
                  ) : (
                    <div>
                      {images && (
                        <Grid>
                          <Grid.Col xs={6}>
                            <img
                              src={images?.file?.Location}
                              height={180}
                              width={300}
                              alt="logoFile"
                              style={{ margin: '10px' }}
                            />
                          </Grid.Col>
                          <Grid.Col xs={6}>
                            <Button
                              onClick={() => removeImage(images?._id)}
                              style={{
                                background: 'none',
                                zIndex: 1,
                                marginLeft: '40px',
                              }}
                            >
                              <ActionIcon
                                sx={{
                                  color: 'black',
                                  backgroundImage:
                                    'linear-gradient(45deg, #fd7e14 0%, #fa5252 100%)',
                                }}
                              >
                                <IconX size={15} />
                              </ActionIcon>
                            </Button>
                          </Grid.Col>
                        </Grid>
                      )}
                    </div>
                  )}
                  {isEditMode ? (
                    <>
                      <Grid.Col xs={12}>
                        <Text fz="sm" style={{ fontSize: '14px' }}>
                          Store Gallery Image(Only
                          image/png,image/jpeg,image/webp file format is
                          supported)
                        </Text>
                      </Grid.Col>
                      {storeGalleryImage?.map((val: any) => (
                        <>
                          <Grid.Col xs={5} key={val}>
                            <img
                              src={val?.file?.Location}
                              height={180}
                              width={300}
                              alt="logoFile"
                              style={{
                                margin: 10,
                              }}
                            />
                          </Grid.Col>
                          <Grid.Col xs={1}>
                            <Button
                              onClick={() => removeImage(val._id)}
                              style={{
                                background: 'none',
                                marginLeft: '-30px',
                              }}
                            >
                              <ActionIcon
                                sx={{
                                  color: 'black',
                                  backgroundImage:
                                    'linear-gradient(45deg, #fd7e14 0%, #fa5252 100%)',
                                }}
                              >
                                <IconX size={15} />
                              </ActionIcon>
                            </Button>
                          </Grid.Col>
                        </>
                      ))}

                      <Grid.Col md={12}>
                        <Button
                          onClick={() => setOpen(true)}
                          style={{ margin: '10px' }}
                        >
                          Add Image
                        </Button>
                      </Grid.Col>
                    </>
                  ) : (
                    ''
                  )}
                </Grid>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="metaDataDetails">
              <Accordion.Control>MetaData</Accordion.Control>
              <Accordion.Panel>
                <>
                  {map(inputFields, (inputField: any, idx: any) => (
                    <AddField
                      key={idx}
                      isEditMode={isEditMode === true ? true : ''}
                      fieldKey={inputField.key}
                      fieldValue={
                        inputField.value?.title
                          ? inputField.value?.title
                          : inputField.value
                      }
                      inputField={inputFields}
                      handleSave={(value) => {
                        setInputFields((prev) => [...prev, value])
                      }}
                      handleDelete={() => {
                        handleDeleteField(idx)
                      }}
                    />
                  ))}
                </>

                <AddField
                  isEditMode={isEditMode === true ? true : ''}
                  inputField={inputFields}
                  handleSave={(value) => {
                    setInputFields((prev) => [...prev, value])
                  }}
                  handleDelete={() => {}}
                />
              </Accordion.Panel>
            </Accordion.Item>
          </>
        )}
      </Accordion>
      <Grid style={{ marginTop: '10px' }}>
        <Grid.Col xs={2}>
          <Checkbox
            checked={formik.values.status}
            required
            name="status"
            label={formik.values.status === true ? 'Active' : 'Inactive'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid.Col>
        <Grid.Col xs={4}>
          <Checkbox
            checked={formik.values.featured}
            required
            name="featured"
            label="Featured Store"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid.Col>
        <Grid.Col xs={2} style={{ marginLeft: '95px' }}>
          <Button
            variant="gradient"
            onClick={() => {
              onClose()
            }}
            gradient={{ from: 'orange', to: 'red' }}
            disabled={loading1}
          >
            Cancel
          </Button>
        </Grid.Col>
        <Grid.Col xs={2}>
          <Button
            variant="gradient"
            gradient={{ from: 'teal', to: 'lime', deg: 105 }}
            className={!formik.dirty ? 'disabled-btn' : ''}
            disabled={!isEditMode ? !(formik.dirty && formik.isValid) : false}
            onClick={() => {
              formik.handleSubmit()
              if (formik.isValid === false) {
                dispatch(
                  openSnackbar({
                    messege: 'Enter all required the fields!!',
                    icon: AlertIcons.error,
                    title: 'Error',
                    color: AlertColor.error,
                  })
                )
              }
            }}
            rightIcon={loading1 ? <Loader size={14} color="white" /> : ''}
          >
            {isEditMode ? 'Update' : 'Save'}
          </Button>
        </Grid.Col>
      </Grid>
      <Modal
        opened={open}
        centered
        onClose={() => {
          setOpen(false)
          setSearchParams({})
        }}
        title="Store Image Gallery (Upto 8 images can be Uploaded)"
        size="lg"
      >
        <Grid>
          <Grid.Col xs={12}>
            <FileInput
              multiple
              label="File Upload (Only image/png,image/jpeg,image/webp file format is supported)"
              // placeholder="image/png,image/jpeg,image/webp,image.jpg"
              description="image/png,image/jpeg,image/webp,image.jpg"
              icon={<IconUpload size={14} />}
              name="storeImage"
              disabled={loading1}
              onChange={fileUpload}
              accept="image/png,image/jpeg,image/webp"
            />
          </Grid.Col>
          {selectedFile?.length !== 0 ? (
            <div>
              {selectedFile?.map((image: any, index: any) => (
                <Grid key={image}>
                  <Grid.Col xs={6}>
                    <img
                      src={URL.createObjectURL(image)}
                      height={200}
                      width={350}
                      alt="logoFile"
                      style={{ margin: 10 }}
                    />
                  </Grid.Col>
                  <Grid.Col xs={6}>
                    <Button
                      onClick={() => handleRemoveGal(index)}
                      style={{ background: 'none', marginLeft: '60px' }}
                    >
                      <ActionIcon
                        sx={{
                          color: 'black',
                          backgroundImage:
                            'linear-gradient(45deg, #fd7e14 0%, #fa5252 100%)',
                        }}
                      >
                        <IconX size={15} />
                      </ActionIcon>
                    </Button>
                  </Grid.Col>
                </Grid>
              ))}
            </div>
          ) : (
            ''
          )}
          <Grid.Col xs={12}>
            <Button
              variant="gradient"
              gradient={{ from: 'teal', to: 'lime', deg: 105 }}
              onClick={(e: any) => handleSubmit(e)}
              loading={loading1 || false}
              loaderPosition="right"
              styles={() => ({
                root: {
                  border: 0,
                  height: 42,
                  paddingLeft: 20,
                  paddingRight: 20,

                  '&:hover': {
                    backgroundColor: theme.fn.darken('#00acee', 0.09),
                  },
                },
              })}
            >
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </div>
  )
}
export default StoreModelForm
