import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as _ from 'lodash'

export interface IRoleProps {
  userName?: string
  role: any
  permission: any
  brandId: any
  storeId: any
  storeCode: any
  brandName?: any
}

const initialState: IRoleProps = {
  userName: '',
  role: '',
  permission: [],
  brandId: '',
  storeId: '',
  storeCode: '',
  brandName: '',
}

export const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    getRole: (state, action: PayloadAction<IRoleProps>) => {
      state.userName = action.payload.userName
      state.role = action.payload.role
      state.permission = action.payload.permission
      state.brandId = action.payload.brandId
      state.storeId = action.payload.storeId
      state.storeCode = action.payload.storeCode
      state.brandName = action.payload.brandName
    },
  },
})

// Action creators are generated for each case reducer function
export const { getRole } = roleSlice.actions

export default roleSlice.reducer
