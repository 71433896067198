import { Box, Grid, Text } from '@mantine/core'
import { map, values } from 'lodash'
import moment from 'moment'

interface Iprops {
  data: any
  endDate: any
  lables: any
}

export default function DetailCard({ data, endDate, lables }: Iprops) {
  const valuesData = values(data).slice(1)

  const k = 'k'

  return (
    <div>
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Text
            sx={{
              textAlign: 'center',
              justifyContent: 'center',
              fontSize: '13px',
              color: 'black',
              fontWeight: 500,
            }}
          >
            {' '}
            {endDate &&
              moment(endDate).subtract(180, 'days').format('DD MMM YYYY')}{' '}
            <span> - </span>
            {moment(endDate).format(' DD MMM YYYY')}
            <hr
              style={{
                borderBottom: '1px dashed #999',
                textDecoration: 'none',
                width: '90%',
                margin: '0 auto',
                marginTop: '5px',
              }}
            />
          </Text>

          <Grid>
            <Grid.Col
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ maxWidth: '50%' }}
            >
              {map(lables, (getLabel) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    padding: '2px',
                    fontSize: '12px',
                    color: '#000',
                  }}
                >
                  {getLabel}
                </div>
              ))}
            </Grid.Col>
            <Grid.Col
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ maxWidth: '50%' }}
            >
              {map(valuesData, (getValuesData) => (
                <div
                  style={{
                    padding: '2px',
                    paddingLeft: '20px',
                    fontSize: '12px',
                    color: '#000',
                  }}
                >
                  {getValuesData < 1000
                    ? getValuesData
                    : (getValuesData / 1000).toFixed(2) + k}
                </div>
              ))}
            </Grid.Col>
          </Grid>
        </Box>
      </Box>
    </div>
  )
}
