/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/aria-unsupported-elements */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */
import {
  Grid,
  Text,
  Group,
  SelectItemProps,
  createStyles,
  Button,
  useMantineTheme,
  MantineColor,
  Select,
} from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { AxiosError, AxiosResponse } from 'axios'
import _, { get } from 'lodash'
import { forwardRef, useEffect, useState } from 'react'
import { DatePicker } from 'rsuite'
import 'rsuite/DateRangePicker/styles/index.less'
import 'rsuite/dist/rsuite.min.css'
import { isAfter } from 'date-fns'
import { IconTrash } from '@tabler/icons'
import moment from 'moment'
import { useAppSelector } from '../../../hooks'
import { API_SEVICES } from '../../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  ROLEPROVIDED,
  BRANDID,
  STORECODE,
} from '../../../constants'

import { openSnackbar } from '../../../redux/snackbar'
import { useAppDispatch } from '../../../redux/store'

interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  description: string
  cityName: string
}

interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}

const useStyles = createStyles((theme) => ({
  outside: {
    opacity: 0,
  },

  weekend: {
    color: `${theme.colors.blue[6]} !important`,
  },

  selected: {
    color: `${theme.white} !important`,
  },
  tableBodyStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
interface IProps {
  onClose: () => void
  fetchData: () => void
}
export default function KeywordsDelete(props: IProps) {
  const theme = useMantineTheme()
  const { onClose, fetchData } = props

  const [recordData, setRecordData] = useState([])

  const [brandData, setBrandData] = useState([])

  const [fromDate, setFromDate] = useState<any>(null)
  const [toDate, setToDate] = useState<any>(null)
  const navigate = useNavigate()

  const [deleteDate, setDeleteDate] = useState<any>(null)
  const [deleteBrand, setDeleteBrand] = useState<any>(null)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const dispatch = useAppDispatch()
  const role = useAppSelector((stat) => stat.role)
  const brandID = role.brandId || BRANDID || null
  const roleProvided = role.role || ROLEPROVIDED
  const storeCode = role.storeCode || STORECODE || null

  useEffect(() => {
    let rangeMatch

    if (deleteBrand === null) {
      if (roleProvided === 'admin') {
        rangeMatch = JSON.stringify({ brand: brandID })
      } else if (roleProvided === 'super admin') {
        rangeMatch = JSON.stringify({})
      } else if (roleProvided === 'user') {
        rangeMatch = JSON.stringify({ brand: brandID, storeId: storeCode })
      } else if (roleProvided === 'CRM manager') {
        rangeMatch = JSON.stringify({ brand: brandID })
      }
    } else if (deleteBrand !== null) {
      rangeMatch = JSON.stringify({ brand: deleteBrand })
    }
    if (deleteBrand !== null) {
      API_SEVICES.PostRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_SEARCH_AVAILABLE_DATES}?match=${rangeMatch}`,
        (res: AxiosResponse) => {
          if (Object.keys(res.data.data).length === 0) {
            setFromDate(null)
            setToDate(null)
            setDeleteDate(null)
          } else {
            setFromDate(moment(res.data.data.startDate).format('MMM/YYYY'))
            setToDate(moment(res.data.data.endDate).format('MMM/YYYY'))
            setDeleteDate(moment(res.data.data.endDate).format('YYYY-MM-DD'))
          }
        },
        (err: AxiosError) => {
          console.error(err)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
        },
        {}
      )
    }
  }, [deleteBrand])

  useEffect(() => {
    let match
    if (brandID.length === 0) {
      match = JSON.stringify({ status: true })
    } else {
      match = JSON.stringify({ _id: brandID, status: true })
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${match}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }

        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Brand Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const brandDetails = brandData.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.name,
  }))

  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, name, description, cityName, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            {description || cityName ? (
              <Text size="xs" opacity={0.65}>
                {description}-{cityName}
              </Text>
            ) : null}
          </div>
        </Group>
      </div>
    )
  )
  SelectItem.displayName = 'SelectItem'

  function handleDeleteSubmit(event: any) {
    event.preventDefault()
    setDeleteLoading(true)
    API_SEVICES.DeleteRequestObj(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.DELETE_KEYWORDS}`,
      (res: AxiosResponse) => {
        setDeleteLoading(false)
        setRecordData([])
        setFromDate(null)
        setToDate(null)
        setDeleteBrand(null)
        setDeleteDate(null)
        onClose()
        fetchData()

        dispatch(
          openSnackbar({
            messege: 'Keywords Deleted Sucessfully',
            icon: AlertIcons.success,
            title: 'Success',
            color: AlertColor.success,
          })
        )
      },
      (err: AxiosError) => {
        setDeleteLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }

        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Keywords Cant be Deleted '
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      },
      {
        date: `${moment(deleteDate).startOf('month').format('YYYY-MM-DD')}`,
        brand: `${deleteBrand}`,
      }
    )
  }

  return (
    <div style={{ zIndex: 8 }}>
      <Grid>
        <Grid.Col xl={5}>
          <Text>Select Brand</Text>
          <Select
            placeholder="Select Brand"
            itemComponent={SelectItem}
            data={brandDetails}
            maxDropdownHeight={400}
            value={deleteBrand}
            nothingFound="No Data Found"
            clearable
            onChange={(value: any) => {
              setDeleteBrand(value)
              setFromDate(null)
              setToDate(null)
            }}
            styles={() => ({
              item: {
                // applies styles to selected item
                '&[data-selected]': {
                  '&, &:hover': {
                    backgroundColor:
                      theme.colorScheme === 'dark'
                        ? theme.colors.teal[9]
                        : theme.colors.teal[1],
                    color:
                      theme.colorScheme === 'dark'
                        ? theme.white
                        : theme.colors.teal[9],
                  },
                }, // applies styles to hovered item (with mouse or keyboard)

                '&[data-hovered]': {},
              },
            })}
          />
        </Grid.Col>
        {fromDate !== null ? (
          <Grid.Col xl={5}>
            <Text>
              {' '}
              Available Month: {fromDate}-{toDate}
            </Text>
          </Grid.Col>
        ) : (
          ''
        )}
        <Grid.Col xl={5}>
          <Text>Select Date</Text>
          <DatePicker
            format="yyyy-MM"
            disabled={deleteBrand === null || fromDate === null}
            ranges={[]}
            oneTap
            style={{
              width: '700px',
            }}
            value={
              deleteDate !== null
                ? moment(deleteDate).startOf('month').toDate()
                : null
            }
            disabledDate={(date: any) => isAfter(date, new Date())}
            onChange={(dates) => {
              if (dates) {
                setDeleteDate(
                  moment(dates).startOf('month').format('YYYY-MM-DD')
                )
              }
            }}
          />
        </Grid.Col>

        <Grid.Col sx={{ textAlign: 'center' }}>
          <Button
            rightIcon={<IconTrash size={17} />}
            onClick={(e: any) => handleDeleteSubmit(e)}
            disabled={deleteDate === null}
            loading={deleteLoading}
          >
            Delete
          </Button>
        </Grid.Col>
      </Grid>
    </div>
  )
}
