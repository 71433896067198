/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import {
  Box,
  Paper,
  Title,
  Grid,
  Button,
  Text,
  Group,
  Select,
  createStyles,
  SelectItemProps,
  MantineColor,
  ActionIcon,
  Loader,
  Popover,
} from '@mantine/core'
import {
  IconUpload,
  IconSearch,
  IconSquareX,
  IconChevronDown,
  IconFilter,
} from '@tabler/icons'
import { CSVLink } from 'react-csv'
import axios, { AxiosError, AxiosResponse } from 'axios'
import _, { get, map, debounce } from 'lodash'
import { DataTableColumn, DataTable } from 'mantine-datatable'
import { useEffect, useMemo, useState, forwardRef, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import 'rsuite/DateRangePicker/styles/index.less'
import 'rsuite/dist/rsuite.min.css'

import { API_SEVICES } from '../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
} from '../../constants'
import ProgressLoader from '../../components/ProgressLoader'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { openSnackbar } from '../../redux/snackbar'
import { getLocalStorageItem } from '../../utils/localStorage'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  description: string
  cityName: string
}
interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}
const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
    fontStyle: 'Helvetica',
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
  tableBodyStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  filter: {
    marginLeft: '115px',
    '@media (max-width: 912px)': {
      marginLeft: '110px',
    },
  },
  import: {
    marginLeft: '52px',
    '@media (max-width: 912px)': {
      marginLeft: '40px',
    },
  },
  csv: {
    marginLeft: '30px',
    '@media (max-width: 912px)': {
      marginLeft: '30px',
    },
  },
  product: {
    marginLeft: '40px',
    '@media (max-width: 912px)': {
      marginLeft: '50px',
    },
  },
}))

export default function KeywordList() {
  // eslint-disable-next-line react/prop-types

  const [brandData, setBrandData] = useState([])

  const [loading, setLoading] = useState(false)
  const { theme } = useStyles()
  const [brandVal, setBrandVal] = useState(null)
  const [keywordVal, setKeywordVal] = useState(null)
  const [pageAvailableVal, setPageAvailableVal] = useState(null)
  const [pageAvailableStatus, setPageAvailableStatus] = useState(false)
  const [keywordList, setKeywordList] = useState<any>([])
  const [keywordData, setKeywordData] = useState<any>([])
  const [availbleData, setAvailableData] = useState<any>([])
  const navigate = useNavigate()
  const filterRef = useRef<HTMLInputElement>(null)
  const [pageSize2, setPageSize2] = useState(PAGESIZES[0])
  const [page2, setPage2] = useState(1)
  const [records, setRecords] = useState<any>([])
  const [search, setSearch] = useState(false)
  const [keywordDownloadData, setKeywordDownloadData] = useState<any>([])
  const dispatch = useAppDispatch()
  const [keywordFilterLoading, setKeywordFilterLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (keywordVal === null || brandVal === null) {
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_VIEW_KEYWORDDETAILS}`,
        (res: AxiosResponse) => {
          setLoading(false)

          setKeywordData(res.data.data.data)

          setRecords(res.data.data.data.slice(0, PAGESIZES[0]))
        },
        (err: AxiosError) => {
          setKeywordData([])
          setRecords([])

          setLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          } else if (err.message !== 'canceled') {
            dispatch(
              openSnackbar({
                messege: get(
                  err,
                  'response.data.message',
                  'Keyword Details Cant be fetched'
                ),
                icon: AlertIcons.error,
                title: 'error',
                color: AlertColor.error,
              })
            )
            setLoading(false)
          }
          console.error(err)
        }
      )
    } else {
      const match1 = { brandId: brandVal, keywords: keywordVal }
      const clean = (obj: any) => {
        for (const propName in obj) {
          if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName]
          }
        }
        return obj
      }
      const mat = clean(match1)
      const match = JSON.stringify(mat)

      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_VIEW_KEYWORDDETAILS}?match=${match}`,
        (res: AxiosResponse) => {
          setLoading(false)
          setKeywordData(res.data.data.data)
          setRecords(res.data.data.data.slice(0, PAGESIZES[0]))
        },
        (err: AxiosError) => {
          setKeywordData([])
          setRecords([])

          setLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          } else if (err.message !== 'canceled') {
            dispatch(
              openSnackbar({
                messege: ' Keyword Details Cant be fetched',
                icon: AlertIcons.error,
                title: 'error',
                color: AlertColor.error,
              })
            )
            setLoading(false)
          }
          console.error(err)
        }
      )
    }
    setSearch(false)
  }, [search])

  useEffect(() => {
    if (brandVal !== null) {
      setKeywordFilterLoading(true)
      const match = JSON.stringify({ brandId: brandVal })
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_VIEW_KEYWORDDETAILS}?match=${match}`,
        (res: AxiosResponse) => {
          setKeywordFilterLoading(false)

          setKeywordList(res.data.data.data)
          const statesArray: any = map(
            res.data.data.data,
            (values: any) => values
          )?.map((option: any) => option.keywords)

          function removeDuplicates(arr: any) {
            return arr.filter(
              (item: any, index: any) => arr.indexOf(item) === index
            )
          }

          setKeywordList(removeDuplicates(statesArray))
        },
        (err: AxiosError) => {
          setKeywordList([])

          setKeywordFilterLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          } else if (err.message !== 'canceled') {
            // dispatch(
            //   openSnackbar({
            //     messege: get(
            //       err,
            //       'response.data.message',
            //       'Keyword Details Cant be fetched'
            //     ),
            //     icon: AlertIcons.error,
            //     title: 'error',
            //     color: AlertColor.error,
            //   })
            // )
            setLoading(false)
          }
          console.error(err)
        }
      )
    }
  }, [brandVal])
  useEffect(() => {
    const controller = new AbortController()

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Brand Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )

    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
  }, [])
  const brand = brandData?.map((item: any) => ({
    ...item,
    label: item.name,
    value: item._id,
  }))

  const keyword = keywordList?.map((item: any) => ({
    ...item,
    label: item,
    value: item,
  }))

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, name, description, cityName, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            {description || cityName ? (
              <Text size="xs" opacity={0.65}>
                {description}-{cityName}
              </Text>
            ) : null}
          </div>
        </Group>
      </div>
    )
  )
  SelectItem.displayName = 'SelectItem'

  const colDef2: DataTableColumn<any>[] = useMemo(
    () => [
      {
        Header: 'Brand',
        accessor: 'brand.name',
        textAlignment: 'center',
        titleSx: () => ({
          '&&': { backgroundColor: '#f4f4f4' },
        }),
        cellsSx: {
          textTransform: 'capitalize',
        },

        width: 150,
      },
      {
        title: 'Store',
        accessor: 'store',
        textAlignment: 'center',
        titleSx: () => ({
          '&&': { backgroundColor: '#f4f4f4' },
        }),
        cellsSx: {
          textTransform: 'capitalize',
        },

        width: 350,

        render: ({ store }: any) => (
          <div>
            {store.storeName}
            <div style={{ color: 'grey', fontSize: '13px' }}>
              {store.locality},{store.city}
            </div>
          </div>
        ),
      },
      {
        title: 'Keyword',
        accessor: 'keywords',
        textAlignment: 'center',
        cellsSx: {
          textTransform: 'capitalize',
        },
        titleSx: () => ({
          '&&': { backgroundColor: '#f4f4f4' },
        }),

        width: 150,

        render: ({ keywords }: any) => <p>{keywords}</p>,
      },
      {
        title: 'Page Availability',
        accessor: 'dynamicContent',
        textAlignment: 'center',
        titleSx: () => ({
          '&&': { backgroundColor: '#f4f4f4' },
        }),
        cellsSx: {
          textTransform: 'capitalize',
        },

        width: 300,

        render: ({ dynamicContent }: any) => (
          <div>{dynamicContent === 'yes' ? 'Available' : 'Not Available'}</div>
        ),
      },

      {
        accessor: 'urls',
        title: 'URL ',
        textAlignment: 'center',
        width: 700,
        minWidth: 900,

        titleSx: () => ({
          '&&': { backgroundColor: '#f4f4f4' },
        }),
        render: ({ urls }: any) => (
          <div>
            {urls !== '' ? (
              <a target="_blank" href={`${urls}`} rel="noreferrer">
                {urls}
              </a>
            ) : (
              'NA'
            )}
          </div>
        ),
      },
      {
        accessor: 'status',
        title: 'Status ',
        textAlignment: 'center',
        width: 150,
        cellsSx: {
          textTransform: 'capitalize',
        },

        titleSx: () => ({
          '&&': { backgroundColor: '#f4f4f4' },
        }),
        render: ({ status }: any) => <p>{status}</p>,
      },
    ],
    []
  )

  useEffect(() => {
    let Data
    if (pageAvailableStatus) {
      Data = availbleData?.map((i: any) => {
        const brandValue = i.brand.name
        const storeValue = i.store.storeName
        const { locality } = i.store
        const { city } = i.store

        return { ...i, brandValue, storeValue, locality, city }
      })
    } else {
      Data = keywordData?.map((i: any) => {
        const brandValue = i.brand.name
        const storeValue = i.store.storeName
        const { locality } = i.store
        const { city } = i.store

        return { ...i, brandValue, storeValue, locality, city }
      })
    }
    // const Data = keywordData?.map((i: any) => {
    //   const brandValue = i.brand.name
    //   const storeValue = i.store.storeName
    //   const { locality } = i.store
    //   const { city } = i.store

    //   return { ...i, brandValue, storeValue, locality, city }
    // })
    const data1 = Data.forEach((object: any) => {
      delete object.brand
      delete object.store
      return object
    })
    setKeywordDownloadData(Data)
  }, [keywordData])

  useEffect(() => {
    const data = keywordData
      .filter((i: any) => get(i, 'brand._id', '') === brandVal)
      .filter((j: any) => get(j, 'status', '') === pageAvailableVal)
    setAvailableData(data)

    setRecords(data.slice(0, PAGESIZES[0]))
  }, [pageAvailableStatus === true])
  useEffect(() => {
    const from = (page2 - 1) * pageSize2
    const to = from + pageSize2
    if (pageAvailableStatus) {
      setRecords(availbleData.slice(from, to))
    } else {
      setRecords(keywordData.slice(from, to))
    }
  }, [page2, pageSize2, records, pageAvailableStatus])

  return (
    <Box>
      <Paper shadow="xs" p="md">
        <>
          <Grid ref={filterRef}>
            <Grid.Col xs={4} sm={3.5} md={9} lg={9} xl={9}>
              <Title order={3} weight={100}>
                Keyword Details List
              </Title>
              <div>
                <p style={{ color: 'grey', fontSize: '15px' }}>
                  Total Number of Keywords :{' '}
                  {!pageAvailableStatus
                    ? keywordData.length
                    : availbleData.length}
                </p>
              </div>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={1.5} lg={1.5} xl={1.5}>
              <Popover width={350} position="bottom" withArrow shadow="md">
                <Popover.Target>
                  <Button disabled={loading}>
                    {' '}
                    <IconFilter />
                    Filter
                  </Button>
                </Popover.Target>
                <Popover.Dropdown>
                  <Grid style={{ margin: '10px' }}>
                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Select
                        label="Select Brand"
                        placeholder="Select Brand"
                        itemComponent={SelectItem}
                        data={brand}
                        limit={brand.length}
                        disabled={brand.length === 0}
                        maxDropdownHeight={200}
                        value={brandVal}
                        nothingFound="No Data Found"
                        // clearable
                        onChange={(value: any) => {
                          setBrandVal(value)
                          setPageAvailableVal(null)
                          setPageAvailableStatus(false)
                        }}
                        styles={() => ({
                          item: {
                            // applies styles to selected item
                            '&[data-selected]': {
                              '&, &:hover': {
                                backgroundColor:
                                  theme.colorScheme === 'dark'
                                    ? theme.colors.teal[9]
                                    : theme.colors.teal[1],
                                color:
                                  theme.colorScheme === 'dark'
                                    ? theme.white
                                    : theme.colors.teal[9],
                              },
                            },

                            // applies styles to hovered item (with mouse or keyboard)
                            '&[data-hovered]': {},
                          },
                        })}
                      />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Select
                        label="Select Keyword"
                        placeholder="Select Keyword"
                        itemComponent={SelectItem}
                        data={keyword}
                        limit={keyword.length}
                        disabled={keyword.length === 0}
                        maxDropdownHeight={200}
                        value={keywordVal}
                        searchable
                        nothingFound="No Data Found"
                        // clearable
                        rightSection={
                          !keywordFilterLoading ? (
                            <IconChevronDown size="1rem" />
                          ) : (
                            <Loader color="teal" size={20} />
                          )
                        }
                        onChange={(value: any) => {
                          setKeywordVal(value)
                          setPageAvailableVal(null)
                          setPageAvailableStatus(false)
                          setAvailableData([])
                        }}
                        styles={() => ({
                          item: {
                            // applies styles to selected item
                            '&[data-selected]': {
                              '&, &:hover': {
                                backgroundColor:
                                  theme.colorScheme === 'dark'
                                    ? theme.colors.teal[9]
                                    : theme.colors.teal[1],
                                color:
                                  theme.colorScheme === 'dark'
                                    ? theme.white
                                    : theme.colors.teal[9],
                              },
                            },
                            '&[data-disabled]': { opacity: 0.8 },

                            // applies styles to hovered item (with mouse or keyboard)
                            '&[data-hovered]': {},
                          },
                        })}
                      />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Select
                        label="Select Indexing"
                        placeholder="Select Indexing"
                        itemComponent={SelectItem}
                        data={[
                          {
                            label: 'Indexed',
                            value: 'Indexed',
                          },
                          {
                            label: 'Pending',
                            value: 'Pending',
                          },
                          {
                            label: 'NA',
                            value: 'NA',
                          },
                        ]}
                        disabled={
                          brandVal === null ||
                          keywordVal !== null ||
                          keyword.length === 0
                        }
                        maxDropdownHeight={200}
                        value={pageAvailableVal}
                        nothingFound="No Data Found"
                        // clearable
                        onChange={(value: any) => {
                          setPageAvailableStatus(false)
                          setPageAvailableVal(value)
                        }}
                        styles={() => ({
                          item: {
                            // applies styles to selected item
                            '&[data-selected]': {
                              '&, &:hover': {
                                backgroundColor:
                                  theme.colorScheme === 'dark'
                                    ? theme.colors.teal[9]
                                    : theme.colors.teal[1],
                                color:
                                  theme.colorScheme === 'dark'
                                    ? theme.white
                                    : theme.colors.teal[9],
                              },
                            },
                            '&[data-disabled]': { opacity: 0.8 },

                            // applies styles to hovered item (with mouse or keyboard)
                            '&[data-hovered]': {},
                          },
                        })}
                      />
                    </Grid.Col>
                    <Grid.Col xs={6} sm={6} md={6} lg={6.9} xl={6.9}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'right',
                          alignItems: 'center',
                          marginTop: '5px',
                        }}
                      >
                        <ActionIcon
                          disabled={brandVal === null}
                          onClick={() => {
                            setSearch(true)
                            setBrandVal(null)
                            setKeywordVal(null)
                          }}
                          sx={{
                            color: 'red',
                            '&[data-disabled]': { opacity: 0.8 },
                            '&[data-loading]': { backgroundColor: 'red' },
                          }}
                        >
                          {' '}
                          <IconSquareX size={90} />
                        </ActionIcon>
                      </div>
                    </Grid.Col>
                    <Grid.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                      <div>
                        <Button
                          variant="gradient"
                          gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                          onClick={() => {
                            if (pageAvailableVal === null) {
                              setSearch(true)
                              setKeywordData([])
                            } else {
                              setPageAvailableStatus(true)
                              setPage2(1)
                              // setKeywordData([])
                              // setLoading(true)
                            }
                          }}
                          disabled={loading || brandVal === null}
                        >
                          <IconSearch />
                          <span style={{ marginLeft: '10px' }}>Search</span>
                        </Button>
                      </div>
                    </Grid.Col>
                  </Grid>
                </Popover.Dropdown>
              </Popover>
            </Grid.Col>

            <Grid.Col xs={2} sm={2} md={2} lg={1.5} xl={1.5}>
              <CSVLink
                data={keywordDownloadData}
                filename="ClosebiKeywordList.csv"
              >
                <Button
                  style={{ width: '100%' }}
                  variant="gradient"
                  gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                  disabled={keywordDownloadData.length === 0}
                  leftIcon={<IconUpload size={18} />}
                >
                  Export
                </Button>
              </CSVLink>{' '}
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col xs={12} style={{ width: 1060 }}>
              <div style={{ height: '700px' }}>
                {loading ? <ProgressLoader /> : null}
                <DataTable
                  styles={() => ({
                    root: {},
                    header: { zIndex: 0 },
                    footer: { fontStyle: 'italic' },
                  })}
                  records={records}
                  columns={colDef2}
                  totalRecords={
                    pageAvailableStatus
                      ? availbleData.length
                      : keywordData.length
                  }
                  recordsPerPage={pageSize2}
                  page={page2}
                  paginationColor="green"
                  onPageChange={(p) => setPage2(p)}
                  recordsPerPageOptions={PAGESIZES}
                  onRecordsPerPageChange={setPageSize2}
                />
              </div>
            </Grid.Col>
          </Grid>
        </>
      </Paper>
    </Box>
  )
}
