/* eslint-disable max-len */
import {
  Box,
  Paper,
  Title,
  Grid,
  Button,
  Modal,
  Text,
  Group,
  SelectItemProps,
  Menu,
  MantineColor,
  Autocomplete,
} from '@mantine/core'
import {
  IconCirclePlus,
  IconEyeCheck,
  IconEdit,
  IconTrash,
  IconDotsVertical,
  IconCircleCheck,
  IconCircleX,
} from '@tabler/icons'
import { AxiosError, AxiosResponse } from 'axios'
import _, { get } from 'lodash'
import { DataTableColumn } from 'mantine-datatable'
import { forwardRef, useEffect, useMemo, useState, useRef } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import CustomTable from '../../components/NewcustomTable'
import { useAppSelector } from '../../hooks'
import { API_SEVICES } from '../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
  ROLEPROVIDED,
  BRANDID,
} from '../../constants'
import { openSnackbar } from '../../redux/snackbar'
import CityModelForm from './CityModelForm'
import { useAppDispatch } from '../../redux/store'
import DeleteModal from '../../components/DeleteModal'
import CityViewModal from './CityViewModel'

interface ItemProps extends SelectItemProps {
  _id: any
  name: string
}
interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}
export default function City() {
  const [searchParams, setSearchParams] = useSearchParams()
  const mode = searchParams.get('mode')
  const [modelState, setModel] = useState(false)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [recordData, setRecordData] = useState([])
  const [state, setState] = useState([])
  const [selected, setSelected] = useState(false)
  const [page, setPage] = useState(1)
  const [pageNo, setPageNo] = useState(1)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [countFrom, setCountForm] = useState<any>()
  const [countTo, setCountTo] = useState<any>()
  const [pageSize, setPageSize] = useState(PAGESIZES[0])
  const [totalRowSize, setTotalRowSize]: any = useState(10)
  const [tableHeight, setTableHeight] = useState(500)
  const filterRef = useRef<HTMLInputElement>(null)
  const deleteCity = API_ENDPOINTS.CITY_DETAILS
  const deletedData = API_ENDPOINTS.CITY_DETAILS
  const deleteName = 'city'
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const role = useAppSelector((stat) => stat.role)
  const brandID = role.brandId || BRANDID || null

  const roleProvided = role.role || ROLEPROVIDED
  const handleClose = () => {
    setModel(false)
    setDeleteOpen(false)
    setSearchParams({})
  }

  const fetchData = () => {
    setLoading(true)
    let match
    if (roleProvided === 'admin' && brandID === null) {
      match = JSON.stringify({ _id: null })
    } else if (roleProvided === 'CRM manager' && brandID === null) {
      match = JSON.stringify({ _id: null })
    } else {
      match = JSON.stringify({})
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.CITY_DETAILS}?pageNo=${page}&size=${pageSize}&match=${match}`,
      (res: AxiosResponse) => {
        setLoading(false)
        setTotalRowSize(res.data.TotalRecords)
        setRecordData(res.data.data)
      },
      (err: AxiosError) => {
        setLoading(false)
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'City Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )
  }
  useEffect(() => {
    setLoading(true)
    let match
    if (roleProvided === 'admin' && brandID === null) {
      match = JSON.stringify({ _id: null })
    } else if (roleProvided === 'CRM manager' && brandID === null) {
      match = JSON.stringify({ _id: null })
    } else {
      match = JSON.stringify({})
    }
    const controller = new AbortController()
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.CITY_DETAILS}?pageNo=${page}&size=${pageSize}&match=${match}`,
      (res: AxiosResponse) => {
        setLoading(false)
        setTotalRowSize(res.data.TotalRecords)
        setRecordData(res.data.data)
      },
      (err: AxiosError) => {
        setRecordData([])
        setTotalRowSize(0)
        if (get(err, 'response.status') === 403) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'City Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          setLoading(false)
        }
        console.error(err)
      },
      controller.signal
    )

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_STATE}`,
      (res: AxiosResponse) => {
        setState(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'State Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize])

  const colDef: DataTableColumn<any>[] = useMemo(
    () => [
      {
        Header: 'Actions',
        accessor: 'actions',
        fixed: 'left',
        title: <Text mr="xs">Actions</Text>,
        textAlignment: 'center',
        minWidth: 200,
        width: 200,
        Cell: ({ ...row }) => (
          <Menu shadow="md" width={130} position="right-start">
            <Menu.Target>
              <Button style={{ background: 'none' }}>
                {' '}
                <IconDotsVertical size={16} color="black" />
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                color="green"
                icon={<IconEyeCheck size={14} />}
                onClick={() => {
                  setOpen(true)
                  setSearchParams({
                    mode: 'view',
                    id: row?.row?.original._id,
                  })
                }}
              >
                View
              </Menu.Item>
              <Menu.Item
                color="blue"
                onClick={() => {
                  setModel(true)
                  setSearchParams({
                    mode: 'edit',
                    id: row?.row?.original._id,
                  })
                }}
                icon={<IconEdit size={14} />}
                disabled={
                  roleProvided === 'user' || roleProvided === 'brand manager'
                }
              >
                Edit
              </Menu.Item>
              <Menu.Item
                color="red"
                onClick={() => {
                  setDeleteOpen(true)
                  setSearchParams({
                    mode: 'delete',
                    id: row?.row?.original._id,
                  })
                }}
                icon={<IconTrash size={14} />}
                disabled={
                  roleProvided === 'user' || roleProvided === 'brand manager'
                }
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ),
      },
      {
        accessor: 'state',
        Header: 'State Name',
        textAlignment: 'center',
        minWidth: 300,
        width: 330,
      },
      {
        accessor: 'labe',
        Header: 'City Name',
        textAlignment: 'center',
        minWidth: 300,
        width: 330,
      },
      {
        accessor: 'status',
        Header: 'Status',
        textAlignment: 'center',
        minWidth: 270,
        width: 200,
        Cell: ({ ...status }) => {
          if (status.row.original.status === true) {
            return <IconCircleCheck color="green" size={30} />
          }
          return <IconCircleX color="#ff2825" size={30} />
        },
      },
    ],
    [setSearchParams, roleProvided]
  )

  const stateData = state.map((item: any) => ({
    ...item,
    value: item.label,
    label: item.label,
  }))
  const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ description, value, image, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text>{value}</Text>
          </div>
        </Group>
      </div>
    )
  )
  AutoCompleteItem.displayName = 'AutoCompleteItem'
  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ value, label, name, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
          </div>
        </Group>
      </div>
    )
  )
  useEffect(() => {
    if (filterRef.current != null) {
      setTableHeight(window.innerHeight - filterRef.current.clientHeight - 110)
    }

    if (totalRowSize > pageSize) {
      const from = (page - 1) * pageSize
      const to = from + pageSize
      setCountForm(from + 1)
      setCountTo(to)
    } else {
      setCountForm(1)
      setCountTo(totalRowSize)
    }
    let rowDataSize

    if (totalRowSize >= pageSize) {
      rowDataSize = totalRowSize / pageSize

      if (rowDataSize - Math.floor(rowDataSize) !== 0) {
        setPageNo(Math.ceil(rowDataSize))
      } else {
        setPageNo(rowDataSize)
      }
    } else {
      rowDataSize = Math.ceil(totalRowSize / pageSize)
      setPageNo(rowDataSize)
    }
  }, [page, pageSize, recordData, totalRowSize])

  return (
    <>
      <Box>
        <Paper shadow="xs" p="md">
          <Grid>
            <Grid.Col
              xs={6.5}
              sm={6.5}
              md={6.5}
              lg={7.5}
              xl={7.5}
              ref={filterRef}
            >
              <Title order={3} weight={100} style={{ marginTop: ' 13px' }}>
                City
              </Title>
            </Grid.Col>
            {roleProvided === 'super admin' ||
            (brandID !== null && roleProvided === 'admin') ||
            (brandID !== null && roleProvided === 'CRM manager') ? (
              <Grid.Col xs={2.5} sm={2.5} md={2.5} lg={1.6} xl={1.6}>
                <Button
                  variant="gradient"
                  gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                  leftIcon={<IconCirclePlus size={18} />}
                  onClick={() => {
                    setModel(true)
                    setSearchParams({ mode: 'add' })
                  }}
                  style={{ marginTop: ' 23px' }}
                  sx={{ display: 'flex', justifyContent: 'right' }}
                >
                  Add City
                </Button>
              </Grid.Col>
            ) : (
              <Grid.Col xs={2.5} sm={2.5} md={2.5} lg={1.6} xl={1.6} />
            )}
            {roleProvided === 'super admin' ||
            (brandID !== null && roleProvided === 'admin') ||
            (brandID !== null && roleProvided === 'CRM manager') ? (
              <Grid.Col xs={3} sm={3} md={3} lg={2.9} xl={2.9}>
                <Autocomplete
                  label="Select State"
                  data={stateData}
                  placeholder="Select State"
                  itemComponent={AutoCompleteItem}
                  maxDropdownHeight={200}
                  limit={stateData.length}
                  onItemSubmit={(item: any) => {
                    setLoading(true)
                    setPageNo(1)
                    let match
                    if (item.label === null) {
                      match = JSON.stringify({})
                    } else {
                      match = JSON.stringify({ state: item.label })
                    }
                    API_SEVICES.GetRequest(
                      `${process.env.REACT_APP_BACKEND_API_PROXY}${
                        API_ENDPOINTS.CITY_DETAILS
                      }?pageNo=${1}&size=${pageSize}&match=${match}`,
                      (res: AxiosResponse) => {
                        setLoading(false)
                        setSelected(true)
                        setTotalRowSize(res.data.data.length)
                        setRecordData(res.data.data)
                      },
                      (err: AxiosError) => {
                        setLoading(false)
                        dispatch(
                          openSnackbar({
                            messege: get(
                              err,
                              'response.data.message',
                              'City Details Cant be fetched'
                            ),
                            icon: AlertIcons.error,
                            title: 'error',
                            color: AlertColor.error,
                          })
                        )
                        console.error(err)
                      }
                    )
                  }}
                />
              </Grid.Col>
            ) : null}

            <Grid.Col xs={12}>
              <CustomTable
                pageFrom={countFrom}
                pageTo={countTo}
                total={pageNo}
                gridApi={(instance) => {}}
                totalRecords={!loading ? totalRowSize : 1}
                checkboxSelection={false}
                recordsPerPage={pageSize}
                page={page}
                height={tableHeight}
                onPageChange={(p: any) => setPage(p)}
                recordsPerPageOptions={PAGESIZES}
                onRecordsPerPageChange={setPageSize}
                loading={!!loading}
                rowData={!loading ? recordData : []}
                colDef={colDef}
              />
            </Grid.Col>
          </Grid>
        </Paper>
      </Box>

      <Modal
        style={{
          fontSize: '18px',
          textTransform: 'capitalize',
          fontWeight: 500,
        }}
        opened={modelState}
        centered
        onClose={() => {
          setModel(false)
          setSearchParams({})
        }}
        title={`${mode} City`}
        size="lg"
      >
        <CityModelForm
          fetchData={() => fetchData()}
          onClose={() => {
            setSearchParams({})
            handleClose()
          }}
          mode={searchParams.get('mode')}
          isEditMode={searchParams.get('mode')?.toLowerCase() === 'edit'}
          isViewMode={searchParams.get('mode')?.toLowerCase() === 'view'}
        />
      </Modal>

      <Modal
        opened={deleteOpen}
        centered
        onClose={() => {
          setDeleteOpen(false)
        }}
        title="Delete City"
        size="lg"
      >
        <DeleteModal
          deleteName={deleteName}
          fetchData={fetchData}
          onClose={handleClose}
          deleteApi={deleteCity}
          deletedGetApi={deletedData}
        />
      </Modal>
      <Modal
        opened={open}
        centered
        onClose={() => {
          setOpen(false)
          setSearchParams({})
        }}
        title="View City"
        size="lg"
      >
        <CityViewModal onClose={handleClose} />
      </Modal>
    </>
  )
}
