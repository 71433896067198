import {
  UnstyledButton,
  UnstyledButtonProps,
  Group,
  Avatar,
  Text,
  createStyles,
} from '@mantine/core'

const useStyles = createStyles((theme) => ({
  user: {
    display: 'block',
    width: '100%',
    padding: theme.spacing.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[8]
          : theme.colors.gray[0],
    },
  },
}))

interface UserButtonProps extends UnstyledButtonProps {
  image: string
  name: string
  email: string
  brand?: any
  icon?: React.ReactNode
}

export default function UserButton({
  image,
  name,
  email,
  icon,
  brand,
  ...others
}: UserButtonProps) {
  const { classes } = useStyles()

  return (
    <UnstyledButton className={classes.user} {...others}>
      <Group>
        <Avatar src={image} radius="xl" />

        <div style={{ flex: 1, marginTop: '-8px' }}>
          <Text size="sm" weight={500}>
            {name}
          </Text>

          <Text color="dimmed" size="xs" tt="capitalize">
            {email}
          </Text>
          <Text color="dimmed" size="xs" tt="capitalize">
            {brand}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  )
}
