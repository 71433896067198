/* eslint-disable func-names */

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface IProps {
  title: string
  data: any
}

function VerticalBarChart(props: IProps) {
  const { title, data } = props

  const options: object = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: title,
        fontSize: 40,
      },
      tooltip: {
        callbacks: {
          beforeTitle: function app(context: any) {
            return title
          },
        },
      },

      legend: {
        position: 'top',
        display: true,
      },
    },
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
  }
  return <Bar data={data} options={options} />
}

export default VerticalBarChart
