/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Paper,
  Grid,
  Text,
  Group,
  SelectItemProps,
  Select,
  createStyles,
  Button,
  useMantineTheme,
  Skeleton,
  Popover,
  MantineColor,
  Loader,
} from '@mantine/core'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { DateRangePicker } from '@mantine/dates'
import axios, { AxiosError, AxiosResponse } from 'axios'
import _, { get, map } from 'lodash'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { IconFilter, IconTableExport } from '@tabler/icons'
import moment from 'moment'
import { useAppSelector } from '../../hooks'
import { API_SEVICES } from '../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
  ROLEPROVIDED,
  BRANDID,
  STORECODE,
} from '../../constants'
import { openSnackbar } from '../../redux/snackbar'
import { useAppDispatch } from '../../redux/store'
import { getLocalStorageItem } from '../../utils/localStorage'
import CustomTable, { CustomColum } from '../../components/NewcustomTable'
import BarChat from '../../components/Barchat'
import ActionCard from '../../components/ActionCard'

interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  description: string
  cityName: string
}

interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}

const useStyles = createStyles((theme) => ({
  outside: {
    opacity: 0,
  },

  weekend: {
    color: `${theme.colors.blue[6]} !important`,
  },

  selected: {
    color: `${theme.white} !important`,
  },
  tableBodyStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

/* eslint @typescript-eslint/no-var-requires: "off" */
const FileDownload = require('js-file-download')

export default function CallActions() {
  const filterRef = useRef<HTMLInputElement>(null)
  const paperRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()
  const theme = useMantineTheme()
  const [totalViews, setTotalViews] = useState<any>([])
  const [tableHeight, setTableHeight] = useState(10)
  const [loading, setLoading] = useState(false)
  const [recordData, setRecordData] = useState([])
  const [state, setState] = useState([])
  const [stateData, setStateData] = useState<any>([])
  const [city, setCity] = useState([])
  const [page, setPage] = useState(1)
  const [pageNo, setPageNo] = useState(1)
  const [pageSize, setPageSize] = useState(PAGESIZES[0])
  const [storeVal, setStoreVal] = useState<any>(null)
  const [brandVal, setBrandVal] = useState<any>(null)
  const [countFrom, setCountForm] = useState<any>()
  const [countTo, setCountTo] = useState<any>()
  const [brandData, setBrandData] = useState([])
  const [search, setSearch] = useState(false)
  const [totalRowSize, setTotalRowSize]: any = useState(0)
  const [stateValue, setStateValue] = useState(null)
  const [cityValue, setCityValue] = useState(null)
  const [getSearchViews, setSearchViews] = useState([])
  const [getWebSiteActions, setWebsiteAction] = useState([])
  const [getMapsViews, setMapsViews] = useState([])
  const [getTotalActions, setTotalActions] = useState([])
  const [getDiractionSearch, setDireactionSearch] = useState([])
  const [fromDate, setFromDate] = useState<any>(null)
  const [toDate, setToDate] = useState<any>(new Date())
  const [endDate, setEndDate] = useState<any>(null)

  const [rangedate, setRangeDate] = useState<any>([])
  const [exportLoading, setExportLoading] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [stateLoading, setStateLoading] = useState(false)
  const [cityLoading, setCityLoading] = useState(false)

  const { classes, cx } = useStyles()

  const dispatch = useAppDispatch()
  const Token = getLocalStorageItem('token')
  const role = useAppSelector((stat) => stat.role)
  const brandID = role.brandId || BRANDID || null
  const roleProvided = role.role || ROLEPROVIDED
  const storeCode = role.storeCode || STORECODE || null

  useEffect(() => {
    setLoading(true)

    let rangeMatch
    const controller = new AbortController()
    if (brandVal === null) {
      if (roleProvided === 'admin') {
        rangeMatch = JSON.stringify({ brandId: brandID })
      } else if (roleProvided === 'CRM manager') {
        rangeMatch = JSON.stringify({ brandId: { $in: brandID } })
      } else if (roleProvided === 'brand manager') {
        rangeMatch = JSON.stringify({ brandId: brandID })
      } else if (roleProvided === 'super admin') {
        rangeMatch = JSON.stringify({})
      } else if (roleProvided === 'user') {
        rangeMatch = JSON.stringify({ brandId: brandID, storeId: storeCode })
      } else if (roleProvided === 'admin manager') {
        rangeMatch = JSON.stringify({ brandId: brandID, storeId: storeCode })
      }
    } else if (brandVal !== null) {
      rangeMatch = JSON.stringify({ brandId: brandVal })
    }

    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_AVAILABLE_DATES}?match=${rangeMatch}`,
        {
          signal: controller.signal,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-type': 'Application/json',
            Authorization: `Bearer ${Token}`,
          },
        }
      )
      .then((response: AxiosResponse) => {
        if (Object.keys(response.data.data).length === 0) {
          setFromDate(null)
          setToDate(null)
          setRangeDate([new Date(), new Date()])
        } else {
          setFromDate(moment(response.data.data.startDate).format('YYYY-MM-DD'))
          setToDate(moment(response.data.data.endDate).format('YYYY-MM-DD'))
          setEndDate(moment(response.data.data.endDate).format('YYYY-MM-DD'))
        }

        let match
        if (stateValue === null && brandVal === null && storeVal === null) {
          if (roleProvided === 'super admin') {
            match = {}
          } else if (roleProvided === 'admin' && brandID) {
            match = { brandId: brandID }
          } else if (roleProvided === 'CRM manager' && brandID) {
            match = { brandId: { $in: brandID } }
          } else if (roleProvided === 'brand manager') {
            match = { brandId: brandID }
          } else if (roleProvided === 'user' && brandID) {
            match = { brandId: brandID, storeId: storeCode }
          } else if (roleProvided === 'admin manager' && brandID) {
            match = { brandId: brandID, storeId: storeCode }
          }
        } else if (roleProvided === 'admin manager' && brandID) {
          match = {
            brandId: brandID,
            state: stateValue,
            city: cityValue,
            storeId: storeVal !== null ? [storeVal] : null,
          }
        } else if (roleProvided === 'CRM manager' && brandID) {
          match = {
            brandId: brandVal,
            state: stateValue,
            city: cityValue,
            storeId: storeVal !== null ? [storeVal] : null,
          }
        } else if (roleProvided === 'brand manager' && brandID) {
          match = {
            brandId: brandID,
            state: stateValue,
            city: cityValue,
            storeId: storeVal !== null ? [storeVal] : null,
          }
        } else {
          match = {
            brandId: brandVal,
            state: stateValue,
            city: cityValue,
            storeId: storeVal !== null ? [storeVal] : null,
          }
        }

        const clean = (obj: any) => {
          for (const propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
              delete obj[propName]
            }
          }
          return obj
        }
        const mat = clean(match)

        const match1 = JSON.stringify(mat)
        setLoading(true)
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_ACTION_TABLE}?pageNo=${page}&size=${pageSize}`,
            {
              from:
                rangedate.length === 0
                  ? moment(response?.data.data.endDate).format('YYYY-MM-DD')
                  : moment(rangedate[0]).format('YYYY-MM-DD'),
              to:
                rangedate.length === 0
                  ? moment(response?.data.data.endDate)
                      .add(1, 'day')
                      .format('YYYY-MM-DD')
                  : moment(rangedate[1]).add(1, 'day').format('YYYY-MM-DD'),
              match: match1,
            },
            {
              signal: controller.signal,
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-type': 'Application/json',
                Authorization: `Bearer ${Token}`,
              },
            }
          )
          .then((res: AxiosResponse) => {
          
            setLoading(false)
            setTotalRowSize(res?.data?.data?.total)
            setRecordData(res?.data?.data?.data)
          })
          .catch((error) => {
            setLoading(false)
            if (
              get(error, 'response.status', '') === 401 ||
              get(error, 'response.status', '') === 403
            ) {
              localStorage.clear()
              navigate('/login', { replace: true })
            }
            dispatch(
              openSnackbar({
                messege: 'Store Not Found',
                icon: AlertIcons.error,
                title: 'error',
                color: AlertColor.error,
              })
            )
            console.error(error)
          })
      })
      .catch((error) => {
        // handle error
        setLoading(false)
        if (
          get(error, 'response.status', '') === 401 ||
          get(error, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        console.error(error)
      })
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
  }, [search, page, pageSize])

  useEffect(() => {
    const controller = new AbortController()
    let brandMatch
    if (brandID.length === 0) {
      brandMatch = JSON.stringify({
        status: 'true',
      })
    } else {
      brandMatch = JSON.stringify({
        status: 'true',
        _id: brandID,
      })
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${brandMatch}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Brand Details canr be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.GET_CITY_DETAILS
      }?match=${JSON.stringify({
        state: stateValue,
        brandId: brandVal || brandID,
        status: 'true',
      })}`,
      (res: AxiosResponse) => {
        setCityLoading(false)
        const statesArray: any = map(
          res.data.data,
          (values: any) => values
        )?.map((option: any) => option.city)

        function removeDuplicates(arr: any) {
          return arr.filter(
            (item: any, index: any) => arr.indexOf(item) === index
          )
        }

        setCity(removeDuplicates(statesArray))
      },
      (err: AxiosError) => {
        setCityLoading(false)

        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Cant get city details'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.GET_STATE_DETAILS
      }?match=${JSON.stringify({
        status: 'true',
        brandId: brandVal || brandID,
      })}`,
      (res: AxiosResponse) => {
        setStateLoading(false)
        const statesArray: any = map(
          res.data.data,
          (values: any) => values
        )?.map((option: any) => option.state)

        function removeDuplicates(arr: any) {
          return arr.filter(
            (item: any, index: any) => arr.indexOf(item) === index
          )
        }

        setStateData(removeDuplicates(statesArray))
      },
      (err: AxiosError) => {
        setStateLoading(false)

        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Cant get State details'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    let storeMatch

    if (brandVal !== null) {
      storeMatch = JSON.stringify({ brandId: brandVal, status: true })
    } else if (brandID && roleProvided === 'admin manager') {
      storeMatch = JSON.stringify({
        brandId: brandID?.[0],
        status: true,
        storeId: storeCode,
      })
    } else if (brandID && brandVal === null) {
      storeMatch = JSON.stringify({ brandId: brandID?.[0], status: true })
    } else {
      storeMatch = JSON.stringify({ status: true })
    }

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_STORE}?match=${storeMatch}`,
      (res: AxiosResponse) => {
        setState(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Store Details Cant be Fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandVal, stateValue])
  useEffect(() => {
    let dateMatch
    const controller = new AbortController()
    if (brandVal === null) {
      if (roleProvided === 'admin') {
        dateMatch = JSON.stringify({ brandId: brandID })
      } else if (roleProvided === 'CRM manager') {
        dateMatch = JSON.stringify({ brandId: { $in: brandID } })
      } else if (roleProvided === 'brand manager') {
        dateMatch = JSON.stringify({ brandId: brandID })
      } else if (roleProvided === 'super admin') {
        dateMatch = JSON.stringify({})
      } else if (roleProvided === 'user') {
        dateMatch = JSON.stringify({ brandId: brandID, storeId: storeCode })
      } else if (roleProvided === 'admin manager') {
        dateMatch = JSON.stringify({ brandId: brandID, storeId: storeCode })
      }
    } else if (brandVal !== null) {
      dateMatch = JSON.stringify({ brandId: brandVal })
    }

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_AVAILABLE_DATES}?match=${dateMatch}`,
      (res: AxiosResponse) => {
        setRangeDate([])
        if (Object.keys(res.data.data).length === 0) {
          setFromDate(null)
          setToDate(null)
          setRangeDate([new Date(), new Date()])
        } else {
          setFromDate(moment(res.data.data.startDate).format('YYYY-MM-DD'))
          setToDate(moment(res.data.data.endDate).format('YYYY-MM-DD'))
          setEndDate(moment(res.data.data.endDate).format('YYYY-MM-DD'))
          setRangeDate([
            dayjs(res.data.data.endDate).toDate(),
            dayjs(res.data.data.endDate).toDate(),
          ])
        }
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Cant Get Available Dates Details'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
  }, [brandVal])

  const onDownload = () => {
    setExportLoading(true)
    let match
    if (stateValue === null && brandVal === null && storeVal === null) {
      if (roleProvided === 'super admin') {
        match = {}
      } else if (roleProvided === 'admin' && brandID) {
        match = { brandId: brandID }
      } else if (roleProvided === 'CRM manager' && brandID) {
        match = { brandId: brandID }
      } else if (roleProvided === 'brand manager' && brandID) {
        match = { brandId: brandID }
      } else if (roleProvided === 'user' && brandID) {
        match = { brandId: brandID, storeId: storeCode }
      } else if (roleProvided === 'admin manager' && brandID) {
        match = { brandId: brandID, storeId: storeCode }
      }
    } else if (roleProvided === 'admin' && brandID) {
      match = {
        brandId: brandID,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'brand manager' && brandID) {
      match = {
        brandId: brandID,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'admin manager' && brandID) {
      match = {
        brandId: brandID,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else {
      match = {
        brandId: brandVal,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    }

    const clean = (obj: any) => {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
      return obj
    }
    const mat = clean(match)

    const match1 = JSON.stringify(mat)
    const fromMatch =
      rangedate.length === 0
        ? moment(toDate).format('YYYY-MM-DD')
        : moment(rangedate[0]).format('YYYY-MM-DD')
    const toMatch =
      rangedate.length === 0
        ? moment(toDate).add(1, 'day').format('YYYY-MM-DD')
        : moment(rangedate[1]).add(1, 'day').format('YYYY-MM-DD')
    const path = `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.EXPORT_GMB_INSIGHTS}`
    axios({
      url: path,
      headers: {
        Authorization: `Bearer ${Token}`,
      },

      method: 'post',
      responseType: 'blob', // important
      data: {
        from: fromMatch,
        to: toMatch,
        match: match1,
      },
    })
      .then((res) => {
        setExportLoading(false)
        FileDownload(res.data, `Close_Bi( ${fromMatch}_${toMatch}).xlsx`)
      })
      .catch((error) => {
        setExportLoading(false)
        dispatch(
          openSnackbar({
            messege: get(
              error,
              'response.data.message',
              'Failed To Download File'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        if (
          get(error, 'response.status', '') === 401 ||
          get(error, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        console.error('download file error', error)
      })
  }

  useEffect(() => {
    let rowDataSize

    if (totalRowSize >= pageSize) {
      rowDataSize = totalRowSize / pageSize

      if (rowDataSize - Math.floor(rowDataSize) !== 0) {
        setPageNo(Math.ceil(rowDataSize))
      } else {
        setPageNo(rowDataSize)
      }
    } else {
      rowDataSize = Math.ceil(totalRowSize / pageSize)
      setPageNo(rowDataSize)
    }
  }, [pageSize, totalRowSize, recordData])

  // Get Graph Api's

  useEffect(() => {
    setSearchLoading(true)
    let match1
    const controller = new AbortController()
    if (stateValue === null && brandVal === null && storeVal === null) {
      if (roleProvided === 'super admin') {
        match1 = {}
      } else if (roleProvided === 'admin' && brandID) {
        match1 = { brandId: brandID }
      } else if (roleProvided === 'CRM manager' && brandID) {
        match1 = { brandId: brandID }
      } else if (roleProvided === 'brand manager' && brandID) {
        match1 = { brandId: brandID }
      } else if (roleProvided === 'user' && brandID) {
        match1 = { brandId: brandID, storeId: storeCode }
      } else if (roleProvided === 'admin manager' && brandID) {
        match1 = { brandId: brandID, storeId: storeCode }
      }
    } else if (roleProvided === 'admin' && brandID) {
      match1 = {
        brandId: brandID,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'admin manager' && brandID) {
      match1 = {
        brandId: brandID,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'brand manager' && brandID) {
      match1 = {
        brandId: brandID,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'CRM manager' && brandID) {
      match1 = {
        brandId: brandVal,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else {
      match1 = {
        brandId: brandVal,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    }

    const clean = (obj: any) => {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
      return obj
    }
    const mat = clean(match1)

    const match = JSON.stringify(mat)

    API_SEVICES.PostObjRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_TOTAL_VIEWS}`,
      (res: AxiosResponse) => {
        setSearchLoading(false)
        setTotalViews(_.get(res, 'data.data', [])[0])
      },
      (err: AxiosError) => {
        setSearchLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        console.error(err)
      },
      {
        date: toDate,
        match: match,
        type: 'googleSearchMobile',
      },
      controller.signal
    )

    API_SEVICES.PostObjRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_TOTAL_VIEWS}`,
      (res: AxiosResponse) => {
        setSearchLoading(false)
        setSearchViews(_.get(res, 'data.data', [])[0])
      },
      (err: AxiosError) => {
        setSearchLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        console.error(err)
      },
      {
        date: toDate,
        match: match,
        type: 'googleSearchDesktop',
      },
      controller.signal
    )
    API_SEVICES.PostObjRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_TOTAL_VIEWS}`,
      (res: AxiosResponse) => {
        setSearchLoading(false)
        setMapsViews(_.get(res, 'data.data', [])[0])
      },
      (err: AxiosError) => {
        setSearchLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        console.error(err)
      },
      {
        date: toDate,
        match: match,
        type: 'googleMapsMobile',
      },
      controller.signal
    )
    API_SEVICES.PostObjRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_TOTAL_VIEWS}`,
      (res: AxiosResponse) => {
        setSearchLoading(false)
        setTotalActions(_.get(res, 'data.data', [])[0])
      },
      (err: AxiosError) => {
        setSearchLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        console.error(err)
      },
      {
        date: toDate,
        match: match,
        type: 'googleMapsDesktop',
      },
      controller.signal
    )
    API_SEVICES.PostObjRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_TOTAL_VIEWS}`,
      (res: AxiosResponse) => {
        setSearchLoading(false)
        setWebsiteAction(_.get(res, 'data.data', [])[0])
      },
      (err: AxiosError) => {
        setSearchLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        console.error(err)
      },
      {
        date: toDate,
        match: match,
        type: 'calls',
      },
      controller.signal
    )
    API_SEVICES.PostObjRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_TOTAL_VIEWS}`,
      (res: AxiosResponse) => {
        setSearchLoading(false)
        setDireactionSearch(_.get(res, 'data.data', [])[0])
      },
      (err: AxiosError) => {
        setSearchLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        console.error(err)
      },
      {
        date: toDate,
        match: match,
        type: 'directions',
      },
      controller.signal
    )

    setSearch(false)
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
  }, [search, endDate])

  const selectDate = moment(rangedate[1]).format('YYYY-MM-DD')
  const label1 = [
    '7 Day',
    `${moment().format('MMMM')}`?.slice(0, 3),
    `${moment().subtract(1, 'months').format('MMMM')}`?.slice(0, 3),
    '3 month',
    '6 month',
  ]
  const labels = [
    '7 Day',
    `${moment().format('MMMM')}`,
    `${moment().subtract(1, 'months').format('MMMM')}`,
    '3 month',
    '6 month',
  ]
  const colDef: CustomColum[] = useMemo(
    () => [
      {
        accessor: 'storeCode',
        textAlignment: 'center',
        width: 10,
        Header: () => (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            Store Code
          </div>
        ),
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.storeCode}
          </p>
        ),
      },
      {
        accessor: 'businessName',
        Header: 'Business Name',
        textAlignment: 'center',
        minWidth: 200,

        Cell: ({ ...value }: any) => (
          <p>
            {value.row.original.businessName}
            <div style={{ fontSize: '13px', color: 'grey' }}>
              {value.row.original.address}
            </div>
          </p>
        ),
      },
      {
        accessor: 'date',
        Header: () => (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            Date
          </div>
        ),
        minWidth: 150,
        width: 150,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.date.substring(0, 10)}
          </p>
        ),
      },
      {
        accessor: 'googleSearchMobile',
        Header: 'Google Search Mobile',
        textAlignment: 'center',
        minWidth: 110,
        width: 110,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.googleSearchMobile}
          </p>
        ),
      },
      {
        accessor: 'googleSearchDesktop',
        Header: 'Google Search Desktop',
        textAlignment: 'center',
        minWidth: 110,
        width: 110,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.googleSearchDesktop
}
          </p>
        ),
      },
      {
        accessor: 'googleMapsMobile',
        Header: 'Google Maps Mobile',
        textAlignment: 'center',
        minWidth: 110,
        width: 110,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.googleMapsMobile
}
          </p>
        ),
      },
      {
        accessor: 'googleMapsDesktop',
        Header: 'Google Maps Desktop',
        textAlignment: 'center',
        minWidth: 110,
        width: 110,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.googleMapsDesktop
}
          </p>
        ),
      },
      {
        accessor: 'directions',
        Header: 'Directions',
        textAlignment: 'center',
        minWidth: 150,
        width: 150,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.directions
}
          </p>
        ),
      },
      {
        accessor: 'calls',
        Header: 'Calls',
        textAlignment: 'center',
        minWidth: 150,
        width: 150,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.calls}
          </p>
        ),
      },
      {
        accessor: 'websiteclicks',
        Header: 'Website Clicks',
        textAlignment: 'center',
        minWidth: 150,
        width: 150,
        Cell: ({ ...value }: any) => (
          <p className={classes.tableBodyStyle}>
            {value.row.original.websiteclicks
}
          </p>
        ),
      },
    ],
    []
  )

  useEffect(() => {
    if (filterRef.current != null) {
      const element = paperRef.current?.getClientRects()
      setTableHeight(window.innerHeight - filterRef.current.clientHeight - 110)
    }
    if (totalRowSize > pageSize) {
      const from = (page - 1) * pageSize
      const to = from + pageSize
      setCountForm(from + 1)
      setCountTo(to)
    } else {
      setCountForm(1)
      setCountTo(totalRowSize)
    }
  }, [page, pageSize, recordData, theme, totalRowSize])

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, name, description, cityName, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        {' '}
        <Group noWrap>
          {' '}
          <div>
            <Text size="sm">{label}</Text>{' '}
            {description || cityName ? (
              <Text size="xs" opacity={0.65}>
                {description}-{cityName}{' '}
              </Text>
            ) : null}{' '}
          </div>{' '}
        </Group>{' '}
      </div>
    )
  )
  SelectItem.displayName = 'SelectItem'
  const storeDetails = state.map((item: any) => ({
    ...item,
    value: item.storeId,
    label: item.storeName,
    description: item.locality,
    cityName: item.city,
  }))
  const brandDetails = brandData.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.name,
  }))
  const stateDetail = stateData?.map((item: any) => ({
    ...item,
    value: item,
    label: item,
  }))
  const cityDetail = city.map((item: any) => ({
    ...item,
    value: item,
    label: item,
  }))

  return (
    <div>
      <Grid>
        <Grid.Col xs={12} ref={filterRef}>
          <Grid>
            <Grid.Col xl={9} lg={9} xs={12} sm={8} md={8}>
              <Text
                weight={100}
                style={{
                  // marginTop: ' 10px',
                  fontSize: '22px',
                }}
              >
                Action
              </Text>
            </Grid.Col>
            <Grid.Col xl={1.5} lg={1.5} xs={12} sm={2} md={2}>
              <Popover
                width={350}
                position="bottom"
                withArrow
                shadow="xl"
                styles={() => ({
                  dropdown: {
                    backgroundColor: '#f0f6f7',
                  },
                })}
              >
                <Popover.Target>
                  <Button
                    sx={{
                      // marginTop: '15px',
                      width: '100%',
                      // marginLeft: '100px',
                      backgroundColor: 'rgb(160, 213, 239)',
                      '&[data-disabled]': { opacity: 0.4 },
                      '&:hover': {
                        backgroundColor: 'rgb(160, 213, 239)',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      },
                    }}
                    disabled={recordData.length === 0}
                  >
                    <IconFilter color="black" />
                    <span style={{ marginLeft: '6px', color: 'black' }}>
                      Filter
                    </span>
                  </Button>
                </Popover.Target>
                <Popover.Dropdown>
                  <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    {' '}
                    {roleProvided === 'super admin' ||
                    (brandID !== null && roleProvided === 'CRM manager') ? (
                      <Select
                        label="Select Brand"
                        placeholder="Select Brand"
                        itemComponent={SelectItem}
                        data={brandDetails}
                        limit={brandDetails.length}
                        disabled={brandDetails.length === 0}
                        maxDropdownHeight={200}
                        value={brandVal}
                        nothingFound="No Data Found"
                        clearable
                        onChange={(value: any) => {
                          setStateLoading(true)
                          setPage(1)
                          setPageNo(1)
                          setFromDate(null)
                          setRangeDate([])
                          setBrandVal(value)
                          setStateValue(null)
                          setStoreVal(null)
                          setCityValue(null)
                        }}
                        styles={() => ({
                          item: {
                            // applies styles to selected item
                            '&[data-selected]': {
                              '&, &:hover': {
                                backgroundColor:
                                  theme.colorScheme === 'dark'
                                    ? theme.colors.teal[9]
                                    : theme.colors.teal[1],
                                color:
                                  theme.colorScheme === 'dark'
                                    ? theme.white
                                    : theme.colors.teal[9],
                              },
                            }, // applies styles to hovered item (with mouse or keyboard)

                            '&[data-hovered]': {},
                          },
                        })}
                      />
                    ) : null}
                  </Grid.Col>
                  <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <DateRangePicker
                      label="Select Date Range"
                      placeholder="Pick dates range"
                      minDate={moment(fromDate).toDate()}
                      allowSingleDateInRange={true}
                      maxDate={moment(toDate).toDate()}
                      disabled={fromDate === null}
                      value={
                        rangedate.length === 0
                          ? [moment(toDate).toDate(), moment(toDate).toDate()]
                          : rangedate
                      }
                      onChange={(i: any) => {
                        setPage(1)
                        if (i[0] && i[1]) {
                          setRangeDate(i)
                          setPage(1)
                          setPageNo(1)
                        }
                      }}
                      dayClassName={(date, modifiers) =>
                        cx({
                          [classes.outside]: modifiers.outside,
                        })
                      }
                    />
                  </Grid.Col>
                  {roleProvided !== 'user' &&
                  roleProvided !== 'admin manager' ? (
                    <>
                      <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Text fz="sm" style={{ fontWeight: 500 }}>
                          Select State{' '}
                          {stateLoading ? (
                            <span style={{ margin: 5 }}>
                              <Loader size={15} />
                            </span>
                          ) : (
                            ''
                          )}
                        </Text>
                        <Select
                          // label="Select State"
                          placeholder="Select state"
                          clearable
                          searchable
                          itemComponent={SelectItem}
                          data={stateDetail}
                          limit={stateDetail.length}
                          disabled={
                            stateDetail.length === 0 || fromDate === null
                          }
                          maxDropdownHeight={200}
                          nothingFound="No Data Found"
                          value={stateValue}
                          onChange={(value: any) => {
                            setCityLoading(true)
                            setPage(1)
                            setPageNo(1)
                            setStateValue(value)
                            setStoreVal(null)
                            setCityValue(null)
                          }}
                          styles={() => ({
                            item: {
                              // applies styles to selected item
                              '&[data-selected]': {
                                '&, &:hover': {
                                  backgroundColor:
                                    theme.colorScheme === 'dark'
                                      ? theme.colors.teal[9]
                                      : theme.colors.teal[1],
                                  color:
                                    theme.colorScheme === 'dark'
                                      ? theme.white
                                      : theme.colors.teal[9],
                                },
                              }, // applies styles to hovered item (with mouse or keyboard)

                              '&[data-hovered]': {},
                            },
                          })}
                        />
                      </Grid.Col>

                      <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Text fz="sm" style={{ fontWeight: 500 }}>
                          Select City{' '}
                          {cityLoading ? (
                            <span style={{ margin: 5 }}>
                              <Loader size={15} />
                            </span>
                          ) : (
                            ''
                          )}
                        </Text>
                        <Select
                          // label="Select City"
                          placeholder="Select city"
                          clearable
                          searchable
                          itemComponent={SelectItem}
                          data={cityDetail}
                          limit={cityDetail.length}
                          disabled={cityDetail.length === 0}
                          maxDropdownHeight={200}
                          nothingFound="No Data Found"
                          value={cityValue}
                          onChange={(value: any) => {
                            setPage(1)
                            setPageNo(1)
                            setCityValue(value)
                          }}
                          styles={() => ({
                            item: {
                              // applies styles to selected item
                              '&[data-selected]': {
                                '&, &:hover': {
                                  backgroundColor:
                                    theme.colorScheme === 'dark'
                                      ? theme.colors.teal[9]
                                      : theme.colors.teal[1],
                                  color:
                                    theme.colorScheme === 'dark'
                                      ? theme.white
                                      : theme.colors.teal[9],
                                },
                              }, // applies styles to hovered item (with mouse or keyboard)

                              '&[data-hovered]': {},
                            },
                          })}
                        />
                      </Grid.Col>
                    </>
                  ) : (
                    ''
                  )}
                  <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    {roleProvided === 'super admin' ||
                    (brandID !== null && roleProvided === 'admin') ||
                    (brandID !== null && roleProvided === 'CRM manager') ||
                    (brandID !== null && roleProvided === 'brand manager') ||
                    (brandID !== null && roleProvided === 'admin manager') ? (
                      <Select
                        label="Select Store"
                        placeholder="Select store"
                        clearable
                        searchable
                        itemComponent={SelectItem}
                        data={storeDetails}
                        limit={storeDetails.length}
                        disabled={fromDate === null}
                        maxDropdownHeight={300}
                        nothingFound="No Data Found"
                        value={storeVal}
                        onChange={(value: any) => {
                          setPage(1)
                          setPageNo(1)
                          setStoreVal(value)
                          setStateValue(null)
                          setCityValue(null)
                        }}
                        styles={() => ({
                          item: {
                            // applies styles to selected item
                            '&[data-selected]': {
                              '&, &:hover': {
                                backgroundColor:
                                  theme.colorScheme === 'dark'
                                    ? theme.colors.teal[9]
                                    : theme.colors.teal[1],
                                color:
                                  theme.colorScheme === 'dark'
                                    ? theme.white
                                    : theme.colors.teal[9],
                              },
                            },

                            // applies styles to hovered item (with mouse or keyboard)
                            '&[data-hovered]': {},
                          },
                        })}
                      />
                    ) : null}
                  </Grid.Col>
                  <Grid.Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ display: 'flex', justifyContent: 'right' }}
                  >
                    <Button
                      sx={{
                        marginTop: '5px',
                        backgroundColor: 'rgb(160, 213, 239)',
                        color: '#000',
                        '&[data-disabled]': { opacity: 0.4 },
                        '&:hover': {
                          backgroundColor: 'rgb(160, 213, 239)',
                          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        },
                      }}
                      onClick={() => {
                        setSearch(true)
                        setRecordData([])
                        setTotalRowSize(0)
                        setSearchViews([])
                        setTotalViews([])
                        setMapsViews([])
                        setTotalActions([])
                        setWebsiteAction([])
                        setDireactionSearch([])
                      }}
                      loading={searchLoading}
                      disabled={fromDate === null}
                    >
                      <Text color="black">Apply</Text>
                    </Button>
                  </Grid.Col>
                </Popover.Dropdown>
              </Popover>
            </Grid.Col>

            <Grid.Col xl={1.5} lg={1.5} xs={3} sm={2} md={2}>
              {' '}
              <Button
                sx={{
                  // marginTop: '15px',
                  width: '100%',
                  // marginLeft: '60px',
                  backgroundColor: 'rgb(160, 213, 239)',
                  '&[data-disabled]': { opacity: 0.4 },
                  '&:hover': {
                    backgroundColor: 'rgb(160, 213, 239)',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                  },
                }}
                onClick={() => {
                  onDownload()
                }}
                disabled={loading}
                loading={exportLoading}
              >
                {!exportLoading ? <IconTableExport color="black" /> : ''}
                <Text color="black">Export</Text>
              </Button>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
      <Grid sx={{ paddingTop: 10 }}>
        <Grid.Col xl={4} lg={4} md={4} xs={12} sm={12}>
          {totalViews?.length === undefined || null ? (
            <Paper
              shadow="xs"
              p="md"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: '10px',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              }}
            >
              <div>
                <BarChat
                  data={{
                    labels: label1,
                    datasets: [
                      {
                        data: _.values(totalViews)?.slice(1),
                        barPercentage: 0.4,
                        backgroundColor: [
                          '#BDB7B7',
                          '#EDD069',
                          '#EDBEBF',
                          '#92F194',
                          '#A0D5EF',
                        ],
                      },
                    ],
                  }}
                  label="Google Search Mobile"
                  title="Google Search Mobile"
                />
              </div>
              <div>
                <ActionCard
                  data={totalViews || [0, 0, 0, 0, 0, 0]}
                  endDate={selectDate}
                  label={labels}
                />
              </div>
            </Paper>
          ) : (
            <>
              {' '}
              <Card shadow="sm" p="lg" radius="md" withBorder>
                <Skeleton height={20} radius="xl" />
                <Skeleton height={20} mt={8} radius="xl" />
                <Skeleton height={160} mt={8} />
              </Card>
            </>
          )}
        </Grid.Col>
        <Grid.Col xl={4} lg={4} md={4} xs={12} sm={12}>
          {getSearchViews?.length === undefined || null ? (
            <Paper
              shadow="xs"
              p="md"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: '10px',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              }}
            >
              <div>
                <BarChat
                  data={{
                    labels: label1,
                    datasets: [
                      {
                        data: _.values(getSearchViews)?.slice(1),
                        barPercentage: 0.4,
                        backgroundColor: [
                          '#BDB7B7',
                          '#EDD069',
                          '#EDBEBF',
                          '#92F194',
                          '#A0D5EF',
                        ],
                      },
                    ],
                  }}
                  label="Google Search Desktop"
                  title="Google Search Desktop"
                />
              </div>
              <div>
                <ActionCard
                  data={getSearchViews || [0, 0, 0, 0, 0, 0]}
                  endDate={selectDate}
                  label={labels}
                />
              </div>
            </Paper>
          ) : (
            <Card shadow="sm" p="lg" radius="md" withBorder>
              <Skeleton height={20} radius="xl" />
              <Skeleton height={20} mt={8} radius="xl" />
              <Skeleton height={160} mt={8} />
            </Card>
          )}
        </Grid.Col>
        <Grid.Col xl={4} lg={4} md={4} xs={12} sm={12}>
          {getMapsViews?.length === undefined || null ? (
            <Paper
              shadow="xs"
              p="md"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: '10px',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              }}
            >
              <div>
                <BarChat
                  data={{
                    labels: label1,
                    datasets: [
                      {
                        data: _.values(getMapsViews)?.slice(1),
                        barPercentage: 0.4,
                        backgroundColor: [
                          '#BDB7B7',
                          '#EDD069',
                          '#EDBEBF',
                          '#92F194',
                          '#A0D5EF',
                        ],
                      },
                    ],
                  }}
                  label="Google Maps Mobile"
                  title="Google Maps Mobile"
                />
              </div>
              <div>
                <ActionCard
                  data={getMapsViews || [0, 0, 0, 0, 0, 0]}
                  endDate={selectDate}
                  label={labels}
                />
              </div>
            </Paper>
          ) : (
            <Card shadow="sm" p="lg" radius="md" withBorder>
              <Skeleton height={20} radius="xl" />
              <Skeleton height={20} mt={8} radius="xl" />
              <Skeleton height={160} mt={8} />
            </Card>
          )}
        </Grid.Col>

        <Grid.Col xl={4} lg={4} md={4} xs={12} sm={12}>
          {getTotalActions?.length === undefined || null ? (
            <Paper
              shadow="xs"
              p="md"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: '10px',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              }}
            >
              <div>
                <BarChat
                  data={{
                    labels: label1,
                    datasets: [
                      {
                        data: _.values(getTotalActions)?.slice(1),
                        barPercentage: 0.4,
                        backgroundColor: [
                          '#BDB7B7',
                          '#EDD069',
                          '#EDBEBF',
                          '#92F194',
                          '#A0D5EF',
                        ],
                      },
                    ],
                  }}
                  label="Google Maps Desktop"
                  title="Google Maps Desktop"
                />
              </div>
              <div>
                <ActionCard
                  data={getTotalActions || [0, 0, 0, 0, 0, 0]}
                  endDate={selectDate}
                  label={labels}
                />
              </div>
            </Paper>
          ) : (
            <Card shadow="sm" p="lg" radius="md" withBorder>
              <Skeleton height={20} radius="xl" />
              <Skeleton height={20} mt={8} radius="xl" />
              <Skeleton height={160} mt={8} />
            </Card>
          )}
        </Grid.Col>

        <Grid.Col xl={4} lg={4} md={4} xs={12} sm={12}>
          {getWebSiteActions?.length === undefined || null ? (
            <Paper
              shadow="xs"
              p="md"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: '10px',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              }}
            >
              <div>
                <BarChat
                  data={{
                    labels: label1,
                    datasets: [
                      {
                        data: _.values(getWebSiteActions)?.slice(1),
                        barPercentage: 0.4,
                        backgroundColor: [
                          '#BDB7B7',
                          '#EDD069',
                          '#EDBEBF',
                          '#92F194',
                          '#A0D5EF',
                        ],
                      },
                    ],
                  }}
                  label="Calls"
                  title="Calls"
                />
              </div>
              <div>
                <ActionCard
                  data={getWebSiteActions || [0, 0, 0, 0, 0, 0]}
                  endDate={selectDate}
                  label={labels}
                />
              </div>
            </Paper>
          ) : (
            <Card shadow="sm" p="lg" radius="md" withBorder>
              <Skeleton height={20} radius="xl" />
              <Skeleton height={20} mt={8} radius="xl" />
              <Skeleton height={160} mt={8} />
            </Card>
          )}
        </Grid.Col>

        <Grid.Col xl={4} lg={4} md={4} xs={12} sm={12}>
          {getDiractionSearch?.length === undefined || null ? (
            <Paper
              shadow="xs"
              p="md"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: '10px',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              }}
            >
              <div>
                <BarChat
                  data={{
                    labels: label1,
                    datasets: [
                      {
                        data: _.values(getDiractionSearch)?.slice(1),
                        barPercentage: 0.4,
                        backgroundColor: [
                          '#BDB7B7',
                          '#EDD069',
                          '#EDBEBF',
                          '#92F194',
                          '#A0D5EF',
                        ],
                      },
                    ],
                  }}
                  label="Directions"
                  title="Directions"
                />
              </div>
              <div>
                <ActionCard
                  data={getDiractionSearch || [0, 0, 0, 0, 0, 0]}
                  endDate={selectDate}
                  label={labels}
                />
              </div>
            </Paper>
          ) : (
            <Card shadow="sm" p="lg" radius="md" withBorder>
              <Skeleton height={20} radius="xl" />
              <Skeleton height={20} mt={8} radius="xl" />
              <Skeleton height={160} mt={8} />
            </Card>
          )}
        </Grid.Col>

        <Paper
          shadow="xs"
          p="md"
          sx={{
            width: '100%',
            height: 'auto',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            paddingTop: 10,
          }}
        >
          <Text style={{ fontSize: '20px' }}>Actions</Text>
          <Text style={{ fontSize: '15px', marginTop: '10px' }}>
            Total Records Found For This Search : {totalRowSize}
          </Text>
          <Grid.Col xs={12}>
            <CustomTable
              pageFrom={countFrom}
              pageTo={countTo}
              total={pageNo}
              gridApi={(instance) => {}}
              totalRecords={!loading ? totalRowSize : 1}
              checkboxSelection={false}
              recordsPerPage={pageSize}
              page={page}
              height={tableHeight}
              onPageChange={(p: any) => setPage(p)}
              recordsPerPageOptions={PAGESIZES}
              onRecordsPerPageChange={setPageSize}
              loading={!!loading}
              rowData={!loading ? recordData : []}
              colDef={colDef}
              positionGiven={true}
            />
          </Grid.Col>
        </Paper>
      </Grid>
    </div>
  )
}
