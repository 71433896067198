/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/aria-unsupported-elements */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */
import {
  Grid,
  Text,
  Group,
  SelectItemProps,
  createStyles,
  Button,
  useMantineTheme,
  MantineColor,
  Select,
} from '@mantine/core'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { DateRangePicker } from '@mantine/dates'
import { AxiosError, AxiosResponse } from 'axios'
import _, { get } from 'lodash'
import { forwardRef, useEffect, useState } from 'react'
import { IconTrash } from '@tabler/icons'
import moment from 'moment'
import { useAppSelector } from '../../../hooks'
import { API_SEVICES } from '../../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  BRANDID,
} from '../../../constants'
import { openSnackbar } from '../../../redux/snackbar'
import { useAppDispatch } from '../../../redux/store'

interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  description: string
  cityName: string
}

interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}

const useStyles = createStyles((theme) => ({
  outside: {
    opacity: 0,
  },

  weekend: {
    color: `${theme.colors.blue[6]} !important`,
  },

  selected: {
    color: `${theme.white} !important`,
  },
  tableBodyStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
interface IProps {
  onClose: () => void
  fetchData: () => void
}
export default function GMBDelete(props: IProps) {
  const theme = useMantineTheme()
  const { onClose, fetchData } = props
  const [loading, setLoading] = useState(false)
  const [brandData, setBrandData] = useState([])
  const [fromDate, setFromDate] = useState<any>(null)
  const [todate, setToDate] = useState<any>(new Date())
  const [rangedate, setRangeDate] = useState<any>([])
  const [deleteBrand, setDeleteBrand] = useState<any>(null)
  const { classes, cx } = useStyles()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const role = useAppSelector((stat) => stat.role)
  const brandID = role.brandId || BRANDID || null

  useEffect(() => {
    let dateMatch

    if (deleteBrand !== null) {
      dateMatch = JSON.stringify({ brandId: deleteBrand })

      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_AVAILABLE_DATES}?match=${dateMatch}`,
        (res: AxiosResponse) => {
          if (Object.keys(res.data.data).length === 0) {
            setFromDate(null)
            setToDate(null)
            setRangeDate([new Date(), new Date()])
          } else {
            setFromDate(moment(res.data.data.startDate).format('YYYY-MM-DD'))
            setToDate(moment(res.data.data.endDate).format('YYYY-MM-DD'))
            setRangeDate([
              dayjs(res.data.data.endDate).toDate(),
              dayjs(res.data.data.endDate).toDate(),
            ])
          }
        },
        (err: AxiosError) => {
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Available Date Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          console.error(err)
        }
      )
    }
  }, [deleteBrand])

  useEffect(() => {
    let match
    if (brandID.length === 0) {
      match = JSON.stringify({ status: true })
    } else {
      match = JSON.stringify({ _id: brandID, status: true })
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${match}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Brand Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const brandDetails = brandData.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.name,
  }))

  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, name, description, cityName, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            {description || cityName ? (
              <Text size="xs" opacity={0.65}>
                {description}-{cityName}
              </Text>
            ) : null}
          </div>
        </Group>
      </div>
    )
  )
  SelectItem.displayName = 'SelectItem'

  function handleSubmit(event: any) {
    event.preventDefault()

    const from =
      rangedate.length === 0
        ? moment(fromDate).format('YYYY-MM-DD')
        : moment(rangedate[0]).format('YYYY-MM-DD')
    const to =
      rangedate.length === 0
        ? moment(todate).add(1, 'day').format('YYYY-MM-DD')
        : moment(rangedate[1]).add(1, 'day').format('YYYY-MM-DD')

    setLoading(true)
    API_SEVICES.DeleteRequestObj(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.DELETE_GMB_INSIGHTS}`,
      (res: AxiosResponse) => {
        setDeleteBrand(null)
        onClose()
        setLoading(false)
        fetchData()
        dispatch(
          openSnackbar({
            messege: 'GMB insights Deleted Sucessfully',
            icon: AlertIcons.success,
            title: 'Success',
            color: AlertColor.success,
          })
        )
      },
      (err: AxiosError) => {
        setLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'GMB insights cant be Deleted'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      },
      {
        brand: `${deleteBrand}`,
        from: `${from}`,
        to: `${to}`,
      }
    )
  }

  return (
    <div style={{ zIndex: 8 }}>
      <Grid>
        <Grid.Col xl={5}>
          <Text>Select Brand</Text>
          <Select
            placeholder="Select Brand"
            itemComponent={SelectItem}
            data={brandDetails}
            maxDropdownHeight={400}
            value={deleteBrand}
            nothingFound="No Data Found"
            clearable
            onChange={(value: any) => {
              setDeleteBrand(value)
            }}
            styles={() => ({
              item: {
                // applies styles to selected item
                '&[data-selected]': {
                  '&, &:hover': {
                    backgroundColor:
                      theme.colorScheme === 'dark'
                        ? theme.colors.teal[9]
                        : theme.colors.teal[1],
                    color:
                      theme.colorScheme === 'dark'
                        ? theme.white
                        : theme.colors.teal[9],
                  },
                }, // applies styles to hovered item (with mouse or keyboard)

                '&[data-hovered]': {},
              },
            })}
          />
        </Grid.Col>
        <Grid.Col xl={5}>
          <DateRangePicker
            label="Select Date Range"
            placeholder="Pick dates range"
            minDate={moment(fromDate).toDate()}
            allowSingleDateInRange={true}
            maxDate={moment(todate).toDate()}
            disabled={fromDate === null}
            value={
              rangedate.length === 0
                ? [moment(todate).toDate(), moment(todate).toDate()]
                : rangedate
            }
            onChange={(i: any) => {
              if (i[0] && i[1]) {
                setRangeDate(i)
              }
            }}
            dayClassName={(date, modifiers) =>
              cx({
                [classes.outside]: modifiers.outside,
              })
            }
          />
        </Grid.Col>

        <Grid.Col sx={{ textAlign: 'center' }}>
          <Button
            rightIcon={<IconTrash size={17} color="black" />}
            onClick={(e: any) => handleSubmit(e)}
            loading={loading}
            disabled={fromDate === null}
            sx={{
              backgroundColor: 'rgb(160, 213, 239)',
              color: 'black',
              '&[data-disabled]': { opacity: 0.7 },
              '&:hover': {
                backgroundColor: 'rgb(160, 213, 239)',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              },
            }}
          >
            Delete
          </Button>
        </Grid.Col>
      </Grid>
    </div>
  )
}
