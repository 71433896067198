/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import {
  Box,
  Paper,
  Title,
  Grid,
  Button,
  Text,
  Group,
  Select,
  createStyles,
  SelectItemProps,
  MantineColor,
  Popover,
  Autocomplete,
  TextInput,
} from '@mantine/core'
import { IconArrowDownCircle, IconFilter } from '@tabler/icons'
import Papa from 'papaparse'
import { AxiosError, AxiosResponse } from 'axios'
import _, { get } from 'lodash'
import { DataTableColumn } from 'mantine-datatable'
import { useEffect, useMemo, useState, forwardRef, useRef } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { DateRangePicker } from '@mantine/dates'
import DataGrid from '../../components/DataGrid'
import { API_SEVICES } from '../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
  ROLEPROVIDED,
  BRANDID,
  STOREID,
} from '../../constants'
import CustomRowData from '../../components/CustomPagination'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { openSnackbar } from '../../redux/snackbar'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  description: string
  cityName: string
}
interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}
const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
    fontStyle: 'Helvetica',
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
  tableBodyStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  filter: {
    marginLeft: '115px',
    '@media (max-width: 912px)': {
      marginLeft: '110px',
    },
  },
  import: {
    marginLeft: '52px',
    '@media (max-width: 912px)': {
      marginLeft: '40px',
    },
  },
  csv: {
    marginLeft: '30px',
    '@media (max-width: 912px)': {
      marginLeft: '30px',
    },
  },
  product: {
    marginLeft: '40px',
    '@media (max-width: 912px)': {
      marginLeft: '50px',
    },
  },
}))
export default function Store() {
  // eslint-disable-next-line react/prop-types
  const [searchParams, setSearchParams] = useSearchParams()
  const mode = searchParams.get('mode')
  const id = searchParams.get('id')
  const [getUrlRankingDetails, setGetUrlRankingDetails] = useState([])
  const [modelState, setModel] = useState(false)
  const [uploadSate, setUploadState] = useState(false)
  const [loading, setLoading] = useState(true)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { theme } = useStyles()
  const [recordData, setRecordData] = useState([])
  const [page, setPage] = useState(1)
  const [pageNo, setPageNo] = useState(1)
  const [tableHeight, setTableHeight] = useState(500)
  const [countFrom, setCountForm] = useState<any>()
  const [countTo, setCountTo] = useState<any>()
  const [pageSize, setPageSize] = useState(PAGESIZES[0])
  const [totalRowSize, setTotalRowSize]: any = useState(10)
  const [brandVal, setBrandVal] = useState(null)
  const [open, setOpen] = useState(false)
  const [active] = useState<any>(null)
  const navigate = useNavigate()
  const filterRef = useRef<HTMLInputElement>(null)
  const [storeVal, setStoreVal] = useState<any>(null)
  const [stateValue, setStateValue] = useState<any>(null)
  const [cityValue, setCityValue] = useState<any>(null)
  const [search, setSearch] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  // const [getSlicePaginationState, setGetSlicePaginationData] = useState<any>([])
  const [getdomainURL, setGetDomainURL] = useState()
  const [getDevice, setGetDevice] = useState()

  const [rangedate, setRangeDate] = useState<[Date | null, Date | null]>([
    new Date(),
    new Date(),
  ])

  const dispatch = useAppDispatch()
  const { classes, cx } = useStyles()
  const role = useAppSelector((state) => state.role)
  const brandID = role.brandId || BRANDID || null
  const roleProvided: string = role.role || ROLEPROVIDED
  const storeProvided: string = role.storeId || STOREID

  const handleClose = () => {
    setModel(false)
    setDeleteOpen(false)
    setOpen(false)
    setUploadState(false)
  }

  const fetchData = () => {
    setLoading(true)
    setSearch(true)
  }

  useEffect(() => {
    setLoading(true)
    setSearchLoading(true)
    let match
    if (
      brandVal === null &&
      storeVal === null &&
      cityValue === null &&
      stateValue === null
    ) {
      if (roleProvided === 'admin' && brandID) {
        match = { brandId: brandID }
      } else if (roleProvided === 'admin' && brandID === null) {
        match = { brandId: null, _id: null }
      } else if (roleProvided === 'user') {
        match = { brandId: brandID, _id: storeProvided }
      } else if (roleProvided === 'brand manager') {
        match = { brandId: brandID }
      } else if (roleProvided === 'CRM manager') {
        match = { brandId: brandID }
      } else {
        match = {}
      }
    } else if (roleProvided === 'admin') {
      match = {
        status: active,
        brandId: brandID?.[0],
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'CRM manager') {
      match = {
        status: active,
        brandId: brandVal,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'brand manager') {
      match = {
        status: active,
        brandId: brandID,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else {
      match = {
        status: active,
        brandId: brandVal,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    }

    const clean = (obj: any) => {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
      return obj
    }
    const mat = clean(match)

    const controller = new AbortController()

    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page, pageSize])

  const handleURLCheckFunction = () => {
    const startDate = dayjs(rangedate[0])
    const endDate = dayjs(rangedate[1])
    const formattedStartDate = startDate.format('YYYY-MM-DD')
    const formattedEndDate = endDate.format('YYYY-MM-DD')

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.SEO_RANKING_CHECKER_TOOL
      }?match=${JSON.stringify({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        domainurl: getdomainURL,
        device: getDevice,
      })}`,
      (res: AxiosResponse) => {
        // const indexOfLastItem = page * 10
        // const indexOfFirstItem = indexOfLastItem - 10
        // const currentItems: any = res.data.data?.slice(
        //   indexOfFirstItem,
        //   indexOfLastItem
        // )
        // setGetSlicePaginationData(currentItems)
        console.log('res', res)
        setGetUrlRankingDetails(res.data.data)
        dispatch(
          openSnackbar({
            messege: get(res, 'data.message', 'Ranking Details fetched'),
            icon: AlertIcons.success,
            title: 'success',
            color: AlertColor.success,
          })
        )
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Ranking Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      }
    )
  }

  const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ description, value, image, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text>{value}</Text>
          </div>
        </Group>
      </div>
    )
  )
  AutoCompleteItem.displayName = 'AutoCompleteItem'

  const colDef: DataTableColumn<any>[] = useMemo(
    () => [
      {
        Header: 'URL',
        accessor: 'url',
        textAlignment: 'center',
        width: 1400,
        Cell: ({ ...value }: any) => <p>{value.row.original.url}</p>,
      },
      {
        accessor: 'keyword',
        Header: 'Keyword',
        textAlignment: 'center',
        minWidth: 300,
        Cell: ({ ...value }: any) => <p>{value.row.original.keyword}</p>,
      },
      {
        accessor: 'position',
        Header: 'Position',
        textAlignment: 'center',
        minWidth: 140,
        Cell: ({ ...value }: any) => <p>{value.row.original.position}</p>,
      },
      {
        accessor: 'impressions',
        Header: 'Impressions',
        textAlignment: 'center',
        minWidth: 80,
        Cell: ({ ...value }: any) => <p>{value.row.original.impressions}</p>,
      },
      {
        accessor: 'CTR',
        Header: 'ctr',
        textAlignment: 'center',
        minWidth: 80,
        Cell: ({ ...value }: any) => <p>{value.row.original.ctr}</p>,
      },
      {
        accessor: 'clicks',
        Header: 'Clicks',
        textAlignment: 'center',
        minWidth: 80,
        Cell: ({ ...value }: any) => <p>{value.row.original.clicks}</p>,
      },
    ],
    [roleProvided, setSearchParams]
  )

  useEffect(() => {
    if (filterRef.current != null) {
      setTableHeight(window.innerHeight - filterRef.current.clientHeight - 104)
    }

    if (totalRowSize > pageSize) {
      const from = (page - 1) * pageSize
      const to = from + pageSize
      setCountForm(from + 1)
      setCountTo(to)
    } else {
      setCountForm(1)
      setCountTo(totalRowSize)
    }
    let rowDataSize

    if (totalRowSize >= pageSize) {
      rowDataSize = totalRowSize / pageSize

      if (rowDataSize - Math.floor(rowDataSize) !== 0) {
        setPageNo(Math.ceil(rowDataSize))
      } else {
        setPageNo(rowDataSize)
      }
    } else {
      rowDataSize = Math.ceil(totalRowSize / pageSize)
      setPageNo(rowDataSize)
    }
  }, [page, pageSize, recordData, theme, totalRowSize])

  const downloadCSV = () => {
    const csv = Papa.unparse(getUrlRankingDetails)
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const csvURL = window.URL.createObjectURL(csvData)
    const tempLink = document.createElement('a')
    tempLink.href = csvURL
    tempLink.setAttribute('download', 'data.csv')
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
  }

  // const handlePagination = (pageParam: any) => {
  //   const indexOfLastItem = pageParam * 10
  //   const indexOfFirstItem = indexOfLastItem - 10
  //   const currentItems: any = getUrlRankingDetails?.slice(
  //     indexOfFirstItem,
  //     indexOfLastItem
  //   )
  //   setGetSlicePaginationData(currentItems)
  // }

  return (
    <Box>
      <Paper shadow="xs" p="md">
        <>
          <Grid ref={filterRef}>
            <Grid.Col xs={4} sm={4} md={3.5} lg={2} xl={2}>
              <Title order={3} weight={100}>
                URL's RANK
              </Title>
            </Grid.Col>

            <Grid.Col xs={3} sm={2} md={2} lg={2} xl={2}>
              <Popover width={330} position="bottom" withArrow shadow="xl">
                <Popover.Target>
                  <Button
                    style={{ width: '100%' }}
                    // className={classes.filter}
                    gradient={{ from: 'teal', to: 'lime', deg: 150 }}
                    // disabled={loading}
                  >
                    <IconFilter />
                    <span style={{ marginLeft: '10px' }}>Filter</span>
                  </Button>
                </Popover.Target>
                <Popover.Dropdown>
                  <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextInput
                      withAsterisk
                      label="Domain URL"
                      name="domainURL"
                      value={getdomainURL}
                      onChange={(e: any) => {
                        setGetDomainURL(e.currentTarget.value)
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <DateRangePicker
                      withAsterisk
                      label="Select Date Range"
                      placeholder="Pick dates range"
                      minDate={dayjs('2022-12-15').toDate()}
                      allowSingleDateInRange={true}
                      maxDate={dayjs(new Date()).toDate()}
                      value={rangedate}
                      range={[
                        dayjs(new Date()).startOf('month').toDate(),
                        dayjs(new Date()).endOf('month').toDate(),
                      ]}
                      onChange={(e: any) => {
                        console.log('event', e)
                        setRangeDate(e)
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Select
                      label="Select Device"
                      placeholder="Select Device"
                      clearable
                      searchable
                      data={['Desktop', 'Mobile']}
                      onChange={(e: any) => setGetDevice(e)}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button
                      variant="filled"
                      color="green"
                      style={{ float: 'right' }}
                      onClick={handleURLCheckFunction}
                    >
                      Submit
                    </Button>
                  </Grid.Col>
                </Popover.Dropdown>
              </Popover>
            </Grid.Col>
            <Grid.Col xs={3} sm={2} md={2} lg={2} xl={2}>
              <Button onClick={downloadCSV}>
                {' '}
                <IconArrowDownCircle />{' '}
                <span style={{ marginLeft: '10px' }}>Download</span>
              </Button>
            </Grid.Col>
            <Grid.Col xs={3} sm={2} md={2} lg={2} xl={2}>
              <Title order={3} weight={80}>
                Total URL's : {getUrlRankingDetails?.length}
              </Title>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col xs={12} style={{ width: 1060 }}>
              <DataGrid
                pageFrom={countFrom}
                pageTo={countTo}
                total={pageNo}
                gridApi={(instance) => {}}
                totalRecords={!loading ? totalRowSize : 1}
                checkboxSelection={false}
                recordsPerPage={pageSize}
                page={page}
                height={tableHeight}
                onPageChange={(p: any) => setPage(p)}
                recordsPerPageOptions={PAGESIZES}
                onRecordsPerPageChange={setPageSize}
                loading={!loading}
                rowData={
                  getUrlRankingDetails?.length ? getUrlRankingDetails : []
                }
                colDef={colDef}
              />
              {/* <div style={{ float: 'right' }}>
                <CustomRowData
                  totalDataCount={getUrlRankingDetails?.length}
                  limit={10}
                  setPage={() => handlePagination}
                />
              </div> */}
            </Grid.Col>
          </Grid>
        </>
      </Paper>
    </Box>
  )
}
