import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import SignIn from '../pages/login'
import PrivateRoute from '../components/PrivateRoute'
import AdminLayout from '../ADMIN'
import SuperAdminLayout from '../SUPERADMIN'
import Adminmanagerlayout from '../ADMINMANAGER'
import UserLayout from '../USER'
import BrandManager from '../MANAGER'
import CRMManager from '../CRM_MANAGER'
import PageNotFound from '../pages/404'
import { ROLE } from '../constants'

function RoutesWrapper() {
  return (
    <div className="root">
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<SignIn />} />

        <Route element={<PrivateRoute element={[ROLE.ADMIN]} />}>
          <Route path="/admin/*" element={<AdminLayout />} />
        </Route>
        <Route element={<PrivateRoute element={[ROLE.CRMMANAGER]} />}>
          <Route path="/crmmanager/*" element={<CRMManager />} />
        </Route>
        <Route element={<PrivateRoute element={[ROLE.SUPERADMIN]} />}>
          <Route path="/superadmin/*" element={<SuperAdminLayout />} />
        </Route>
        <Route element={<PrivateRoute element={[ROLE.BRANDMANAGER]} />}>
          <Route path="/brandmanager/*" element={<BrandManager />} />
        </Route>
        <Route element={<PrivateRoute element={[ROLE.USER]} />}>
          <Route path="/user/*" element={<UserLayout />} />
        </Route>
        <Route element={<PrivateRoute element={[ROLE.ADMINMANAGER]} />}>
          <Route path="/adminmanager/*" element={<Adminmanagerlayout />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  )
}

export default RoutesWrapper
