/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Paper,
  Title,
  Grid,
  Text,
  Group,
  SelectItemProps,
  createStyles,
  Select,
  Button,
  ScrollArea,
  useMantineTheme,
  FileInput,
  Modal,
} from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { AxiosError, AxiosResponse } from 'axios'
import { get } from 'lodash'
import './styles.css'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { IconFileUpload, IconRefresh, IconUpload } from '@tabler/icons'
import ReactQuill from 'react-quill'
import moment from 'moment'
import { useAppSelector } from '../../hooks'
// import EditorToolbar, { modules, formats } from '../../components/TextEditor'
import { API_SEVICES } from '../../config/services'

import { AlertColor, AlertIcons, API_ENDPOINTS, BRANDID } from '../../constants'
import { openSnackbar } from '../../redux/snackbar'
import { useAppDispatch } from '../../redux/store'
import 'react-quill/dist/quill.snow.css'

// const Buffer: any = import('buffer')
// const zlib: any = import('zlib')

interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  description: string
  cityName: string
}

const useStyles = createStyles((theme) => ({
  outside: {
    opacity: 0,
  },

  weekend: {
    color: `${theme.colors.blue[6]} !important`,
  },

  selected: {
    color: `${theme.white} !important`,
  },
  tableBodyStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableHeaderStyle: {
    textAlign: 'center',
  },
  'mantine-Navbar-root': {
    zIndex: 0,
  },
}))

export default function TextFile() {
  const [serviceData, setServiceData] = useState([])
  const [dataURL, setDataURL] = useState<any>([])
  const [response, setResponse] = useState<any>(null)
  const [brandData, setBrandData] = useState([])
  const [serviceVal, setServiceVal] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [textValue, setTextValue] = useState('')
  const [uploadDrawer, setUploadDrawer] = useState(false)
  const [uploadBrand, setUploadBrand] = useState<any>(null)
  const [fileData, setFileData] = useState<any>(null)
  const [UploadLoading, setUploadLoading] = useState(false)
  const { classes } = useStyles()
  const navigate = useNavigate()
  const reactQuillRef = useRef<ReactQuill>(null)

  const dispatch = useAppDispatch()
  const role = useAppSelector((stat) => stat.role)
  const brandID = role.brandId || BRANDID || null

  const theme = useMantineTheme()
  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, name, description, cityName, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            {description || cityName ? (
              <Text size="xs" opacity={0.65}>
                {description}-{cityName}
              </Text>
            ) : null}
          </div>
        </Group>
      </div>
    )
  )

  useEffect(() => {
    // const controller = new AbortController()

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_SERVICE_ACCOUNT}`,
      (res: AxiosResponse) => {
        setServiceData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Service Account Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }

      // controller.signal
    )
    // return () => {
    //   setLoading(false)
    //   controller.abort()
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = () => {
    setLoading(true)
    const match = JSON.stringify({ _id: serviceVal })
    API_SEVICES.PostObjRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GENERATE_SERVICE_ACCOUNT}?match=${match}`,
      (res: AxiosResponse) => {
        setLoading(false)

        setResponse(res.data.data)
      },
      (err: AxiosError) => {
        setLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Error in Indexing Request'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      },
      {
        url: dataURL,
      }
    )
  }

  const handleChangeEditor = (event: any) => {
    const unprivilegedEditor = reactQuillRef?.current?.unprivilegedEditor
    const range = reactQuillRef?.current?.selection

    if (
      typeof unprivilegedEditor !== 'undefined' &&
      typeof range !== 'undefined'
    ) {
      const firstLine =
        unprivilegedEditor?.getText(0, range?.index).split('\n').length - 1

      if (firstLine > 102) {
        dispatch(
          openSnackbar({
            messege: 'Maximum Number of Lines Reached',
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
      }
    }

    const text = reactQuillRef.current?.editor
      ?.getText()
      .split('\n')
      .filter((line) => line.trim() !== '')
    setDataURL(text)
    setTextValue(event)
  }
  useEffect(() => {
    const controller = new AbortController()
    let match
    if (brandID.length > 0) {
      match = JSON.stringify({ _id: brandID, status: true })
    } else {
      match = JSON.stringify({ status: true })
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${match}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                ' Brand Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )

    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const serviceDetails = serviceData?.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.name,
  }))

  const brandDetails = brandData.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.name,
  }))

  const handleChange = (file: any) => {
    const fileName = moment(file.name, 'YYYY-MM-DD').format()
    if (moment(fileName)) {
      setFileData(file)
    } else {
      dispatch(
        openSnackbar({
          messege: 'Invalid File Name',
          icon: AlertIcons.error,
          title: 'error',
          color: AlertColor.error,
        })
      )
    }
  }

  function handleFileSubmit(event: any) {
    event.preventDefault()
    setUploadLoading(true)
    const formData = new FormData()
    formData.append('file', fileData)
    // formData.append('brand', uploadBrand)

    API_SEVICES.PostRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.INDEXING_FILE_UPLOAD}`,
      (res: AxiosResponse) => {
        setUploadDrawer(false)
        setUploadBrand(null)
        setUploadLoading(false)

        dispatch(
          openSnackbar({
            messege: 'Indexing data Upload Sucessfully',
            icon: AlertIcons.success,
            title: 'Success',
            color: AlertColor.success,
          })
        )
      },
      (err: AxiosError) => {
        setUploadLoading(false)
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'GMB Insights Data Cant beUpload '
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      },
      formData
    )
  }
  return (
    <>
      <div>
        <Paper
          shadow="xs"
          p="md"
          style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
        >
          <Grid>
            <Grid.Col xs={12} sm={12} md={12} lg={9.5} xl={9.5}>
              <Title order={2} weight={100} style={{ marginTop: ' 10px' }}>
                Indexing
              </Title>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={1.7} xl={1.7}>
              <Button
                sx={{
                  marginTop: '15px',
                  width: '100%',
                  backgroundColor: 'rgb(160, 213, 239)',
                  '&[data-disabled]': { opacity: 0.4 },
                  '&:hover': {
                    backgroundColor: 'rgb(160, 213, 239)',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                  },
                }}
                onClick={() => {
                  setUploadDrawer(true)
                }}
                gradient={{ from: 'teal', to: 'lime', deg: 105 }}
              >
                <IconFileUpload color="black" />
                <Text color="black" style={{ marginLeft: '10px' }}>
                  File Upload
                </Text>
              </Button>
            </Grid.Col>
            <Grid.Col
              xs={10}
              sm={10}
              md={6}
              lg={3}
              xl={3}
              // style={{ marginLeft: '20px' }}
            >
              <Select
                label="Select Service Account"
                placeholder="Select Service Account"
                itemComponent={SelectItem}
                data={serviceDetails}
                maxDropdownHeight={400}
                value={serviceVal !== null ? serviceVal : ''}
                nothingFound="No Data Found"
                clearable
                onChange={(value: any) => {
                  setServiceVal(value)
                }}
                styles={() => ({
                  item: {
                    // applies styles to selected item
                    '&[data-selected]': {
                      '&, &:hover': {
                        backgroundColor:
                          theme.colorScheme === 'dark'
                            ? theme.colors.teal[9]
                            : theme.colors.teal[1],
                        color:
                          theme.colorScheme === 'dark'
                            ? theme.white
                            : theme.colors.teal[9],
                      },
                    },

                    // applies styles to hovered item (with mouse or keyboard)
                    '&[data-hovered]': {},
                  },
                })}
              />
            </Grid.Col>

            <Grid.Col
              xs={2}
              sm={2}
              md={6}
              lg={1}
              xl={1}
              sx={{ marginTop: '25px' }}
            >
              <Button
                gradient={{ from: 'teal', to: 'lime', deg: 125 }}
                disabled={response === null}
                onClick={() => {
                  setTextValue('')
                  setDataURL([])
                  setServiceVal(null)
                  setResponse(null)
                }}
              >
                <IconRefresh />
              </Button>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Text style={{ fontSize: '13px' }}>
                Note : Maximum 100 Lines of URLs are Allowed
              </Text>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div style={{ minHeight: '400px' }} className="ql-editor">
                <ReactQuill
                  style={{ height: '350px' }}
                  theme="snow"
                  ref={reactQuillRef}
                  value={textValue}
                  onChange={handleChangeEditor}
                  // onKeyDown={checkCharacterCount}
                  readOnly={serviceVal === null}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    position: 'sticky',
                    margin: '0px 20px 20px 0px',
                  }}
                >
                  <Button
                    onClick={handleSubmit}
                    disabled={dataURL.length === 0}
                    variant="gradient"
                    gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                    loading={loading}
                  >
                    Generate
                  </Button>
                </div>
              </div>
            </Grid.Col>
          </Grid>
        </Paper>
        {response !== null ? (
          <Paper
            sx={{
              backgroundColor: 'black',
              marginTop: '20px',
            }}
          >
            <Grid>
              <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <ScrollArea
                  sx={{ height: '300px' }}
                  styles={() => ({
                    scrollbar: {
                      '&, &:hover': {
                        background: '#808080',
                      },

                      '.mantine-ScrollArea-viewport': {
                        backgroundColor: theme.colors.gray[1],
                        borderRadius: '1px',
                      },
                      '&[data-orientation="vertical"] .mantine-ScrollArea-thumb':
                        {
                          backgroundColor: '#c3c3c3',
                        },
                    },
                  })}
                >
                  <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p
                      style={{
                        color: 'white',
                        fontFamily: 'sans-serif',
                        marginLeft: '20px',
                        whiteSpace: 'pre',
                      }}
                    >
                      {'>'} {response}
                    </p>
                  </Grid.Col>
                </ScrollArea>
              </Grid.Col>
            </Grid>
          </Paper>
        ) : (
          ''
        )}
      </div>

      <Modal
        opened={uploadDrawer}
        onClose={() => {
          setUploadDrawer(false)
        }}
        sx={{ marginTop: '70px', zIndex: 10 }}
        overlayOpacity={0.55}
        size="md"
      >
        <FileInput
          label="Select file "
          description="Your File"
          // placeholder="Your File"
          icon={<IconUpload size={14} />}
          accept=".xls,.xlsx,.csv"
          onChange={handleChange}
          // disabled={uploadBrand === null}
        />

        <Text style={{ fontSize: '13px', margin: '10px' }}>
          Note: Please Upload YYYY-MM-DD File Format(.csv,.xlsx,.xls)
        </Text>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          <Button
            gradient={{ from: 'teal', to: 'lime', deg: 105 }}
            onClick={(e: any) => handleFileSubmit(e)}
            loading={UploadLoading}
            disabled={fileData === null}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  )
}
