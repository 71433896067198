import { useEffect, useRef } from 'react'
import {
  useNavigate,
  createSearchParams,
  URLSearchParamsInit,
} from 'react-router-dom'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '../redux/store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useIsMount = () => {
  const isMountRef = useRef(true)
  useEffect(() => {
    isMountRef.current = false
  }, [])
  return isMountRef.current
}
interface navProps {
  pathName: any
  params: any
  query: any
}
export const useNavigateParam = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate()

  return (
    pathname: any,
    params: URLSearchParamsInit | undefined,
    query: URLSearchParamsInit | undefined
  ) =>
    navigate(
      `${pathname}?${createSearchParams(params)}?${createSearchParams(query)}`
    )
}
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
