import { useEffect, useState, useMemo } from 'react'
import {
  createStyles,
  Grid,
  Paper,
  Tabs,
  Button,
  TextInput,
  Textarea,
} from '@mantine/core'
import { IconPhoto, IconMessageCircle, IconSettings } from '@tabler/icons'
import { AxiosError, AxiosResponse } from 'axios'

import { useSearchParams, useNavigate } from 'react-router-dom'

import { get } from 'lodash'
import MetaDetialsForm from './MetadetailsForm'
import CustomTable from '../../../components/NewcustomTable'
import { API_SEVICES } from '../../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
} from '../../../constants'
import SpinningLoader from '../../../components/SpinningLoader'
import { openSnackbar } from '../../../redux/snackbar'
import { useAppDispatch } from '../../../hooks'
import SelectionTable from './SelectionTable'

// Dropzone Styles
const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
    fontStyle: 'Helvetica',
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
}))

interface IProps {
  onClose: () => void
  rowdataValue: any
  fetchData: () => void
}

export default function EditContent(props: IProps) {
  const { onClose, rowdataValue, fetchData } = props
  const [productData, setDataProduct] = useState<any>([])
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pageNo, setPageNo] = useState(1)
  const [pageSize, setPageSize] = useState(PAGESIZES[0])
  const [countFrom, setCountForm] = useState<any>()
  const [tableHeight, setTableHeight] = useState(500)
  const [totalRowSize, setTotalRowSize]: any = useState(10)
  const [countTo, setCountTo] = useState<any>()

  const dispatch = useAppDispatch()

  const colDef = useMemo(
    () => [
      {
        Header: 'productName',
        accessor: 'productName',
        textAlignment: 'center',
        width: 600,
        Cell: ({ ...value }: any) => <p>{value.row.original.productName}</p>,
      },
    ],
    []
  )
  const colDef1 = useMemo(
    () => [
      {
        Header: 'productName',
        accessor: 'productName',
        textAlignment: 'center',
        width: 20,
        Cell: ({ ...value }: any) => <p>{value.row.original.productName}</p>,
      },
      {
        accessor: 'storeName',
        Header: 'Store Name',
        textAlignment: 'center',
        minWidth: 300,
        Cell: ({ ...value }: any) => (
          <p>
            {value.row.original.storeName}
            <div
              style={{
                display: 'flex',
                fontSize: '13px',
                color: 'grey',
                borderSpacing: 0,
              }}
            >
              {value.row.original.locality},{value.row.original.city}.
            </div>
          </p>
        ),
      },
      {
        accessor: 'brandId.name',
        Header: 'Brand',
        textAlignment: 'center',
        minWidth: 140,
        Cell: ({ ...value }: any) => <p>{value.row.original.brandId.name}</p>,
      },
    ],
    []
  )

  useEffect(() => {
    // if (filterRef.current != null) {
    //   const element = paperRef.current?.getClientRects()

    //   setTableHeight(window.innerHeight - filterRef.current.clientHeight - 110)
    // }

    if (totalRowSize > pageSize) {
      const from = (page - 1) * pageSize
      const to = from + pageSize
      setCountForm(from + 1)
      setCountTo(to)
    } else {
      setCountForm(1)
      setCountTo(totalRowSize)
    }
    let rowDataSize

    if (totalRowSize >= pageSize) {
      rowDataSize = totalRowSize / pageSize

      if (rowDataSize - Math.floor(rowDataSize) !== 0) {
        setPageNo(Math.ceil(rowDataSize))
      } else {
        setPageNo(rowDataSize)
      }
    } else {
      rowDataSize = Math.ceil(totalRowSize / pageSize)
      setPageNo(rowDataSize)
    }
  }, [page, pageSize, productData, totalRowSize])
  const handleClose = () => {
    onClose()
    fetchData()
  }

  return (
    <div className={classes.wrapper} style={{ width: '100%' }}>
      <div style={{ width: '740px', minHeight: '300px' }}>
        <div>
          {loading ? (
            <SpinningLoader />
          ) : (
            <div>
              <Grid>
                <Grid.Col span={12}>
                  <MetaDetialsForm
                    id={get(rowdataValue, '_id', '')}
                    metaTitle={rowdataValue?.metaTitle}
                    metaDescription={rowdataValue?.metaDescription}
                    title={rowdataValue?.title}
                    status={rowdataValue?.status}
                    content={rowdataValue?.content}
                    onClose={handleClose}
                    relatedProducts={get(rowdataValue, 'relatedProducts', '')}
                    brandID={get(rowdataValue, 'brandId._id', '')}
                  />
                  {/* <Tabs color="green" defaultValue="gallery">
                      <Tabs.List grow>
                        <Tabs.Tab
                          value="gallery"
                          icon={<IconPhoto size="0.8rem" />}
                        >
                          Related Products
                        </Tabs.Tab>
                        <Tabs.Tab
                          value="messages"
                          icon={<IconMessageCircle size="0.8rem" />}
                        >
                          Add Related products
                        </Tabs.Tab>
                        <Tabs.Tab
                          value="settings"
                          icon={<IconSettings size="0.8rem" />}
                        >
                          Keyword Content
                        </Tabs.Tab>

                        {/* <Tabs.Tab
                          value="metadetails"
                          icon={<IconSettings size="0.8rem" />}
                        >
                        metdDat
                        </Tabs.Tab> */}
                  {/* </Tabs.List>

                      <Tabs.Panel value="gallery" pt="xs"> */}
                  {/* <CustomTable
                      pageFrom={countFrom}
                      pageTo={countTo}
                      total={pageNo}
                      gridApi={(instance) => {}}
                      totalRecords={!loading ? totalRowSize : 1}
                      checkboxSelection={false}
                      recordsPerPage={pageSize}
                      page={page}
                      height={tableHeight}
                      onPageChange={(p: any) => setPage(p)}
                      recordsPerPageOptions={PAGESIZES}
                      onRecordsPerPageChange={setPageSize}
                      loading={!!loading}
                      rowData={!loading ? recordData : []}
                      colDef={colDef}
                    /> */}
                  {/* Related products
                      </Tabs.Panel>

                      <Tabs.Panel value="messages" pt="xs">
                        <SelectionTable /> */}
                  {/* <CustomTable
                          pageFrom={countFrom}
                          pageTo={countTo}
                          total={pageNo}
                          gridApi={(instance) => {}}
                          totalRecords={!loading ? totalRowSize : 1}
                          checkboxSelection={true}
                          recordsPerPage={pageSize}
                          page={page}
                          height={500}
                          onPageChange={(p: any) => setPage(p)}
                          recordsPerPageOptions={PAGESIZES}
                          onRecordsPerPageChange={setPageSize}
                          loading={!!loading}
                          rowData={!loading ? productData : []}
                          colDef={colDef}
                        /> */}
                  {/* <p>fgfg</p> */}
                  {/* </Tabs.Panel>

                      <Tabs.Panel value="settings" pt="xs">
                        
                      </Tabs.Panel>
                    </Tabs> */}
                </Grid.Col>
              </Grid>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
