/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
  createStyles,
  Grid,
  Checkbox,
  Button,
  TextInput,
  Textarea,
  MultiSelect,
  Paper,
  Loader,
  // MultiSelectValueProps,
} from '@mantine/core'

import { AxiosError, AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ReactQuill from 'react-quill'
import { useNavigate } from 'react-router-dom'
import { get } from 'lodash'
import { IconChevronDown } from '@tabler/icons'
import EditorToolbar, {
  modules,
  formats,
} from '../../../../components/TextEditor'
import { API_ENDPOINTS, AlertColor, AlertIcons } from '../../../../constants'

import { API_SEVICES } from '../../../../config/services'

import SpinningLoader from '../../../../components/SpinningLoader'
import { useAppDispatch } from '../../../../hooks'
import { openSnackbar } from '../../../../redux/snackbar'

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
    fontStyle: 'Helvetica',
    minHeight: 500,
  },
}))

interface IProps {
  id: any
  title: any
  metaTitle: string
  metaDescription: string
  content: string
  status: boolean
  relatedProducts: any
  onClose: () => void
  brandID: string
}

export default function MetaDetialsForm(props: IProps) {
  const {
    metaTitle,
    metaDescription,
    content,
    status,
    title,
    id,
    relatedProducts,
    onClose,
    brandID,
  } = props
  const { classes } = useStyles()
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)

  const [productList, setProductList] = useState<any>(relatedProducts)
  const [recordData, setRecordData] = useState([])

  const [textValue, setTextValue] = useState(content)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      metaTitle: metaTitle,
      metaDescription: metaDescription,
      content: content,
      status: status,
      title: title,
      relatedProducts: relatedProducts,
    },
    onSubmit: (values) => {
      setLoading(true)

      API_SEVICES.PatchObjRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.UPDATE_DYNAMIC_CONTENT}/${id}`,
        (res: AxiosResponse) => {
          formik.resetForm()
          onClose()
          dispatch(
            openSnackbar({
              messege: 'Dynamic Content Updated Sucessfully',
              icon: AlertIcons.success,
              title: 'Success',
              color: AlertColor.success,
            })
          )
        },
        (err: AxiosError) => {
          setLoading(false)
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Dynamic Content Cant be Updated'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
          console.error(err)
        },
        values
      )
    },
    validationSchema: Yup.object({}),
  })

  const handleChangeEditor = (e: any) => {
    formik.setFieldValue('content', e)
    setTextValue(e)
  }
  useEffect(() => {
    setLoading1(true)

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_DYNAMIC_CONTENT_PRODUCT}?id=${brandID}`,
      (res: AxiosResponse) => {
        setLoading1(false)

        setRecordData(res.data.data.data)
        // setTotalRowSize(res.data.data.data.length)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'product Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )
  }, [])
  const product = recordData.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.productName,
  }))

  return (
    <div className={classes.wrapper} style={{ width: '100%' }}>
      <Paper shadow="xs" radius="xs" p="xs">
        {loading ? (
          <SpinningLoader />
        ) : (
          <div>
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  placeholder="Title"
                  label="Title"
                  withAsterisk
                  name="title"
                  id="title"
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  onBlur={formik.handleBlur}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput
                  placeholder="Meta Title"
                  label="Meta Title"
                  withAsterisk
                  name="metaTitle"
                  id="title"
                  onChange={formik.handleChange}
                  value={formik.values.metaTitle}
                  onBlur={formik.handleBlur}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Textarea
                  name="metaDescription"
                  placeholder="Enter meta description Details"
                  label="MetaDescription"
                  onChange={formik.handleChange}
                  value={formik.values.metaDescription}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <MultiSelect
                  maxDropdownHeight={200}
                  placeholder="Select Products"
                  label="Add Related Products"
                  data={product}
                  searchable
                  disableSelectedItemFiltering
                  switchDirectionOnFlip={true}
                  disabled={loading1}
                  rightSection={
                    !loading1 ? (
                      <IconChevronDown size="1rem" />
                    ) : (
                      <Loader color="green" size="xs" />
                    )
                  }
                  value={productList}
                  nothingFound="Nobody product found"
                  onChange={(value: any) => {
                    setProductList(value)
                    formik.setFieldValue('relatedProducts', value)
                  }}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <p
                  style={{
                    color: '#212529',
                    fontWeight: 500,
                    fontSize: '14px',
                  }}
                >
                  Content
                </p>
                <EditorToolbar />
                <ReactQuill
                  style={{ height: '350px' }}
                  theme="snow"
                  value={textValue}
                  modules={modules}
                  formats={formats}
                  onChange={(e) => handleChangeEditor(e)}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid>
                  <Grid.Col
                    style={{
                      display: 'flex',
                      justifyContent: 'right',
                      alignItems: 'center',
                    }}
                    span={9}
                  >
                    <Checkbox
                      checked={formik.values.status}
                      required
                      name="status"
                      label={
                        formik.values.status === true ? 'Active' : 'Inactive'
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Button
                      variant="gradient"
                      gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                      onClick={() => {
                        formik.handleSubmit()
                      }}
                      className={
                        !(formik.dirty && formik.isValid) ? 'disabled-btn' : ''
                      }
                    >
                      Submit
                    </Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </div>
        )}
      </Paper>
    </div>
  )
}
