/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/aria-unsupported-elements */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */
import {
  Box,
  Grid,
  Text,
  Group,
  SelectItemProps,
  createStyles,
  Button,
  useMantineTheme,
  MantineColor,
  Select,
  FileInput,
} from '@mantine/core'
import { CSVLink } from 'react-csv'
import { AxiosError, AxiosResponse } from 'axios'
import _, { get } from 'lodash'
import { forwardRef, useEffect, useState } from 'react'
import { IconUpload } from '@tabler/icons'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../hooks'
import { API_SEVICES } from '../../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  ROLEPROVIDED,
  BRANDID,
  STOREID,
  STORECODE,
} from '../../../constants'
import { openSnackbar } from '../../../redux/snackbar'
import { useAppDispatch } from '../../../redux/store'
import { getLocalStorageItem } from '../../../utils/localStorage'

interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  description: string
  cityName: string
}

interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}

const useStyles = createStyles((theme) => ({
  outside: {
    opacity: 0,
  },

  weekend: {
    color: `${theme.colors.blue[6]} !important`,
  },

  selected: {
    color: `${theme.white} !important`,
  },
  tableBodyStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
interface IProps {
  onClose: () => void
  fetchData: () => void
}
export default function SelectedKeyword(props: IProps) {
  const theme = useMantineTheme()
  const { onClose, fetchData } = props
  const [brandData, setBrandData] = useState([])
  const [fileData, setFileData] = useState<any>(null)

  const [uploadBrand, setUploadBrand] = useState<any>(null)
  const [uploadLoading, setUploadLoading] = useState(false)
  const dispatch = useAppDispatch()
  const role = useAppSelector((stat: { role: any }) => stat.role)
  const brandID = role.brandId || BRANDID || null
  const navigate = useNavigate()

  const data = [
    {
      storecode: 'CH24',
      storename: 'SATHYA Agencies Pvt Ltd',
      keyword: 'best tv showroom in ambattur chennai',
      volume: 15,
      Keywordrelevant: 'relevant',
      category: 'category',
    },
    {
      storecode: 'CH24',
      storename: 'SATHYA Agencies Pvt Ltd',
      keyword: 'electric shop in ambattur',
      volume: 15,
      Keywordrelevant: 'relevant',
      category: 'category',
    },
  ]

  useEffect(() => {
    let match
    if (brandID.length === 0) {
      match = JSON.stringify({ status: true })
    } else {
      match = JSON.stringify({ _id: brandID, status: true })
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${match}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              ' Brand Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const brandDetails = brandData.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.name,
  }))

  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, name, description, cityName, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            {description || cityName ? (
              <Text size="xs" opacity={0.65}>
                {description}-{cityName}
              </Text>
            ) : null}
          </div>
        </Group>
      </div>
    )
  )
  SelectItem.displayName = 'SelectItem'

  const handleChange = (file: any) => {
    setFileData(file)
  }
  function handleSubmit(event: any) {
    event.preventDefault()

    setUploadLoading(true)
    const formData = new FormData()
    formData.append('file', fileData)
    formData.append('id', uploadBrand)

    API_SEVICES.PostObjRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.UPLOAD_SELECTED_KEYWORD}`,
      (res: AxiosResponse) => {
        setUploadLoading(false)

        setUploadBrand(null)

        onClose()
        fetchData()
        dispatch(
          openSnackbar({
            messege: ' Selected Keywords Data Upload Sucessfully',
            icon: AlertIcons.success,
            title: 'Success',
            color: AlertColor.success,
          })
        )
      },
      (err: AxiosError) => {
        setUploadLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Keywords Data Cant be Upload '
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      },
      formData
    )
  }

  return (
    <div style={{ zIndex: 8 }}>
      <Grid>
        <Grid.Col span={12}>
          <Text>Select Brand</Text>
          <Select
            placeholder="Select Brand"
            itemComponent={SelectItem}
            data={brandDetails}
            maxDropdownHeight={400}
            value={uploadBrand}
            nothingFound="No Data Found"
            clearable
            onChange={(value: any) => {
              setUploadBrand(value)
            }}
            styles={() => ({
              item: {
                // applies styles to selected item
                '&[data-selected]': {
                  '&, &:hover': {
                    backgroundColor:
                      theme.colorScheme === 'dark'
                        ? theme.colors.teal[9]
                        : theme.colors.teal[1],
                    color:
                      theme.colorScheme === 'dark'
                        ? theme.white
                        : theme.colors.teal[9],
                  },
                }, // applies styles to hovered item (with mouse or keyboard)

                '&[data-hovered]': {},
              },
            })}
          />
        </Grid.Col>

        <Grid.Col>
          <Text>Select File</Text>
          <FileInput
            description="Upload Your File"
            // placeholder="Upload Your File"
            clearable={true}
            accept=".xls,.xlsx,.csv"
            onChange={handleChange}
            disabled={uploadBrand === null}
          />
        </Grid.Col>
        <Grid.Col>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: '5px',
            }}
          >
            <CSVLink data={data} filename="samplekeyword.csv">
              Download Sample
            </CSVLink>
          </div>
        </Grid.Col>
        <Grid.Col>
          <Text style={{ fontSize: '13px' }}>
            Note: File should be in (.xls,.xlsx,.csv) format
          </Text>
        </Grid.Col>
        <Grid.Col sx={{ textAlign: 'center' }}>
          <Button
            rightIcon={<IconUpload size={17} />}
            onClick={(e: any) => handleSubmit(e)}
            loading={uploadLoading}
            disabled={fileData === null}
            sx={{
              backgroundColor: 'rgb(160, 213, 239)',

              color: '#000',
              '&[data-disabled]': { opacity: 0.4 },
              '&:hover': {
                backgroundColor: 'rgb(160, 213, 239)',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              },
            }}
          >
            Submit
          </Button>
        </Grid.Col>
      </Grid>
    </div>
  )
}
