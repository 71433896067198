import { useEffect, useState } from 'react'
import { Text, createStyles, Grid, Paper } from '@mantine/core'
import { AxiosError, AxiosResponse } from 'axios'
import { get } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { API_SEVICES } from '../../../config/services'
import { API_ENDPOINTS } from '../../../constants'
import SpinningLoader from '../../../components/SpinningLoader'
// Dropzone Styles
const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
    fontStyle: 'Helvetica',
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
}))
interface IProps {
  onClose: () => void
}

export default function UserViewModel(props: IProps) {
  const { onClose } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const [userData, setUserData] = useState<any>([])
  const id = searchParams.get('id')
  const { classes } = useStyles()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id) {
      setLoading(true)
      const match = {
        _id: id,
      }
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_ENDPOINTS.GET_USER
        }?match=${JSON.stringify(match)}`,
        (res: AxiosResponse) => {
          setLoading(false)
          setUserData(res.data.data[0])
        },
        (err: AxiosError) => {
          setLoading(false)
          console.error('err', err)
        }
      )
    }
  }, [id])

  return (
    <div className={classes.wrapper}>
      <Grid gutter={5}>
        <Grid.Col xs={6} sm={6} xl={6} lg={6} md={6}>
          <Paper
            shadow="xs"
            p="md"
            style={{ width: '580px', minHeight: '300px' }}
          >
            <Grid>
              {loading ? (
                <SpinningLoader />
              ) : (
                <Grid.Col xs={6} sm={6} xl={6} lg={6} md={6}>
                  <Text fw={500}>First Name</Text>
                  <Text fz="sm">
                    {' '}
                    {get(userData, 'firstName')
                      ? get(userData, 'firstName')
                      : '-'}
                  </Text>
                  <Text fw={500} style={{ marginTop: '10px' }}>
                    {' '}
                    Last Name
                  </Text>
                  <Text fz="sm">
                    {get(userData, 'lastName')
                      ? get(userData, 'lastName')
                      : '-'}
                  </Text>
                  <Text fw={500} style={{ marginTop: '10px' }}>
                    {' '}
                    Email
                  </Text>
                  <Text fz="sm">
                    {get(userData, 'email') ? get(userData, 'email') : '-'}
                  </Text>
                  <Text fw={500} style={{ marginTop: '10px' }}>
                    {' '}
                    Role
                  </Text>
                  <Text fz="sm">
                    {get(userData, 'role') ? get(userData, 'role') : '-'}
                  </Text>

                  <Text fw={500} style={{ marginTop: '10px' }}>
                    {' '}
                    Brand Name
                  </Text>

                  <Text fz="sm">
                    {userData?.brand !== null
                      ? get(userData, 'brand', []).map((i: any, idx: any) => (
                          <Text
                            fz="sm"
                            key={i._id}
                            sx={{ textTransform: 'capitalize' }}
                          >
                            {i.name}
                          </Text>
                        ))
                      : '-'}
                  </Text>
                  <Text fw={500} style={{ marginTop: '10px' }}>
                    {' '}
                    Store Name
                  </Text>

                  <Text fz="sm">
                    {get(userData, 'store.storeName')
                      ? get(userData, 'store.storeName')
                      : '-'}
                  </Text>
                </Grid.Col>
              )}
            </Grid>
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  )
}
