import {
  Pagination,
  PaginationProps,
  Select,
  SelectItem,
  Grid,
} from '@mantine/core'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps extends PaginationProps {
  rowPerPageOptions: (string | SelectItem)[]
  onRowPerPageChange: (value: string | null) => void
  rowPerPageDefault: string | null | undefined | any
  rowPerPage: string | null | undefined | any
  totalRecords: any
  pageFrom: any
  pageTo: any
  total: any
  page: any
}
function CustomPagination({
  total,
  onRowPerPageChange,
  rowPerPage,
  rowPerPageOptions,
  rowPerPageDefault,
  totalRecords,
  pageFrom,
  pageTo,
  page,

  ...rest
}: IProps) {
  return (
    <Grid>
      <Grid.Col xs={12} sm={3} md={3} lg={6} xl={6}>
        <span style={{ display: 'flex', justifyContent: 'left' }}>
          {totalRecords !== 0 ? (
            <>
              {pageFrom} - {pageTo} / {totalRecords}
            </>
          ) : (
            'No Records Found'
          )}
        </span>
      </Grid.Col>
      <Grid.Col xs={12} sm={9} md={9} lg={6} xl={6}>
        <span
          style={{
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <Select
            // style={{ marginLeft: '10px' }}
            data={rowPerPageOptions}
            sx={(theme) => ({
              width: '80px',
              marginRight: '10px',
            })}
            value={`${rowPerPage}`}
            onChange={onRowPerPageChange}
            defaultValue={rowPerPageDefault}
          />
          <Pagination total={total} page={page} {...rest} />
        </span>
      </Grid.Col>
    </Grid>
  )
}
export default CustomPagination
