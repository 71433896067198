import { createStyles, keyframes } from '@mantine/core'

interface Iprops {
  height?: string
  width?: string
}
export const p6 = keyframes({
  ' 0%': { backgroundPosition: '-150% 0,-150% 0' },
  '66%': { backgroundPosition: '250% 0,-150% 0' },
  '100%': { backgroundPosition: '250% 0, 250% 0' },
})
const useStyles = createStyles((theme) => ({
  progress: {
    height: '4px',
    background: `linear-gradient(105deg, #12b886 0%, #82c91e 100%)#ecf7dc `,
    backgroundSize: '60% 90%',
    backgroundRepeat: 'no-repeat',
    animation: `${p6} 2s infinite`,
  },
}))
export default function ProgressLoader() {
  const { classes } = useStyles()

  return <div className={classes.progress} />
}
