import { IconUsers, IconChartBar } from '@tabler/icons'
import { LinksGroupProps } from '../components/NavBarLinkGroups'

export const ROUTE_PATH = {
  home: '/',
  login: '/login',
  brand: '/brandmanager/brand',
  type: '/brandmanager/types',
  category: '/brandmanager/category',
  user: '/brandmanager/user',
  token: '/brandmanager/token',
  store: '/brandmanager/stores',
  product: '/brandmanager/products',
  state: '/brandmanager/state',
  city: '/brandmanager/city',
  subCategory: '/brandmanager/subCategory',
  knowlarity: '/brandmanager/virtualphonenumber',
  calllog: '/brandmanager/calllog',
  keywords: '/brandmanager/keywords',
  calllogChart: '/brandmanager/calllogChart',
  action: '/brandmanager/action',
  callActions: '/brandmanager/callActions',
  discovery: '/brandmanager/discovery',
  gmbDelete: '/brandmanager/gmbDelete',
  keywordsDelete: '/brandmanager/keywordsdelete',
  monthlyReport: '/brandmanager/monthlyReport',
  fileUpload: '/brandmanager/textFile',
}

export const drawerOptions: LinksGroupProps[] = [
  // {
  //   label: 'Admin',
  //   icon: IconUsers,
  //   initiallyOpened: true,
  //   links: [
  //     { label: 'Brand', link: ROUTE_PATH.brand },
  //     { label: 'Store', link: ROUTE_PATH.store },
  //     { label: 'Product', link: ROUTE_PATH.product },
  //     { label: 'Type', link: ROUTE_PATH.type },
  //     { label: 'Category', link: ROUTE_PATH.category },
  //     { label: 'SubCategory', link: ROUTE_PATH.subCategory },
  //     { label: 'State', link: ROUTE_PATH.state },
  //     { label: 'City', link: ROUTE_PATH.city },
  //     { label: 'Virtual Number Mapping ', link: ROUTE_PATH.knowlarity },
  //   ],
  // },
  {
    label: 'Dashboard',
    icon: IconChartBar,
    initiallyOpened: false,
    links: [
      { label: 'CallLog', link: ROUTE_PATH.calllog },
      { label: 'Discovery', link: ROUTE_PATH.discovery },
      { label: 'Action', link: ROUTE_PATH.callActions },
      { label: 'Keywords', link: ROUTE_PATH.keywords },
      { label: 'Monthly Report', link: ROUTE_PATH.monthlyReport },
    ],
  },
]
