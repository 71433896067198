import { Box, Grid, Skeleton, Text, createStyles, Card } from '@mantine/core'
import styled from '@emotion/styled'
import moment from 'moment'
import barArrow from '../../assets/barArrow.png'
import barLine from '../../assets/barLine.png'
import barUpward from '../../assets/barUpward.png'

interface Iprops {
  direct: any
  discovery: any
  total: any
  endDate: any
}

const Item = styled.div`
...theme.typography.body2,
color: theme.palette.text.secondary,
"&:hover": {
  boxShadow: "3px 3px 3px 3px #DCDCDC",
  cursor: "pointer",
  width: "100%",
},
`
const StyledCard = styled.div`
  margin-bottom: "15px",
  position: "relative",
  min-height: 130,
  border-radius: "10px",
`
const useStyles = createStyles((theme) => ({
  root: {
    margin: 'auto',
    padding: 'auto',
  },
  card: {
    marginBottom: '15px',
    position: 'relative',
    minHeight: 150,
  },
  content: {
    top: '35px',
    position: 'absolute',
  },
  header: {
    float: 'right',
    textAlign: 'right',
    top: 10,
    position: 'absolute',
    PaddingTop: '20px',
    paddingRight: '15px',
    right: '8px',
    color: 'black',
    fontSize: '15px',
    fontWeight: 500,
  },
  date: {
    float: 'right',
    textAlign: 'right',
    top: 35,
    position: 'absolute',
    PaddingTop: '20px',
    paddingRight: '15px',
    right: '8px',
    color: 'black',
    fontSize: '14px',
  },
  value: {
    float: 'right',
    textAlign: 'right',
    top: 67,
    position: 'absolute',
    PaddingTop: '20px',
    paddingRight: '15px',
    right: '8px',
    fontSize: '14px',
  },
  percentage: {
    top: '15px',
    left: '90px',
    position: 'absolute',
  },
}))
export default function DiscoveryCard({
  direct,
  discovery,
  total,
  endDate,
}: Iprops) {
  const directSearch: number = direct['6 months']
  const discoverySearch: number = discovery['6 months']
  const totalSearch: number = total['6 months']

  const { classes, theme } = useStyles()
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid>
        <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <Item className={classes.root}>
            <StyledCard>
              <Card
                style={{
                  backgroundColor: '#f7f2f4',
                  border: '1px solid #F1416C',
                  height: '100px',
                }}
              >
                <Box>
                  <span className={classes.content}>
                    <img
                      src={barUpward}
                      height={50}
                      width={70}
                      alt="barchart"
                    />
                  </span>
                  <Box component="span">
                    <Text className={classes.header}>Google Search Mobile</Text>
                    <Text className={classes.date}>
                      {endDate &&
                        moment(new Date(endDate))
                          .subtract(180, 'days')
                          .format('DD MMM YYYY')}{' '}
                      <span> - </span>
                      {moment(endDate).format('DD MMM YYYY')}
                    </Text>

                    <Text className={classes.value}>{totalSearch}</Text>
                  </Box>
                </Box>
              </Card>
            </StyledCard>
          </Item>
        </Grid.Col>

        <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <Item className={classes.root}>
            <StyledCard
              style={{
                backgroundColor: '#EDF9FF',
                border: '1px solid #51BEFA',
              }}
            >
              <Card
                style={{
                  backgroundColor: '#EDF9FF',
                  border: '1px solid #51BEFA',
                  height: '100px',
                }}
              >
                <Box>
                  <span className={classes.content}>
                    <img src={barArrow} height={50} width={70} alt="barchart" />
                  </span>
                  <Box component="span">
                    <Text className={classes.header}>Google Maps Mobile</Text>
                    <Text className={classes.date}>
                      {endDate &&
                        moment(new Date(endDate))
                          .subtract(180, 'days')
                          .format('DD MMM YYYY')}{' '}
                      <span> - </span>
                      {moment(endDate).format('DD MMM YYYY')}
                    </Text>

                    <Text className={classes.value}>{discoverySearch}</Text>
                  </Box>
                </Box>
              </Card>
            </StyledCard>
          </Item>
        </Grid.Col>
        <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <Item className={classes.root}>
            <StyledCard
              style={{
                backgroundColor: '#FFF8DD',
                border: '1px solid #FFC700',
              }}
            >
              <Card
                style={{
                  backgroundColor: '#FFF8DD',
                  border: '1px solid #FFC700',
                  height: '100px',
                }}
              >
                <Box>
                  <span className={classes.content}>
                    <img src={barLine} height={50} width={70} alt="barchart" />
                  </span>
                  <Box component="span">
                    <Text className={classes.header}>Calls</Text>

                    <Text className={classes.date}>
                      {endDate &&
                        moment(new Date(endDate))
                          .subtract(180, 'days')
                          .format('DD MMM YYYY')}{' '}
                      <span> - </span>
                      {moment(endDate).format('DD MMM YYYY')}
                    </Text>

                    <Text className={classes.value}>{directSearch}</Text>
                  </Box>
                </Box>
              </Card>
            </StyledCard>
          </Item>
        </Grid.Col>
      </Grid>
    </Box>
  )
}
