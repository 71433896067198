/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import {
  Box,
  Paper,
  Title,
  Grid,
  Button,
  Modal,
  Text,
  Group,
  Select,
  createStyles,
  SelectItemProps,
  Menu,
  MantineColor,
  Tabs,
  Loader,
  Popover,
} from '@mantine/core'
import {
  IconUpload,
  IconEyeCheck,
  IconTrash,
  IconDotsVertical,
  IconFilter,
  IconMessageCircle,
  IconCircleCheck,
  IconCircleX,
  IconX,
  IconChevronDown,
  IconExternalLink,
  IconKeyboardShow,
} from '@tabler/icons'
import * as XLSX from 'xlsx'
import { CSVLink } from 'react-csv'
import axios, { AxiosError, AxiosResponse } from 'axios'
import _, { get, map } from 'lodash'
import { DataTableColumn } from 'mantine-datatable'
import { useEffect, useMemo, useState, forwardRef, useRef } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { DatePicker } from 'rsuite'
import 'rsuite/DateRangePicker/styles/index.less'
import 'rsuite/dist/rsuite.min.css'
import moment from 'moment'
import isAfter from 'date-fns/isAfter'
import EditContent from './EditContent'
import CustomTable from '../../components/NewcustomTable'
import { API_SEVICES } from '../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
  ROLEPROVIDED,
  BRANDID,
} from '../../constants'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { openSnackbar } from '../../redux/snackbar'
import SelectedKeyword from './UploadSelectedKeyword'
import DeleteModal from '../../components/DeleteModal'
import ViewContent from './ViewContent'
import { getLocalStorageItem } from '../../utils/localStorage'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  description: string
  cityName: string
}
interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}
const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
    fontStyle: 'Helvetica',
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
  tableBodyStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  filter: {
    marginLeft: '115px',
    '@media (max-width: 912px)': {
      marginLeft: '110px',
    },
  },
  import: {
    marginLeft: '52px',
    '@media (max-width: 912px)': {
      marginLeft: '40px',
    },
  },
  csv: {
    marginLeft: '30px',
    '@media (max-width: 912px)': {
      marginLeft: '30px',
    },
  },
  product: {
    marginLeft: '40px',
    '@media (max-width: 912px)': {
      marginLeft: '50px',
    },
  },
}))
// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileDownload = require('js-file-download')

export default function PagesonFly() {
  // eslint-disable-next-line react/prop-types
  const [searchParams, setSearchParams] = useSearchParams()
  const [brandData, setBrandData] = useState([])
  const [uploadSate, setUploadState] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [keywordVal, setKeywordVal] = useState(null)
  const [statusVal, setStatusVal] = useState<any>(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { theme } = useStyles()
  const [recordData, setRecordData] = useState([])
  const [recordData1, setRecordData1] = useState([])
  const [page, setPage] = useState(1)
  const [pageNo, setPageNo] = useState(1)
  const [page1, setPage1] = useState(1)
  const [pageNo1, setPageNo1] = useState(1)

  const [tableHeight, setTableHeight] = useState(500)
  const [tableHeight1, setTableHeight1] = useState(500)
  const [countFrom, setCountForm] = useState<any>()
  const [countTo, setCountTo] = useState<any>()
  const [pageSize, setPageSize] = useState(PAGESIZES[0])
  const [totalRowSize, setTotalRowSize]: any = useState(10)
  const [totalRowSize1, setTotalRowSize1]: any = useState(10)
  const [countFrom1, setCountForm1] = useState<any>()
  const [countTo1, setCountTo1] = useState<any>()
  const [pageSize1, setPageSize1] = useState(PAGESIZES[0])
  const [selectedDate, setSelectedDate] = useState<any>(new Date())
  const [brandVal, setBrandVal] = useState(null)
  const [keywordData, setKeywordData] = useState<any>([])
  const [open, setOpen] = useState(false)
  const [openView, setOpenView] = useState(false)
  const navigate = useNavigate()
  const [storeData, setStoreData] = useState([])
  const [buttonData, setButtonData] = useState<any>()
  const filterRef = useRef<HTMLInputElement>(null)
  const [storeVal, setStoreVal] = useState<any>(null)
  const [search, setSearch] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [storeLoading, setStoreLoading] = useState(false)
  const [viewData, setViewData] = useState<any>(null)
  const deleteStore = API_ENDPOINTS.DELETE_DYNAMIC_CONTENT
  const deletedData = API_ENDPOINTS.GET_DYNAMIC_CONTENT
  const deleteName = 'Dynamic Content'
  const dispatch = useAppDispatch()
  const role = useAppSelector((state) => state.role)
  const brandID = role.brandId || BRANDID || null
  const roleProvided: string = role.role || ROLEPROVIDED
  const Token = getLocalStorageItem('token')

  const [toDate, setToDate] = useState<any>(new Date())
  const [openFilter, setOpenFilter] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)

  const handleClose = () => {
    setDeleteOpen(false)
    setOpen(false)
    setOpenView(false)
    setUploadState(false)
  }

  const fetchData = () => {
    setLoading(true)
    setLoading(true)
    setSearch(true)
    setLoading1(true)
  }

  useEffect(() => {
    setLoading(true)
    setSearchLoading(true)
    const controller = new AbortController()
    let match
    if (brandVal === null && storeVal === null && statusVal === null) {
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_SELECTED_KEYWORD}?pageNo=${page}&size=${pageSize}`,
        (res: AxiosResponse) => {
          setSearchLoading(false)
          setLoading(false)

          if (res.data.data.data.length === 0) {
            setTotalRowSize(10)
            setRecordData([])
            dispatch(
              openSnackbar({
                messege: 'No Keyword Found',
                icon: AlertIcons.error,
                title: 'error',
                color: AlertColor.error,
              })
            )
          } else {
            setTotalRowSize(res.data.TotalRecords)
            setRecordData(res.data.data.data)
          }
        },
        (err: AxiosError) => {
          setRecordData([])
          setTotalRowSize(0)
          setSearchLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          } else if (err.message !== 'canceled') {
            dispatch(
              openSnackbar({
                messege: get(
                  err,
                  'response.data.message',
                  'Keyword Details Cant be fetched'
                ),
                icon: AlertIcons.error,
                title: 'error',
                color: AlertColor.error,
              })
            )
            setLoading(false)
          }
          console.error(err)
        }
      )
    } else {
      match = {
        fromdate: moment(selectedDate).startOf('month').format('YYYY-MM-DD'),
        todate: moment(selectedDate).endOf('month').format('YYYY-MM-DD'),
        brandId: brandVal,
        storeCode: storeVal,
        keywords: keywordVal,
        status: statusVal,
      }

      const clean = (obj: any) => {
        for (const propName in obj) {
          if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName]
          }
        }
        return obj
      }
      const mat = clean(match)
      const match1 = JSON.stringify(mat)
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_SELECTED_KEYWORD}?match=${match1}&pageNo=${page}10&size=${pageSize}`,
        (res: AxiosResponse) => {
          setSearchLoading(false)
          setLoading(false)

          if (res.data.data.data.length === 0) {
            setTotalRowSize(10)
            setRecordData([])
            dispatch(
              openSnackbar({
                messege: 'No Keyword Found',
                icon: AlertIcons.error,
                title: 'error',
                color: AlertColor.error,
              })
            )
          } else {
            setTotalRowSize(res.data.TotalRecords)
            setRecordData(res.data.data.data)
          }
        },
        (err: AxiosError) => {
          setRecordData([])
          setTotalRowSize(0)
          setSearchLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          } else if (err.message !== 'canceled') {
            dispatch(
              openSnackbar({
                messege: get(
                  err,
                  'response.data.message',
                  'Keyword Details Cant be fetched'
                ),
                icon: AlertIcons.error,
                title: 'error',
                color: AlertColor.error,
              })
            )
            setLoading(false)
          }
          console.error(err)
        },
        controller.signal
      )
    }

    setSearch(false)
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page, pageSize])

  useEffect(() => {
    setLoading1(true)
    setSearchLoading(true)
    const controller = new AbortController()

    const match = {
      fromdate: moment(selectedDate).startOf('month').format('YYYY-MM-DD'),
      todate: moment(selectedDate).endOf('month').format('YYYY-MM-DD'),
      brandId: brandVal,
      store: storeVal,
      keywords: keywordVal,
    }

    const clean = (obj: any) => {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
      return obj
    }
    const mat = clean(match)

    const match1 = JSON.stringify(mat)
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_DYNAMIC_CONTENT}?match=${match1}&pageNo=${page1}&size=${pageSize1} `,
      (res: AxiosResponse) => {
        setSearchLoading(false)
        setLoading1(false)

        setRecordData1(res.data.data.data)
        setTotalRowSize1(res.data.TotalRecords)
      },
      (err: AxiosError) => {
        setRecordData1([])
        setTotalRowSize1(0)
        setSearchLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        } else if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Keyword Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          setLoading(false)
        }
        console.error(err)
      },
      controller.signal
    )

    setSearch(false)
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page1, pageSize1])

  useEffect(() => {
    const controller = new AbortController()
    let match
    if (brandID.length === 0) {
      match = JSON.stringify({ status: true })
    } else {
      match = JSON.stringify({ _id: brandID, status: true })
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${match}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Brand Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    let storeMatch
    if (brandVal !== null) {
      setStoreLoading(true)
      storeMatch = JSON.stringify({ brandId: brandVal, status: true })
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_STORE}?match=${storeMatch}`,
        (res: AxiosResponse) => {
          setStoreLoading(false)
          setStoreData(res.data.data)
        },
        (err: AxiosError) => {
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            setStoreLoading(false)
            localStorage.clear()
            navigate('/login', { replace: true })
          }
          if (err.message !== 'canceled') {
            setStoreLoading(false)
            dispatch(
              openSnackbar({
                messege: get(
                  err,
                  'response.data.message',
                  'Store Details Cant be fetched'
                ),
                icon: AlertIcons.error,
                title: 'error',
                color: AlertColor.error,
              })
            )
          }
          console.error(err)
        },
        controller.signal
      )
      const match1 = JSON.stringify({ brandId: brandVal, keywords: keywordVal })
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_SELECTED_KEYWORD}?match=${match1}`,
        (res: AxiosResponse) => {
          setLoading(false)

          const statesArray: any = map(
            res.data.data.data,
            (values: any) => values
          )?.map((option: any) => option.keywords)

          function removeDuplicates(arr: any) {
            return arr.filter(
              (item: any, index: any) => arr.indexOf(item) === index
            )
          }

          setKeywordData(removeDuplicates(statesArray))
        },
        (err: AxiosError) => {
          setKeywordData([])

          setLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          } else if (err.message !== 'canceled') {
            dispatch(
              openSnackbar({
                messege: get(
                  err,
                  'response.data.message',
                  'Keyword Details Cant be fetched'
                ),
                icon: AlertIcons.error,
                title: 'error',
                color: AlertColor.error,
              })
            )
            setLoading(false)
          }
          console.error(err)
        }
      )
    }

    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
  }, [brandVal])

  const brand = brandData.map((item: any) => ({
    ...item,
    label: item.name,
    value: item._id,
  }))
  const storeDetails = storeData.map((item: any) => ({
    ...item,
    value: item.storeId,
    label: item.storeName,
    description: item.locality,
    cityName: item.city,
  }))
  const keyword = keywordData.map((item: any) => ({
    ...item,
    label: item,
    value: item,
  }))

  const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ description, value, image, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text>{value}</Text>
          </div>
        </Group>
      </div>
    )
  )
  AutoCompleteItem.displayName = 'AutoCompleteItem'
  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, name, description, cityName, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            {description || cityName ? (
              <Text size="xs" opacity={0.65}>
                {description}-{cityName}
              </Text>
            ) : null}
          </div>
        </Group>
      </div>
    )
  )
  const handleButtonClick = (props: any) => {
    const value = props?.cell?.row?.original
    setButtonData(value)

    setOpen(true)
  }

  const colDef1: DataTableColumn<any>[] = useMemo(
    () => [
      {
        Header: 'Actions',
        accessor: 'actions',
        fixed: 'left',
        title: <Text mr="xs">Actions</Text>,
        textAlignment: 'center',
        width: 80,
        minWidth: 40,
        maxWidth: 100,
        Cell: ({ ...row }) => (
          <Menu shadow="md" width={130} position="right-start">
            <Menu.Target>
              <Button style={{ background: 'none' }}>
                {' '}
                <IconDotsVertical size={16} color="black" />
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                color="green"
                icon={<IconEyeCheck size={14} />}
                onClick={() => {
                  setOpenView(true)
                  setViewData(row?.row?.original)
                  setSearchParams({
                    mode: 'view',
                    id: row?.row?.original._id,
                  })
                }}
              >
                View
              </Menu.Item>

              <Menu.Item
                color="red"
                onClick={() => {
                  setDeleteOpen(true)
                  setSearchParams({
                    mode: 'delete',
                    id: row?.row?.original._id,
                  })
                }}
                icon={<IconTrash size={14} />}
                disabled={
                  roleProvided === 'user' || roleProvided === 'brand manager'
                }
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ),
      },
      {
        Header: 'Brand Id',
        accessor: 'brandId',
        textAlignment: 'center',
        width: 80,
        minWidth: 40,
        maxWidth: 100,
        Cell: ({ ...value }: any) => <p>{value.row.original.brandId.name}</p>,
      },

      {
        Header: 'Store Id',
        accessor: 'store',
        textAlignment: 'center',
        width: 10,
        Cell: ({ ...value }: any) => <p>{value.row.original.store}</p>,
      },
      {
        accessor: 'storeName',
        Header: 'Store Name',
        textAlignment: 'center',
        minWidth: 200,
        Cell: ({ ...value }: any) => (
          <div>
            {value.row.original.storeName}
            <div style={{ color: 'grey', fontSize: '13px' }}>
              {get(value.row.original.storeId, 'locality')},
              {get(value.row.original.storeId, 'city')}
            </div>
          </div>
        ),
      },

      {
        Header: 'Keyword',
        accessor: 'keywords',
        textAlignment: 'center',
        width: 280,
        Cell: ({ ...value }: any) => <p>{value.row.original.keywords}</p>,
      },
      {
        accessor: 'status1',
        Header: 'Edit Content',
        width: 50,
        textAlignment: 'center',

        Cell: ({ ...status }) => (
          <Button
            onClick={() => {
              handleButtonClick(status)
            }}
          >
            Open{' '}
          </Button>
        ),
      },

      {
        accessor: 'Link',
        Header: 'Link',
        width: 50,
        textAlignment: 'center',

        Cell: ({ ...status }) => {
          if (status.row.original.status === true) {
            return (
              <Button
                component="a"
                // href={`http://localhost:3000/sathya-agency-${get(
                //   status.row.original.storeId,
                //   'locality'
                // )}-${get(status.row.original.storeId, 'city')}/${get(
                //   status.row.original.storeId,
                //   '_id'
                // )}/${get(status.row.original, 'store')}/${get(
                //   status.row.original,
                //   'keywords'
                // )}/${get(status.row.original, '_id')}`
                //   .toLowerCase()
                //   .replace(/\s/g, '-')}
                target="_blank"
                href={`${status.row.original.brandId.domainName}-${get(
                  status.row.original.storeId,
                  'locality'
                )}-${get(status.row.original.storeId, 'city')}/${get(
                  status.row.original.storeId,
                  '_id'
                )}/${get(status.row.original, 'store')}/${get(
                  status.row.original,
                  'keywords'
                )}/${get(status.row.original, '_id')}`
                  .toLowerCase()
                  .replace(/\s/g, '-')}
                variant="outline"
                leftIcon={<IconExternalLink size="0.9rem" />}
              >
                Open Link
              </Button>
            )
          }
          return (
            <Button
              component="a"
              // href={`http://localhost:3000/sathya-agency-${get(
              //   status.row.original.storeId,
              //   'locality'
              // )}-${get(status.row.original.storeId, 'city')}/${get(
              //   status.row.original.storeId,
              //   '_id'
              // )}/${get(status.row.original, 'store')}/${get(
              //   status.row.original,
              //   'keywords'
              // )}/${get(status.row.original, '_id')}`
              //   .toLowerCase()
              //   .replace(/\s/g, '-')}
              target="_blank"
              href={`${status.row.original.brandId.domainName}-${get(
                status.row.original.storeId,
                'locality'
              )}-${get(status.row.original.storeId, 'city')}/${get(
                status.row.original.storeId,
                '_id'
              )}/${get(status.row.original, 'store')}/${get(
                status.row.original,
                'keywords'
              )}/${get(status.row.original, '_id')}`
                .toLowerCase()
                .replace(/\s/g, '-')}
              variant="outline"
              leftIcon={<IconExternalLink size="0.9rem" />}
              disabled={true}
            >
              Open Link
            </Button>
          )
        },
      },

      {
        accessor: 'status',
        Header: 'Status',
        textAlignment: 'center',
        width: 70,
        Cell: ({ ...status }) => {
          if (status.row.original.status === true) {
            return <IconCircleCheck color="green" size={30} />
          }
          return <IconCircleX color="#ff2825" size={30} />
        },
      },
    ],
    [roleProvided, setSearchParams]
  )

  const colDef: DataTableColumn<any>[] = useMemo(
    () => [
      {
        Header: 'Store Id',
        accessor: 'storeCode',
        textAlignment: 'center',
        minWidth: 80,
        width: 90,
        maxWidth: 100,
        Cell: ({ ...value }: any) => <p>{value.row.original.storeCode}</p>,
      },
      {
        accessor: 'storeName',
        Header: 'Store Name',
        textAlignment: 'center',
        minWidth: 150,
        Cell: ({ ...value }: any) => <p>{value.row.original.storeName}</p>,
      },
      {
        accessor: 'brandId.name',
        Header: 'Brand',
        textAlignment: 'center',
        minWidth: 140,
        Cell: ({ ...value }: any) => <p>{value.row.original.brandId.name}</p>,
      },
      {
        Header: 'Keyword',
        accessor: 'keywords',
        textAlignment: 'center',
        width: 280,
        Cell: ({ ...value }: any) => <p>{value.row.original.keywords}</p>,
      },

      {
        Header: 'Volume',
        accessor: 'valume',
        textAlignment: 'center',
        width: 60,
        Cell: ({ ...value }: any) => <p>{value.row.original.valume}</p>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        textAlignment: 'center',
        width: 180,
        Cell: ({ ...value }: any) => <p>{value.row.original.status}</p>,
      },
      {
        Header: 'KeyWords Related',
        accessor: 'keyWordsRelated',
        textAlignment: 'center',
        width: 180,
        Cell: ({ ...value }: any) => (
          <p>{value.row.original.keyWordsRelated}</p>
        ),
      },
    ],
    [roleProvided, setSearchParams]
  )

  const onDownload = () => {
    setExportLoading(true)

    const match = {
      fromdate: moment(selectedDate).startOf('month').format('YYYY-MM-DD'),
      todate: moment(selectedDate).endOf('month').format('YYYY-MM-DD'),
      brandId: brandVal,
      store: storeVal,
      keywords: keywordVal,
    }

    const clean = (obj: any) => {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
      return obj
    }
    const mat = clean(match)

    const match1 = JSON.stringify(mat)
    const path = `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.EXPORT_DYNAMIC_CONTENT}?match=${match1}`
    axios({
      url: path,
      headers: {
        Authorization: `Bearer ${Token}`,
      },

      method: 'get',
      responseType: 'json', // important
    })
      .then((res) => {
        setExportLoading(false)
        const worksheet = XLSX.utils.json_to_sheet(res.data.data)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

        XLSX.writeFile(workbook, 'Closebi_Selected_Keywords.xlsx')
      })
      .catch((error) => {
        setExportLoading(false)
        dispatch(
          openSnackbar({
            messege: get(
              error,
              'response.data.message',
              'Failed To Download File'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        if (
          get(error, 'response.status', '') === 401 ||
          get(error, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        console.error('download file error', error)
      })
  }

  useEffect(() => {
    if (filterRef.current != null) {
      setTableHeight(window.innerHeight - filterRef.current.clientHeight - 104)
    }

    if (totalRowSize > pageSize) {
      const from = (page - 1) * pageSize
      const to = from + pageSize
      setCountForm(from + 1)
      setCountTo(to)
    } else {
      setCountForm(1)
      setCountTo(totalRowSize)
    }
    let rowDataSize

    if (totalRowSize >= pageSize) {
      rowDataSize = totalRowSize / pageSize

      if (rowDataSize - Math.floor(rowDataSize) !== 0) {
        setPageNo(Math.ceil(rowDataSize))
      } else {
        setPageNo(rowDataSize)
      }
    } else {
      rowDataSize = Math.ceil(totalRowSize / pageSize)
      setPageNo(rowDataSize)
    }
  }, [page, pageSize, recordData, theme, totalRowSize])
  useEffect(() => {
    if (filterRef.current != null) {
      setTableHeight1(window.innerHeight - filterRef.current.clientHeight - 104)
    }

    if (totalRowSize1 > pageSize1) {
      const from = (page1 - 1) * pageSize1
      const to = from + pageSize1
      setCountForm1(from + 1)
      setCountTo1(to)
    } else {
      setCountForm1(1)
      setCountTo1(totalRowSize1)
    }
    let rowDataSize

    if (totalRowSize1 >= pageSize1) {
      rowDataSize = totalRowSize1 / pageSize1

      if (rowDataSize - Math.floor(rowDataSize) !== 0) {
        setPageNo1(Math.ceil(rowDataSize))
      } else {
        setPageNo1(rowDataSize)
      }
    } else {
      rowDataSize = Math.ceil(totalRowSize1 / pageSize1)
      setPageNo1(rowDataSize)
    }
  }, [page1, pageSize1, recordData1, totalRowSize1])

  return (
    <>
      <Box>
        <Paper shadow="xs" p="md">
          <>
            <Grid ref={filterRef}>
              <Grid.Col xs={4} sm={4} md={4} lg={7} xl={7}>
                <Title order={3} weight={100}>
                  Pages On Fly
                </Title>
              </Grid.Col>

              <Grid.Col
                xs={12}
                sm={2}
                md={2}
                lg={1.5}
                xl={1.5}
                sx={{ zIndex: 1, display: 'flex', justifyContent: 'right' }}
              >
                <Popover width={350} withArrow shadow="xl" opened={openFilter}>
                  <Popover.Target>
                    <Button
                      variant="gradient"
                      gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                      onClick={() => setOpenFilter((o) => !o)}
                      disabled={loading}
                    >
                      <IconFilter />
                      <span style={{ marginLeft: '10px' }}>Filter</span>
                    </Button>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                      <Button
                        style={{
                          background: 'none',
                        }}
                        onClick={() => {
                          setOpenFilter(false)
                        }}
                      >
                        <IconX
                          color="black"
                          size={20}
                          style={{ marginLeft: '50px' }}
                        />
                      </Button>
                    </div>
                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      {' '}
                      <Select
                        label="Select Brand"
                        placeholder="Select Brand"
                        itemComponent={SelectItem}
                        data={brand}
                        limit={brand.length}
                        disabled={brand.length === 0}
                        maxDropdownHeight={200}
                        value={brandVal}
                        nothingFound="No Data Found"
                        clearable
                        onChange={(value: any) => {
                          setBrandVal(value)
                          setStoreVal(null)
                          setKeywordVal(null)
                        }}
                        styles={() => ({
                          item: {
                            // applies styles to selected item
                            '&[data-selected]': {
                              '&, &:hover': {
                                backgroundColor:
                                  theme.colorScheme === 'dark'
                                    ? theme.colors.teal[9]
                                    : theme.colors.teal[1],
                                color:
                                  theme.colorScheme === 'dark'
                                    ? theme.white
                                    : theme.colors.teal[9],
                              },
                            },

                            // applies styles to hovered item (with mouse or keyboard)
                            '&[data-hovered]': {},
                          },
                        })}
                      />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      {' '}
                      <Select
                        label="Select keyword"
                        placeholder="Select keyword"
                        itemComponent={SelectItem}
                        data={keyword}
                        limit={keyword.length}
                        disabled={keyword.length === 0 || storeVal !== null}
                        maxDropdownHeight={200}
                        value={keywordVal}
                        nothingFound="No Data Found"
                        clearable
                        onChange={(value: any) => {
                          setKeywordVal(value)
                        }}
                        styles={() => ({
                          item: {
                            // applies styles to selected item
                            '&[data-selected]': {
                              '&, &:hover': {
                                backgroundColor:
                                  theme.colorScheme === 'dark'
                                    ? theme.colors.teal[9]
                                    : theme.colors.teal[1],
                                color:
                                  theme.colorScheme === 'dark'
                                    ? theme.white
                                    : theme.colors.teal[9],
                              },
                            },

                            // applies styles to hovered item (with mouse or keyboard)
                            '&[data-hovered]': {},
                          },
                        })}
                      />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Select
                        label="Select Store"
                        placeholder="Select store"
                        clearable
                        searchable
                        disabled={
                          brandVal === null ||
                          storeDetails.length === 0 ||
                          keywordVal !== null
                        }
                        itemComponent={SelectItem}
                        data={storeDetails}
                        limit={storeDetails.length}
                        maxDropdownHeight={400}
                        rightSection={
                          !storeLoading ? (
                            <IconChevronDown size="1rem" />
                          ) : (
                            <Loader color="teal" size={20} />
                          )
                        }
                        nothingFound="No Data Found"
                        value={storeVal}
                        onChange={(value: any) => {
                          setStoreVal(value)
                          setStatusVal(null)
                        }}
                        styles={() => ({
                          item: {
                            // applies styles to selected item
                            '&[data-selected]': {
                              '&, &:hover': {
                                backgroundColor:
                                  theme.colorScheme === 'dark'
                                    ? theme.colors.teal[9]
                                    : theme.colors.teal[1],
                                color:
                                  theme.colorScheme === 'dark'
                                    ? theme.white
                                    : theme.colors.teal[9],
                              },
                            },

                            // applies styles to hovered item (with mouse or keyboard)
                            '&[data-hovered]': {},
                          },
                        })}
                      />
                    </Grid.Col>

                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Text>Select Month</Text>

                      <DatePicker
                        format="yyyy-MM"
                        ranges={[]}
                        oneTap
                        style={{
                          width: '700px',
                        }}
                        value={
                          selectedDate !== null
                            ? dayjs(selectedDate).toDate()
                            : toDate
                        }
                        shouldDisableDate={(date: any) => isAfter(date, toDate)}
                        disabled={storeVal === null}
                        onChange={(dates) => {
                          if (dates) {
                            setSelectedDate(moment(dates).format('YYYY-MM'))
                          }
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      {' '}
                      <Select
                        label="Select Status"
                        placeholder="Select Status"
                        data={[
                          {
                            label: 'Pending',
                            value: 'pending',
                          },
                          {
                            label: 'Rejected',
                            value: 'rejected',
                          },
                          {
                            label: 'Completed',
                            value: 'completed',
                          },
                        ]}
                        disabled={storeVal !== null || brandVal === null}
                        maxDropdownHeight={200}
                        value={statusVal}
                        nothingFound="No Data Found"
                        clearable
                        onChange={(value: any) => {
                          setStatusVal(value)
                        }}
                        styles={() => ({
                          item: {
                            // applies styles to selected item
                            '&[data-selected]': {
                              '&, &:hover': {
                                backgroundColor:
                                  theme.colorScheme === 'dark'
                                    ? theme.colors.teal[9]
                                    : theme.colors.teal[1],
                                color:
                                  theme.colorScheme === 'dark'
                                    ? theme.white
                                    : theme.colors.teal[9],
                              },
                            },

                            // applies styles to hovered item (with mouse or keyboard)
                            '&[data-hovered]': {},
                          },
                        })}
                      />
                    </Grid.Col>

                    <Grid.Col sx={{ display: 'flex', justifyContent: 'right' }}>
                      <Button
                        sx={{
                          backgroundColor: 'rgb(160, 213, 239)',

                          color: '#000',
                          '&[data-disabled]': { opacity: 0.4 },
                          '&:hover': {
                            backgroundColor: 'rgb(160, 213, 239)',
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                          },
                        }}
                        disabled={brandVal === null}
                        onClick={() => {
                          setOpenFilter(false)
                          setPage(1)
                          setPageNo(1)
                          setSearch(true)
                          setRecordData([])
                          setTotalRowSize(0)
                        }}
                      >
                        <Text color="black">Apply</Text>
                      </Button>
                    </Grid.Col>
                  </Popover.Dropdown>
                </Popover>
              </Grid.Col>
              <Grid.Col xs={2} sm={2} md={2} lg={1.5} xl={1.5}>
                <Button
                  style={{ width: '100%' }}
                  variant="gradient"
                  gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                  // className={classes.import}
                  leftIcon={<IconUpload size={18} />}
                  onClick={() => {
                    setUploadState(true)
                    setSearchParams({
                      mode: 'upload',
                    })
                  }}
                >
                  Upload
                </Button>
              </Grid.Col>

              <Grid.Col xs={2} sm={2} md={2} lg={1.5} xl={1.5}>
                <Button
                  style={{ width: '100%' }}
                  variant="gradient"
                  gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                  disabled={recordData1.length === 0}
                  leftIcon={<IconUpload size={18} />}
                  loading={exportLoading}
                  onClick={() => {
                    onDownload()
                  }}
                >
                  Export
                </Button>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col xs={12} style={{ width: 1060 }}>
                <Tabs color="green" defaultValue="keyword">
                  <Tabs.List>
                    <Tabs.Tab
                      value="keyword"
                      icon={<IconKeyboardShow size="0.9rem" color="green" />}
                    >
                      Keywords Uploaded
                    </Tabs.Tab>
                    <Tabs.Tab
                      value="edit"
                      icon={<IconMessageCircle size="0.9rem" color="green" />}
                      disabled={loading}
                    >
                      Edit Content
                    </Tabs.Tab>
                    {/* <Tabs.Tab
                      value="viewkeyword"
                      icon={<IconSquareKey size="0.9rem" color="green" />}
                      disabled={loading}
                    >
                      View Keyword Details
                    </Tabs.Tab> */}
                  </Tabs.List>

                  <Tabs.Panel value="keyword" pt="xs">
                    <CustomTable
                      pageFrom={countFrom}
                      pageTo={countTo}
                      total={pageNo}
                      gridApi={(instance) => {}}
                      totalRecords={!loading ? totalRowSize : 1}
                      checkboxSelection={false}
                      recordsPerPage={pageSize}
                      page={page}
                      height={tableHeight}
                      onPageChange={(p: any) => setPage(p)}
                      recordsPerPageOptions={PAGESIZES}
                      onRecordsPerPageChange={setPageSize}
                      loading={!!loading}
                      rowData={!loading ? recordData : []}
                      colDef={colDef}
                    />
                  </Tabs.Panel>

                  <Tabs.Panel value="edit" pt="xs">
                    <CustomTable
                      pageFrom={countFrom1}
                      pageTo={countTo1}
                      total={pageNo1}
                      gridApi={(instance) => {}}
                      totalRecords={!loading ? totalRowSize1 : 1}
                      checkboxSelection={false}
                      recordsPerPage={pageSize1}
                      page={page1}
                      height={tableHeight1}
                      onPageChange={(p: any) => setPage1(p)}
                      recordsPerPageOptions={PAGESIZES}
                      onRecordsPerPageChange={setPageSize1}
                      loading={!!loading1}
                      rowData={!loading1 ? recordData1 : []}
                      colDef={colDef1}
                    />
                  </Tabs.Panel>
                </Tabs>
              </Grid.Col>
            </Grid>
          </>
        </Paper>
      </Box>
      <Modal
        style={{
          fontSize: '18px',
          textTransform: 'capitalize',
          fontWeight: 500,
        }}
        opened={openView}
        centered
        onClose={() => {
          setOpenView(false)
          setSearchParams({})
        }}
        title="View Dynamic Content"
        size="xl"
      >
        <ViewContent onClose={handleClose} data={viewData} />
      </Modal>
      <Modal
        opened={uploadSate}
        centered
        onClose={() => {
          setUploadState(false)
          setSearchParams({})
        }}
        title="Upload Keyword"
        size="lg"
      >
        <SelectedKeyword onClose={handleClose} fetchData={() => fetchData()} />
      </Modal>
      <Modal
        opened={deleteOpen}
        centered
        onClose={() => {
          setDeleteOpen(false)
          setSearchParams({})
        }}
        title="Delete Store"
        size="lg"
      >
        <DeleteModal
          deleteName={deleteName}
          fetchData={fetchData}
          onClose={handleClose}
          deleteApi={deleteStore}
          deletedGetApi={deletedData}
        />
      </Modal>

      <Modal
        opened={open}
        centered
        onClose={() => {
          setOpen(false)
          setSearchParams({})
        }}
        title="Edit Pages On Fly"
        size="auto"
      >
        <EditContent
          onClose={handleClose}
          rowdataValue={buttonData}
          fetchData={() => fetchData()}
        />
      </Modal>
    </>
  )
}
