/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import {
  Box,
  Paper,
  Title,
  Grid,
  Button,
  Modal,
  Text,
  Group,
  Select,
  createStyles,
  SelectItemProps,
  Menu,
  MantineColor,
  Popover,
  Loader,
} from '@mantine/core'
import {
  IconCirclePlus,
  IconUpload,
  IconDownload,
  IconEyeCheck,
  IconEdit,
  IconTrash,
  IconDotsVertical,
  IconCircleX,
  IconCircleCheck,
  IconFilter,
  IconRefresh,
} from '@tabler/icons'
import { CSVLink } from 'react-csv'
import { AxiosError, AxiosResponse } from 'axios'
import _, { get, map } from 'lodash'
import { DataTableColumn } from 'mantine-datatable'
import { useEffect, useMemo, useState, forwardRef, useRef } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'

import CustomTable from '../../components/NewcustomTable'
import { API_SEVICES } from '../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
  ROLEPROVIDED,
  BRANDID,
  STOREID,
} from '../../constants'
import StoreModelForm from './StoreModelForm'
import UploadFileForm from './UploadFileForm'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { openSnackbar } from '../../redux/snackbar'
import DeleteModal from '../../components/DeleteModal'
import StoreView from './StoreView'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  description: string
  cityName: string
}
interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}
const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
    fontStyle: 'Helvetica',
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
  tableBodyStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  filter: {
    marginLeft: '115px',
    '@media (max-width: 912px)': {
      marginLeft: '110px',
    },
  },
  import: {
    marginLeft: '52px',
    '@media (max-width: 912px)': {
      marginLeft: '40px',
    },
  },
  csv: {
    marginLeft: '30px',
    '@media (max-width: 912px)': {
      marginLeft: '30px',
    },
  },
  product: {
    marginLeft: '40px',
    '@media (max-width: 912px)': {
      marginLeft: '50px',
    },
  },
}))
export default function Store() {
  // eslint-disable-next-line react/prop-types
  const [searchParams, setSearchParams] = useSearchParams()
  const mode = searchParams.get('mode')
  const id = searchParams.get('id')
  const [brandData, setBrandData] = useState([])
  const [modelState, setModel] = useState(false)
  const [uploadSate, setUploadState] = useState(false)
  const [loading, setLoading] = useState(true)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { theme } = useStyles()
  const [recordData, setRecordData] = useState([])
  const [page, setPage] = useState(1)
  const [pageNo, setPageNo] = useState(1)
  const [tableHeight, setTableHeight] = useState(500)
  const [countFrom, setCountForm] = useState<any>()
  const [countTo, setCountTo] = useState<any>()
  const [pageSize, setPageSize] = useState(PAGESIZES[0])
  const [totalRowSize, setTotalRowSize]: any = useState(10)
  const [brandVal, setBrandVal] = useState(null)
  const [open, setOpen] = useState(false)
  const [active, setActive] = useState<any>(null)
  const [stateData, setStateData] = useState<any>([])
  const [city, setCity] = useState([])
  const navigate = useNavigate()
  const [storeData, setStoreData] = useState([])
  const filterRef = useRef<HTMLInputElement>(null)
  const [storeVal, setStoreVal] = useState<any>(null)
  const [stateValue, setStateValue] = useState<any>(null)
  const [cityValue, setCityValue] = useState<any>(null)
  const [search, setSearch] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [stateLoading, setStateLoading] = useState(false)
  const [cityLoading, setCityLoading] = useState(false)
  const deleteStore = API_ENDPOINTS.DELETE_STORE
  const deletedData = API_ENDPOINTS.GET_STORE
  const deleteName = 'store'
  const dispatch = useAppDispatch()
  const { classes, cx } = useStyles()
  const role = useAppSelector((state) => state.role)
  const brandID = role.brandId || BRANDID || null
  const roleProvided: string = role.role || ROLEPROVIDED
  const storeProvided: string = role.storeId || STOREID

  const handleClose = () => {
    setModel(false)
    setDeleteOpen(false)
    setOpen(false)
    setUploadState(false)
  }

  const fetchData = () => {
    setLoading(true)
    setSearch(true)
  }

  useEffect(() => {
    setLoading(true)
    setSearchLoading(true)
    let match
    if (
      brandVal === null &&
      storeVal === null &&
      cityValue === null &&
      stateValue === null
    ) {
      if (roleProvided === 'admin' && brandID) {
        match = { brandId: brandID }
      } else if (roleProvided === 'admin' && brandID === null) {
        match = { brandId: null, _id: null }
      } else if (roleProvided === 'user') {
        match = { brandId: brandID, _id: storeProvided }
      } else if (roleProvided === 'brand manager') {
        match = { brandId: brandID }
      } else if (roleProvided === 'CRM manager') {
        match = { brandId: brandID }
      } else {
        match = {}
      }
    } else if (roleProvided === 'admin') {
      match = {
        status: active,
        brandId: brandID?.[0],
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'CRM manager') {
      match = {
        status: active,
        brandId: brandVal,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'brand manager') {
      match = {
        status: active,
        brandId: brandID,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    } else {
      match = {
        status: active,
        brandId: brandVal,
        state: stateValue,
        city: cityValue,
        storeId: storeVal !== null ? [storeVal] : null,
      }
    }

    const clean = (obj: any) => {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
      return obj
    }
    const mat = clean(match)

    const match1 = JSON.stringify(mat)

    const controller = new AbortController()
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_STORE}?match=${match1}&pageNo=${page}&size=${pageSize} `,
      (res: AxiosResponse) => {
        setSearchLoading(false)
        setLoading(false)
        setTotalRowSize(res.data.TotalRecords)
        setRecordData(res.data.data)
      },
      (err: AxiosError) => {
        setRecordData([])
        setTotalRowSize(0)
        setSearchLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        } else if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Store Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          setLoading(false)
        }
        console.error(err)
      },
      controller.signal
    )
    setSearch(false)
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page, pageSize])

  useEffect(() => {
    const controller = new AbortController()
    let match
    if (brandID.length === 0) {
      match = JSON.stringify({ status: true })
    } else {
      match = JSON.stringify({ _id: brandID, status: true })
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${match}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Brand Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    let storeMatch
    if (brandID === null && roleProvided === 'admin') {
      storeMatch = JSON.stringify({
        brandId: '',
      })
    } else if (brandID === null && roleProvided === 'CRM manager') {
      storeMatch = JSON.stringify({
        brandId: '',
      })
    } else if (brandVal !== null) {
      storeMatch = JSON.stringify({ brandId: brandVal })
    } else if (brandID) {
      storeMatch = JSON.stringify({ brandId: brandID })
    } else {
      storeMatch = JSON.stringify({})
    }

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_STORE}?match=${storeMatch}`,
      (res: AxiosResponse) => {
        setStoreData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Store Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.GET_STATE_DETAILS
      }?match=${JSON.stringify({
        status: 'true',
        brandId: brandVal || brandID,
      })}`,
      (res: AxiosResponse) => {
        setStateLoading(false)
        const statesArray: any = map(
          res.data.data,
          (values: any) => values
        )?.map((option: any) => option.state)

        function removeDuplicates(arr: any) {
          return arr.filter(
            (item: any, index: any) => arr.indexOf(item) === index
          )
        }

        setStateData(removeDuplicates(statesArray))
      },
      (err: AxiosError) => {
        setStateLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'State Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.GET_CITY_DETAILS
      }?match=${JSON.stringify({
        state: stateValue,
        brandId: brandVal || brandID,
        status: 'true',
      })}`,
      (res: AxiosResponse) => {
        setCityLoading(false)
        const statesArray: any = map(
          res.data.data,
          (values: any) => values
        )?.map((option: any) => option.city)

        function removeDuplicates(arr: any) {
          return arr.filter(
            (item: any, index: any) => arr.indexOf(item) === index
          )
        }

        setCity(removeDuplicates(statesArray))
      },
      (err: AxiosError) => {
        setCityLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'City Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )

    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
  }, [brandVal, storeVal, stateValue])

  const brand = brandData.map((item: any) => ({
    ...item,
    label: item.name,
    value: item._id,
  }))
  const storeDetails = storeData.map((item: any) => ({
    ...item,
    value: item.storeId,
    label: item.storeName,
    description: item.locality,
    cityName: item.city,
  }))

  const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ description, value, image, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text>{value}</Text>
          </div>
        </Group>
      </div>
    )
  )
  AutoCompleteItem.displayName = 'AutoCompleteItem'
  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, name, description, cityName, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            {description || cityName ? (
              <Text size="xs" opacity={0.65}>
                {description}-{cityName}
              </Text>
            ) : null}
          </div>
        </Group>
      </div>
    )
  )

  const colDef: DataTableColumn<any>[] = useMemo(
    () => [
      {
        Header: 'Actions',
        accessor: 'actions',
        fixed: 'left',
        title: <Text mr="xs">Actions</Text>,
        textAlignment: 'center',
        width: 10,
        Cell: ({ ...row }) => (
          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}
          >
            <Menu shadow="md" width={130} position="right-start">
              <Menu.Target>
                <Button style={{ background: 'none' }}>
                  {' '}
                  <IconDotsVertical size={16} color="black" />
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  color="green"
                  icon={<IconEyeCheck size={14} />}
                  onClick={() => {
                    setOpen(true)
                    setSearchParams({
                      mode: 'view',
                      id: row?.row?.original._id,
                    })
                  }}
                >
                  View
                </Menu.Item>
                <Menu.Item
                  color="blue"
                  onClick={() => {
                    setModel(true)
                    setSearchParams({
                      mode: 'edit',
                      id: row?.row?.original._id,
                    })
                  }}
                  icon={<IconEdit size={14} />}
                  disabled={
                    roleProvided === 'user' || roleProvided === 'brand manager'
                  }
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  color="red"
                  onClick={() => {
                    setDeleteOpen(true)
                    setSearchParams({
                      mode: 'delete',
                      id: row?.row?.original._id,
                    })
                  }}
                  icon={<IconTrash size={14} />}
                  disabled={
                    roleProvided === 'user' || roleProvided === 'brand manager'
                  }
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        ),
      },
      {
        Header: 'Store Id',
        accessor: 'storeId',
        textAlignment: 'center',
        width: 20,
        Cell: ({ ...value }: any) => <p>{value.row.original.storeId}</p>,
      },
      {
        accessor: 'storeName',
        Header: 'Store Name',
        textAlignment: 'center',
        minWidth: 300,
        Cell: ({ ...value }: any) => (
          <p>
            {value.row.original.storeName}
            <div
              style={{
                display: 'flex',
                fontSize: '13px',
                color: 'grey',
                borderSpacing: 0,
              }}
            >
              {value.row.original.locality},{value.row.original.city}.
            </div>
          </p>
        ),
      },
      {
        accessor: 'brandId.name',
        Header: 'Brand',
        textAlignment: 'center',
        minWidth: 140,
        Cell: ({ ...value }: any) => <p>{value.row.original.brandId.name}</p>,
      },
      {
        accessor: 'type',
        Header: 'Type',
        textAlignment: 'center',
        minWidth: 80,
        Cell: ({ ...value }: any) => <p>{value.row.original.type}</p>,
      },
      {
        accessor: 'category',
        Header: 'Category',
        textAlignment: 'center',
        minWidth: 80,
        Cell: ({ ...value }: any) => <p>{value.row.original.category}</p>,
      },
      {
        accessor: 'status',
        Header: 'Status',
        width: 50,
        textAlignment: 'center',
        Cell: ({ ...status }) => {
          if (status.row.original.status === true) {
            return (
              <p>
                <IconCircleCheck color="green" size={30} />
              </p>
            )
          }
          return (
            <p>
              <IconCircleX color="#ff2825" size={30} />
            </p>
          )
        },
      },
      {
        accessor: 'subCategory',
        Header: 'SubCategory',
        textAlignment: 'center',
        minWidth: 80,
        Cell: ({ ...value }: any) => <p>{value.row.original.subCategory}</p>,
      },
      {
        accessor: 'state',
        Header: 'State',
        textAlignment: 'center',
        minWidth: 150,
        Cell: ({ ...value }: any) => <p>{value.row.original.state}</p>,
      },
      {
        accessor: 'city',
        Header: 'City',
        textAlignment: 'center',
        minWidth: 150,
        Cell: ({ ...value }: any) => <p>{value.row.original.city}</p>,
      },
      {
        accessor: 'locality',
        Header: 'Locality',
        textAlignment: 'center',
        minWidth: 150,
        Cell: ({ ...value }: any) => <p>{value.row.original.locality}</p>,
      },
    ],
    [roleProvided, setSearchParams]
  )

  useEffect(() => {
    if (filterRef.current != null) {
      setTableHeight(window.innerHeight - filterRef.current.clientHeight - 104)
    }

    if (totalRowSize > pageSize) {
      const from = (page - 1) * pageSize
      const to = from + pageSize
      setCountForm(from + 1)
      setCountTo(to)
    } else {
      setCountForm(1)
      setCountTo(totalRowSize)
    }
    let rowDataSize

    if (totalRowSize >= pageSize) {
      rowDataSize = totalRowSize / pageSize

      if (rowDataSize - Math.floor(rowDataSize) !== 0) {
        setPageNo(Math.ceil(rowDataSize))
      } else {
        setPageNo(rowDataSize)
      }
    } else {
      rowDataSize = Math.ceil(totalRowSize / pageSize)
      setPageNo(rowDataSize)
    }
  }, [page, pageSize, recordData, theme, totalRowSize])
  const stateDetail = stateData?.map((item: any) => ({
    ...item,
    value: item,
    label: item,
  }))

  const cityDetail = city.map((item: any) => ({
    ...item,
    value: item,
    label: item,
  }))
  const handleFlush = () => {
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.CACHE_FLUSH}`,
      (res: AxiosResponse) => {
        dispatch(
          openSnackbar({
            messege: 'Refreshed Successfully',
            icon: AlertIcons.success,
            title: 'success',
            color: AlertColor.success,
          })
        )
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(err, 'response.data.message', 'Cant be Refreshed'),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      }
    )
  }

  return (
    <>
      <Box>
        <Paper shadow="xs" p="md">
          <>
            <Grid ref={filterRef}>
              <Grid.Col xs={4} sm={4} md={3.5} lg={6} xl={6}>
                <Title order={3} weight={100}>
                  Stores
                </Title>
              </Grid.Col>
              {roleProvided === 'super admin' ? (
                <>
                  <Grid.Col xs={3} sm={2} md={2} lg={1.5} xl={1.5}>
                    <Popover
                      width={330}
                      position="bottom"
                      withArrow
                      shadow="xl"
                    >
                      <Popover.Target>
                        <Button
                          style={{ width: '100%' }}
                          // className={classes.filter}
                          gradient={{ from: 'teal', to: 'lime', deg: 150 }}
                          disabled={loading}
                        >
                          <IconFilter />
                          <span style={{ marginLeft: '10px' }}>Filter</span>
                        </Button>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          {' '}
                          {roleProvided === 'super admin' ? (
                            <Select
                              label="Select Brand"
                              placeholder="Select Brand"
                              itemComponent={SelectItem}
                              data={brand}
                              limit={brand.length}
                              maxDropdownHeight={200}
                              searchable
                              value={brandVal}
                              nothingFound="No Data Found"
                              clearable
                              onChange={(value: any) => {
                                setStateLoading(true)
                                setBrandVal(value)
                                setStoreData([])
                                setStateValue(null)
                                setStoreVal(null)

                                setCityValue(null)
                              }}
                              styles={() => ({
                                item: {
                                  // applies styles to selected item
                                  '&[data-selected]': {
                                    '&, &:hover': {
                                      backgroundColor:
                                        theme.colorScheme === 'dark'
                                          ? theme.colors.teal[9]
                                          : theme.colors.teal[1],
                                      color:
                                        theme.colorScheme === 'dark'
                                          ? theme.white
                                          : theme.colors.teal[9],
                                    },
                                  },

                                  // applies styles to hovered item (with mouse or keyboard)
                                  '&[data-hovered]': {},
                                },
                              })}
                            />
                          ) : null}
                        </Grid.Col>
                        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Text fz="sm" style={{ fontWeight: 500 }}>
                            Select State{' '}
                            {stateLoading ? (
                              <span style={{ margin: 5 }}>
                                <Loader size={15} />
                              </span>
                            ) : (
                              ''
                            )}
                          </Text>
                          <Select
                            // label="Select State"
                            placeholder="Select state"
                            clearable
                            searchable
                            itemComponent={SelectItem}
                            data={stateDetail}
                            limit={stateDetail.length}
                            disabled={stateData.length === 0}
                            maxDropdownHeight={200}
                            nothingFound="No Data Found"
                            value={stateValue}
                            onChange={(value: any) => {
                              setCityLoading(true)
                              setStateValue(value)
                              setStoreVal(null)
                              setCityValue(null)
                            }}
                            styles={() => ({
                              item: {
                                // applies styles to selected item
                                '&[data-selected]': {
                                  '&, &:hover': {
                                    backgroundColor:
                                      theme.colorScheme === 'dark'
                                        ? theme.colors.teal[9]
                                        : theme.colors.teal[1],
                                    color:
                                      theme.colorScheme === 'dark'
                                        ? theme.white
                                        : theme.colors.teal[9],
                                  },
                                }, // applies styles to hovered item (with mouse or keyboard)

                                '&[data-hovered]': {},
                              },
                            })}
                          />
                        </Grid.Col>

                        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Text fz="sm" style={{ fontWeight: 500 }}>
                            Select City{' '}
                            {cityLoading ? (
                              <span style={{ margin: 5 }}>
                                <Loader size={15} />
                              </span>
                            ) : (
                              ''
                            )}
                          </Text>
                          <Select
                            // label="Select City"
                            placeholder="Select city"
                            clearable
                            searchable
                            itemComponent={SelectItem}
                            data={cityDetail}
                            limit={cityDetail.length}
                            disabled={city.length === 0}
                            maxDropdownHeight={200}
                            nothingFound="No Data Found"
                            value={cityValue}
                            onChange={(value: any) => {
                              setCityValue(value)
                            }}
                            styles={() => ({
                              item: {
                                // applies styles to selected item
                                '&[data-selected]': {
                                  '&, &:hover': {
                                    backgroundColor:
                                      theme.colorScheme === 'dark'
                                        ? theme.colors.teal[9]
                                        : theme.colors.teal[1],
                                    color:
                                      theme.colorScheme === 'dark'
                                        ? theme.white
                                        : theme.colors.teal[9],
                                  },
                                }, // applies styles to hovered item (with mouse or keyboard)

                                '&[data-hovered]': {},
                              },
                            })}
                          />
                        </Grid.Col>
                        {roleProvided === 'super admin' ||
                        (brandID !== null && roleProvided === 'admin') ||
                        (brandID !== null && roleProvided === 'CRM manager') ? (
                          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Select
                              label="Select Store"
                              placeholder="Select store"
                              clearable
                              searchable
                              itemComponent={SelectItem}
                              data={storeDetails}
                              limit={storeDetails.length}
                              disabled={storeDetails.length === 0}
                              maxDropdownHeight={200}
                              nothingFound="No Data Found"
                              value={storeVal}
                              onChange={(value: any) => {
                                setStoreVal(value)
                                setStateValue(null)
                                setCityValue(null)
                              }}
                              styles={() => ({
                                item: {
                                  // applies styles to selected item
                                  '&[data-selected]': {
                                    '&, &:hover': {
                                      backgroundColor:
                                        theme.colorScheme === 'dark'
                                          ? theme.colors.teal[9]
                                          : theme.colors.teal[1],
                                      color:
                                        theme.colorScheme === 'dark'
                                          ? theme.white
                                          : theme.colors.teal[9],
                                    },
                                  },

                                  // applies styles to hovered item (with mouse or keyboard)
                                  '&[data-hovered]': {},
                                },
                              })}
                            />
                          </Grid.Col>
                        ) : null}
                        <Grid.Col xs={12}>
                          <Select
                            label="Status"
                            placeholder="Status"
                            clearable
                            searchable
                            value={active}
                            data={[
                              { value: 'true', label: 'Active' },
                              { value: 'false', label: 'In Active' },
                            ]}
                            onChange={(value: any) => {
                              setActive(value)
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          sx={{ display: 'flex', justifyContent: 'right' }}
                        >
                          <Button
                            sx={{
                              marginTop: '5px',
                              backgroundColor: 'rgb(160, 213, 239)',
                              color: '#000',
                              '&[data-disabled]': { opacity: 0.4 },
                              '&:hover': {
                                backgroundColor: 'rgb(160, 213, 239)',
                              },
                            }}
                            onClick={() => {
                              setPage(1)
                              setPageNo(1)
                              setSearch(true)
                              setRecordData([])
                              setTotalRowSize(0)
                            }}
                            loading={searchLoading}
                          >
                            <Text color="black">Apply</Text>
                          </Button>
                        </Grid.Col>
                      </Popover.Dropdown>
                    </Popover>
                  </Grid.Col>

                  <Grid.Col xs={2} sm={2} md={2} lg={1.5} xl={1.5}>
                    <Button
                      style={{ width: '100%' }}
                      variant="gradient"
                      gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                      // className={classes.import}
                      leftIcon={<IconUpload size={18} />}
                      onClick={() => {
                        setUploadState(true)
                        setSearchParams({
                          mode: 'upload',
                        })
                      }}
                    >
                      Upload
                    </Button>
                  </Grid.Col>
                  <Grid.Col xs={2} sm={2} md={2.5} lg={1.5} xl={1.5}>
                    <Button
                      style={{ width: '100%' }}
                      // className={classes.product}
                      variant="gradient"
                      gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                      leftIcon={<IconCirclePlus size={18} />}
                      onClick={() => {
                        setModel(true)
                        setSearchParams({ mode: 'add' })
                      }}
                    >
                      Add Store
                    </Button>
                  </Grid.Col>
                  <Grid.Col xs={2} sm={2} md={2} lg={1.5} xl={1.5}>
                    <Button
                      style={{ width: '100%' }}
                      variant="gradient"
                      gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                      // className={classes.import}
                      leftIcon={<IconRefresh size={18} />}
                      onClick={handleFlush}
                    >
                      Flush
                    </Button>
                  </Grid.Col>
                </>
              ) : (
                <>
                  <Grid.Col xs={12} sm={4} md={5} lg={3.5} xl={3.5} />
                  <Grid.Col
                    xs={12}
                    sm={2}
                    md={2}
                    lg={1.5}
                    xl={1.5}
                    sx={{ display: 'flex', justifyContent: 'right' }}
                  >
                    {roleProvided !== 'user' ? (
                      <Popover
                        width={350}
                        position="bottom"
                        withArrow
                        shadow="xl"
                      >
                        <Popover.Target>
                          <Button
                            // className={classes.filter}
                            style={{ width: '100%' }}
                            gradient={{ from: 'teal', to: 'lime', deg: 150 }}
                            disabled={loading}
                          >
                            <IconFilter />
                            <span style={{ marginLeft: '10px' }}>Filter</span>
                          </Button>
                        </Popover.Target>
                        <Popover.Dropdown>
                          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            {' '}
                            {roleProvided === 'CRM manager' ? (
                              <Select
                                label="Select Brand"
                                placeholder="Select Brand"
                                itemComponent={SelectItem}
                                data={brand}
                                limit={brand.length}
                                maxDropdownHeight={200}
                                searchable
                                value={brandVal}
                                nothingFound="No Data Found"
                                clearable
                                onChange={(value: any) => {
                                  setStateLoading(true)
                                  setBrandVal(value)
                                  setStoreData([])
                                  setStateValue(null)
                                  setStoreVal(null)
                                  setCityValue(null)
                                }}
                                styles={() => ({
                                  item: {
                                    // applies styles to selected item
                                    '&[data-selected]': {
                                      '&, &:hover': {
                                        backgroundColor:
                                          theme.colorScheme === 'dark'
                                            ? theme.colors.teal[9]
                                            : theme.colors.teal[1],
                                        color:
                                          theme.colorScheme === 'dark'
                                            ? theme.white
                                            : theme.colors.teal[9],
                                      },
                                    },

                                    // applies styles to hovered item (with mouse or keyboard)
                                    '&[data-hovered]': {},
                                  },
                                })}
                              />
                            ) : null}
                          </Grid.Col>
                          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Text fz="sm" style={{ fontWeight: 500 }}>
                              Select State{' '}
                              {stateLoading ? (
                                <span style={{ margin: 5 }}>
                                  <Loader size={15} />
                                </span>
                              ) : (
                                ''
                              )}
                            </Text>
                            <Select
                              // label="Select State"
                              placeholder="Select state"
                              clearable
                              searchable
                              itemComponent={SelectItem}
                              data={stateDetail}
                              limit={stateDetail.length}
                              disabled={stateData.length === 0}
                              maxDropdownHeight={200}
                              nothingFound="No Data Found"
                              value={stateValue}
                              onChange={(value: any) => {
                                setCityLoading(true)
                                setStateValue(value)
                                setStoreVal(null)
                                setCityValue(null)
                              }}
                              styles={() => ({
                                item: {
                                  // applies styles to selected item
                                  '&[data-selected]': {
                                    '&, &:hover': {
                                      backgroundColor:
                                        theme.colorScheme === 'dark'
                                          ? theme.colors.teal[9]
                                          : theme.colors.teal[1],
                                      color:
                                        theme.colorScheme === 'dark'
                                          ? theme.white
                                          : theme.colors.teal[9],
                                    },
                                  }, // applies styles to hovered item (with mouse or keyboard)

                                  '&[data-hovered]': {},
                                },
                              })}
                            />
                          </Grid.Col>

                          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Text fz="sm" style={{ fontWeight: 500 }}>
                              Select City{' '}
                              {cityLoading ? (
                                <span style={{ margin: 5 }}>
                                  <Loader size={15} />
                                </span>
                              ) : (
                                ''
                              )}
                            </Text>
                            <Select
                              // label="Select City"
                              placeholder="Select city"
                              clearable
                              searchable
                              itemComponent={SelectItem}
                              data={cityDetail}
                              limit={cityDetail.length}
                              disabled={city.length === 0}
                              maxDropdownHeight={200}
                              nothingFound="No Data Found"
                              value={cityValue}
                              onChange={(value: any) => {
                                setCityValue(value)
                              }}
                              styles={() => ({
                                item: {
                                  // applies styles to selected item
                                  '&[data-selected]': {
                                    '&, &:hover': {
                                      backgroundColor:
                                        theme.colorScheme === 'dark'
                                          ? theme.colors.teal[9]
                                          : theme.colors.teal[1],
                                      color:
                                        theme.colorScheme === 'dark'
                                          ? theme.white
                                          : theme.colors.teal[9],
                                    },
                                  }, // applies styles to hovered item (with mouse or keyboard)

                                  '&[data-hovered]': {},
                                },
                              })}
                            />
                          </Grid.Col>
                          {roleProvided === 'super admin' ||
                          (brandID !== null && roleProvided === 'admin') ||
                          (brandID !== null &&
                            roleProvided === 'CRM manager') ? (
                            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Select
                                label="Select Store"
                                placeholder="Select store"
                                clearable
                                searchable
                                itemComponent={SelectItem}
                                data={storeDetails}
                                limit={storeDetails.length}
                                disabled={storeDetails.length === 0}
                                maxDropdownHeight={200}
                                nothingFound="No Data Found"
                                value={storeVal}
                                onChange={(value: any) => {
                                  setStoreVal(value)
                                  setStateValue(null)
                                  setCityValue(null)
                                }}
                                styles={() => ({
                                  item: {
                                    // applies styles to selected item
                                    '&[data-selected]': {
                                      '&, &:hover': {
                                        backgroundColor:
                                          theme.colorScheme === 'dark'
                                            ? theme.colors.teal[9]
                                            : theme.colors.teal[1],
                                        color:
                                          theme.colorScheme === 'dark'
                                            ? theme.white
                                            : theme.colors.teal[9],
                                      },
                                    },

                                    // applies styles to hovered item (with mouse or keyboard)
                                    '&[data-hovered]': {},
                                  },
                                })}
                              />
                            </Grid.Col>
                          ) : (
                            ''
                          )}
                          <Grid.Col xs={12}>
                            <Select
                              label="Select Status"
                              placeholder="Status"
                              clearable
                              searchable
                              value={active}
                              data={[
                                { value: 'true', label: 'Active' },
                                { value: 'false', label: 'In Active' },
                              ]}
                              onChange={(value: any) => {
                                setActive(value)
                              }}
                            />
                          </Grid.Col>
                          <Grid.Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{ display: 'flex', justifyContent: 'right' }}
                          >
                            <Button
                              sx={{
                                marginTop: '5px',
                                backgroundColor: 'rgb(160, 213, 239)',
                                color: '#000',
                                '&[data-disabled]': { opacity: 0.4 },
                                '&:hover': {
                                  backgroundColor: 'rgb(160, 213, 239)',
                                },
                              }}
                              onClick={() => {
                                setPage(1)
                                setPageNo(1)
                                setSearch(true)
                                setRecordData([])
                                setTotalRowSize(0)
                              }}
                              loading={searchLoading}
                            >
                              <Text color="black">Apply</Text>
                            </Button>
                          </Grid.Col>
                        </Popover.Dropdown>
                      </Popover>
                    ) : (
                      ''
                    )}
                  </Grid.Col>
                </>
              )}
            </Grid>
            <Grid>
              <Grid.Col xs={12} style={{ width: 1060 }}>
                <CustomTable
                  pageFrom={countFrom}
                  pageTo={countTo}
                  total={pageNo}
                  gridApi={(instance) => {}}
                  totalRecords={!loading ? totalRowSize : 1}
                  checkboxSelection={false}
                  recordsPerPage={pageSize}
                  page={page}
                  height={tableHeight}
                  onPageChange={(p: any) => setPage(p)}
                  recordsPerPageOptions={PAGESIZES}
                  onRecordsPerPageChange={setPageSize}
                  loading={!!loading}
                  rowData={!loading ? recordData : []}
                  colDef={colDef}
                />
              </Grid.Col>
            </Grid>
          </>
        </Paper>
      </Box>
      <Modal
        style={{
          fontSize: '18px',
          textTransform: 'capitalize',
          fontWeight: 500,
        }}
        opened={modelState}
        centered
        onClose={() => {
          setModel(false)
          setSearchParams({})
        }}
        title={`${mode} Store`}
        size="xl"
      >
        <StoreModelForm
          fetchData={() => fetchData()}
          onOpen={() => setModel(true)}
          onClose={() => {
            setSearchParams({})
            handleClose()
          }}
          mode={searchParams.get('mode')}
          isEditMode={searchParams.get('mode')?.toLowerCase() === 'edit'}
          isViewMode={searchParams.get('mode')?.toLowerCase() === 'view'}
        />
      </Modal>
      <Modal
        opened={uploadSate}
        centered
        onClose={() => {
          setUploadState(false)
          setSearchParams({})
        }}
        title="Upload Store"
        size="xl"
      >
        <UploadFileForm onClose={handleClose} fetchData={() => fetchData()} />
      </Modal>
      <Modal
        opened={deleteOpen}
        centered
        onClose={() => {
          setDeleteOpen(false)
          setSearchParams({})
        }}
        title="Delete Store"
        size="lg"
      >
        <DeleteModal
          deleteName={deleteName}
          fetchData={fetchData}
          onClose={handleClose}
          deleteApi={deleteStore}
          deletedGetApi={deletedData}
        />
      </Modal>
      <Modal
        opened={open}
        centered
        onClose={() => {
          setOpen(false)
          setSearchParams({})
        }}
        title="View Store"
        size="xl"
      >
        <StoreView onClose={handleClose} />
      </Modal>
    </>
  )
}
