import { useEffect, useState } from 'react'
import {
  TextInput,
  PasswordInput,
  Paper,
  Container,
  Button,
  Center,
  createStyles,
} from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { AxiosError, AxiosResponse } from 'axios'
import { get } from 'lodash'
import { API_SEVICES } from '../../config/services'
import {
  AlertColor,
  AlertIcons,
  ROUTE_PATH,
  PERMISSION,
  SATHYAID,
} from '../../constants'
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../utils/localStorage'
import { useAppDispatch } from '../../redux/store'
import { openSnackbar } from '../../redux/snackbar'
import useAuth from '../../hooks/useAuth'
import { getRole } from '../../redux/role'

const useStyles = createStyles((theme) => ({
  formikError: {
    color: '#CC0000',
    fontSize: '13px',
  },
  outline: {
    border: 'none',
    outline: 'none !important',
  },
}))
export default function Login() {
  const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { classes } = useStyles()
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      setLoading(true)
      localStorage.clear()
      console.log('localstorage cleared')

      if (getLocalStorageItem('brandId') === 'null') {
        localStorage.clear()
        navigate('/login', { replace: true })
      }
      API_SEVICES.login(
        (res: AxiosResponse) => {
          setLoading(false)

          const brandValue = res?.data?.data?.data?.brand?.map(
            (val: any) => val?._id
          )
          const storeIdValue = res?.data?.data?.data?.store?.map(
            (val: any) => val?._id
          )
          const storeCodeValue = res?.data?.data?.data?.store?.map(
            (val: any) => val?.storeId
          )

          const role = get(res.data, 'data.data.role', '')

          setLocalStorageItem('token', get(res.data, 'data.token', null))
          setLocalStorageItem('role', get(res.data, 'data.data.role', ''))
          setLocalStorageItem(
            'user',
            JSON.stringify(get(res.data, 'data.data.firstName', {}))
          )
          setLocalStorageItem(
            'brandId',
            JSON.stringify(
              res?.data?.data?.data?.brand?.map((val: any) => val?._id)
            )
          )
          setLocalStorageItem(
            'brandName',
            JSON.stringify(
              res?.data?.data?.data?.brand?.map((val: any) => val?.name) || []
            )
          )

          setLocalStorageItem('storeId', JSON.stringify(storeIdValue || []))

          setLocalStorageItem('storeCode', JSON.stringify(storeCodeValue || []))
          if (getLocalStorageItem('role') === 'super admin') {
            navigate(`/superadmin${ROUTE_PATH.brand}`)
          } else if (
            getLocalStorageItem('role') === 'admin' &&
            res.data.data.data.brand.map((val: any) => val._id) ===
              '63e387a7f60befaca421836e'
          ) {
            navigate(`/${getLocalStorageItem('role')}${ROUTE_PATH.calllog}`)
          } else if (
            getLocalStorageItem('role') === 'CRM manager' &&
            res.data.data.data.brand.map((val: any) => val._id)
          ) {
            navigate(`/CRMmanager${ROUTE_PATH.brand}`)
          } else if (
            getLocalStorageItem('role') === 'admin' &&
            brandValue?.[0] === SATHYAID
          ) {
            navigate(`/${getLocalStorageItem('role')}${ROUTE_PATH.calllog}`)
          } else if (getLocalStorageItem('role') === 'user') {
            navigate(`/${getLocalStorageItem('role')}${ROUTE_PATH.calllog}`)
          } else if (getLocalStorageItem('role') === 'brand manager') {
            navigate(`/brandmanager${ROUTE_PATH.calllog}`)
          } else if (getLocalStorageItem('role') === 'admin manager') {
            navigate(`/adminmanager${ROUTE_PATH.calllog}`)
          }

          dispatch(
            getRole({
              userName: get(res.data, 'data.data.firstName', ''),
              role: get(res.data, 'data.data.role', ''),
              permission: get(PERMISSION, `${role}`, 'user'),
              brandId: res?.data?.data?.data?.brand?.map(
                (val: any) => val?._id
              ),
              storeId: res?.data?.data?.data?.store?.map(
                (val: any) => val?._id
              ),
              storeCode: storeCodeValue,

              brandName: res?.data?.data?.data?.brand?.map(
                (val: any) => val?.name
              ),
            })
          )
        },
        (err: AxiosError) => {
          setLoading(false)
          dispatch(
            openSnackbar({
              messege: get(err, 'response.data.data'),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          console.error(err)
        },
        values
      )
    },
  })
  const isAuth = useAuth({ role: getLocalStorageItem('role') })

  useEffect(() => {
    const BrandID = JSON.parse(getLocalStorageItem('brandId') as any)

    if (isAuth) {
      if (getLocalStorageItem('role') === 'super admin') {
        navigate(`/superadmin${ROUTE_PATH.brand}`)
      } else if (
        getLocalStorageItem('role') === 'admin' &&
        JSON.parse(getLocalStorageItem('brandId') as any)?.[0] === SATHYAID
      ) {
        navigate(`/${getLocalStorageItem('role')}${ROUTE_PATH.calllog}`)
      } else if (
        getLocalStorageItem('role') === 'admin' &&
        JSON.parse(getLocalStorageItem('brandId') as any)?.[0] !== SATHYAID
      ) {
        navigate(`/${getLocalStorageItem('role')}${ROUTE_PATH.brand}`)
      } else if (getLocalStorageItem('role') === 'CRM manager') {
        navigate(`/CRMmanager${ROUTE_PATH.brand}`)
      } else if (getLocalStorageItem('role') === 'brand manager') {
        navigate(`/brandmanager${ROUTE_PATH.calllog}`)
      } else if (getLocalStorageItem('role') === 'user') {
        navigate(`/user${ROUTE_PATH.calllog}`)
      } else if (
        getLocalStorageItem('role') === 'admin' &&
        BrandID?.[0] === SATHYAID
      ) {
        navigate(`/${getLocalStorageItem('role')}${ROUTE_PATH.calllog}`)
      } else if (getLocalStorageItem('role') === 'admin manager') {
        navigate(`/adminmanager${ROUTE_PATH.calllog}`)
      } else {
        navigate(`/${getLocalStorageItem('role')}${ROUTE_PATH.calllog}`)
      }
    }
  })
  return (
    <div
      className={classes.outline}
      role="button"
      tabIndex={0}
      onKeyDown={(e: any) => {
        if (e.key === 'Enter') {
          formik.handleSubmit()
        }
      }}
    >
      <Center style={{ width: '100VW', height: '100VH' }}>
        <Container
          my={40}
          sx={() => ({
            width: 500,
          })}
        >
          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <TextInput
              label="Email"
              placeholder="Your Mail Id"
              required
              id="email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <PasswordInput
              label="Password"
              placeholder="Your password"
              required
              id="password"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              mt="md"
            />

            <Button
              fullWidth
              mt="xl"
              onClick={() => {
                formik.handleSubmit()
              }}
              loading={loading || false}
              loaderPosition="right"
            >
              Sign in
            </Button>
          </Paper>
        </Container>
      </Center>
    </div>
  )
}
