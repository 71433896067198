import { useEffect, useState } from 'react'
import {
  Text,
  createStyles,
  Grid,
  SelectItemProps,
  Paper,
  Skeleton,
} from '@mantine/core'
import { AxiosError, AxiosResponse } from 'axios'
import { get } from 'lodash'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { API_SEVICES } from '../../../config/services'
import { API_ENDPOINTS } from '../../../constants'
import SpinningLoader from '../../../components/SpinningLoader'

// Dropzone Styles
const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
    fontStyle: 'Helvetica',
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
}))
interface IProps {
  onClose: () => void
}
interface ItemProps extends SelectItemProps {
  _id: any
  name: string
}
export default function StoreView(props: IProps) {
  const { onClose } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const [storeData, setStoreData] = useState([])
  const id = searchParams.get('id')
  const { classes } = useStyles()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  // eslint-disable-next-line react/display-name

  useEffect(() => {
    if (id) {
      setLoading(true)
      const match = {
        _id: id,
      }
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_ENDPOINTS.GET_STORE
        }?match=${JSON.stringify(match)}`,
        (res: AxiosResponse) => {
          setLoading(false)
          setStoreData(res.data.data[0])
        },
        (err: AxiosError) => {
          setLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
          console.error('err', err)
        }
      )
    }
  }, [id, navigate])

  return (
    <div className={classes.wrapper} style={{ width: '100%' }}>
      <Grid gutter={5}>
        <Grid.Col xs={6} sm={6} xl={6} lg={6} md={6}>
          <Paper
            shadow="xs"
            p="md"
            style={{ width: '740px', minHeight: '300px' }}
          >
            <Grid>
              {loading ? (
                <SpinningLoader />
              ) : (
                <>
                  <Grid.Col xs={6} sm={6} xl={6} lg={6} md={6}>
                    <Text fw={500}>Store Id</Text>
                    <Text fz="sm">
                      {' '}
                      {get(storeData, 'storeId') ? (
                        get(storeData, 'storeId')
                      ) : (
                        <Skeleton />
                      )}
                    </Text>
                    <Text fw={500} style={{ marginTop: '12px' }}>
                      {' '}
                      Store Name
                    </Text>
                    <Text fz="sm">
                      {' '}
                      {get(storeData, 'storeName')
                        ? get(storeData, 'storeName')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '12px' }}>
                      {' '}
                      Brand
                    </Text>
                    <Text fz="sm">
                      {' '}
                      {get(storeData, 'brandId.name')
                        ? get(storeData, 'brandId.name')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '12px' }}>
                      {' '}
                      Type
                    </Text>
                    <Text fz="sm">
                      {get(storeData, 'type') ? get(storeData, 'type') : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Category
                    </Text>
                    <Text fz="sm">
                      {' '}
                      {get(storeData, 'category')
                        ? get(storeData, 'category')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      SubCategory
                    </Text>
                    <Text fz="sm">
                      {get(storeData, 'subCategory')
                        ? get(storeData, 'subCategory')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Email
                    </Text>
                    <Text fz="sm">
                      {' '}
                      {get(storeData, 'email') ? get(storeData, 'email') : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Title
                    </Text>
                    <Text fz="sm">
                      {get(storeData, 'title') ? get(storeData, 'title') : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      AddressLine1
                    </Text>
                    <Text fz="sm">
                      {get(storeData, 'addressLine1')
                        ? get(storeData, 'addressLine1')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      AddressLine2
                    </Text>
                    <Text fz="sm">
                      {get(storeData, 'addressLine2')
                        ? get(storeData, 'addressLine2')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      State
                    </Text>
                    <Text fz="sm">
                      {' '}
                      {get(storeData, 'state') ? get(storeData, 'state') : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      City
                    </Text>
                    <Text fz="sm">
                      {' '}
                      {get(storeData, 'city') ? get(storeData, 'city') : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Locality
                    </Text>
                    <Text fz="sm">
                      {' '}
                      {get(storeData, 'locality')
                        ? get(storeData, 'locality')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      PinCode
                    </Text>
                    <Text fz="sm">
                      {' '}
                      {get(storeData, 'pinCode')
                        ? get(storeData, 'pinCode')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      OwnerName
                    </Text>
                    <Text fz="sm">
                      {' '}
                      {get(storeData, 'ownerName')
                        ? get(storeData, 'ownerName')
                        : '-'}
                    </Text>

                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      PhoneNumber
                    </Text>
                    <Text fz="sm">
                      {get(storeData, 'phoneNumber')
                        ? get(storeData, 'phoneNumber')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Notification Number
                    </Text>
                    <Text fz="sm">
                      {get(storeData, 'notificationNumber')
                        ? get(storeData, 'notificationNumber')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Offer
                    </Text>
                    {get(storeData, 'metaData') && (
                      <Grid>
                        <Grid.Col span={6}>
                          <Text fz="sm">Offer Title</Text>
                          <Text fz="sm">Offer Description</Text>
                        </Grid.Col>
                        <Grid.Col span={6}>
                          {Object.values(
                            JSON?.parse(get(storeData, 'metaData', '{}'))
                          ).map((data: any) => (
                            <>
                              <Text fz="sm" key={data}>
                                {get(data, 'title') !== ''
                                  ? get(data, 'title')
                                  : ''}
                              </Text>
                              <Text fz="sm" key={data}>
                                {get(data, 'description') !== ''
                                  ? get(data, 'description')
                                  : ''}
                              </Text>
                            </>
                          ))}
                        </Grid.Col>
                      </Grid>
                    )}
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      NoOfYearsInBussiness
                    </Text>
                    <Text fz="sm">
                      {get(storeData, 'noOfYearsInBussiness')
                        ? get(storeData, 'noOfYearsInBussiness')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      No of Marriages
                    </Text>
                    <Text fz="sm">
                      {get(storeData, 'noOfMarriage')
                        ? get(storeData, 'noOfMarriage')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Description
                    </Text>

                    <Text fz="sm">
                      {get(storeData, 'description')
                        ? get(storeData, 'description')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Longdescription
                    </Text>
                    <Text fz="sm">
                      {get(storeData, 'longdescription')
                        ? get(storeData, 'longdescription')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      MetaTitle
                    </Text>
                    <Text fz="sm">
                      {' '}
                      {get(storeData, 'metaTitle')
                        ? get(storeData, 'metaTitle')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      MetaKeywords
                    </Text>
                    <Text fz="sm">
                      {get(storeData, 'metaKeywords')
                        ? get(storeData, 'metaKeywords')
                        : '-'}
                    </Text>
                  </Grid.Col>
                  <Grid.Col xs={6} sm={6} xl={6} lg={6} md={6}>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      ShortDescription
                    </Text>

                    <Text fz="sm">
                      {get(storeData, 'shortDescription')
                        ? get(storeData, 'shortDescription')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      MetaDescription
                    </Text>
                    <Text fz="sm">
                      {get(storeData, 'metaDescription')
                        ? get(storeData, 'metaDescription')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      LinkedInUrl
                    </Text>
                    <Text fz="sm">
                      {get(storeData, 'linkedInUrl')
                        ? get(storeData, 'linkedInUrl')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      TwitterUrl
                    </Text>
                    <Text fz="sm">
                      {' '}
                      {get(storeData, 'twitterUrl')
                        ? get(storeData, 'twitterUrl')
                        : '-'}
                    </Text>

                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      InstagramUrl
                    </Text>

                    <Text fz="sm">
                      {get(storeData, 'instagramUrl')
                        ? get(storeData, 'instagramUrl')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      LocationUrl
                    </Text>

                    <Text fz="sm">
                      {get(storeData, 'locationUrl')
                        ? get(storeData, 'locationUrl')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      YoutubeUrl
                    </Text>

                    <Text fz="sm">
                      {' '}
                      {get(storeData, 'youtubeUrl')
                        ? get(storeData, 'youtubeUrl')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Video Url
                    </Text>

                    <Text fz="sm">
                      {' '}
                      {get(storeData, 'videos[0]') !== 'undefined'
                        ? get(storeData, 'videos[0]')
                        : '-'}
                    </Text>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Status
                    </Text>
                    <Text fz="sm">
                      {get(storeData, 'status') ? 'Active' : 'Inactive'}
                    </Text>

                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Store Image
                    </Text>
                    {get(storeData, 'storeImage') ? (
                      <div>
                        <img
                          src={get(storeData, 'storeImage.file.Location')}
                          height={150}
                          width={260}
                          alt="logoFile"
                        />
                      </div>
                    ) : (
                      '-'
                    )}
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      Store Gallery
                    </Text>

                    {get(storeData, 'storeGallery') ? (
                      <div>
                        {get(storeData, 'storeGallery', []).map(
                          (image: any) => (
                            <div style={{ marginTop: '10px' }} key={image}>
                              <img
                                src={image.file.Location}
                                height={150}
                                width={260}
                                alt="logoFile"
                              />
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      '-'
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12} sm={12} xl={12} lg={12} md={12}>
                    <Text fw={500} style={{ marginTop: '10px' }}>
                      {' '}
                      MetaData
                    </Text>
                    {get(storeData, 'metaData') && (
                      <Grid>
                        <Grid.Col span={6}>
                          <>
                            {Object.keys(
                              JSON?.parse(get(storeData, 'metaData', '{}'))
                            ).map((val) => (
                              <Text fz="sm" key={val}>
                                {val}
                              </Text>
                            ))}
                          </>
                        </Grid.Col>
                        <Grid.Col span={6}>
                          {Object.values(
                            JSON.parse(get(storeData, 'metaData', '{}'))
                          ).map((data: any) => (
                            <Text fz="sm" key={data}>
                              {get(data, 'title') === '' &&
                                (get(data, 'title') === '' ? '-' : data)}
                              {get(data, 'title') !== '' &&
                                (get(data, 'title')
                                  ? get(data, 'title')
                                  : data)}
                            </Text>
                          ))}
                        </Grid.Col>
                      </Grid>
                    )}
                  </Grid.Col>
                </>
              )}
            </Grid>
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  )
}
