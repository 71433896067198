/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  ChartDataset,
  CategoryScale,
  LinearScale,
  registerables,
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, ...registerables)
interface IProps {
  data: ChartDataset
  title: string
}
function BarChat(props: any) {
  const { data, title } = props

  const k = 'k'
  const m = 'M'
  const options: any = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: title,
      },
      tooltip: {
        callbacks: {
          beforeTitle: function (context: any) {
            return title
          },
        },
      },
      legend: {
        position: 'top',
        display: false,
      },
    },
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      y: {
        ticks: {
          callback: function (datas: any) {
            if (datas < 1000) {
              return datas
            }
            if (datas < 900000) {
              return datas / 1000 + k
            }
            return datas / 1000000 + m
          },
        },
      },
    },
  }
  return <Bar data={data} options={options} />
}

export default BarChat
