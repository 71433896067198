/* eslint-disable react/destructuring-assignment */

import { Navigate, Outlet } from 'react-router-dom'
import { ROUTE_PATH } from '../../constants'
import useAuth from '../../hooks/useAuth'

function PrivateRoute(children: any) {
  const auth = useAuth({ role: children.element.toString() })

  return auth ? <Outlet /> : <Navigate to={ROUTE_PATH.login} replace={true} />
}
export default PrivateRoute
