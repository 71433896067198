/* eslint-disable max-len */
import {
  Box,
  Paper,
  Title,
  Grid,
  Button,
  Modal,
  Text,
  Group,
  Menu,
  Select,
  createStyles,
  SelectItemProps,
} from '@mantine/core'
import {
  IconCirclePlus,
  IconEyeCheck,
  IconEdit,
  IconTrash,
  IconDotsVertical,
} from '@tabler/icons'
import { AxiosError, AxiosResponse } from 'axios'
import { get } from 'lodash'
import { DataTableColumn } from 'mantine-datatable'
import { useEffect, useMemo, useState, forwardRef, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import CustomTable from '../../components/NewcustomTable'
import { API_SEVICES } from '../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
  ROLEPROVIDED,
} from '../../constants'
import { openSnackbar } from '../../redux/snackbar'
import UserModelForm from './UserModelForm'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import DeleteModal from '../../components/DeleteModal'
import UserViewModel from './UserViewModel'

interface ItemProps extends SelectItemProps {
  _id: any
  name: string
}
const useStyles = createStyles((theme) => ({
  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
}))
export default function Users() {
  const [searchParams, setSearchParams] = useSearchParams()
  const mode = searchParams.get('mode')
  const [modelState, setModel] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [recordData, setRecordData] = useState([])
  const [page, setPage] = useState(1)
  const [pageNo, setPageNo] = useState(1)
  const [pageSize, setPageSize] = useState(PAGESIZES[0])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [countFrom, setCountForm] = useState<any>()
  const [countTo, setCountTo] = useState<any>()
  const { theme } = useStyles()
  const [brandData, setBrandData] = useState([])
  const [totalRowSize, setTotalRowSize]: any = useState(10)
  const deleteBrand = API_ENDPOINTS.DELETE_USER
  const deletedData = API_ENDPOINTS.GET_USER
  const deleteName = 'User Name'
  const [tableHeight, setTableHeight] = useState(500)
  const [brandValue, setBrandValue] = useState(null)
  const filterRef = useRef<HTMLInputElement>(null)
  const dispatch = useAppDispatch()

  const role = useAppSelector((state) => state.role)
  const roleProvided = role.role || ROLEPROVIDED

  const handleClose = () => {
    setModel(false)
    setDeleteOpen(false)
    setSearchParams({})
  }

  const fetchData = () => {
    setLoading(true)
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_USER}?page=${page}&size=${pageSize}`,
      (res: AxiosResponse) => {
        setLoading(false)
        setTotalRowSize(res.data.TotalRecords)
        setRecordData(res.data.data)
      },
      (err: AxiosError) => {
        openSnackbar({
          messege: get(
            err,
            'response.data.message',
            'User Details Cant be fetched'
          ),
          icon: AlertIcons.error,
          title: 'error',
          color: AlertColor.error,
        })
        setLoading(false)
        console.error(err)
      }
    )
  }
  useEffect(() => {
    setLoading(true)
    const controller = new AbortController()
    let match
    if (brandValue !== null) {
      match = JSON.stringify({ brand: brandValue })
    } else {
      match = JSON.stringify({})
    }

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_USER}?page=${page}&size=${pageSize}&match=${match}`,
      (res: AxiosResponse) => {
        setLoading(false)
        setTotalRowSize(res.data.TotalRecords)
        setRecordData(res.data.data)
      },
      (err: AxiosError) => {
        setRecordData([])
        setTotalRowSize(0)
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'User Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          setLoading(false)
        }
        console.error(err)
      },
      controller.signal
    )
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.GET_BRAND
      }?match=${JSON.stringify({
        status: 'true',
      })}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Brand Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, brandValue])

  const brand = brandData.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.name,
  }))
  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ value, label, name, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
          </div>
        </Group>
      </div>
    )
  )
  const colDef: DataTableColumn<any>[] = useMemo(
    () => [
      {
        Header: 'Actions',
        accessor: 'actions',
        fixed: 'left',
        title: <Text mr="xs">Actions</Text>,
        textAlignment: 'center',
        Cell: ({ ...row }) => (
          <Menu shadow="md" width={130} position="right-start">
            <Menu.Target>
              <Button style={{ background: 'none' }}>
                {' '}
                <IconDotsVertical size={16} color="black" />
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                color="green"
                icon={<IconEyeCheck size={14} />}
                onClick={() => {
                  setOpen(true)
                  setSearchParams({
                    mode: 'view',
                    id: row?.row?.original._id,
                  })
                }}
              >
                View
              </Menu.Item>
              <Menu.Item
                color="blue"
                onClick={() => {
                  setModel(true)
                  setSearchParams({
                    mode: 'edit',
                    id: row?.row?.original._id,
                  })
                }}
                icon={<IconEdit size={14} />}
                disabled={roleProvided === 'user'}
              >
                Edit
              </Menu.Item>
              <Menu.Item
                color="red"
                onClick={() => {
                  setDeleteOpen(true)
                  setSearchParams({
                    mode: 'delete',
                    id: row?.row?.original._id,
                  })
                }}
                icon={<IconTrash size={14} />}
                disabled={roleProvided === 'admin'}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ),
      },
      {
        accessor: 'firstName',
        Header: 'First Name',
        textAlignment: 'center',
      },
      {
        accessor: 'lastName',
        Header: 'Last Name',
        textAlignment: 'center',
      },
      {
        accessor: 'email',
        Header: 'Email',
        textAlignment: 'center',
      },
      {
        accessor: 'role',
        Header: 'Role',
        textAlignment: 'center',
      },
      {
        accessor: 'brand',
        Header: 'Brand',
        textAlignment: 'center',
        Cell: ({ ...value }: any) => (
          <p>
            {value?.row?.original?.brand
              ?.map((val: any) => val?.name)
              .join(' , ')}
          </p>
        ),
      },

      {
        accessor: 'store',
        Header: 'Store',
        textAlignment: 'center',
        Cell: ({ ...value }: any) => (
          <p>
            {value?.row?.original?.store
              ?.map((val: any) => val?.storeName)
              .join(' , ')}
          </p>
        ),
      },
    ],
    [setSearchParams, roleProvided]
  )
  useEffect(() => {
    if (filterRef.current != null) {
      setTableHeight(window.innerHeight - filterRef.current.clientHeight - 110)
    }

    if (totalRowSize > pageSize) {
      const from = (page - 1) * pageSize
      const to = from + pageSize
      setCountForm(from + 1)
      setCountTo(to)
    } else {
      setCountForm(1)
      setCountTo(totalRowSize)
    }
    let rowDataSize

    if (totalRowSize >= pageSize) {
      rowDataSize = totalRowSize / pageSize

      if (rowDataSize - Math.floor(rowDataSize) !== 0) {
        setPageNo(Math.ceil(rowDataSize))
      } else {
        setPageNo(rowDataSize)
      }
    } else {
      rowDataSize = Math.ceil(totalRowSize / pageSize)
      setPageNo(rowDataSize)
    }
  }, [page, pageSize, recordData, totalRowSize])

  return (
    <>
      <Box>
        <Paper shadow="xs" p="md">
          <Grid>
            <Grid.Col
              xl={7}
              lg={7.5}
              md={6}
              xs={6}
              sm={6}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
              ref={filterRef}
            >
              <Title order={3} weight={100}>
                Users
              </Title>
            </Grid.Col>
            <Grid.Col xl={2} lg={1.5} md={3} xs={3} sm={3}>
              <Button
                // style={{ marginLeft: '40px' }}
                variant="gradient"
                gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                leftIcon={<IconCirclePlus size={18} />}
                onClick={() => {
                  setModel(true)
                  setSearchParams({ mode: 'add' })
                }}
              >
                Add Users
              </Button>
            </Grid.Col>
            {roleProvided === 'super admin' ? (
              <Grid.Col xl={3} lg={3} md={3} xs={3} sm={3}>
                <Select
                  placeholder="Select Brand"
                  itemComponent={SelectItem}
                  data={brand}
                  limit={brand.length}
                  searchable
                  maxDropdownHeight={200}
                  nothingFound="No Data Found"
                  clearable
                  onChange={(value: any) => {
                    setPage(1)
                    setPageNo(1)
                    setBrandValue(value)
                    // handleChange(value)
                  }}
                  styles={() => ({
                    item: {
                      // applies styles to selected item
                      '&[data-selected]': {
                        '&, &:hover': {
                          backgroundColor:
                            theme.colorScheme === 'dark'
                              ? theme.colors.teal[9]
                              : theme.colors.teal[1],
                          color:
                            theme.colorScheme === 'dark'
                              ? theme.white
                              : theme.colors.teal[9],
                        },
                      },

                      // applies styles to hovered item (with mouse or keyboard)
                      '&[data-hovered]': {},
                    },
                  })}
                />
              </Grid.Col>
            ) : null}
          </Grid>
          <Grid>
            <Grid.Col xs={12}>
              <CustomTable
                pageFrom={countFrom}
                pageTo={countTo}
                total={pageNo}
                gridApi={(instance) => {}}
                totalRecords={!loading ? totalRowSize : 1}
                checkboxSelection={false}
                recordsPerPage={pageSize}
                page={page}
                height={tableHeight}
                onPageChange={(p: any) => setPage(p)}
                recordsPerPageOptions={PAGESIZES}
                onRecordsPerPageChange={setPageSize}
                loading={!!loading}
                rowData={!loading ? recordData : []}
                colDef={colDef}
              />
            </Grid.Col>
          </Grid>
        </Paper>
      </Box>
      <Modal
        style={{
          fontSize: '18px',
          textTransform: 'capitalize',
          fontWeight: 500,
        }}
        opened={modelState}
        centered
        onClose={() => {
          setSearchParams({})
          setModel(false)
        }}
        title={`${mode} Users`}
        size="xl"
      >
        <UserModelForm
          fetchData={() => fetchData()}
          onClose={() => {
            setSearchParams({})
            handleClose()
          }}
          mode={searchParams.get('mode')}
          isEditMode={searchParams.get('mode')?.toLowerCase() === 'edit'}
          isViewMode={searchParams.get('mode')?.toLowerCase() === 'view'}
        />
      </Modal>

      <Modal
        opened={deleteOpen}
        centered
        onClose={() => {
          setDeleteOpen(false)
          setSearchParams({})
        }}
        title="Delete Users"
        size="lg"
      >
        <DeleteModal
          fetchData={fetchData}
          onClose={handleClose}
          deleteApi={deleteBrand}
          deletedGetApi={deletedData}
          deleteName={deleteName}
        />
      </Modal>
      <Modal
        opened={open}
        centered
        onClose={() => {
          setOpen(false)
          setSearchParams({})
        }}
        title="View Users"
        size="lg"
      >
        <UserViewModel onClose={handleClose} />
      </Modal>
    </>
  )
}
