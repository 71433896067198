import { Text, Grid } from '@mantine/core'
import { map, values } from 'lodash'
import moment from 'moment'

function ActionCard({ data, endDate, label }: any) {
  const valuesData = values(data).slice(1)
  const k = 'k'
  return (
    <div>
      <Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Text sx={{ textAlign: 'center', fontSize: '14px' }}>
            {' '}
            {endDate &&
              moment(new Date(endDate))
                .subtract(180, 'days')
                .format('DD MMM YYYY')}{' '}
            <span> - </span>
            {moment(endDate).format('DD MMM YYYY')}
          </Text>

          <hr />
        </Grid.Col>

        {/* <table>
        <tr> */}
        {/* <Grid> */}
        <Grid.Col xl={6} lg={6} xs={4} sm={6} md={6} sx={{ maxWidth: '50%' }}>
          {map(label, (getLabel) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                padding: '2px',
                fontSize: '12px',
              }}
            >
              {/* <td style={{ fontSize: '12px' }}>
              {getLabel}</td> */}
              {getLabel}
            </div>
          ))}
        </Grid.Col>
        <Grid.Col xs={4} sm={6} md={6} lg={6} xl={6} sx={{ maxWidth: '50%' }}>
          {map(valuesData, (getValuesData) => (
            <div style={{ padding: '2px', fontSize: '12px' }}>
              {/* <td style={{ fontSize: '12px' }}> */}
              {getValuesData < 1000
                ? getValuesData
                : (getValuesData / 1000).toFixed(2) + k}
              {/* </td> */}
            </div>
          ))}
        </Grid.Col>
      </Grid>
      {/* </tr>
      </table> */}
      {/* </Grid> */}
    </div>
  )
}

export default ActionCard
