import {
  useEffect,
  useState,
  forwardRef,
  useMemo,
  useRef,
  useCallback,
} from 'react'
import {
  Grid,
  TextInput,
  Button,
  FileInput,
  Checkbox,
  Loader,
  Group,
  Text,
  SelectItemProps,
  Autocomplete,
  Modal,
  ActionIcon,
  createStyles,
  Accordion,
  Textarea,
  MantineColor,
  Card,
  MultiSelect,
  MultiSelectValueProps,
  Box,
  CloseButton,
} from '@mantine/core'

import {
  IconTrash,
  IconUpload,
  IconCheck,
  IconX,
  IconPlus,
} from '@tabler/icons'
import { filter, get, map } from 'lodash'
import { AxiosError, AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import ReactQuill from 'react-quill'
import Compressor from 'compressorjs'
import * as Yup from 'yup'
import { useSearchParams, useNavigate } from 'react-router-dom'
import {
  AlertColor,
  AlertIcons,
  API_ENDPOINTS,
  BRANDID,
  STOREID,
  ROLEPROVIDED,
} from '../../../constants'
import EditorToolbar, { modules, formats } from '../../../components/TextEditor'
import { API_SEVICES } from '../../../config/services'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { openSnackbar } from '../../../redux/snackbar'
import AddField from '../../../components/AddField'
import SpinningLoader from '../../../components/SpinningLoader'

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
    fontStyle: 'Helvetica',
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
  formikError: {
    color: '#CC0000',
    fontSize: '13px',
  },
  outline: {
    border: 'none',
    outline: 'none !important',
  },
}))
interface IProps {
  fetchData: () => void
  onClose: () => void
  mode: any
  isEditMode: any
  isViewMode: any
  totalRowSize?: any
  productId?: any
}
interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  description: string
  cityName: string
}
interface Ifields {
  key: string
  value: string
}

interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}

function ProductModelForm(props: IProps) {
  const [productImage, setProductImage] = useState<any | null>(null)
  const dispatch = useAppDispatch()
  const {
    onClose,
    mode,
    isEditMode,
    isViewMode,
    fetchData,
    totalRowSize,
    productId,
  } = props

  const ref1 = useRef<HTMLInputElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [inputFields, setInputFields] = useState<Ifields[] | any[]>([])
  const [brandData, setBrandData] = useState([])
  const { theme, classes } = useStyles()
  const [textValue, setTextValue] = useState('')
  const [storeData, setStoreData] = useState([])
  const [category, setCategory] = useState([])
  const [categoryValue, setCategoryValue] = useState('')
  const [subCategory, setSubCategory] = useState([])
  const [brandIds, setBrandIds] = useState('')
  const id = searchParams.get('id')
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [productData, setProductData] = useState([])
  const [images, setImages] = useState<any | null>(null)
  const [productGalleryImages, setProductGalleryImages] = useState<any | null>(
    null
  )
  const [open, setOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [inputList, setInputList] = useState<Ifields[] | any[]>([''])
  const [storeList, setStoreList] = useState<string[]>([])
  const [featuresList, setFeaturesList] = useState<Ifields[] | any[]>([''])
  const [brandValueId, setBrandValueId] = useState('')
  const [storeValueId, setStoreValueId] = useState('')
  const [storeReturnData, setStoreReturnData] = useState<any[]>([])
  const role = useAppSelector((state) => state.role)
  const navigate = useNavigate()
  const brandID = role.brandId || BRANDID || null
  const storeID = role.storeId || STOREID || null
  const roleProvided = role.role || ROLEPROVIDED
  const [productList, setProductList] = useState([''])
  const [productListOfData, setProductListOfData] = useState([])
  const [productListData, setProductListData] = useState<Ifields[] | any[]>([
    '',
  ])

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, description, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          {/* <Avatar src={image} /> */}

          <div>
            <Text>{label}</Text>
          </div>
        </Group>
      </div>
    )
  )
  SelectItem.displayName = 'SelectItem'

  const getData: any = productList?.map((val: any) => ({
    name: val,
    status: true,
  }))

  // handle input change
  const handleRemoveClick = (index: any) => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }
  const handleInputChange = (e: any, index: any) => {
    const { value } = e.target
    const list: any = [...inputList]

    list[index] = value

    setInputList(list)
  }
  const handleAddClick = () => {
    setInputList([...inputList, ''])
  }
  // handle input change
  const handleRemoveClick1 = (index: any) => {
    const list = [...productListData]
    list.splice(index, 1)
    setProductListData(list)
  }
  const handleInputChange1 = (e: any, index: any) => {
    const { value } = e.target
    const list: any = [...productListData]

    list[index] = value

    setProductListData(list)
  }
  const handleAddClick1 = () => {
    setProductListData([...productListData, ''])
  }
  const featuresRemoveField = (index: any) => {
    const list = [...featuresList]
    list.splice(index, 1)
    setFeaturesList(list)
  }
  const featuresInputChange = (e: any, index: any) => {
    const { value } = e.target
    const list: any = [...featuresList]
    list[index] = value
    setFeaturesList(list)
  }
  const featuresAddClick = () => {
    setFeaturesList([...featuresList, ''])
  }
  const fileUpload = (event: any) => {
    if (event) {
      const file = [...event]
      const arr: any = []
      for (let i = 0; i < event.length; i++) {
        if (
          event[i].type === 'image/png' ||
          event[i].type === 'image/jpeg' ||
          event[i].type === 'image/webp' ||
          event[i].type === 'image/jpg'
        ) {
          const files: any = file[i]
          const image = new Compressor(files, {
            quality: 0.8,
            success: (compressedResult) => compressedResult,
          })
          const image1 = Object.values(image)
          arr.push(image1[0])
          setSelectedFile(arr)
        } else {
          setSelectedFile(null)
          dispatch(
            openSnackbar({
              messege: 'Image File Format is not supported',
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
      }
    }
  }

  const handleRemoveGal1 = (e: any) => {
    const s = selectedFile.filter((item: any, index: any) => index !== e)
    setSelectedFile(s)
  }
  // eslint-disable-next-line react/display-name
  const handleRemoveGal = (idx: any) => {
    const deleteImage = JSON.stringify({ imageId: idx })
    setLoading1(true)
    API_SEVICES.DeleteRequestObj(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.PRODUCT_DELETE_GALLERY}?id=${id}`,
      (res: AxiosResponse) => {
        setLoading1(false)
        dispatch(
          openSnackbar({
            messege: 'Product image Deleted Sucessfully',
            icon: AlertIcons.success,
            title: 'Success',
            color: AlertColor.success,
          })
        )
        setOpen(false)
        onClose()
      },
      (err: AxiosError) => {
        setLoading1(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Product image cant be Deleted '
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      },
      deleteImage
    )
  }
  function handleSubmit(event: any) {
    event.preventDefault()
    const formData = new FormData()
    if (selectedFile === null) {
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append('productGallery', selectedFile[i])
      }
    } else if (selectedFile !== null) {
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append('productGallery', selectedFile[i])
      }
    }

    setLoading1(true)
    API_SEVICES.PostRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.PRODUCT_GALLERY}?id=${id}`,
      (res: AxiosResponse) => {
        setLoading1(false)

        dispatch(
          openSnackbar({
            messege: 'Product image upload Sucessfully',
            icon: AlertIcons.success,
            title: 'Success',
            color: AlertColor.success,
          })
        )
        setOpen(false)
        onClose()
      },
      (err: AxiosError) => {
        setLoading1(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Product image Cant be upload '
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      },
      formData
    )
  }

  const formik = useFormik({
    initialValues: {
      productId: '',
      productName: '',
      description: '',
      title: '',
      brandId: '',
      store: [],
      category: '',
      subCategory: '',
      videos: [],
      status: true,
      rating: '',
      offer: {
        title: '',
        description: '',
      },
      specification: '',
      price: '',
      metaTitle: '',
      metaData: {},
      metaDescription: '',
      metaKeywords: '',
      shortDescription: '',
      featured: false,
      features: '',
      productImage: [],
      vendor: '',
      htmlTemplate1: '',
    },
    onSubmit: (values) => {
      const formData = new FormData()

      formData.append('productId', values.productId)
      formData.append('productName', values.productName)
      formData.append('brandId', values.brandId)
      formData.append('category', values.category)
      formData.append('description', values.description)
      formData.append('featured', JSON.stringify(values.featured))
      formData.append('metaDescription', values.metaDescription)
      formData.append('metaKeywords', values.metaKeywords)
      formData.append('metaTitle', values.metaTitle)
      formData.append('offer', JSON.stringify(values.offer))
      formData.append('price', values.price)
      formData.append('rating', values.rating)
      formData.append('shortDescription', values.shortDescription)
      formData.append('status', JSON.stringify(values.status))
      formData.append('subCategory', values.subCategory)
      formData.append('title', values.title)
      formData.append('vendor', values.vendor)

      if (getData.length > 0) {
        for (let i = 0; i < getData.length; i++) {
          formData.append(`aliasName.${[i]}`, JSON.stringify(getData[i]))
        }
      }

      if (storeList.length > 0) {
        for (let i = 0; i < storeList.length; i++) {
          formData.append(`store`, storeList[i])
        }
      }

      // if (values.store !== '') {
      //   formData.append('store', values.store)
      // }

      const object: any = {}

      if (inputList.length > 0) {
        for (let i = 0; i < [...inputList].length; i++) {
          object[i] = inputList[i]
        }
        formData.append('specification', JSON.stringify(object))
      }

      const featureObject: any = {}
      if (featuresList.length > 0) {
        for (let i = 0; i < [...featuresList].length; i++) {
          featureObject[i] = featuresList[i]
        }
        formData.append('features', JSON.stringify(featureObject))
      }
      if (values.videos.length > 0) {
        formData.append('videos.0', values.videos[0])
      }
      if (productImage !== null) {
        formData.append('productImage', productImage)
      }

      if (inputFields.length > 0) {
        const obj: any = {}
        for (let i = 0; i < [...inputFields].length; i++) {
          obj[inputFields[i].key] = inputFields[i].value
        }

        formData.append('metaData', JSON.stringify(obj))
      }

      setLoading1(true)
      !isEditMode || isViewMode
        ? API_SEVICES.PostRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.POST_PRODUCT}?brandId=${brandIds}`,
            (res: AxiosResponse) => {
              setLoading1(false)

              dispatch(
                openSnackbar({
                  messege: 'Product Added Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              if (
                get(err, 'response.status', '') === 401 ||
                get(err, 'response.status', '') === 403
              ) {
                localStorage.clear()
                navigate('/login', { replace: true })
              }
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'Product cant be  Added'
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              console.error(err)
            },
            formData
          )
        : API_SEVICES.PatchRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.EDIT_PRODUCT}?id=${id}`,
            (res: AxiosResponse) => {
              setLoading1(false)
              dispatch(
                openSnackbar({
                  messege: 'Product Updated Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              if (get(err, 'response.status') === 403) {
                localStorage.clear()
                navigate('/login', { replace: true })
              }
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'Product cant be  Updated'
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              console.error(err)
            },
            formData
          )
    },
    validationSchema: Yup.object({
      productId: Yup.string().required('Product Id is Required'),
      productName: Yup.string()
        .required('Product Name is Required')
        .min(2)
        .max(255),
      title: Yup.string().required('Title is Required').min(2).max(255),
      // store: Yup.string().required('Store is Required'),
      brandId: Yup.string().required('Brand Id is Required'),
      category: Yup.string().required('Category is Required'),
    }),
  })
  useEffect(() => {
    if (id) {
      setLoading(true)
      const match = {
        _id: id,
      }
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_ENDPOINTS.GET_PRODUCT
        }?match=${JSON.stringify(match)}`,
        (res: AxiosResponse) => {
          setBrandValueId(get(res, 'data.data[0].brandId.name'))
          setStoreValueId(get(res, 'data.data[0].store[0].storeName'))

          setLoading(false)
          formik.setFieldValue(
            'productId',
            get(res, 'data.data[0].productId', '')
          )
          formik.setFieldValue(
            'productName',
            get(res, 'data.data[0].productName', '')
          )
          formik.setFieldValue(
            'brandId',
            get(res, 'data.data[0].brandId._id', '')
          )
          formik.setFieldValue(
            'category',
            get(res, 'data.data[0].category', '')
          )
          formik.setFieldValue(
            'description',
            get(res, 'data.data[0].description', '')
          )
          formik.setFieldValue(
            'featured',
            get(res, 'data.data[0].featured', false)
          )
          formik.setFieldValue(
            'features',
            get(res, 'data.data[0].features', '')
          )
          formik.setFieldValue(
            'metaDescription',
            get(res, 'data.data[0].metaDescription', '')
          )
          formik.setFieldValue(
            'metaKeywords',
            get(res, 'data.data[0].metaKeywords', '')
          )
          formik.setFieldValue(
            'metaTitle',
            get(res, 'data.data[0].metaTitle', '')
          )
          formik.setFieldValue('price', get(res, 'data.data[0].price', ''))

          formik.setFieldValue(
            'productId',
            get(res, 'data.data[0].productId', '')
          )
          formik.setFieldValue(
            'productName',
            get(res, 'data.data[0].productName', '')
          )
          formik.setFieldValue('rating', get(res, 'data.data[0].rating', ''))
          formik.setFieldValue(
            'shortDescription',
            get(res, 'data.data[0].shortDescription', '')
          )
          formik.setFieldValue(
            'specification',
            get(res, 'data.data[0].specification', '')
          )
          formik.setFieldValue('status', get(res, 'data.data[0].status', false))
          formik.setFieldValue('store', get(res, 'data.data[0].store', ''))
          formik.setFieldValue(
            'subCategory',
            get(res, 'data.data[0].subCategory', '')
          )

          formik.setFieldValue('title', get(res, 'data.data[0].title', ''))
          formik.setFieldValue('vendor', get(res, 'data.data[0].vendor', ''))
          formik.setFieldValue('videos', get(res, 'data.data[0].videos', ''))
          formik.setFieldValue(
            'productImage',
            get(res, 'data.data[0].productImage[0].file', '')
          )
          formik.setFieldValue(
            'htmlTemplate1',
            get(res, 'data.data[0].htmlTemplate1', '')
          )
          setTextValue(get(res, 'data.data[0].htmlTemplate1', ''))
          setImages(res.data.data[0].productImage)

          setProductGalleryImages(res.data.data[0].productGallery)

          if (res.data.data[0].metaData.length === 0) {
            setInputFields([])
          } else {
            const metaData = JSON.parse(res.data.data[0].metaData)

            const metakeys = Object.keys(metaData)
            const metaArray = map(metakeys, (key) => ({
              key,
              value: metaData[key],
            }))
            setInputFields(metaArray)
          }
          if (res.data.data[0].specification.length === 0) {
            setInputList([])
          } else {
            const specification = JSON.parse(res.data.data[0].specification)
            const metakeys: any = Object.values(specification)
            setInputList(metakeys)
          }
          if (res.data.data[0].features.length === 0) {
            setFeaturesList([])
          } else {
            const features = JSON.parse(res.data.data[0].features)
            const metakeys: any = Object.values(features)
            setFeaturesList(metakeys)
          }
          formik.setFieldValue(
            'offer',
            JSON.parse(get(res, 'data.data[0].offer', ''))
          )
          setProductListData(
            get(res, 'data.data[0].aliasName')?.map((val: any) =>
              JSON.parse(val?.split(' '))?.name?.replace(',', ' ')
            )
          )
        },
        (err: AxiosError) => {
          setLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    setStoreList(formik.values.store?.map((i: any) => i._id))
  }, [formik.values.store, id])
  const handleDeleteField = (idx: any) => {
    setInputFields(filter(inputFields, (o) => o.key !== inputFields[idx].key))
  }

  const imageChange = async (e: any) => {
    if (
      e.type === 'image/png' ||
      e.type === 'image/jpeg' ||
      e.type === 'image/webp' ||
      e.type === 'image/jpg'
    ) {
      const file = e
      const image = new Compressor(file, {
        quality: 0.7,
        success: (compressedResult: any) => compressedResult,
      })
      const image1 = Object.values(image)
      setProductImage(image1[0])
      formik.setFieldValue('productImage', image1[0])
    } else {
      setProductImage(null)
      dispatch(
        openSnackbar({
          messege: 'Image File Format is not supported',
          icon: AlertIcons.error,
          title: 'error',
          color: AlertColor.error,
        })
      )
    }
  }

  const handleChangeEditor = (e: any) => {
    formik.setFieldValue('htmlTemplate1', e)
    setTextValue(e)
  }
  const removeImage = () => {
    setProductImage(null)
    setImages(null)
    formik.setFieldValue('productImage', [])
  }
  useEffect(() => {
    const match = {
      status: 'true',
      _id: brandID,
    }
    const mat =
      brandID.length !== 0 ? JSON.stringify(match) : JSON.stringify({})
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${mat}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Brand Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.CATEGORY_DETAILS
      }?match=${JSON.stringify({
        parentCategory: categoryValue,
        isSubCategory: 'true',
        status: 'true',
      })}`,
      (res: AxiosResponse) => {
        setSubCategory(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'SubCategory Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.CATEGORY_DETAILS
      }?match=${JSON.stringify({
        isSubCategory: 'false',
        status: 'true',
      })}`,
      (res: AxiosResponse) => {
        setCategory(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Category Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )

    if (formik.values.brandId !== '') {
      const storeMatch = JSON.stringify({
        brandId: formik.values.brandId,
        // status: true,
      })
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_STORE}?match=${storeMatch}`,
        (res: AxiosResponse) => {
          setStoreData(res.data.data)
        },
        (err: AxiosError) => {
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Store Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          console.error(err)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryValue, brandID, formik.values.brandId])
  const categoryDetail = category.map((item: any) => ({
    ...item,
    value: item.label,
  }))

  const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ description, value, image, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text>{value}</Text>
          </div>
        </Group>
      </div>
    )
  )
  AutoCompleteItem.displayName = 'AutoCompleteItem'

  const subCategoryDetail = subCategory.map((item: any) => ({
    ...item,
    value: item.label,
  }))
  const brand = useMemo(
    () =>
      brandData.map((item: any) => ({
        ...item,
        label: item._id,
        value: item.name,
      })),
    [brandData]
  )

  const store1 = useMemo(
    () =>
      storeData.map((item: any) => ({
        ...item,
        label: item.storeName,
        value: item._id,
        description: item.locality,
        cityName: item.city,
      })),
    [storeData]
  )

  const Value = useCallback(
    ({
      value,
      label,
      onRemove,
      classNames,
      ...others
    }: MultiSelectValueProps & { value: string }) => (
      <div {...others}>
        <Box
          sx={() => ({
            display: 'flex',
            cursor: 'default',
            alignItems: 'center',
            backgroundColor:
              theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            border: `1px solid ${
              theme.colorScheme === 'dark'
                ? theme.colors.dark[7]
                : theme.colors.gray[4]
            }`,
            paddingLeft: theme.spacing.xs,
            borderRadius: theme.radius.sm,
          })}
        >
          <Box sx={{ lineHeight: 1, fontSize: '15px' }}>{label}</Box>
          <CloseButton
            onMouseDown={onRemove}
            variant="transparent"
            size={22}
            iconSize={14}
            tabIndex={-1}
          />
        </Box>
      </div>
    ),
    [
      theme.colorScheme,
      theme.colors.dark,
      theme.colors.gray,
      theme.radius.sm,
      theme.spacing.xs,
      theme.white,
    ]
  )

  return (
    <div
      className={classes.outline}
      role="button"
      tabIndex={0}
      onKeyDown={(e: any) => {
        if (e.key === 'Enter') {
          formik.handleSubmit()
        }
      }}
    >
      <Accordion defaultValue="productDetails" sx={{ minHeight: '200px' }}>
        {loading ? (
          <SpinningLoader />
        ) : (
          <>
            {isEditMode ? (
              <Text fz="sm" style={{ fontSize: '15px' }}>
                Product Name : {formik.values.productName}
              </Text>
            ) : null}
            <Accordion.Item value="productDetails">
              <Accordion.Control>ProductDetails</Accordion.Control>
              <Accordion.Panel>
                <Grid>
                  <Grid.Col xs={12}>
                    <Autocomplete
                      label="category"
                      placeholder="e.g Select Category"
                      data={categoryDetail}
                      limit={categoryDetail.length}
                      withAsterisk
                      maxDropdownHeight={200}
                      defaultValue={formik.values.category}
                      itemComponent={AutoCompleteItem}
                      onItemSubmit={(item: any) => {
                        setCategoryValue(item.label)
                        formik.setFieldValue('category', item.label)
                      }}
                    />
                    {formik.errors.category && formik.touched.category && (
                      <div className={classes.formikError}>
                        {formik.errors.category}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Autocomplete
                      label="subCategory"
                      placeholder="e.g Select SubCategory"
                      data={subCategoryDetail}
                      maxDropdownHeight={200}
                      limit={subCategoryDetail.length}
                      defaultValue={formik.values.subCategory}
                      itemComponent={AutoCompleteItem}
                      onItemSubmit={(item: any) => {
                        formik.setFieldValue('subCategory', item.label)
                      }}
                    />
                    {formik.errors.subCategory &&
                      formik.touched.subCategory && (
                        <div className={classes.formikError}>
                          {formik.errors.subCategory}
                        </div>
                      )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Autocomplete
                      label="Select Brand"
                      placeholder="e.g Select Brand"
                      data={brand}
                      limit={brand.length}
                      withAsterisk
                      maxDropdownHeight={200}
                      defaultValue={brandValueId}
                      itemComponent={AutoCompleteItem}
                      onItemSubmit={(item: any) => {
                        setBrandIds(item._id)
                        formik.setFieldValue('brandId', item._id)
                      }}
                    />
                    {formik.errors.brandId && formik.touched.brandId && (
                      <div className={classes.formikError}>
                        {formik.errors.brandId}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    {/* <Autocomplete
                      label="Select Store"
                      placeholder="e.g Select Store"
                      data={store}
                      limit={store.length}
                      // withAsterisk
                      maxDropdownHeight={200}
                      itemComponent={AutoCompleteItem}
                      defaultValue={storeValueId}
                      onItemSubmit={(item: any) => {
                        setStoreId(item._id)
                        formik.setFieldValue('store', item._id)
                      }}
                    /> */}

                    <MultiSelect
                      label="Select Store"
                      placeholder="e.g Select Store"
                      clearable
                      data={store1}
                      ref={ref1}
                      defaultValue={storeList}
                      value={storeList}
                      // valueComponent={Value}
                      searchable
                      onChange={(item: any) => {
                        setStoreList(item)
                      }}
                    />
                    {formik.errors.store && formik.touched.store && (
                      <div className={classes.formikError}>
                        {formik.errors.store}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g PRODUCT001"
                      name="productId"
                      label="Product Id"
                      withAsterisk
                      onChange={formik.handleChange}
                      value={formik.values.productId}
                    />
                    {formik.errors.productId && formik.touched.productId && (
                      <div className={classes.formikError}>
                        {formik.errors.productId}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g Product Name"
                      name="productName"
                      label="Product Name"
                      withAsterisk
                      onChange={formik.handleChange}
                      value={formik.values.productName}
                    />
                    {formik.errors.productName &&
                      formik.touched.productName && (
                        <div className={classes.formikError}>
                          {formik.errors.productName}
                        </div>
                      )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g Product Title"
                      name="title"
                      label="Title"
                      withAsterisk
                      onChange={formik.handleChange}
                      value={formik.values.title}
                    />
                    {formik.errors.title && formik.touched.title && (
                      <div className={classes.formikError}>
                        {formik.errors.title}
                      </div>
                    )}
                  </Grid.Col>

                  <Grid.Col xs={12}>
                    <Textarea
                      placeholder="e.g Find closest TVS Electronic Store. Get contact information..."
                      name="description"
                      label="Description"
                      onChange={formik.handleChange}
                      value={formik.values.description}
                      autosize
                    />
                    {formik.errors.description &&
                      formik.touched.description && (
                        <div className={classes.formikError}>
                          {formik.errors.description}
                        </div>
                      )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g https://www.youtube.com/watch?v=zWh3CShX_do"
                      name="videos[0]"
                      label="Videos"
                      onChange={formik.handleChange}
                      value={formik.values.videos[0]}
                    />
                    {formik.errors.videos && formik.touched.videos && (
                      <div className={classes.formikError}>
                        {formik.errors.videos}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g 1.5"
                      name="rating"
                      label="Rating"
                      onChange={formik.handleChange}
                      value={formik.values.rating}
                    />
                  </Grid.Col>

                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="Title"
                      name="offer.title"
                      label="Offer Title"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.offer.title}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="Description"
                      name="offer.description"
                      label="Offer Description"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.offer.description}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g 10000"
                      name="price"
                      label="Price"
                      onChange={formik.handleChange}
                      value={formik.values.price}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g brand meta Title"
                      name="metaTitle"
                      label="MetaTitle"
                      onChange={formik.handleChange}
                      value={formik.values.metaTitle}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Textarea
                      placeholder="e.g Find closest TVS Electronic Store. Get contact information..."
                      name="metaDescription"
                      label="MetaDescription"
                      onChange={formik.handleChange}
                      value={formik.values.metaDescription}
                      autosize
                    />
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Textarea
                      placeholder="e.g Electronic store, printers and scanners price.."
                      name="metaKeywords"
                      label="MetaKeywords"
                      onChange={formik.handleChange}
                      value={formik.values.metaKeywords}
                      autosize
                    />
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Textarea
                      placeholder="e.g Find closest TVS Electronic Store. Get contact information..."
                      name="shortDescription"
                      label="Short Description"
                      onChange={formik.handleChange}
                      value={formik.values.shortDescription}
                      autosize
                    />
                  </Grid.Col>

                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g vendor"
                      name="vendor"
                      label="Vendor"
                      onChange={formik.handleChange}
                      value={formik.values.vendor}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <FileInput
                      label="Product Images (Only image/png,image/jpeg,image/webp file format is supported)"
                      // placeholder="image/png,image/jpeg,image/webp,image.jpg"
                      description="image/png,image/jpeg,image/webp,image.jpg"
                      icon={<IconUpload size={14} />}
                      name="productImage"
                      accept="image/png,image/jpeg,image/webP"
                      onChange={imageChange}
                    />
                  </Grid.Col>
                  {productImage ? (
                    <>
                      <Grid.Col xs={6}>
                        {productImage && (
                          <div>
                            <img
                              src={URL.createObjectURL(productImage)}
                              height={180}
                              width={300}
                              alt="logoFile"
                              style={{ marginTop: 10 }}
                            />
                          </div>
                        )}
                      </Grid.Col>
                      <Grid.Col xs={6}>
                        <Button
                          onClick={() => removeImage()}
                          style={{ background: 'none', marginLeft: '-95px' }}
                        >
                          <ActionIcon
                            sx={{
                              color: 'black',
                              backgroundImage:
                                'linear-gradient(45deg, #fd7e14 0%, #fa5252 100%)',
                            }}
                          >
                            <IconX size={15} />
                          </ActionIcon>
                        </Button>
                      </Grid.Col>
                    </>
                  ) : (
                    <div>
                      {images?.file && (
                        <Grid>
                          <Grid.Col xs={6}>
                            <img
                              src={images?.file?.Location}
                              height={180}
                              width={300}
                              alt="logoFile"
                              style={{ margin: 10 }}
                            />
                          </Grid.Col>
                          <Grid.Col xs={6}>
                            <Button
                              style={{
                                background: 'none',
                                marginLeft: '45px',
                              }}
                              onClick={() => handleRemoveGal(images._id)}
                            >
                              <ActionIcon
                                sx={{
                                  color: 'black',
                                  backgroundImage:
                                    'linear-gradient(45deg, #fd7e14 0%, #fa5252 100%)',
                                }}
                              >
                                <IconX size={15} />
                              </ActionIcon>
                            </Button>
                          </Grid.Col>
                        </Grid>
                      )}
                    </div>
                  )}
                  {isEditMode ? (
                    <>
                      <Grid.Col xs={12}>
                        <Text fz="sm" style={{ fontSize: '14px' }}>
                          Product Gallery Image(Only
                          image/png,image/jpeg,image/webp file format is
                          supported)
                        </Text>
                      </Grid.Col>
                      {productGalleryImages?.map((val: any) => (
                        <>
                          <Grid.Col xs={5} key={val}>
                            <img
                              style={{ margin: 10 }}
                              src={val?.file?.Location}
                              height={180}
                              width={300}
                              alt="logoFile"
                            />
                          </Grid.Col>
                          <Grid.Col xs={1} key={val}>
                            <Button
                              style={{
                                background: 'none',
                                marginLeft: '-30px',
                              }}
                              onClick={() => handleRemoveGal(val._id)}
                            >
                              <ActionIcon
                                sx={{
                                  color: 'black',
                                  backgroundImage:
                                    'linear-gradient(45deg, #fd7e14 0%, #fa5252 100%)',
                                }}
                              >
                                <IconX size={15} />
                              </ActionIcon>
                            </Button>
                          </Grid.Col>
                        </>
                      ))}

                      <Grid.Col xs={12}>
                        <Button
                          onClick={() => setOpen(true)}
                          style={{ margin: 10 }}
                        >
                          Add Image
                        </Button>
                      </Grid.Col>
                    </>
                  ) : (
                    ''
                  )}
                </Grid>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="AliasName">
              <Accordion.Control>Alias Name</Accordion.Control>
              <Accordion.Panel>
                <>
                  <Grid>
                    <Grid.Col xs={11}>
                      {open ? <TextInput placeholder="Alias Name" /> : ''}
                      {productListData.map((values: any, index: number) => (
                        <Grid key={values?.id}>
                          <Grid.Col xs={10}>
                            <TextInput
                              name="aliasName"
                              placeholder="Enter Alias Name"
                              value={values}
                              onChange={(e) => handleInputChange1(e, index)}
                            />
                          </Grid.Col>

                          <Grid.Col xs={2}>
                            {productListData.length !== 1 && (
                              <IconX
                                size={22}
                                color="red"
                                onClick={() => handleRemoveClick1(index)}
                                style={{ cursor: 'pointer' }}
                              />
                            )}
                            {productListData.length - 1 === index && (
                              <IconPlus
                                size={22}
                                color="green"
                                onClick={handleAddClick1}
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '10px',
                                }}
                              />
                            )}
                          </Grid.Col>
                        </Grid>
                      ))}
                    </Grid.Col>
                  </Grid>

                  <MultiSelect
                    style={{ marginTop: '10px' }}
                    placeholder="Alias Name"
                    itemComponent={SelectItem}
                    data={productListData}
                    searchable
                    nothingFound="Nobody here"
                    maxDropdownHeight={400}
                    onChange={(value: any) => {
                      setProductList(value)
                    }}
                  />

                  {productList.length > 1
                    ? productList.map((value: any, idx: any) => (
                        <Card key={value}>
                          <Text fz="xs" fw={700} style={{ lineHeight: '1px' }}>
                            {idx + 1} . {value}
                          </Text>
                        </Card>
                      ))
                    : ''}
                </>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="specification">
              <Accordion.Control>Specification Details</Accordion.Control>
              <Accordion.Panel>
                {inputList.map((values: any, index: number) => (
                  <Grid key={values?.id}>
                    <Grid.Col xs={10}>
                      <TextInput
                        name="specification"
                        placeholder="Enter Specification"
                        value={values}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </Grid.Col>

                    <Grid.Col xs={2}>
                      {inputList.length !== 1 && (
                        <IconX
                          size={24}
                          color="red"
                          onClick={() => handleRemoveClick(index)}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                      {inputList.length - 1 === index && (
                        <IconCheck
                          size={24}
                          color="green"
                          onClick={handleAddClick}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </Grid.Col>
                  </Grid>
                ))}
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="features">
              <Accordion.Control>Features Details</Accordion.Control>
              <Accordion.Panel>
                {featuresList.map((values: any, index: number) => (
                  <Grid key={values?.id}>
                    <Grid.Col xs={10}>
                      <TextInput
                        name="features"
                        placeholder="Enter Features Details"
                        value={values}
                        onChange={(e) => featuresInputChange(e, index)}
                      />
                    </Grid.Col>

                    <Grid.Col xs={2}>
                      {featuresList.length !== 1 && (
                        <IconX
                          size={24}
                          color="red"
                          onClick={() => featuresRemoveField(index)}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                      {featuresList.length - 1 === index && (
                        <IconCheck
                          size={24}
                          color="green"
                          onClick={featuresAddClick}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </Grid.Col>
                  </Grid>
                ))}
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="metaDataDetails">
              <Accordion.Control>MetaData</Accordion.Control>
              <Accordion.Panel>
                <>
                  {map(inputFields, (inputField: any, idx: any) => (
                    <AddField
                      key={idx}
                      isEditMode={isEditMode === true ? true : ''}
                      fieldKey={inputField.key}
                      fieldValue={inputField.value}
                      inputField={inputFields}
                      handleSave={(value) => {
                        setInputFields((prev) => [...prev, value])
                      }}
                      handleDelete={() => {
                        handleDeleteField(idx)
                      }}
                    />
                  ))}
                </>

                <AddField
                  isEditMode={isEditMode === true ? true : ''}
                  inputField={inputFields}
                  handleSave={(value) => {
                    setInputFields((prev) => [...prev, value])
                  }}
                  handleDelete={() => {}}
                />
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="HTMLTemplates">
              <Accordion.Control>HTML Templates</Accordion.Control>
              <Accordion.Panel>
                <Grid>
                  <Grid.Col xs={12}>
                    <div style={{ minHeight: '400px' }}>
                      <EditorToolbar />
                      <ReactQuill
                        style={{ height: '350px' }}
                        theme="snow"
                        value={textValue}
                        modules={modules}
                        formats={formats}
                        onChange={(e) => handleChangeEditor(e)}
                      />
                    </div>
                  </Grid.Col>
                </Grid>
              </Accordion.Panel>
            </Accordion.Item>
          </>
        )}
      </Accordion>
      <Grid style={{ marginTop: '10px' }}>
        <Grid.Col xs={3}>
          <Checkbox
            checked={formik.values.status}
            required
            name="status"
            label={formik.values.status === true ? 'Active' : 'Inactive'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid.Col>
        <Grid.Col xs={4}>
          <Checkbox
            checked={formik.values.featured}
            required
            name="featured"
            label="featured products"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid.Col>
        <Grid.Col xs={2} style={{ marginLeft: '48px' }}>
          <Button
            variant="gradient"
            onClick={() => {
              onClose()
            }}
            gradient={{ from: 'orange', to: 'red' }}
            disabled={loading1}
          >
            Cancel
          </Button>
        </Grid.Col>
        <Grid.Col xs={2}>
          <Button
            variant="gradient"
            gradient={{ from: 'teal', to: 'lime', deg: 105 }}
            className={!(formik.dirty && formik.isValid) ? 'disabled-btn' : ''}
            disabled={!isEditMode ? !(formik.dirty && formik.isValid) : false}
            onClick={() => {
              formik.handleSubmit()
              if (formik.isValid === false) {
                dispatch(
                  openSnackbar({
                    messege: 'Enter all required the fields!!',
                    icon: AlertIcons.error,
                    title: 'Error',
                    color: AlertColor.error,
                  })
                )
              }
            }}
            rightIcon={loading1 ? <Loader size={14} color="white" /> : ''}
          >
            {isEditMode ? 'Update' : 'Save'}
          </Button>
        </Grid.Col>
      </Grid>
      <Modal
        opened={open}
        centered
        onClose={() => {
          setOpen(false)
          setSearchParams({})
        }}
        title="Product Image Gallery (Upto 8 images can be Uploaded)"
        size="lg"
      >
        <Grid>
          <Grid.Col xs={12}>
            <FileInput
              multiple
              label="File Upload (Only image/png,image/jpeg,image/webp file format is supported)"
              description="image/png,image/jpeg,image/webp,image.jpg"
              // placeholder="image/png,image/jpeg,image/webp,image.jpg"
              icon={<IconUpload size={14} />}
              name="storeImage"
              disabled={loading1}
              onChange={fileUpload}
              accept="image/png,image/jpeg,image/webP"
            />
          </Grid.Col>
          {selectedFile?.length !== 0 ? (
            <div>
              {selectedFile?.map((image: any, index: any) => (
                <Grid key={image}>
                  {selectedFile && (
                    <>
                      <Grid.Col xs={6} sm={6} md={6} xl={6} lg={6}>
                        <div>
                          <img
                            src={URL.createObjectURL(image)}
                            height={200}
                            width={350}
                            alt="logoFile"
                            style={{ margin: 10 }}
                          />
                        </div>
                      </Grid.Col>
                      <Grid.Col xs={6} sm={6} md={6} xl={6} lg={6}>
                        <div>
                          <Button
                            onClick={() => handleRemoveGal1(index)}
                            style={{ background: 'none', marginLeft: '60px' }}
                          >
                            <ActionIcon
                              sx={{
                                color: 'black',
                                backgroundImage:
                                  'linear-gradient(45deg, #fd7e14 0%, #fa5252 100%)',
                              }}
                            >
                              <IconX size={15} />
                            </ActionIcon>
                          </Button>
                        </div>
                      </Grid.Col>
                    </>
                  )}
                </Grid>
              ))}
            </div>
          ) : (
            ''
          )}
          <Grid.Col xs={12}>
            <Button
              variant="gradient"
              gradient={{ from: 'teal', to: 'lime', deg: 105 }}
              onClick={(e: any) => handleSubmit(e)}
              loading={loading1 || false}
              loaderPosition="right"
              styles={() => ({
                root: {
                  border: 0,
                  height: 42,

                  paddingLeft: 20,
                  paddingRight: 20,

                  '&:hover': {
                    backgroundColor: theme.fn.darken('#00acee', 0.09),
                  },
                },
              })}
            >
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </div>
  )
}
export default ProductModelForm
