import { forwardRef, useEffect, useMemo, useState } from 'react'
import {
  Text,
  Group,
  Button,
  createStyles,
  Grid,
  FileInput,
  Select,
  SelectItemProps,
  Loader,
} from '@mantine/core'
import { CSVLink } from 'react-csv'
import { AxiosError, AxiosResponse } from 'axios'
import { IconUpload } from '@tabler/icons'
import { get } from 'lodash'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { API_SEVICES } from '../../../config/services'

import {
  AlertColor,
  AlertIcons,
  API_ENDPOINTS,
  BRANDID,
  ROLEPROVIDED,
} from '../../../constants'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { openSnackbar } from '../../../redux/snackbar'

// Dropzone Styles
const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
    border: 'none',
    outline: 'none !important',
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },
  formikError: {
    color: '#CC0000',
    fontSize: '13px',
  },
  outline: {
    border: 'none',
    outline: 'none !important',
  },
  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
}))
interface IProps {
  onClose: () => void
  fetchData: () => void
}
interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  description: string
  cityName: string
}
export default function UploadFileForm(props: IProps) {
  const { onClose, fetchData } = props
  const { classes, theme } = useStyles()
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [brandData, setBrandData] = useState([])
  const [storeData, setStoreData] = useState([])
  const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const role = useAppSelector((state) => state.role)
  const roleProvided = role.role || ROLEPROVIDED || null
  const brandID = role.brandId || BRANDID || null

  const FILE_SIZE = 160 * 1024
  const SUPPORTED_FORMATS = ['csv']
  const formik = useFormik({
    initialValues: {
      brandId: '',
      store: '',
      file: '',
    },
    onSubmit: (values) => {
      const formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('brandId', formik.values.brandId)
      formData.append('store', formik.values.store)
      setLoading(true)
      API_SEVICES.PostRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.POST_UPLOAD}`,
        (res: AxiosResponse) => {
          setLoading(false)
          dispatch(
            openSnackbar({
              messege: 'Product Upload Sucessfully',
              icon: AlertIcons.success,
              title: 'Success',
              color: AlertColor.success,
            })
          )
          onClose()
          fetchData()
        },
        (err: AxiosError) => {
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Product cant be Upload '
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          console.error(err)
        },
        formData
      )
    },
    validationSchema: Yup.object({
      brandId: Yup.string().required('Brand Id is Required'),
      store: Yup.string().required('Store Id is Required'),
      file: Yup.mixed()
        .test(
          'fileSize',
          'File Size is too large',
          (value) => value <= FILE_SIZE
        )
        .test('fileType', 'Unsupported File Format', (value) =>
          SUPPORTED_FORMATS.includes(value)
        ),
    }),
  })

  useEffect(() => {
    let match
    if (roleProvided === 'super admin') {
      match = JSON.stringify({ status: true })
    } else {
      match = JSON.stringify({ _id: brandID, status: true })
    }

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${match}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Brand Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )
    if (formik.values.brandId !== '') {
      const storeMatch = JSON.stringify({
        brandId: formik.values.brandId,
        status: true,
      })

      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_STORE}?match=${storeMatch}`,
        (res: AxiosResponse) => {
          setStoreData(res.data.data)
        },
        (err: AxiosError) => {
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Store Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          console.error(err)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandID, roleProvided, formik.values.brandId])
  function handleSubmit(event: any) {
    event.preventDefault()
    const formData = new FormData()
    formData.append('file', selectedFile)
    formData.append('brandId', formik.values.brandId)
    formData.append('store', formik.values.store)
    setLoading(true)
    API_SEVICES.PostRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.POST_UPLOAD}`,
      (res: AxiosResponse) => {
        setLoading(false)
        dispatch(
          openSnackbar({
            messege: 'Product Upload Sucessfully',
            icon: AlertIcons.success,
            title: 'Success',
            color: AlertColor.success,
          })
        )
        onClose()
        fetchData()
      },
      (err: AxiosError) => {
        setLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }

        dispatch(
          openSnackbar({
            messege: get(err, 'response.data.statusText'),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      },
      formData
    )
  }

  const fileUpload = (event: any) => {
    if (event.type === 'text/csv') {
      setSelectedFile(event)
    } else {
      setSelectedFile(null)
      dispatch(
        openSnackbar({
          messege: 'File Format is not supported',
          icon: AlertIcons.error,
          title: 'error',
          color: AlertColor.error,
        })
      )
    }
  }

  const brand = brandData.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.name,
  }))
  const store = useMemo(
    () =>
      storeData.map((item: any) => ({
        ...item,
        value: item._id,
        label: item.storeName,
        description: item.locality,
        cityName: item.city,
      })),
    [storeData]
  )
  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    (
      { value, label, name, description, cityName, ...others }: ItemProps,
      ref
    ) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            {description || cityName ? (
              <Text size="xs" opacity={0.65}>
                {description}-{cityName}
              </Text>
            ) : null}
          </div>
        </Group>
      </div>
    )
  )
  const data = [
    {
      productId: 'PRODUCT01',
      productName: 'bookwedgo kalyana mandapam',
      title: 'bookwedgo kalyana mandapam',
      category: 'hall',
      subCategory: 'banquet hall',
      status: '1',
      featured: '0',
      price: '1000',
      description:
        'Description is the pattern of narrative development that aims to make vivid a place, object, character, or group',
      shortDescription:
        'A short description is text that briefly introduces and describes a topic',
      metaDescription:
        'Description is the pattern of narrative development that aims to make vivid a place, object, character, or group',
      metaKeywords: '',
      metaTitle: 'brand meta title',
    },
  ]
  return (
    <div
      className={classes.wrapper}
      role="button"
      tabIndex={0}
      onKeyDown={(e: any) => {
        if (e.key === 'Enter') {
          handleSubmit(e)
        }
      }}
    >
      <Grid>
        <Grid.Col xs={12}>
          <Select
            label="Select Brand"
            placeholder="Select Brand"
            itemComponent={SelectItem}
            data={brand}
            withAsterisk
            maxDropdownHeight={400}
            nothingFound="No Brand Found"
            onChange={(value: any) => {
              formik.setFieldValue('brandId', value)
            }}
            styles={() => ({
              item: {
                // applies styles to selected item
                '&[data-selected]': {
                  '&, &:hover': {
                    backgroundColor:
                      theme.colorScheme === 'dark'
                        ? theme.colors.teal[9]
                        : theme.colors.teal[1],
                    color:
                      theme.colorScheme === 'dark'
                        ? theme.white
                        : theme.colors.teal[9],
                  },
                },

                // applies styles to hovered item (with mouse or keyboard)
                '&[data-hovered]': {},
              },
            })}
          />
          {formik.errors.brandId && formik.touched.brandId && (
            <div className={classes.formikError}>{formik.errors.brandId}</div>
          )}
        </Grid.Col>
        <Grid.Col xs={12}>
          <Select
            label="Select Store"
            placeholder="Select Store"
            itemComponent={SelectItem}
            data={store}
            withAsterisk
            maxDropdownHeight={200}
            nothingFound="No Store Found"
            onChange={(value: any) => {
              formik.setFieldValue('store', value)
            }}
            styles={() => ({
              item: {
                // applies styles to selected item
                '&[data-selected]': {
                  '&, &:hover': {
                    backgroundColor:
                      theme.colorScheme === 'dark'
                        ? theme.colors.teal[9]
                        : theme.colors.teal[1],
                    color:
                      theme.colorScheme === 'dark'
                        ? theme.white
                        : theme.colors.teal[9],
                  },
                },

                // applies styles to hovered item (with mouse or keyboard)
                '&[data-hovered]': {},
              },
            })}
          />
          {formik.errors.store && formik.touched.store && (
            <div className={classes.formikError}>{formik.errors.store}</div>
          )}
        </Grid.Col>
        <Grid.Col xs={12}>
          <FileInput
            label="File Upload (Only '.csv' file format is supported)"
            description="fileUpload"
            // placeholder="fileUpload"
            icon={<IconUpload size={14} />}
            name="storeImage"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={fileUpload}
          />
        </Grid.Col>
        <Grid.Col xs={12}>
          <CSVLink
            filename="UploadProduct-Sample"
            data={data}
            style={{ textDecoration: 'underline', color: 'green' }}
          >
            Download Sample CSV
          </CSVLink>
        </Grid.Col>
        <Button
          variant="gradient"
          gradient={{ from: 'teal', to: 'lime', deg: 105 }}
          onClick={(e: any) => handleSubmit(e)}
          rightIcon={loading ? <Loader size={14} color="white" /> : ''}
          className={!(formik.dirty && formik.isValid) ? 'disabled-btn' : ''}
          disabled={!formik.dirty}
          style={{ marginTop: '10px' }}
        >
          Submit
        </Button>
      </Grid>
    </div>
  )
}
