/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react'
import styled from '@emotion/styled'
import { Checkbox } from '@mantine/core'

const areEqual = (prevProps: any, nextProps: any) =>
  prevProps.checked === nextProps.checked &&
  prevProps.indeterminate === nextProps.indeterminate

export const IndeterminateCheckbox = React.memo(styled(Checkbox)({}), areEqual)

export default IndeterminateCheckbox
