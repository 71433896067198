/* eslint-disable max-len */
import {
  Box,
  Paper,
  Title,
  Grid,
  Button,
  Modal,
  Text,
  Group,
  Select,
  SelectItemProps,
  createStyles,
  Menu,
  ThemeIcon,
  Popover,
} from '@mantine/core'
import {
  IconCirclePlus,
  IconUpload,
  IconEyeCheck,
  IconEdit,
  IconTrash,
  IconDotsVertical,
  IconCircleCheck,
  IconCircleX,
  IconBuildingSkyscraper,
  IconFilter,
  IconRefresh,
} from '@tabler/icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AxiosError, AxiosResponse } from 'axios'
import _, { get } from 'lodash'
import { DataTableColumn } from 'mantine-datatable'
import { useEffect, useMemo, useState, forwardRef, useRef } from 'react'
import CustomTable from '../../components/NewcustomTable'
import { API_SEVICES } from '../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
  BRANDID,
  STOREID,
  ROLEPROVIDED,
} from '../../constants'
import { openSnackbar } from '../../redux/snackbar'
import ProductModelForm from './ProductModelForm'
import UploadFileForm from './UploadFileForm'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import DeleteModal from '../../components/DeleteModal'
import ProductViewModel from './ProductViewModal'

interface ImageProps {
  Location: string
}
interface ItemProps extends SelectItemProps, ImageProps {
  _id: any
  name: string
  description: string
  cityName: string
  logo: ImageProps
  storeName: string
}
const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
    fontStyle: 'Helvetica',
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
  filter: {
    marginLeft: '102px',
    '@media (max-width: 912px)': {
      marginLeft: '90px',
    },
  },
  import: {
    marginLeft: '38px',
    '@media (max-width: 912px)': {
      marginLeft: '20px',
    },
  },
  csv: {
    marginLeft: '15px',
    '@media (max-width: 912px)': {
      marginLeft: '10px',
    },
  },
  product: {
    marginLeft: '25px',
    '@media (max-width: 912px)': {
      marginLeft: '30px',
    },
  },
}))
export default function Product() {
  const [searchParams, setSearchParams] = useSearchParams()
  const mode = searchParams.get('mode')
  const [modelState, setModel] = useState(false)
  const [brandData, setBrandData] = useState<any>([])
  const [storeData, setStoreData] = useState<any>([])
  const [uploadSate, setUploadState] = useState(false)
  const [loading, setLoading] = useState(false)
  const [countFrom, setCountForm] = useState<any>()
  const [countTo, setCountTo] = useState<any>()
  const [recordData, setRecordData] = useState([])
  const [page, setPage] = useState(1)
  const [pageNo, setPageNo] = useState(1)
  const [pageSize, setPageSize] = useState(PAGESIZES[0])
  const [totalRowSize, setTotalRowSize]: any = useState(10)
  const [active, setActive] = useState<any>(null)
  const { classes, theme } = useStyles()
  const [open, setOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [brandValue, setBrandValue] = useState<any>(null)
  const [storeValue, setStoreValue] = useState<any>(null)
  const [tableHeight, setTableHeight] = useState(500)
  const deleteProduct = API_ENDPOINTS.DELETE_PRODUCT
  const deletedData = API_ENDPOINTS.GET_PRODUCT
  const deleteName = 'product'
  const dispatch = useAppDispatch()
  const filterRef = useRef<HTMLInputElement>(null)
  const role = useAppSelector((state) => state.role)
  const navigate = useNavigate()
  const brandID = role.brandId || BRANDID || null
  const storeProvided = role.storeId || STOREID || null
  const roleProvided = role.role || ROLEPROVIDED

  const handleClose = () => {
    setModel(false)
    setDeleteOpen(false)
    setUploadState(false)
  }

  const fetchData = () => {
    setLoading(true)
    let match
    if (brandValue === null && storeValue === null) {
      if (roleProvided === 'admin' && brandID) {
        match = { brandId: brandID }
      } else if (roleProvided === 'admin' && brandID === null) {
        match = { brandId: null, _id: null }
      } else if (roleProvided === 'CRM manager' && brandID) {
        match = { brandId: brandID }
      } else if (roleProvided === 'CRM manager' && brandID === null) {
        match = { brandId: null, _id: null }
      } else if (roleProvided === 'user') {
        match = { brandId: brandID, store: storeProvided }
      } else if (roleProvided === 'brand manager') {
        match = { brandId: brandID }
      } else {
        match = {}
      }
    } else if (roleProvided === 'admin' || roleProvided === 'CRM manager') {
      match = {
        brandId: brandID,
        store: storeValue !== null ? [storeValue] : null,
      }
    } else {
      match = {
        brandId: brandValue,
        store: storeValue !== null ? [storeValue] : null,
      }
    }

    const clean = (obj: any) => {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
      return obj
    }
    const mat = clean(match)
    const match1 = JSON.stringify(mat)

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_PRODUCT}?match=${match1}&pageNo=${page}&size=${pageSize} `,
      (res: AxiosResponse) => {
        setLoading(false)
        setTotalRowSize(res.data.TotalRecords)
        setRecordData(res.data.data)
      },
      (err: AxiosError) => {
        setLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Product Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )
  }
  useEffect(() => {
    setLoading(true)
    let match
    if (brandValue === null && storeValue === null) {
      if (roleProvided === 'admin' && brandID) {
        match = { brandId: brandID }
      } else if (roleProvided === 'admin' && brandID === null) {
        match = { brandId: null, _id: null }
      } else if (roleProvided === 'CRM manager' && brandID) {
        match = { brandId: brandID }
      } else if (roleProvided === 'CRM manager' && brandID === null) {
        match = { brandId: null, _id: null }
      } else if (roleProvided === 'user') {
        match = { brandId: brandID, store: storeProvided }
      } else if (roleProvided === 'brand manager') {
        match = { brandId: brandID }
      } else {
        match = {}
      }
    } else if (roleProvided === 'admin') {
      match = {
        status: active,
        brandId: brandID,
        store: storeValue !== null ? [storeValue] : null,
      }
    } else if (roleProvided === 'CRM manager') {
      match = {
        status: active,
        brandId: brandValue,
        store: storeValue !== null ? [storeValue] : null,
      }
    } else {
      match = {
        status: active,
        brandId: brandValue,
        store: storeValue !== null ? [storeValue] : null,
      }
    }

    const clean = (obj: any) => {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
      return obj
    }
    const mat = clean(match)
    const match1 = JSON.stringify(mat)

    const controller = new AbortController()
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_PRODUCT}?match=${match1}&pageNo=${page}&size=${pageSize} `,
      (res: AxiosResponse) => {
        setLoading(false)
        setTotalRowSize(res.data.TotalRecords)
        setRecordData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        } else if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Product Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          setLoading(false)
        }
        console.error(err)
      },
      controller.signal
    )
    let brandMatch
    if (brandID.length === 0) {
      brandMatch = JSON.stringify({
        status: 'true',
      })
    } else {
      brandMatch = JSON.stringify({
        status: 'true',
        _id: brandID,
      })
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${brandMatch}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Brand Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    let storeMatch
    if (brandID === null && roleProvided === 'admin') {
      storeMatch = JSON.stringify({
        brandId: '',
      })
    } else if (brandValue !== null) {
      storeMatch = JSON.stringify({ brandId: brandValue, status: true })
    } else if (brandID) {
      storeMatch = JSON.stringify({ brandId: brandID, status: true })
    } else {
      storeMatch = JSON.stringify({ status: true })
    }

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_STORE}?match=${storeMatch}`,
      (res: AxiosResponse) => {
        setStoreData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Store Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, brandValue, storeValue, active])

  const brand = brandData.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.name,
  }))
  const store = storeData.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.storeName,
    description: item.locality,
    cityName: item.city,
  }))
  const colDef: DataTableColumn<any>[] = useMemo(
    () => [
      {
        Header: 'Actions',
        accessor: 'actions',
        fixed: 'left',
        title: <Text mr="xs">Actions</Text>,
        textAlignment: 'center',
        width: 20,
        Cell: ({ ...row }) => (
          <Menu shadow="md" width={130} position="right-start">
            <Menu.Target>
              <Button style={{ background: 'none' }}>
                {' '}
                <IconDotsVertical size={16} color="black" />
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                color="green"
                icon={<IconEyeCheck size={14} />}
                onClick={() => {
                  setOpen(true)

                  setSearchParams({
                    mode: 'view',
                    id: row?.row?.original._id,
                  })
                }}
              >
                View
              </Menu.Item>
              <Menu.Item
                color="blue"
                onClick={() => {
                  setModel(true)
                  setSearchParams({
                    mode: 'edit',
                    id: row?.row?.original._id,
                  })
                }}
                icon={<IconEdit size={14} />}
                disabled={
                  roleProvided === 'user' || roleProvided === 'brand manager'
                }
              >
                Edit
              </Menu.Item>
              <Menu.Item
                color="red"
                onClick={() => {
                  setDeleteOpen(true)
                  setSearchParams({
                    mode: 'delete',
                    id: row?.row?.original._id,
                  })
                }}
                icon={<IconTrash size={14} />}
                disabled={
                  roleProvided === 'user' || roleProvided === 'brand manager'
                }
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ),
      },
      {
        accessor: 'productId',
        Header: 'Product Id',
        textAlignment: 'center',
        minWidth: 40,
      },
      {
        accessor: 'productName',
        Header: 'Product Name',
        textAlignment: 'center',
        minWidth: 200,
      },
      {
        accessor: 'brandId.name',
        Header: 'Brand',
        textAlignment: 'center',
        minWidth: 100,
      },
      {
        accessor: 'store.storeName',
        Header: 'Store',
        textAlignment: 'center',
        minWidth: 340,
        Cell: ({ ...status }) => status.row.original.store?.[0]?.storeName,
      },

      {
        accessor: 'category',
        Header: 'Category',
        textAlignment: 'center',
        minWidth: 100,
      },
      {
        accessor: 'subCategory',
        Header: 'SubCategory',
        textAlignment: 'center',
        minWidth: 100,
      },
      {
        accessor: 'status',
        Header: 'Status',
        width: 90,
        textAlignment: 'center',
        Cell: ({ ...status }) => {
          if (status.row.original.status === true) {
            return <IconCircleCheck color="green" size={30} />
          }
          return <IconCircleX color="#ff2825" size={30} />
        },
      },
    ],
    [roleProvided, setSearchParams]
  )

  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    (
      {
        value,
        label,
        name,
        description,
        cityName,
        logo,
        storeName,
        ...others
      }: ItemProps,
      ref
    ) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Grid>
              {logo ? (
                <Grid.Col span={10}>
                  <Text size="sm" sx={{ textTransform: 'capitalize' }}>
                    {label}
                  </Text>
                </Grid.Col>
              ) : (
                <>
                  <Grid.Col span={1} sx={{ marginRight: '4px' }}>
                    <ThemeIcon
                      size="sm"
                      variant="light"
                      gradient={{ from: 'indigo', to: 'cyan' }}
                    >
                      <IconBuildingSkyscraper />
                    </ThemeIcon>
                  </Grid.Col>
                  <Grid.Col span={10}>
                    <Text size="sm" sx={{ textTransform: 'capitalize' }}>
                      {label}
                    </Text>
                    <Text
                      size="xs"
                      opacity={0.65}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {description}-{cityName}
                    </Text>
                  </Grid.Col>
                </>
              )}
            </Grid>
          </div>
        </Group>
      </div>
    )
  )
  const data = [
    {
      productId: 'PRODUCT01',
      productName: 'bookwedgo kalyana mandapam',
      title: 'bookwedgo kalyana mandapam',
      category: 'hall',
      subCategory: 'banquet hall',
      status: '1',
      featured: '0',
      price: '1000',
      description:
        'Description is the pattern of narrative development that aims to make vivid a place, object, character, or group',
      shortDescription:
        'A short description is text that briefly introduces and describes a topic',
      metaDescription:
        'Description is the pattern of narrative development that aims to make vivid a place, object, character, or group',
      metaKeywords: '',
      metaTitle: 'brand meta title',
    },
  ]

  useEffect(() => {
    if (filterRef.current != null) {
      setTableHeight(window.innerHeight - filterRef.current.clientHeight - 125)
    }

    if (totalRowSize > pageSize) {
      const from = (page - 1) * pageSize
      const to = from + pageSize
      setCountForm(from + 1)
      setCountTo(to)
    } else {
      setCountForm(1)
      setCountTo(totalRowSize)
    }
    let rowDataSize

    if (totalRowSize >= pageSize) {
      rowDataSize = totalRowSize / pageSize

      if (rowDataSize - Math.floor(rowDataSize) !== 0) {
        setPageNo(Math.ceil(rowDataSize))
      } else {
        setPageNo(rowDataSize)
      }
    } else {
      rowDataSize = Math.ceil(totalRowSize / pageSize)
      setPageNo(rowDataSize)
    }
  }, [page, pageSize, recordData, theme, totalRowSize])
  const handleFlush = () => {
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.CACHE_FLUSH}`,
      (res: AxiosResponse) => {
        dispatch(
          openSnackbar({
            messege: 'Refreshed Successfully',
            icon: AlertIcons.success,
            title: 'success',
            color: AlertColor.success,
          })
        )
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(err, 'response.data.message', 'Cant be Refreshed'),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      }
    )
  }

  return (
    <>
      <Box>
        <Paper shadow="xs" p="md">
          <Grid ref={filterRef}>
            <Grid.Col xs={12} sm={4} md={4} lg={5.5} xl={5.5}>
              <Title order={3} weight={100}>
                Products
              </Title>
            </Grid.Col>
            {roleProvided === 'super admin' ? (
              <>
                <Grid.Col xs={12} sm={2} md={2} lg={1.5} xl={1.5}>
                  <Popover width={340} position="bottom" withArrow shadow="xl">
                    <Popover.Target>
                      <Button
                        // className={classes.filter}
                        style={{ width: '100%' }}
                        gradient={{ from: 'teal', to: 'lime', deg: 150 }}
                        disabled={loading}
                      >
                        <IconFilter />
                        <span style={{ marginLeft: '10px' }}>Filter</span>
                      </Button>
                    </Popover.Target>
                    <Popover.Dropdown>
                      {roleProvided === 'super admin' ? (
                        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Select
                            label="Select Brand"
                            placeholder="Select Brand"
                            itemComponent={SelectItem}
                            data={brand}
                            limit={brand.length}
                            disabled={brand.length === 0}
                            searchable
                            maxDropdownHeight={200}
                            value={brandValue}
                            nothingFound="No Data Found"
                            clearable
                            onChange={(value: any) => {
                              setLoading(true)
                              setPage(1)
                              setPageNo(1)
                              setBrandValue(value)
                              setStoreValue(null)
                            }}
                            styles={() => ({
                              item: {
                                // applies styles to selected item
                                '&[data-selected]': {
                                  '&, &:hover': {
                                    backgroundColor:
                                      theme.colorScheme === 'dark'
                                        ? theme.colors.teal[9]
                                        : '#e1f5d0',
                                    color:
                                      theme.colorScheme === 'dark'
                                        ? theme.white
                                        : theme.black,
                                  },
                                },

                                // applies styles to hovered item (with mouse or keyboard)
                                '&[data-hovered]': {},
                              },
                            })}
                          />
                        </Grid.Col>
                      ) : null}
                      <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {roleProvided === 'super admin' ||
                        (brandID !== null && roleProvided === 'admin') ||
                        (brandID !== null && roleProvided === 'CRM manager') ? (
                          <Select
                            label="Select Store"
                            placeholder="Select store"
                            clearable
                            searchable
                            itemComponent={SelectItem}
                            data={store}
                            limit={store.length}
                            disabled={store.length === 0}
                            maxDropdownHeight={200}
                            nothingFound="No Data Found"
                            value={storeValue}
                            onChange={(value: any) => {
                              setPage(1)
                              setPageNo(1)
                              setStoreValue(value)
                            }}
                            styles={() => ({
                              item: {
                                // applies styles to selected item
                                '&[data-selected]': {
                                  '&, &:hover': {
                                    backgroundColor:
                                      theme.colorScheme === 'dark'
                                        ? theme.colors.teal[9]
                                        : '#e1f5d0',
                                    color:
                                      theme.colorScheme === 'dark'
                                        ? theme.white
                                        : theme.black,
                                  },
                                },

                                // applies styles to hovered item (with mouse or keyboard)
                                '&[data-hovered]': {},
                              },
                            })}
                          />
                        ) : null}
                      </Grid.Col>
                      <Grid.Col xs={12}>
                        <Select
                          label="Status"
                          placeholder="Status"
                          clearable
                          searchable
                          value={active}
                          data={[
                            { value: 'true', label: 'Active' },
                            { value: 'false', label: 'In Active' },
                          ]}
                          onChange={(value: any) => {
                            setActive(value)
                          }}
                        />
                      </Grid.Col>
                    </Popover.Dropdown>
                  </Popover>
                </Grid.Col>
                <Grid.Col xs={12} sm={2} md={2} lg={1.5} xl={1.5}>
                  <Button
                    variant="gradient"
                    // className={classes.import}
                    style={{ width: '100%' }}
                    gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                    leftIcon={<IconUpload size={18} />}
                    onClick={() => {
                      setUploadState(true)
                      setSearchParams({
                        mode: 'upload',
                      })
                    }}
                  >
                    Upload
                  </Button>
                </Grid.Col>

                <Grid.Col xs={12} sm={2} md={2} lg={2} xl={2}>
                  <Button
                    // className={classes.product}
                    style={{ width: '100%' }}
                    variant="gradient"
                    gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                    leftIcon={<IconCirclePlus size={18} />}
                    onClick={() => {
                      setModel(true)
                      setSearchParams({ mode: 'add' })
                    }}
                  >
                    Add Product
                  </Button>
                </Grid.Col>
                <Grid.Col xs={12} sm={2} md={2} lg={1.5} xl={1.5}>
                  <Button
                    style={{ width: '100%' }}
                    variant="gradient"
                    gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                    // className={classes.import}
                    leftIcon={<IconRefresh size={18} />}
                    onClick={handleFlush}
                  >
                    Flush
                  </Button>
                </Grid.Col>
              </>
            ) : (
              <>
                <Grid.Col xs={12} sm={4.5} md={4.5} lg={3.5} xl={3.5} />
                <Grid.Col
                  xs={12}
                  sm={2}
                  md={2}
                  lg={1.5}
                  xl={1.5}
                  sx={{ display: 'flex', justifyContent: 'right' }}
                >
                  {roleProvided !== 'user' ? (
                    <Popover
                      width={350}
                      position="bottom"
                      withArrow
                      shadow="xl"
                    >
                      <Popover.Target>
                        <Button
                          // className={classes.filter}
                          style={{ width: '100%' }}
                          gradient={{ from: 'teal', to: 'lime', deg: 150 }}
                          disabled={loading}
                        >
                          <IconFilter />
                          <span style={{ marginLeft: '10px' }}>Filter</span>
                        </Button>
                      </Popover.Target>
                      <Popover.Dropdown>
                        {roleProvided === 'CRM manager' ? (
                          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Select
                              label="Select Brand"
                              placeholder="Select Brand"
                              itemComponent={SelectItem}
                              data={brand}
                              limit={brand.length}
                              disabled={brand.length === 0}
                              searchable
                              maxDropdownHeight={200}
                              value={brandValue}
                              nothingFound="No Data Found"
                              clearable
                              onChange={(value: any) => {
                                setLoading(true)
                                setPage(1)
                                setPageNo(1)
                                setBrandValue(value)
                                setStoreValue(null)
                              }}
                              styles={() => ({
                                item: {
                                  // applies styles to selected item
                                  '&[data-selected]': {
                                    '&, &:hover': {
                                      backgroundColor:
                                        theme.colorScheme === 'dark'
                                          ? theme.colors.teal[9]
                                          : '#e1f5d0',
                                      color:
                                        theme.colorScheme === 'dark'
                                          ? theme.white
                                          : theme.black,
                                    },
                                  },

                                  // applies styles to hovered item (with mouse or keyboard)
                                  '&[data-hovered]': {},
                                },
                              })}
                            />
                          </Grid.Col>
                        ) : null}
                        <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          {roleProvided === 'super admin' ||
                          (brandID !== null && roleProvided === 'admin') ||
                          (brandID !== null &&
                            roleProvided === 'CRM manager') ||
                          (brandID !== null &&
                            roleProvided === 'brand manager') ? (
                            <Select
                              label="Select Store"
                              placeholder="Select store"
                              clearable
                              searchable
                              itemComponent={SelectItem}
                              data={store}
                              limit={store.length}
                              disabled={store.length === 0}
                              maxDropdownHeight={200}
                              nothingFound="No Data Found"
                              value={storeValue}
                              onChange={(value: any) => {
                                setPage(1)
                                setPageNo(1)
                                setStoreValue(value)
                              }}
                              styles={() => ({
                                item: {
                                  // applies styles to selected item
                                  '&[data-selected]': {
                                    '&, &:hover': {
                                      backgroundColor:
                                        theme.colorScheme === 'dark'
                                          ? theme.colors.teal[9]
                                          : '#e1f5d0',
                                      color:
                                        theme.colorScheme === 'dark'
                                          ? theme.white
                                          : theme.black,
                                    },
                                  },

                                  // applies styles to hovered item (with mouse or keyboard)
                                  '&[data-hovered]': {},
                                },
                              })}
                            />
                          ) : null}
                        </Grid.Col>
                      </Popover.Dropdown>
                    </Popover>
                  ) : (
                    ''
                  )}
                </Grid.Col>
              </>
            )}{' '}
          </Grid>
          <Grid>
            <Grid.Col xs={12} style={{ marginTop: '20px', width: 1060 }}>
              <CustomTable
                pageFrom={countFrom}
                pageTo={countTo}
                total={pageNo}
                gridApi={(instance) => {}}
                totalRecords={!loading ? totalRowSize : 1}
                checkboxSelection={false}
                recordsPerPage={pageSize}
                page={page}
                height={tableHeight}
                onPageChange={(p: any) => setPage(p)}
                recordsPerPageOptions={PAGESIZES}
                onRecordsPerPageChange={setPageSize}
                loading={!!loading}
                rowData={!loading ? recordData : []}
                colDef={colDef}
              />
            </Grid.Col>
          </Grid>
        </Paper>
      </Box>

      <Modal
        style={{
          fontSize: '18px',
          textTransform: 'capitalize',
          fontWeight: 500,
        }}
        opened={modelState}
        centered
        onClose={() => {
          setModel(false)
          setSearchParams({})
        }}
        title={`${mode} product`}
        size="xl"
      >
        <ProductModelForm
          fetchData={() => fetchData()}
          totalRowSize={totalRowSize}
          onClose={() => {
            setSearchParams('')
            handleClose()
          }}
          mode={searchParams.get('mode')}
          isEditMode={searchParams.get('mode')?.toLowerCase() === 'edit'}
          isViewMode={searchParams.get('mode')?.toLowerCase() === 'view'}
          // productId={searchParams.get('id')}
        />
      </Modal>
      <Modal
        opened={uploadSate}
        centered
        onClose={() => {
          setUploadState(false)
          setSearchParams({})
        }}
        title="Upload Product"
        size="lg"
      >
        <UploadFileForm onClose={handleClose} fetchData={() => fetchData()} />
      </Modal>
      <Modal
        opened={deleteOpen}
        centered
        onClose={() => {
          setDeleteOpen(false)
          setSearchParams({})
        }}
        title="Delete Product"
        size="lg"
      >
        <DeleteModal
          deleteName={deleteName}
          fetchData={fetchData}
          onClose={handleClose}
          deleteApi={deleteProduct}
          deletedGetApi={deletedData}
        />
      </Modal>
      <Modal
        opened={open}
        centered
        onClose={() => {
          setOpen(false)
          setSearchParams({})
        }}
        title="View Product"
        size="xl"
      >
        <ProductViewModel onClose={handleClose} />
      </Modal>
    </>
  )
}
