/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import {
  Box,
  Paper,
  Title,
  Grid,
  Button,
  Modal,
  Text,
  Group,
  SelectItemProps,
  Select,
  Menu,
  Popover,
} from '@mantine/core'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  IconCirclePlus,
  IconEyeCheck,
  IconEdit,
  IconTrash,
  IconDotsVertical,
  IconFilter,
} from '@tabler/icons'
import { AxiosError, AxiosResponse } from 'axios'
import { get } from 'lodash'
import { DataTableColumn } from 'mantine-datatable'
import { forwardRef, useEffect, useMemo, useState, useRef } from 'react'

import CustomTable from '../../components/NewcustomTable'
import { useAppSelector } from '../../hooks'
import { API_SEVICES } from '../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
  ROLEPROVIDED,
  BRANDID,
  STOREID,
} from '../../constants'
import { openSnackbar } from '../../redux/snackbar'
import KnowlarityModelForm from './KnowlarityModelForm'
import { useAppDispatch } from '../../redux/store'
import DeleteModal from '../../components/DeleteModal'
import KnowlarityViewModal from './KnowlarityViewModel'

interface ItemProps extends SelectItemProps {
  _id: any
  name: string
}
export default function KnowlarityMapping() {
  const [searchParams, setSearchParams] = useSearchParams()
  const mode = searchParams.get('mode')
  const [modelState, setModel] = useState(false)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [recordData, setRecordData] = useState([])
  const [page, setPage] = useState(1)
  const [pageNo, setPageNo] = useState(1)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [totalRowSize, setTotalRowSize]: any = useState(10)
  const [countFrom, setCountForm] = useState<any>()
  const [countTo, setCountTo] = useState<any>()
  const [pageSize, setPageSize] = useState(PAGESIZES[0])
  const [tableHeight, setTableHeight] = useState(500)
  const filterRef = useRef<HTMLInputElement>(null)
  const [storeVal, setStoreVal] = useState<any>(null)
  const [brandVal, setBrandVal] = useState<any>(null)
  const [brandData, setBrandData] = useState([])
  const [storeData, setStoreData] = useState([])
  const deleteCity = API_ENDPOINTS.DELETE_CALLMAP
  const deletedData = API_ENDPOINTS.GET_CALLMAP
  const deleteName = 'Mapping Number'
  const dispatch = useAppDispatch()
  const role = useAppSelector((stat) => stat.role)
  const brandID = role.brandId || BRANDID || null
  const storeProvided = role.storeId || STOREID || null
  const navigate = useNavigate()

  const roleProvided = role.role || ROLEPROVIDED
  const handleClose = () => {
    setModel(false)
    setDeleteOpen(false)
    setSearchParams({})
  }

  const fetchData = () => {
    setLoading(true)
    let match
    if (brandVal === null && storeVal === null) {
      if (roleProvided === 'admin' && brandID) {
        match = { brand: brandID }
      } else if (roleProvided === 'admin' && brandID === null) {
        match = { brand: null, _id: null }
      } else if (roleProvided === 'CRM manager' && brandID) {
        match = { brand: brandID }
      } else if (roleProvided === 'CRM manager' && brandID === null) {
        match = { brand: null, _id: null }
      } else if (roleProvided === 'user') {
        match = { brand: brandID, store: storeProvided }
      } else if (roleProvided === 'brand manager') {
        match = { brand: brandID }
      } else {
        match = {}
      }
    } else if (roleProvided === 'admin') {
      match = {
        brand: brandID,
        store: storeVal,
      }
    } else if (roleProvided === 'CRM manager') {
      match = {
        brand: brandVal,
        store: storeVal,
      }
    } else if (roleProvided === 'brand manager') {
      match = {
        brand: brandID,
        store: storeVal,
      }
    } else {
      match = {
        brand: brandVal,
        store: storeVal,
      }
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_CALLMAP}?page=${page}&size=${pageSize}&match=${match}`,
      (res: AxiosResponse) => {
        setLoading(false)
        setTotalRowSize(res.data.TotalRecords)

        setRecordData(res.data.data)
      },
      (err: AxiosError) => {
        setLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Call Map Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )
  }
  useEffect(() => {
    setLoading(true)
    let match

    if (brandVal === null && storeVal === null) {
      if (roleProvided === 'admin' && brandID) {
        match = { brand: brandID }
      } else if (roleProvided === 'admin' && brandID === null) {
        match = { brand: null, _id: null }
      } else if (roleProvided === 'CRM manager' && brandID) {
        match = { brand: brandID }
      } else if (roleProvided === 'CRM manager' && brandID === null) {
        match = { brand: null, _id: null }
      } else if (roleProvided === 'user') {
        match = { brand: brandID, store: storeProvided }
      } else if (roleProvided === 'brand manager') {
        match = { brand: brandID }
      } else {
        match = {}
      }
    } else if (roleProvided === 'admin') {
      match = {
        brand: brandID,
        store: storeVal,
      }
    } else if (roleProvided === 'CRM manager') {
      match = {
        brand: brandVal,
        store: storeVal,
      }
    } else if (roleProvided === 'brand manager') {
      match = {
        brand: brandID,
        store: storeVal,
      }
    } else {
      match = {
        brand: brandVal,

        store: storeVal,
      }
    }
    const clean = (obj: any) => {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
      return obj
    }
    const mat = clean(match)

    const match1 = JSON.stringify(mat)

    const controller = new AbortController()
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_CALLMAP}?page=${page}&size=${pageSize}&match=${match1}`,
      (res: AxiosResponse) => {
        setLoading(false)
        setTotalRowSize(res.data.TotalRecords)
        setRecordData(res.data.data)
      },
      (err: AxiosError) => {
        setLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Call Map Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          setLoading(false)
        }
        console.error(err)
      },
      controller.signal
    )
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
  }, [page, pageSize, brandVal, storeVal])

  const colDef: DataTableColumn<any>[] = useMemo(
    () => [
      {
        Header: 'Actions',
        accessor: 'actions',
        fixed: 'left',
        title: <Text mr="xs">Actions</Text>,
        textAlignment: 'center',
        width: 120,
        minWidth: 100,
        Cell: ({ ...row }) => (
          <Menu shadow="md" width={130} position="right-start">
            <Menu.Target>
              <Button style={{ background: 'none' }}>
                {' '}
                <IconDotsVertical size={16} color="black" />
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                color="green"
                icon={<IconEyeCheck size={14} />}
                onClick={() => {
                  setOpen(true)
                  setSearchParams({
                    mode: 'view',
                    id: row?.row?.original._id,
                  })
                }}
              >
                View
              </Menu.Item>
              <Menu.Item
                color="blue"
                onClick={() => {
                  setModel(true)
                  setSearchParams({
                    mode: 'edit',
                    id: row?.row?.original._id,
                  })
                }}
                icon={<IconEdit size={14} />}
                disabled={
                  roleProvided === 'user' || roleProvided === 'brand manager'
                }
              >
                Edit
              </Menu.Item>
              <Menu.Item
                color="red"
                onClick={() => {
                  setDeleteOpen(true)
                  setSearchParams({
                    mode: 'delete',
                    id: row?.row?.original._id,
                  })
                }}
                icon={<IconTrash size={14} />}
                disabled={
                  roleProvided === 'user' || roleProvided === 'brand manager'
                }
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ),
      },
      {
        accessor: 'store.storeId',
        Header: 'Store Id',
        textAlignment: 'center',
        minWidth: 150,
        width: 180,
      },
      {
        accessor: 'store.storeName',
        Header: 'Store Name',
        textAlignment: 'center',
        minWidth: 250,
        width: 250,
      },
      {
        accessor: 'brand.name',
        Header: 'Brand Name',
        textAlignment: 'center',
        minWidth: 150,
        width: 150,
      },
      {
        accessor: 'store.city',
        Header: 'City',
        textAlignment: 'center',
        minWidth: 150,
        width: 150,
      },
      {
        accessor: 'mappingNumber',
        Header: 'Virtual Phone Number',
        textAlignment: 'center',
        minWidth: 150,
        width: 250,
      },
    ],
    [setSearchParams, roleProvided]
  )
  useEffect(() => {
    const controller = new AbortController()
    let brandMatch
    if (brandID.length === 0) {
      brandMatch = JSON.stringify({ status: true })
    } else {
      brandMatch = JSON.stringify({ _id: brandID, status: true })
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${brandMatch}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Brand Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )

    let storeMatch
    if (brandID === null && roleProvided === 'admin') {
      storeMatch = JSON.stringify({
        brandId: '',
      })
    } else if (brandID === null && roleProvided === 'CRM manager') {
      storeMatch = JSON.stringify({
        brandId: '',
      })
    } else if (roleProvided === 'CRM manager' && brandVal) {
      storeMatch = JSON.stringify({
        brandId: brandVal,
        status: true,
      })
    } else if (brandVal !== null) {
      storeMatch = JSON.stringify({ brandId: brandVal, status: true })
    } else if (brandID.length > 0) {
      storeMatch = JSON.stringify({ brandId: brandID, status: true })
    } else {
      storeMatch = JSON.stringify({ status: true })
    }

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_STORE}?match=${storeMatch}`,
      (res: AxiosResponse) => {
        setStoreData(res.data.data)
      },
      (err: AxiosError) => {
        // if (err.message !== 'canceled') {
        //   dispatch(
        //     openSnackbar({
        //       messege: get(
        //         err,
        //         'response.data.message',
        //         'Store Details Cant be Fetched'
        //       ),
        //       icon: AlertIcons.error,
        //       title: 'error',
        //       color: AlertColor.error,
        //     })
        //   )
        // }
        console.error(err)
      },
      controller.signal
    )
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
  }, [brandVal, storeVal])

  const brand = brandData.map((item: any) => ({
    ...item,
    label: item.name,
    value: item._id,
  }))
  const storeDetails = storeData.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.storeName,
    description: item.locality,
    cityName: item.city,
  }))

  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ value, label, name, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
          </div>
        </Group>
      </div>
    )
  )
  useEffect(() => {
    if (filterRef.current != null) {
      setTableHeight(window.innerHeight - filterRef.current.clientHeight - 110)
    }

    if (totalRowSize > pageSize) {
      const from = (page - 1) * pageSize
      const to = from + pageSize
      setCountForm(from + 1)
      setCountTo(to)
    } else {
      setCountForm(1)
      setCountTo(totalRowSize)
    }
    let rowDataSize

    if (totalRowSize >= pageSize) {
      rowDataSize = totalRowSize / pageSize

      if (rowDataSize - Math.floor(rowDataSize) !== 0) {
        setPageNo(Math.ceil(rowDataSize))
      } else {
        setPageNo(rowDataSize)
      }
    } else {
      rowDataSize = Math.ceil(totalRowSize / pageSize)
      setPageNo(rowDataSize)
    }
  }, [page, pageSize, recordData, totalRowSize])

  return (
    <>
      <Box>
        <Paper shadow="xs" p="md">
          <Grid>
            <Grid.Col xs={12} sm={7} md={7} lg={8.5} xl={8.5} ref={filterRef}>
              <Title order={3} weight={100} style={{ marginTop: ' 13px' }}>
                Mapping Virtual Number
              </Title>
            </Grid.Col>
            {roleProvided !== 'user' ? (
              <Grid.Col xs={12} sm={2} md={2} lg={1.5} xl={1.5}>
                <Popover width={330} position="bottom" withArrow shadow="xl">
                  <Popover.Target>
                    <Button
                      style={{ width: '100%' }}
                      gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                    >
                      <IconFilter />
                      <span style={{ marginLeft: '10px' }}>Filter</span>
                    </Button>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      {' '}
                      {roleProvided === 'CRM manager' ||
                      roleProvided === 'super admin' ? (
                        <Select
                          label="Select Brand"
                          placeholder="Select Brand"
                          itemComponent={SelectItem}
                          data={brand}
                          limit={brand.length}
                          maxDropdownHeight={200}
                          searchable
                          value={brandVal}
                          nothingFound="No Data Found"
                          clearable
                          onChange={(value: any) => {
                            setLoading(true)
                            setPage(1)
                            setPageNo(1)
                            setBrandVal(value)
                            setStoreVal(null)
                            setRecordData([])
                          }}
                          styles={(theme) => ({
                            item: {
                              // applies styles to selected item
                              '&[data-selected]': {
                                '&, &:hover': {
                                  backgroundColor:
                                    theme.colorScheme === 'dark'
                                      ? theme.colors.teal[9]
                                      : theme.colors.teal[1],
                                  color:
                                    theme.colorScheme === 'dark'
                                      ? theme.white
                                      : theme.colors.teal[9],
                                },
                              },

                              // applies styles to hovered item (with mouse or keyboard)
                              '&[data-hovered]': {},
                            },
                          })}
                        />
                      ) : null}
                    </Grid.Col>
                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      {roleProvided === 'super admin' ||
                      (brandID !== null && roleProvided === 'admin') ||
                      (brandID !== null && roleProvided === 'CRM manager') ||
                      (brandID !== null && roleProvided === 'brand manager') ? (
                        <Select
                          label="Select Store"
                          placeholder="Select store"
                          clearable
                          searchable
                          itemComponent={SelectItem}
                          data={storeDetails}
                          limit={storeDetails.length}
                          maxDropdownHeight={200}
                          nothingFound="No Data Found"
                          value={storeVal}
                          disabled={storeData.length === 0}
                          onChange={(value: any) => {
                            setPage(1)
                            setPageNo(1)
                            setStoreVal(value)
                            setRecordData([])
                          }}
                          styles={(theme) => ({
                            item: {
                              // applies styles to selected item
                              '&[data-selected]': {
                                '&, &:hover': {
                                  backgroundColor:
                                    theme.colorScheme === 'dark'
                                      ? theme.colors.teal[9]
                                      : theme.colors.teal[1],
                                  color:
                                    theme.colorScheme === 'dark'
                                      ? theme.white
                                      : theme.colors.teal[9],
                                },
                              },

                              // applies styles to hovered item (with mouse or keyboard)
                              '&[data-hovered]': {},
                            },
                          })}
                        />
                      ) : null}
                    </Grid.Col>
                  </Popover.Dropdown>
                </Popover>
              </Grid.Col>
            ) : null}
            {roleProvided === 'super admin' ? (
              <Grid.Col xs={12} sm={3} md={3} lg={2} xl={2}>
                <Button
                  variant="gradient"
                  gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                  leftIcon={<IconCirclePlus size={18} />}
                  onClick={() => {
                    setModel(true)
                    setSearchParams({ mode: 'add' })
                  }}
                  style={{ width: '100%' }}
                  // sx={{ display: 'flex', justifyContent: 'right' }}
                >
                  Add Number
                </Button>
              </Grid.Col>
            ) : (
              <Grid.Col xs={12} sm={3} md={3} lg={2} xl={2} />
            )}

            <Grid.Col xs={12}>
              <CustomTable
                pageFrom={countFrom}
                pageTo={countTo}
                total={pageNo}
                gridApi={(instance) => {}}
                totalRecords={!loading ? totalRowSize : 1}
                checkboxSelection={false}
                recordsPerPage={pageSize}
                page={page}
                height={tableHeight}
                onPageChange={(p: any) => setPage(p)}
                recordsPerPageOptions={PAGESIZES}
                onRecordsPerPageChange={setPageSize}
                loading={!!loading}
                rowData={!loading ? recordData : []}
                colDef={colDef}
              />
            </Grid.Col>
          </Grid>
        </Paper>
      </Box>

      <Modal
        style={{
          fontSize: '18px',
          textTransform: 'capitalize',
          fontWeight: 500,
        }}
        opened={modelState}
        centered
        onClose={() => {
          setModel(false)
          setSearchParams({})
        }}
        title={`${mode} Virtual Number`}
        size="lg"
      >
        <KnowlarityModelForm
          fetchData={() => fetchData()}
          onClose={() => {
            setSearchParams({})
            handleClose()
          }}
          mode={searchParams.get('mode')}
          isEditMode={searchParams.get('mode')?.toLowerCase() === 'edit'}
          isViewMode={searchParams.get('mode')?.toLowerCase() === 'view'}
        />
      </Modal>

      <Modal
        opened={deleteOpen}
        centered
        onClose={() => {
          setDeleteOpen(false)
        }}
        title="Delete MappingNumber"
        size="lg"
      >
        <DeleteModal
          deleteName={deleteName}
          fetchData={fetchData}
          onClose={handleClose}
          deleteApi={deleteCity}
          deletedGetApi={deletedData}
        />
      </Modal>
      <Modal
        opened={open}
        centered
        onClose={() => {
          setOpen(false)
          setSearchParams({})
        }}
        title="View MappingNumber Details"
        size="lg"
      >
        <KnowlarityViewModal onClose={handleClose} />
      </Modal>
    </>
  )
}
