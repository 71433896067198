import { DEFAULT_THEME, LoadingOverlay } from '@mantine/core'

interface Iprops {
  height?: string
  width?: string
}
export default function SpinningLoader({
  height = '45',
  width = '45',
}: Iprops) {
  return (
    <LoadingOverlay
      loader={
        <svg
          width={width}
          height={height}
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          // stroke={DEFAULT_THEME.colors.blue[6]}
          stroke={DEFAULT_THEME.colors.teal[5]}
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth="2">
              <circle strokeOpacity=".8" cx="18" cy="18" r="16" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      }
      visible
    />
  )
}
