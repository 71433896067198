import { forwardRef, useEffect, useMemo, useState } from 'react'
import {
  Grid,
  TextInput,
  Button,
  Loader,
  SelectItemProps,
  Group,
  Text,
  Select,
  createStyles,
} from '@mantine/core'
import { get } from 'lodash'
import { AxiosError, AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSearchParams, useNavigate } from 'react-router-dom'
import {
  AlertColor,
  AlertIcons,
  API_ENDPOINTS,
  BRANDID,
  ROLEPROVIDED,
} from '../../../constants'
import { API_SEVICES } from '../../../config/services'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { openSnackbar } from '../../../redux/snackbar'
import SpinningLoader from '../../../components/SpinningLoader'

interface IProps {
  fetchData: () => void
  onClose: () => void
  mode: any
  isEditMode: any
  isViewMode: any
}
interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  cityName: string
  description: string
  storeId: string
}
const useStyles = createStyles({
  formikError: {
    color: '#CC0000',
    fontSize: '13px',
  },
  outline: {
    border: 'none',
    outline: 'none !important',
  },
})
function KnowlarityForm(props: IProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { onClose, mode, isEditMode, isViewMode, fetchData } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [state, setState] = useState([])
  const [store, setStore] = useState([])
  const { classes } = useStyles()
  const role = useAppSelector((stat) => stat.role)
  const roleProvided = role.role || ROLEPROVIDED || null
  const brandID = role.brandId || BRANDID || null

  const formik = useFormik({
    initialValues: {
      brand: '',
      store: '',
      mappingNumber: '',
    },
    onSubmit: (values) => {
      setLoading1(true)
      !isEditMode || isViewMode
        ? API_SEVICES.PostObjRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.POST_CALLMAP}`,
            (res: AxiosResponse) => {
              setLoading1(false)
              formik.resetForm()
              dispatch(
                openSnackbar({
                  messege: 'Virtual Number Added Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              if (
                get(err, 'response.status', '') === 401 ||
                get(err, 'response.status', '') === 403
              ) {
                localStorage.clear()
                navigate('/login', { replace: true })
              }
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'Virtual Number cant be Added '
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              console.error(err)
            },
            values
          )
        : API_SEVICES.PatchObjRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.PATCH_CALLMAP}?id=${id}`,
            (res: AxiosResponse) => {
              setLoading1(false)
              formik.resetForm()
              dispatch(
                openSnackbar({
                  messege: 'Virtual Number Updated Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              if (
                get(err, 'response.status', '') === 401 ||
                get(err, 'response.status', '') === 403
              ) {
                localStorage.clear()
                navigate('/login', { replace: true })
              }
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'Virtual Number cant be Updated '
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              console.error(err)
            },
            values
          )
    },

    validationSchema: Yup.object({
      mappingNumber: Yup.string()
        .matches(/(\d+91)?[5-9]\d{1}?\d{9}\d/, {
          message: 'Virtual Phone number is not valid',
        })
        .required('Virtual Phone number is required')
        .min(13)
        .max(13),
    }),
  })
  useEffect(() => {
    if (id) {
      setLoading(true)
      const match = {
        _id: id,
      }

      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_ENDPOINTS.GET_CALLMAP
        }?match=${JSON.stringify(match)}`,
        (res: AxiosResponse) => {
          setLoading(false)
          formik.setFieldValue('store', get(res, 'data.data[0].store._id', ''))
          formik.setFieldValue('brand', get(res, 'data.data[0].brand._id', ''))
          formik.setFieldValue(
            'mappingNumber',
            get(res, 'data.data[0].mappingNumber', '')
          )
        },
        (err: AxiosError) => {
          setLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  useEffect(() => {
    let match
    if (roleProvided === 'super admin') {
      match = JSON.stringify({ status: true })
    } else {
      match = JSON.stringify({ _id: brandID, status: true })
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${match}`,
      (res: AxiosResponse) => {
        setState(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Brand Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )

    if (formik.values.brand !== '') {
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_ENDPOINTS.GET_STORE
        }?match=${JSON.stringify({
          status: 'true',
          brandId: formik.values.brand || {},
        })}`,
        (res: AxiosResponse) => {
          setStore(res.data.data)
        },
        (err: AxiosError) => {
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Store Details cant be Fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          console.error(err)
        }
      )
    }
  }, [brandID, dispatch, formik.values.brand, navigate, roleProvided])
  const brandData = state.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.name,
  }))

  const storeData = useMemo(
    () =>
      store.map((item: any) => ({
        ...item,
        value: item._id,
        label: item.storeName,
        description: item.locality,
        cityName: item.city,
      })),
    [store]
  )

  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    (
      {
        value,
        label,
        name,
        description,
        cityName,
        storeId,
        ...others
      }: ItemProps,
      ref
    ) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Grid sx={{ marginBottom: '3px' }}>
              <Text size="sm" sx={{ marginRight: '5px' }}>
                {label}
              </Text>{' '}
              {storeId ? (
                <Text
                  size="xs"
                  opacity={0.65}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  - {storeId}
                </Text>
              ) : (
                ''
              )}
            </Grid>
            {description || cityName ? (
              <Text size="xs" opacity={0.65}>
                {description}-{cityName}
              </Text>
            ) : null}
          </div>
        </Group>
      </div>
    )
  )

  return (
    <div
      className={classes.outline}
      role="button"
      tabIndex={0}
      onKeyDown={(e: any) => {
        if (e.key === 'Enter') {
          formik.handleSubmit()
        }
      }}
    >
      <Grid sx={{ minHeight: '200px' }}>
        {loading ? (
          <SpinningLoader />
        ) : (
          <>
            {isEditMode ? (
              <Text fz="sm" style={{ fontSize: '15px', margin: '8px' }}>
                Store Name : {formik.values.store}
              </Text>
            ) : (
              ''
            )}
            <Grid.Col xs={12}>
              <Select
                label="Select Brand"
                placeholder="Select Brand"
                itemComponent={SelectItem}
                data={brandData}
                limit={brandData.length}
                searchable
                clearable
                withAsterisk
                value={formik.values.brand}
                maxDropdownHeight={200}
                nothingFound="No Store Found"
                onChange={(value: any) => {
                  formik.setFieldValue('brand', value)
                  formik.setFieldValue('store', '')
                  setStore([])
                }}
                styles={(theme) => ({
                  item: {
                    // applies styles to selected item
                    '&[data-selected]': {
                      '&, &:hover': {
                        backgroundColor:
                          theme.colorScheme === 'dark'
                            ? theme.colors.teal[9]
                            : theme.colors.teal[1],
                        color:
                          theme.colorScheme === 'dark'
                            ? theme.white
                            : theme.colors.teal[9],
                      },
                    },

                    // applies styles to hovered item (with mouse or keyboard)
                    '&[data-hovered]': {},
                  },
                })}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <Select
                label="Select Store"
                placeholder="Select Store"
                itemComponent={SelectItem}
                data={storeData}
                limit={storeData.length}
                disabled={storeData.length === 0}
                searchable
                clearable
                withAsterisk
                value={formik.values.store}
                maxDropdownHeight={200}
                nothingFound="No Store Found"
                onChange={(value: any) => {
                  formik.setFieldValue('store', value)
                }}
                styles={(theme) => ({
                  item: {
                    // applies styles to selected item
                    '&[data-selected]': {
                      '&, &:hover': {
                        backgroundColor:
                          theme.colorScheme === 'dark'
                            ? theme.colors.teal[9]
                            : theme.colors.teal[1],
                        color:
                          theme.colorScheme === 'dark'
                            ? theme.white
                            : theme.colors.teal[9],
                      },
                    },

                    // applies styles to hovered item (with mouse or keyboard)
                    '&[data-hovered]': {},
                  },
                })}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <TextInput
                placeholder="+919876543210"
                name="mappingNumber"
                id="mappingNumber"
                label="Virtual Number"
                withAsterisk
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mappingNumber}
              />
              {formik.errors.mappingNumber && formik.touched.mappingNumber && (
                <div className={classes.formikError}>
                  {formik.errors.mappingNumber}
                </div>
              )}
            </Grid.Col>

            <Grid.Col xs={2} offset={8}>
              <Button
                variant="gradient"
                gradient={{ from: 'orange', to: 'red' }}
                onClick={onClose}
                disabled={loading1}
              >
                Cancel
              </Button>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Button
                variant="gradient"
                gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                onClick={() => {
                  formik.handleSubmit()
                }}
                className={
                  !(formik.dirty && formik.isValid) ? 'disabled-btn' : ''
                }
                disabled={!(formik.dirty && formik.isValid)}
                rightIcon={loading1 ? <Loader size={14} color="white" /> : ''}
                loading={loading1}
                loaderPosition="right"
                style={{ marginLeft: '5px' }}
              >
                {isEditMode ? 'Update' : 'Save'}
              </Button>
            </Grid.Col>
          </>
        )}
      </Grid>
    </div>
  )
}
export default KnowlarityForm
