/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, KeyboardEvent, forwardRef } from 'react'
import {
  Grid,
  TextInput,
  Button,
  Checkbox,
  Loader,
  Autocomplete,
  Text,
  SelectItemProps,
  MantineColor,
  createStyles,
  Group,
} from '@mantine/core'
import { get } from 'lodash'

import { AxiosError, AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { AlertColor, AlertIcons, API_ENDPOINTS } from '../../../constants'
import { API_SEVICES } from '../../../config/services'
import { useAppDispatch } from '../../../redux/store'
import { openSnackbar } from '../../../redux/snackbar'
import SpinningLoader from '../../../components/SpinningLoader'

interface IProps {
  fetchData: () => void
  onClose: () => void
  mode: any
  isEditMode: any
  isViewMode: any
}
interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}
const useStyles = createStyles({
  formikError: {
    color: '#CC0000',
    fontSize: '13px',
  },
  outline: {
    border: 'none',
    outline: 'none !important',
  },
})

function CategoryModelForm(props: IProps) {
  const dispatch = useAppDispatch()
  const { onClose, mode, isEditMode, isViewMode, fetchData } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [categories, setCategories] = useState([])
  const { classes } = useStyles()
  const navigate = useNavigate()
  useEffect(() => {
    setLoading(true)
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${
        API_ENDPOINTS.GET_TYPE
      }?match=${JSON.stringify({
        status: 'true',
      })}`,
      (res: AxiosResponse) => {
        setLoading(false)
        setCategories(res.data.data)
      },
      (err: AxiosError) => {
        setLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Type Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )
  }, [])

  const data = categories.map((item: any) => ({
    ...item,
    value: item.label,
  }))
  const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ description, value, image, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text>{value}</Text>
          </div>
        </Group>
      </div>
    )
  )
  AutoCompleteItem.displayName = 'AutoCompleteItem'
  const formik = useFormik({
    initialValues: {
      label: '',
      parentCategory: '',
      status: true,
      isSubCategory: false,
    },
    onSubmit: (values) => {
      setLoading1(true)
      !isEditMode || isViewMode
        ? API_SEVICES.PostObjRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.CATEGORY_DETAILS}`,
            (res: AxiosResponse) => {
              setLoading1(false)
              formik.resetForm()
              dispatch(
                openSnackbar({
                  messege: 'Category Added Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'Type Details Cant be Posted'
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              if (
                get(err, 'response.status', '') === 401 ||
                get(err, 'response.status', '') === 403
              ) {
                localStorage.clear()
                navigate('/login', { replace: true })
              }
              console.error(err)
            },
            values
          )
        : API_SEVICES.PatchObjRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.CATEGORY_DETAILS}?id=${id}`,
            (res: AxiosResponse) => {
              formik.resetForm()
              setLoading1(false)
              dispatch(
                openSnackbar({
                  messege: 'Category Updated Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'Type Details Cant be Updated'
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              if (
                get(err, 'response.status', '') === 401 ||
                get(err, 'response.status', '') === 403
              ) {
                localStorage.clear()
                navigate('/login', { replace: true })
              }
              console.error(err)
            },
            values
          )
    },
    validationSchema: Yup.object({
      label: Yup.string().required('Category Name is Required'),
      parentCategory: Yup.string().required('Type is Required'),
    }),
  })
  useEffect(() => {
    if (id) {
      setLoading(true)
      const match = {
        _id: id,
      }
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_ENDPOINTS.CATEGORY_DETAILS
        }?match=${JSON.stringify(match)}`,
        (res: AxiosResponse) => {
          setLoading(false)
          formik.setFieldValue('label', get(res, 'data.data[0].label', ''))
          formik.setFieldValue(
            'parentCategory',
            get(res, 'data.data[0].parentCategory', '')
          )
          formik.setFieldValue('status', get(res, 'data.data[0].status', ''))
          formik.setFieldValue(
            'isSubCategory',
            get(res, 'data.data[0].isSubCategory', '')
          )
        },
        (err: AxiosError) => {
          setLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div
      className={classes.outline}
      role="button"
      tabIndex={0}
      onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
          formik.handleSubmit()
        }
      }}
    >
      <Grid sx={{ minHeight: '200px' }}>
        {loading ? (
          <SpinningLoader />
        ) : (
          <>
            {isEditMode ? (
              <Text fz="sm" style={{ fontSize: '15px', margin: '8px' }}>
                Category Name : {formik.values.label}
              </Text>
            ) : (
              ''
            )}
            <Grid.Col xs={12}>
              <Autocomplete
                label="Type"
                placeholder="Select Type"
                data={data}
                withAsterisk
                limit={data.length}
                maxDropdownHeight={200}
                itemComponent={AutoCompleteItem}
                defaultValue={formik.values.parentCategory}
                onBlur={formik.handleBlur}
                onItemSubmit={(item: any) => {
                  formik.setFieldValue('parentCategory', item.label)
                }}
              />
              {formik.errors.parentCategory &&
                formik.touched.parentCategory && (
                  <div className={classes.formikError}>
                    {formik.errors.parentCategory}
                  </div>
                )}
            </Grid.Col>
            <Grid.Col xs={12}>
              <TextInput
                placeholder="Category Name"
                name="label"
                id="label"
                label="Category"
                withAsterisk
                onChange={formik.handleChange}
                value={formik.values.label}
                onBlur={formik.handleBlur}
              />
              {formik.errors.label && formik.touched.label && (
                <div className={classes.formikError}>{formik.errors.label}</div>
              )}
            </Grid.Col>
            <Grid.Col xs={3}>
              <Checkbox
                checked={formik.values.status}
                required
                name="status"
                label={formik.values.status === true ? 'Active' : 'Inactive'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid.Col>
            <Grid.Col xs={3}>
              <Checkbox
                checked={formik.values.isSubCategory}
                required
                name="isSubCategory"
                label="SubCategory"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid.Col>
            <Grid.Col xs={2} style={{ marginLeft: '75px' }}>
              <Button
                variant="gradient"
                gradient={{ from: 'orange', to: 'red' }}
                onClick={onClose}
                disabled={loading1}
              >
                Cancel
              </Button>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Button
                variant="gradient"
                gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                onClick={() => {
                  formik.handleSubmit()
                }}
                className={
                  !(formik.dirty && formik.isValid) ? 'disabled-btn' : ''
                }
                disabled={!(formik.dirty && formik.isValid)}
                rightIcon={loading1 ? <Loader size={14} color="white" /> : ''}
                loading={loading1}
                style={{ marginLeft: '5px' }}
                loaderPosition="right"
              >
                {isEditMode ? 'Update' : 'Save'}
              </Button>
            </Grid.Col>
          </>
        )}
      </Grid>
    </div>
  )
}
export default CategoryModelForm
