import { getLocalStorageItem } from '../utils/localStorage'

interface Iprops {
  role: Object | unknown
}

const useAuth = (role: Iprops) => {
  const token = getLocalStorageItem('token')
  const roleGiven = getLocalStorageItem('role')
  if (roleGiven === role.role && token) {
    return true
  }
  return false
}

export default useAuth
