import { useEffect, useState } from 'react'
import { Text, Button, createStyles } from '@mantine/core'
import { AxiosError, AxiosResponse } from 'axios'
import { get } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { API_SEVICES } from '../../config/services'
import { AlertColor, AlertIcons } from '../../constants'
import { useAppDispatch } from '../../redux/store'
import { openSnackbar } from '../../redux/snackbar'
import SpinningLoader from '../SpinningLoader'

// Dropzone Styles
const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
}))
interface IProps {
  fetchData: () => void
  onClose: () => void
  deletedGetApi: any
  deleteApi: any
  deleteName: any
}
export default function DeleteModal(props: IProps) {
  const { onClose, deleteApi, deletedGetApi, fetchData, deleteName } = props
  const { classes } = useStyles()
  const [searchParams, setSearchParams] = useSearchParams()
  const [deletedData, setDeletedData] = useState([])
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const id = searchParams.get('id')
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (id) {
      setLoading(true)
      const match = {
        _id: id,
      }
      API_SEVICES.GetRequest(
        `${
          process.env.REACT_APP_BACKEND_API_PROXY
        }${deletedGetApi}?match=${JSON.stringify(match)}`,
        (res: AxiosResponse) => {
          setLoading(false)

          setDeletedData(res.data.data)
        },
        (err: AxiosError) => {
          console.error(err)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div className={classes.wrapper}>
      {loading ? (
        <SpinningLoader />
      ) : (
        <Text fz="sm" style={{ fontSize: '15px' }}>
          Are you sure want to delete this {deleteName}{' '}
          <span style={{ fontWeight: 500 }}>
            {get(deletedData[0], 'labe')
              ? get(deletedData[0], 'labe')
              : get(deletedData[0], 'label')}
            {get(deletedData, 'data[0].productName')
              ? get(deletedData, 'data[0].productName')
              : get(deletedData[0], 'storeName')}
            {get(deletedData[0], 'name')
              ? get(deletedData[0], 'name')
              : get(deletedData[0], 'firstName')}
            {get(deletedData[0], 'mappingNumber')
              ? get(deletedData[0], 'mappingNumber')
              : ''}
            {get(deletedData[0], 'keywords')
              ? get(deletedData[0], 'keywords')
              : ''}
          </span>{' '}
          ?
        </Text>
      )}
      <Button
        variant="gradient"
        gradient={{ from: 'orange', to: 'red' }}
        onClick={onClose}
        disabled={loading}
      >
        Cancel
      </Button>
      <Button
        style={{ marginTop: '30px', marginLeft: '10px' }}
        variant="gradient"
        gradient={{ from: 'teal', to: 'lime', deg: 105 }}
        loading={loading1}
        loaderPosition="right"
        onClick={() => {
          setLoading1(true)
          API_SEVICES.DeleteRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${deleteApi}?id=${id}`,
            (res: AxiosResponse) => {
              setLoading1(false)
              dispatch(
                openSnackbar({
                  messege: `${deleteName} Deleted Sucessfully`,
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading(false)
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    `${deleteName} Cant be Deleted`
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
            }
          )
        }}
      >
        Delete
      </Button>
    </div>
  )
}
