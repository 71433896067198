import { IconChartBar, IconUsers } from '@tabler/icons'
import { LinksGroupProps } from '../components/NavBarLinkGroups'

export const ROUTE_PATH = {
  home: '/CRMmanager',
  login: '/login',
  brand: '/CRMmanager/brand',
  type: '/CRMmanager/types',
  category: '/CRMmanager/category',
  users: '/CRMmanager/users',
  token: '/CRMmanager/token',
  store: '/CRMmanager/stores',
  product: '/CRMmanager/products',
  state: '/CRMmanager/state',
  city: '/CRMmanager/city',
  subCategory: '/CRMmanager/subCategory',
  knowlarity: '/CRMmanager/virtualphonenumber',
  calllog: '/CRMmanager/calllog',
  keywords: '/CRMmanager/keywords',
  action: '/CRMmanager/action',
  calllogChart: '/CRMmanager/calllogChart',
  callActions: '/CRMmanager/callActions',
  discovery: '/CRMmanager/discovery',
  monthlyReport: '/CRMmanager/monthlyReport',
}

// export const drawerOptions: LinksGroupProps[] = [
//   {
//     label: 'Dashboard',
//     icon: IconChartBar,
//     initiallyOpened: true,
//     links: [
//       { label: 'Exotel CallLog', link: ROUTE_PATH.exotelcalllog },
//       { label: 'Discovery', link: ROUTE_PATH.discovery },
//       { label: 'Action', link: ROUTE_PATH.callActions },
//       { label: 'Keywords', link: ROUTE_PATH.keywords },
//       { label: 'Monthly Report', link: ROUTE_PATH.monthlyReport },
//     ],
//   },
// ]

export const drawerOptions: LinksGroupProps[] = [
  {
    label: 'Admin',
    icon: IconUsers,
    initiallyOpened: true,
    brandData: '63e387a7f60befaca421836e',
    links: [
      {
        label: 'Brand',
        link: ROUTE_PATH.brand,
        brand: '63e387a7f60befaca421836e',
      },
      {
        label: 'Store',
        link: ROUTE_PATH.store,
        brand: '63e387a7f60befaca421836e',
      },
      {
        label: 'Product',
        link: ROUTE_PATH.product,
        brand: '63e387a7f60befaca421836e',
      },
      {
        label: 'Type',
        link: ROUTE_PATH.type,
        brand: '63e387a7f60befaca421836e',
      },
      {
        label: 'Category',
        link: ROUTE_PATH.category,
        brand: '63e387a7f60befaca421836e',
      },
      {
        label: 'SubCategory',
        link: ROUTE_PATH.subCategory,
        brand: '63e387a7f60befaca421836e',
      },
      {
        label: 'State',
        link: ROUTE_PATH.state,
        brand: '63e387a7f60befaca421836e',
      },
      {
        label: 'City',
        link: ROUTE_PATH.city,
        brand: '63e387a7f60befaca421836e',
      },
      {
        label: 'Virtual Phone No Mapping ',
        link: ROUTE_PATH.knowlarity,
        brand: '63e387a7f60befaca421836e',
      },
    ],
  },
  {
    label: 'Dashboard',
    icon: IconChartBar,
    initiallyOpened: false,
    links: [
      { label: 'CallLog', link: ROUTE_PATH.calllog },
      { label: 'Discovery', link: ROUTE_PATH.discovery },
      { label: 'Action', link: ROUTE_PATH.callActions },
      { label: 'Keywords', link: ROUTE_PATH.keywords },
      { label: 'Monthly Report', link: ROUTE_PATH.monthlyReport },
    ],
  },
]
