import {
  Box,
  Paper,
  Title,
  Grid,
  Button,
  Modal,
  Text,
  Menu,
} from '@mantine/core'
import {
  IconCirclePlus,
  IconEyeCheck,
  IconEdit,
  IconTrash,
  IconDotsVertical,
  IconCircleCheck,
  IconCircleX,
} from '@tabler/icons'
import { AxiosError, AxiosResponse } from 'axios'
import _, { get } from 'lodash'
import { DataTableColumn } from 'mantine-datatable'
import { useEffect, useMemo, useState, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { API_SEVICES } from '../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
  ROLEPROVIDED,
  BRANDID,
} from '../../constants'
import { openSnackbar } from '../../redux/snackbar'
import TypesModelForm from './TypesModelForm'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import DeleteModal from '../../components/DeleteModal'
import CustomTable from '../../components/NewcustomTable'
import TypeViewModal from './TypeViewModal'

export default function Types(params: any) {
  // eslint-disable-next-line react/prop-types
  const [searchParams, setSearchParams] = useSearchParams()
  const mode = searchParams.get('mode')
  const [modelState, setModel] = useState(false)
  const [rowData, setRowData] = useState([])
  const [loading, setLoading] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [countFrom, setCountForm] = useState<any>()
  const [countTo, setCountTo] = useState<any>()
  const [totalRowSize, setTotalRowSize]: any = useState(10)
  const [pageSize, setPageSize] = useState(PAGESIZES[0])
  const [tableHeight, setTableHeight] = useState(500)
  const [recordData, setRecordData] = useState([])
  const [page, setPage] = useState(1)
  const [pageNo, setPageNo] = useState(1)
  const [open, setOpen] = useState(false)
  const filterRef = useRef<HTMLInputElement>(null)
  const paperRef = useRef<HTMLInputElement>(null)
  const deleteType = API_ENDPOINTS.DELETE_TYPE
  const deletedData = API_ENDPOINTS.GET_TYPE
  const deleteName = 'type'
  const dispatch = useAppDispatch()

  const role = useAppSelector((state) => state.role)

  const roleProvided = role.role || ROLEPROVIDED
  const brandID = role.brandId || BRANDID || null

  const handleClose = () => {
    setModel(false)
    setDeleteOpen(false)
    setSearchParams({})
  }
  const fetchData = () => {
    setLoading(true)
    let match
    if (roleProvided === 'admin' && brandID === null) {
      match = JSON.stringify({ _id: null })
    } else if (roleProvided === 'CRM manager' && brandID === null) {
      match = JSON.stringify({ _id: null })
    } else {
      match = JSON.stringify({})
    }
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_TYPE}?pageNo=${page}&size=${pageSize}&match=${match}`,
      (res: AxiosResponse) => {
        setLoading(false)
        setTotalRowSize(res.data.TotalRecords)
        setRecordData(res.data.data)
        setRowData(res.data.data.slice(0, pageSize))
      },
      (err: AxiosError) => {
        setLoading(false)
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Type Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
      }
    )
  }
  useEffect(() => {
    setLoading(true)
    let match
    if (roleProvided === 'admin' && brandID === null) {
      match = JSON.stringify({ _id: null })
    } else if (roleProvided === 'CRM manager' && brandID === null) {
      match = JSON.stringify({ _id: null })
    } else {
      match = JSON.stringify({})
    }
    const controller = new AbortController()

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_TYPE}?pageNo=${page}&size=${pageSize}&match=${match}`,
      (res: AxiosResponse) => {
        setLoading(false)
        setTotalRowSize(res.data.TotalRecords)
        setRecordData(res.data.data)
        setRowData(res.data.data.slice(0, pageSize))
      },
      (err: AxiosError) => {
        setRecordData([])
        setRowData([])
        setTotalRowSize(0)
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Type Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          setLoading(false)
        }
        console.error(err)
      },
      controller.signal
    )
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize])

  useEffect(() => {
    if (filterRef.current != null) {
      const element = paperRef.current?.getClientRects()

      setTableHeight(window.innerHeight - filterRef.current.clientHeight - 110)
    }

    if (totalRowSize > pageSize) {
      const from = (page - 1) * pageSize
      const to = from + pageSize
      setCountForm(from + 1)
      setCountTo(to)
    } else {
      setCountForm(1)
      setCountTo(totalRowSize)
    }
    let rowDataSize

    if (totalRowSize >= pageSize) {
      rowDataSize = totalRowSize / pageSize

      if (rowDataSize - Math.floor(rowDataSize) !== 0) {
        setPageNo(Math.ceil(rowDataSize))
      } else {
        setPageNo(rowDataSize)
      }
    } else {
      rowDataSize = Math.ceil(totalRowSize / pageSize)
      setPageNo(rowDataSize)
    }
  }, [page, pageSize, recordData, totalRowSize])

  const colDef: DataTableColumn<any>[] = useMemo(
    () => [
      {
        Header: 'Actions',
        accessor: 'actions',
        fixed: 'left',
        title: <Text mr="xs">Actions</Text>,
        textAlignment: 'center',
        minWidth: 300,
        width: 350,

        Cell: ({ ...row }) => (
          <Menu shadow="md" width={130} position="right-start">
            <Menu.Target>
              <Button style={{ background: 'none' }}>
                {' '}
                <IconDotsVertical size={16} color="black" />
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                color="green"
                icon={<IconEyeCheck size={14} />}
                onClick={() => {
                  setOpen(true)
                  setSearchParams({
                    mode: 'view',
                    id: row?.row?.original._id,
                  })
                }}
              >
                View
              </Menu.Item>
              <Menu.Item
                color="blue"
                onClick={() => {
                  setModel(true)
                  setSearchParams({
                    mode: 'edit',
                    id: row?.row?.original._id,
                  })
                }}
                icon={<IconEdit size={14} />}
                disabled={
                  roleProvided === 'user' || roleProvided === 'brand manager'
                }
              >
                Edit
              </Menu.Item>
              <Menu.Item
                color="red"
                onClick={() => {
                  setDeleteOpen(true)
                  setSearchParams({
                    mode: 'delete',
                    id: row?.row?.original._id,
                  })
                }}
                icon={<IconTrash size={14} />}
                disabled={
                  roleProvided === 'user' || roleProvided === 'brand manager'
                }
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ),
      },
      {
        accessor: 'label',
        Header: 'Type',
        textAlignment: 'center',
        minWidth: 380,
        width: 450,
      },
      {
        accessor: 'status',
        Header: 'Status',
        textAlignment: 'center',
        minWidth: 300,
        width: 350,
        Cell: ({ ...status }) => {
          if (status.row.original.status === true) {
            return <IconCircleCheck color="green" size={30} />
          }
          return <IconCircleX color="#ff2825" size={30} />
        },
      },
    ],
    [roleProvided, setSearchParams]
  )

  return (
    <>
      <Box>
        <Paper shadow="xs" p="md">
          <Grid>
            <Grid.Col
              xs={12}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
              ref={filterRef}
            >
              <Title order={3} weight={100}>
                Type
              </Title>
              {roleProvided === 'super admin' ||
              (brandID !== null && roleProvided === 'admin') ||
              (brandID !== null && roleProvided === 'CRM manager') ? (
                <Grid>
                  <Grid.Col span={4}>
                    <Button
                      variant="gradient"
                      gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                      leftIcon={<IconCirclePlus size={18} />}
                      onClick={() => {
                        setModel(true)
                        setSearchParams({ mode: 'add' })
                      }}
                    >
                      Add Types
                    </Button>
                  </Grid.Col>
                </Grid>
              ) : null}
            </Grid.Col>

            <Grid.Col xs={12}>
              <CustomTable
                pageFrom={countFrom}
                pageTo={countTo}
                total={pageNo}
                gridApi={(instance) => {}}
                totalRecords={!loading ? totalRowSize : 1}
                checkboxSelection={false}
                recordsPerPage={pageSize}
                page={page}
                height={tableHeight}
                onPageChange={(p: any) => setPage(p)}
                recordsPerPageOptions={PAGESIZES}
                onRecordsPerPageChange={setPageSize}
                loading={!!loading}
                rowData={!loading ? recordData : []}
                colDef={colDef}
              />
            </Grid.Col>
          </Grid>
        </Paper>
      </Box>

      <Modal
        style={{
          fontSize: '18px',
          textTransform: 'capitalize',
          fontWeight: 500,
        }}
        opened={modelState}
        centered
        onClose={() => {
          setModel(false)
          setSearchParams({})
        }}
        title={`${mode} Type`}
        size="lg"
      >
        <TypesModelForm
          fetchData={() => fetchData()}
          onClose={() => {
            setSearchParams({})
            handleClose()
          }}
          mode={searchParams.get('mode')}
          isEditMode={searchParams.get('mode')?.toLowerCase() === 'edit'}
          isViewMode={searchParams.get('mode')?.toLowerCase() === 'view'}
        />
      </Modal>

      <Modal
        opened={deleteOpen}
        centered
        onClose={() => {
          setDeleteOpen(false)
        }}
        title="Delete Type"
        size="lg"
      >
        <DeleteModal
          deleteName={deleteName}
          fetchData={fetchData}
          onClose={handleClose}
          deleteApi={deleteType}
          deletedGetApi={deletedData}
        />
      </Modal>
      <Modal
        opened={open}
        centered
        onClose={() => {
          setOpen(false)
          setSearchParams({})
        }}
        title="View Type"
        size="lg"
      >
        <TypeViewModal onClose={handleClose} />
      </Modal>
    </>
  )
}
