import axios from 'axios'
import { getLocalStorageItem } from '../utils/localStorage'

export const API_SEVICES = {
  fetchWithParams: (
    EndPoint: string,
    sucessCallback: any,
    errorCallback: any,
    params: object,
    token?: string,
    contentType?: string
  ) => {
    const Token = getLocalStorageItem('token')

    axios
      .get(`${process.env.REACT_APP_BACKEND_API_PROXY}${EndPoint}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': contentType || 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        params: { ...params },
      })
      .then((resp) => {
        sucessCallback && sucessCallback(resp)
      })
      .catch((err) => {
        errorCallback && errorCallback(err)
      })
  },
  GetRequest: (
    EndPoint: string,
    sucessCallback: any,
    errorCallback: any,
    // contentType?: string,
    // token?: string,
    signal?: any
  ) => {
    const Token = getLocalStorageItem('token')

    axios
      .get(`${EndPoint}`, {
        signal: signal,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((resp) => {
        sucessCallback && sucessCallback(resp)
      })
      .catch((err) => {
        errorCallback && errorCallback(err)
      })
  },
  GetObjRequest: (
    EndPoint: string,
    sucessCallback: any,
    errorCallback: any,
    params: object,
    token?: string,
    contentType?: string
  ) => {
    const Token = getLocalStorageItem('token')
    axios
      .get(`${process.env.REACT_APP_BACKEND_API_PROXY}${EndPoint}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': contentType || 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        params: { ...params },
      })
      .then((resp) => {
        sucessCallback && sucessCallback(resp)
      })
      .catch((err) => {
        errorCallback && errorCallback(err)
      })
  },
  ExportFileRequest: (
    EndPoint: string,
    sucessCallback: any,
    errorCallback: any,
    contentType?: string,
    token?: string
  ) => {
    const Token = getLocalStorageItem('token')
    axios
      .get(`${EndPoint}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': contentType || 'text/csv',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((resp) => {
        sucessCallback && sucessCallback(resp)
      })
      .catch((err) => {
        errorCallback && errorCallback(err)
      })
  },
  PostRequest: (
    EndPoint: string,
    sucessCallback: any,
    errorCallback: any,
    values: any,
    signal?: any,
    contentType?: string,
    token?: string
  ) => {
    const Token = getLocalStorageItem('token')
    axios
      .post(EndPoint, values, {
        signal: signal,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': contentType || 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((resp) => {
        sucessCallback && sucessCallback(resp)
      })
      .catch((err) => {
        errorCallback && errorCallback(err)
      })
  },
  PostObjRequest: (
    EndPoint: string,
    sucessCallback: any,
    errorCallback: any,
    values: any,
    signal?: any,
    contentType?: string,
    token?: string
  ) => {
    const Token = getLocalStorageItem('token')
    axios
      .post(EndPoint, values, {
        signal: signal,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((resp) => {
        sucessCallback && sucessCallback(resp)
      })
      .catch((err) => {
        errorCallback && errorCallback(err)
      })
  },
  DeleteRequest: (
    EndPoint: string,
    // token: string,
    sucessCallback: any,
    errorCallback: any
    // values: any
  ) => {
    const Token = getLocalStorageItem('token')
    axios
      .delete(`${EndPoint}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        // data: { ...values },
      })
      .then((resp) => {
        sucessCallback && sucessCallback(resp)
      })
      .catch((err) => {
        errorCallback && errorCallback(err)
      })
  },
  DeleteRequestObj: (
    EndPoint: string,
    // token: string,
    sucessCallback: any,
    errorCallback: any,
    values: any
  ) => {
    const Token = getLocalStorageItem('token')
    axios
      .delete(`${EndPoint}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        data: values,
      })
      .then((resp) => {
        sucessCallback && sucessCallback(resp)
      })
      .catch((err) => {
        errorCallback && errorCallback(err)
      })
  },
  PutRequest: (
    EndPoint: string,
    sucessCallback: any,
    errorCallback: any,
    values: any,
    contentType?: string,
    token?: string
  ) => {
    const Token = getLocalStorageItem('token')
    axios
      .put(`${EndPoint}`, values, {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': contentType || 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((resp) => {
        sucessCallback && sucessCallback(resp)
      })
      .catch((err) => {
        errorCallback && errorCallback(err)
      })
  },
  PatchRequest: (
    EndPoint: string,
    sucessCallback: any,
    errorCallback: any,
    values: any,
    contentType?: string,
    token?: string
  ) => {
    const Token = getLocalStorageItem('token')
    axios
      .patch(`${EndPoint}`, values, {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': contentType || 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((resp) => {
        sucessCallback && sucessCallback(resp)
      })
      .catch((err) => {
        errorCallback && errorCallback(err)
      })
  },
  PatchObjRequest: (
    EndPoint: string,
    sucessCallback: any,
    errorCallback: any,
    values: any,
    contentType?: string,
    token?: string
  ) => {
    const Token = getLocalStorageItem('token')
    axios
      .patch(`${EndPoint}`, values, {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': contentType || 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((resp) => {
        sucessCallback && sucessCallback(resp)
      })
      .catch((err) => {
        errorCallback && errorCallback(err)
      })
  },
  login: (sucessCallback: any, errorCallback: any, values: any) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API_PROXY}/api/user/login`,
        values,
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          // timeout: 5000,
        }
      )
      .then((resp) => {
        sucessCallback && sucessCallback(resp)
      })
      .catch((err) => {
        errorCallback && errorCallback(err)
      })
  },
}
